import axios, { fileDownloader } from '@/utils/request.js'
import { toLine } from '@/utils/utils'
import { Order, SearchOps, ViewType } from '@/api/entity'

export * from '@/api/entity'

const app = '/unicorn-ticket'

export function ticketCount () {
  const url = `${app}/tickets/ticket_count`
  return axios({
    url,
    method: 'get'
  })
}

export function ticketList (type: ViewType, pageNumber: number, pageSize: number, ops: SearchOps = {}, filterField = {}) {
  const url = `${app}/tickets/ticket_list`
  const params: any = {
    viewType: type,
    page: pageNumber,
    limit: pageSize,
    filterField: JSON.stringify(filterField)

  }
  if (ops.keywords) {
    params.keywords = ops.keywords
  }
  if (ops.urgentLevel) {
    params.urgentLevel = ops.urgentLevel
  }
  if (ops.templateId) {
    params.templateId = ops.templateId
  }
  if (ops.processDuration) {
    params.processDuration = ops.processDuration
  }
  if (ops.workflow) {
    params.workflow = ops.workflow
  }
  if (ops.startTime) {
    params.startTime = ops.startTime
  }
  if (ops.endTime) {
    params.endTime = ops.endTime
  }
  if (ops.workType) {
    params.workType = ops.workType
  }
  if (ops.tagFilter) {
    params.tagFilter = ops.tagFilter
  }
  if (ops.orderField) {
    if (ops.orderField.prop === 'curPhase') {
      ops.orderField.prop = 'curPhaseId'
    }
    params.orderField = (ops.orderField.order === Order.ASCENDING ? '' : '-') + toLine(ops.orderField.prop)
  }
  return axios({
    url,
    method: 'get',
    params
  })
}

export function exportTicketList (type: ViewType, pageNumber: number, pageSize: number, ops: SearchOps = {}) : Promise<string> {
  const url = `${app}/tickets/ticket_list`
  const params: any = {
    viewType: type,
    page: pageNumber,
    limit: pageSize
  }
  if (ops.keywords) {
    params.keywords = ops.keywords
  }
  if (ops.urgentLevel) {
    params.urgentLevel = ops.urgentLevel
  }
  if (ops.startTime) {
    params.startTime = ops.startTime
  }
  if (ops.endTime) {
    params.endTime = ops.endTime
  }
  if (ops.workType) {
    params.workType = ops.workType
  }
  if (ops.tagFilter) {
    params.tagFilter = ops.tagFilter
  }
  if (ops.templateId) {
    params.templateId = ops.templateId
  }
  if (ops.processDuration) {
    params.processDuration = ops.processDuration
  }
  if (ops.workflow) {
    params.workflow = ops.workflow
  }
  if (ops.orderField) {
    if (ops.orderField.prop === 'curPhase') {
      ops.orderField.prop = 'curPhaseId'
    }
    params.orderField = (ops.orderField.order === Order.ASCENDING ? '' : '-') + toLine(ops.orderField.prop)
  }
  params.export = 1
  return fileDownloader({
    url,
    method: 'get',
    params
  })
}

export function ticketsAssignUpdate (ticketId: number, ldap: string, name: string, phaseId: any = undefined) {
  const url = `${app}/tickets/${ticketId}/assign/`
  const data: any = {
    responsibleAgent: ldap,
    responsibleAgentName: name,

  }
  if (phaseId) {
    data.phaseId = phaseId
  }
  return axios({
    url,
    method: 'put',
    data,
  })
}

export function transactCurDuePerson (ticketId: number, ldap: string, name: string, phaseId: any = undefined) {
  const url = `${app}/tickets/${ticketId}/change_assign/`
  const data: any = {
    responsibleAgent: ldap,
    responsibleAgentName: name,
  }
  if (phaseId) {
    data.phaseId = phaseId
  }
  return axios({
    url,
    method: 'put',
    data,
  })
}

export function ticketRestart (ticketId: number) {
  const url = `${app}/tickets/${ticketId}/restart/`
  const data: any = {}
  return axios({
    url,
    method: 'put',
    data,
  })
}

export function ticketDirectClose (ticketId: number) {
  const url = `${app}/tickets/${ticketId}/close/`
  const data: any = {}
  return axios({
    url,
    method: 'put',
    data,
  })
}

export function ticketsAssignRedraw (ticketId: number, ldap: string, name: string, operator: string, phaseId: any = undefined) {
  const url = `${app}/tickets/${ticketId}/redraw/`
  return axios({
    url,
    method: 'put',
    data: {
      responsibleAgent: ldap,
      responsibleAgentName: name,
      operator: operator,
      phaseId,
    }
  })
}

export function ticketDetail (id: number) {
  const url = `${app}/tickets/${id}/details/`
  return axios({
    url,
    method: 'get'
  })
}

export function transTicket (id: number, data: Record<string, any>) {
  const url = `${app}/tickets/${id}/update/`
  return axios({
    url,
    method: 'put',
    data
  })
}

export function updateTicket (id: number, data: Record<string, any>) {
  const url = `${app}/tickets/${id}/patch/`
  return axios({
    url,
    method: 'put',
    data
  })
}

export function ticketBatchAssignDeal (data: Record<string, any>) {
  const url = `${app}/tickets/ticket_batch_assign_deal`
  return axios({
    url,
    method: 'post',
    data
  })
}

export function ticketBatchDeal (data: Record<string, any>) {
  const url = `${app}/tickets/ticket_batch_deal`
  return axios({
    url,
    method: 'post',
    data
  })
}

export function ticketBatchAssign (data: Record<string, any>) {
  const url = `${app}/tickets/ticket_batch_assign`
  return axios({
    url,
    method: 'post',
    data
  })
}

// 工单挂起
export function ticketsSuspend (ticketId: number, data: Record<string, any>) {
  const url = `${app}/tickets/${ticketId}/suspend`
  return axios({
    url,
    method: 'put',
    data,
  })
}

export function ticketBatchSuspend (data: Record<string, any>) {
  const url = `${app}/tickets/ticket_batch_suspend`
  return axios({
    url,
    method: 'post',
    data,
  })
}

export function ticketBatchAssignSuspend (data: Record<string, any>) {
  const url = `${app}/tickets/ticket_batch_assign_suspend`
  return axios({
    url,
    method: 'post',
    data,
  })
}

export function getBatchTemplateBlankField (ticketList) {
  const url = `${app}/tickets/batch_template_blank_field`
  return axios({
    url,
    method: 'get',
    params: {
      ticketList,
    }
  })
}

export function putMilestoneTime (id, obj) {
  const url = `${app}/tickets/${id}/milestone`
  return axios({
    url,
    method: 'PUT',
    data: obj
  })
}

export function getTicketFlowDetail (params) {
  const url = `${app}/tickets/wf_task_form_detail`
  return axios({
    url,
    method: 'get',
    params: params
  })
}

export function putProblemCreatetime (id, obj) {
  const url = `${app}/tickets/${id}/problem_create_time`
  return axios({
    url,
    method: 'PUT',
    data: obj
  })
}

export function putTicketCreatetime (id, obj) {
  const url = `${app}/tickets/${id}/ticket_create_time`
  return axios({
    url,
    method: 'PUT',
    data: obj
  })
}

export function putTransactiontime (id, obj) {
  const url = `${app}/tickets/${id}/transaction_time`
  return axios({
    url,
    method: 'PUT',
    data: obj
  })
}

export function getActivityInstances (instanceId) {
  const url = `${app}/workflows/activity_instances`
  const params: any = {
    instanceId
  }
  return axios({
    url,
    method: 'get',
    params: params
  })
}

export function getHistoryActivityInstances (instanceId) {
  const url = `${app}/workflows/history_activity_instances`
  const params: any = {
    instanceId
  }
  return axios({
    url,
    method: 'get',
    params: params
  })
}
