<template>
  <el-dialog
    v-model="visible"
    title="选择里程碑关键节点"
    width="60%"
    custom-class="milestone"
  >
    <el-tabs v-model="activeName" class="active-tabs" @tab-click="changeTabs">
      <el-tab-pane
        :label="item.name"
        :name="item.name"
        v-for="item in selectNodeData"
        :key="item.key"
      >
        <span class="workImg"
          ><img :src="item.description" style="width: 100%"
        /></span>
        <div class="row"><span>当前工作流：</span>{{ item.name }}</div>
        <div class="row">
          <span class="title">关键节点：</span>
          <el-popover placement="right" width="auto" trigger="click">
            <template #reference>
              <div>
                <span @click="addImportNode" class="add-node"
                  ><img src="../svg/add-node-icon.svg" />添加关键节点</span
                >
              </div>
            </template>
            <template #default>
              <el-cascader-panel
                v-model="item.milestones"
                :options="milestoneList"
                :props="cascaderProps"
                filterable
                @change="(val) => changNode(item,val)"
              />
            </template>
          </el-popover>
        </div>
        <div class="row milestoneList">
            <div v-for="(item, idx) in item.milestone" :key="idx" class="milestoneItem">
                <div class="title">{{ item.name || '脏数据请删除重新填写' }}</div>
                <div class="input-button">
                    <span style="width:25px;">T+</span>
                    <div class="number-input"><el-input v-model="item.delayDay" type="number" placeholder="0" :min="0" @input="(i) => changeNumber(item,i)"/></div>
                    <span>天</span>
                </div>
                <div class="del-icon" @click="delNodeTag(item)"><img src="../svg/del-icon.svg"/></div>
            </div>
          <!-- <el-tag
            v-for="(item, idx) in item.milestone"
            :key="idx"
            type="info"
            class="node-tag"
            closable
            @close="delNodeTag(item)"
          >
            {{ item }}
          </el-tag> -->
        </div>
      </el-tab-pane>
    </el-tabs>
    <div></div>
    <template #footer>
      <div class="dialog-footer">
        <div>
          <!-- <el-button class="btn" @click="clearTags">取消</el-button> -->
          <el-button type="primary" class="btn" @click="$emit('milestoneVisible',false)" >确定</el-button>
        </div>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { getMilestoneList } from '@/api/template'
import { cloneDeep, delay } from 'lodash'
export default {
  props: {
    milestoneVisible: Boolean,
    selectData: {
      type: [Object, Array],
    },
    milestone: {
      type: [Object, Array],
    },
  },
  emits: ['milestone', 'milestoneVisible', 'selectNodeData'],
  data () {
    return {
      activeName: null,
      milestoneList: [],
      cascaderProps: {
        multiple: true,
        label: 'name',
        value: 'name',
        checkStrictly: true,
        emitPath: false
      },
    }
  },
  methods: {
    changeNumber (val, i) {
      if (parseInt(i) < 0) {
        this.$message({
          type: 'warning',
          message: '输入需为正整数'
        })
      }
    },
    async changeTabs (val) {
      const data = await getMilestoneList(this.activeName)
      this.milestoneList = data
    },
    addImportNode () {
      console.log('add')
    },
    changNode (item, val) {
      const index = this.selectNodeData?.findIndex(i => i.key === item.key)
      this.selectNodeData[index].milestone = val.map(i => ({ name: i, delayDay: null }))
      this.selectNodeData[index].milestones = val
    },
    delNodeTag (val) {
      console.log(val)
      const index = this.selectNodeData?.findIndex(i => i.name === this.activeName)
      const idx = this.selectNodeData[index].milestone?.findIndex(i => i === val)
      const idxs = this.selectNodeData[index].milestones?.findIndex(i => i === val)
      this.selectNodeData[index].milestone.splice(idx, 1)
      this.selectNodeData[index].milestones.splice(idx, 1)
    },
    clearTags () {
      this.selectNodeData = this.selectNodeData.map(i => { i.milestone = []; i.milestones = []; return i })
      this.$emit('milestoneVisible', false)
    }
  },
  watch: {
    milestone: {
      handler (val) {
        console.log('更新handel', val)
      },
    },
    milestoneVisible: {
      async handler (val) {
        if (val) {
          this.activeName = this.selectData[0]?.name
          this.milestoneList = await getMilestoneList(this.activeName)
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    visible: {
      get () {
        return this.milestoneVisible
      },
      set (val) {
        this.$emit('milestoneVisible', val)
      }
    },
    selectNodeData: {
      get () {
        return this.selectData
      },
      set (val) {
        this.$emit('selectNodeData', val)
      }
    },
  }
}
</script>
<style lang="less" scoped>
.workImg {
  margin: 20px 0;
  display: inline-block;
}
.active-tabs {
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  .row {
    margin: 15px 0;
    display: flex;
    flex-wrap: wrap;
    .title {
      margin-right: 20px;
    }
  }
  .milestoneList{
    display: block;
    padding-left: 70px;
    .milestoneItem{
        display: flex;
        align-items: center;
        margin: 5px 0;
        .title{
            font-size: 14px;
            line-height: 14px;
            min-width: 100px;
        }
        .input-button{
            font-size: 14px;
            display: flex;
            align-items: center;
            & > span{
                margin: 0 5px;
            }
            .number-input{
                width: 80px;
            }
        }
        .del-icon{
            width: 16px;
            height: 16px;
            margin: 0 15px;
        }
    }
  }
  .add-node {
    font-size: 14px;
    line-height: 14px;
    color: #4b79f3;
    img {
      vertical-align: middle;
      margin-right: 10px;
      position: relative;
      top: -2px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .node-tag {
    margin: 5px 10px;
  }
}
.dialog-footer {
  box-sizing: content-box;
  height: 50px;
  border-top: 1px solid #e4e4e4;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  & > div {
    display: flex;
    justify-content: space-between;
  }
  .btn {
    width: 150px !important;
  }
}
</style>
<style lang="less">
.milestone {
  box-sizing: content-box;
  overflow: auto;
  .el-dialog__body {
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .el-dialog__footer {
    padding: 0;
  }
  .el-tabs__content{
    height: 500px;
    overflow: auto;
  }
}
</style>
