<template>
  <div class="page">
    <div class="header-nav">
      <span class="title">{{pageTitle}}</span>
    </div>
    <div class="content1">
      <div class="work-order">
        <div class="search" style="">
          <span class="search-item">
            <label>关键字查询：</label>
            <el-input
              style="width: 241px;"
              v-model="criteria.keywords"
              placeholder="输入报警组或报警规则名称"
              @keyup.enter="getList"
              clearable
              @clear="getList"
            />
          </span>
          <span class="search-item">
            <label>报警等级：</label>
            <el-select v-model="criteria.alarmLevels" multiple placeholder="报警等级可选">
              <el-option
                v-for="level in alarmLevelsList"
                :key="level"
                :label="level"
                :value="level">
              </el-option>
            </el-select>
          </span>
          <span class="search-item">
            <label>是否接入：</label>
            <el-select v-model="criteria.connected" filterable clearable placeholder="问题来源可选">
              <el-option
                v-for="item in connectedList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </span>
          <el-button type="primary" @click="getList" class="search-btn" style="margin:0;"
                     :data-track-click="JSON.stringify({
                         category: '问题列表-查询',
                         action: '点击',
                       })"
          >查 询</el-button>
        </div>
      </div>
      <div class="data-table">
        <el-table
          class="question-list"
          v-loading="loading"
          :data="tableData"
          ref="questionTable"
          border
          tooltip-effect="dark"
          style="width: 100%">
          <el-table-column
            width="300"
            prop="problemId"
            label="报警组"
          >
            <template #default="{row}">
              <span class="color-blue">{{row.group}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="problemTitle"
            show-overflow-tooltip
            label="报警规则名称">
            <template #default="{row}">
              <span class="color-blue">{{row.rule}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="channel"
            width="100"
            label="报警等级">
            <template #default="{row}">
              <span class="text">{{row.level}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="updatedAt"
            width="180"
            label="更新时间">
            <template #default="{row}">
              <span class="text">{{row.updatedAt}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="updatedByName"
            width="100"
            label="更新人">
            <template #default="{row}">
              <span class="text">{{row.updatedByName}}</span>
            </template>
          </el-table-column>
          <el-table-column
            width="300"
            label="操作">
            <template #default="{row}">
              <!-- 未创建的时候 ｜｜ 创建工单 + ...  -->
              <div class="popover-item">
                <button @click="openKnowledgeDialog(row)" class="operation">编辑知识库</button>
                <span class="line-icon">|</span>
                <button @click="changeConnected(row.id, row.connected)" class="operation">
                  <span v-if="row.connected === false">接入</span>
                  <span v-else>取消接入</span>
                </button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-wrap" v-if="totalCount > 0">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="prev, pager, next, sizes, jumper, total"
            :total="totalCount">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="close-question-dialog">
      <el-dialog
        title="编辑知识库"
        v-model="knowledgeDialog"
        width="40%"
        destroy-on-close
        :close-on-click-modal="false"
        center>
        <div>
          <div style="margin-bottom:20px;color: rgba(0, 0, 0, 0.85);">报警规则名称：<text v-html="rule_name"></text></div>
          <div class="close-item">
            <label>内容填写：</label>
            <el-input type="textarea" v-model="knowledge" :rows="4" clearable placeholder="可填写飞书文档链接或文字" style="margin-bottom:20px;"></el-input>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <button @click="knowledgeDialog = false" class="close-btn">取 消</button>
            <button @click="submitKnowledge" class="close-btn close-btn1">确 定</button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  loadAlarmRuleList, updateAlarmRuleById
} from '@/api/template.js'
import * as _ from 'lodash'

export default {
  name: 'questionList',
  components: {
  },
  data () {
    return {
      pageTitle: '配置报警',
      loading: false,
      selectLoading: false,

      // 报警等级列表
      alarmLevelsList: ['P0', 'P1', 'P2', 'P3', 'P4'],
      // 是否接入
      connectedList: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 'True',
          label: '已接入',
        },
        {
          value: 'False',
          label: '未接入',
        },
      ],
      // 查询条件
      criteria: {
        keywords: '',
        alarmLevels: [],
        connected: '',
      },

      tableData: [],
      totalCount: 0,
      pageSize: 10,
      pageNumber: 1,

      knowledgeDialog: false,
      rule_name: '',
      knowledge: '',
      row_id: '',
    }
  },
  mounted () {
    const query = this.$route.query
    console.log('query=', query)

    if (query.keywords) {
      this.criteria.keywords = query.keywords
    }
    if (query.alarmLevels) {
      this.criteria.alarmLevels = query.alarmLevels
    }
    if (query.connected) {
      this.criteria.connected = query.connected
    }

    this.getList()
  },
  methods: {
    getList () {
      const params = {
        keywords: this.criteria.keywords,
        level: this.criteria.alarmLevels.join(),
        connected: this.criteria.connected,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      }
      if (this.loading) return
      this.loading = true
      console.log(params)
      loadAlarmRuleList(params).then((res) => {
        console.log('res', res)
        this.totalCount = res.count
        this.tableData = res.results
      }, (err) => {
        this.$message({
          type: 'error',
          message: '获取配置报警列表失败: ' + err.errmsg
        })
        console.error('获取配置报警列表失败:', err)
      }).finally(() => {
        this.loading = false
        const q = { ...params }
        this.$router.replace({
          query: q
        })
      })
    },
    handleSizeChange (e) {
      this.pageSize = e
      this.pageNumber = 1
      this.$router.push({
        name: this.$route.name,
        query: {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize
        },
      })
      this.getList()
    },
    handleCurrentChange (e) {
      this.pageNumber = e
      this.$router.push({
        name: this.$route.name,
        query: {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize
        },
      })
      this.getList()
    },
    openKnowledgeDialog (row) {
      this.knowledgeDialog = true
      this.rule_name = row.rule
      this.knowledge = row.knowledge
      this.row_id = row.id
    },
    submitKnowledge () {
      const data = {
        knowledge: this.knowledge,
      }
      updateAlarmRuleById(this.row_id, data).then(() => {
        this.knowledgeDialog = false
        this.$message({
          type: 'success',
          message: '保存成功！'
        })
        this.getList()
      }, (err) => {
        this.$message({
          type: 'error',
          message: err.errmsg
        })
        console.error(err)
      })
    },
    changeConnected (id, connected) {
      const data = {
        connected: 'False',
      }
      if (connected === false) {
        data.connected = 'True'
      } else {
        data.connected = 'False'
      }
      updateAlarmRuleById(id, data).then(() => {
        this.$message({
          type: 'success',
          message: '保存成功！'
        })
        this.getList()
      }, (err) => {
        this.$message({
          type: 'error',
          message: err.errmsg
        })
        console.error(err)
      })
    },
  },
  // computed
  computed: {
  },
  watch: {
  },
}
</script>

<style lang="less" scoped>
@import "../../assets/style/home.less";
.title {
  vertical-align: middle;
}
.text {
  cursor: default;
  pointer-events: none;
  &::before {
    content: '';
    display: block;
  }
}
.problem-describe {
  width: 100%;
  height:23px;
  overflow:hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
  &::before {
    content: '';
    display: block;
  }
}
.btn-refresh {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  cursor: pointer;
  &.loading {
    animation: refresh 1s;
  }
}
@keyframes refresh
{
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.status-num-box {
  float: right;
  margin-top: 15px;
  height: 30px;
  line-height: 28px;
  padding: 0 24px;
  background: rgba(75,121,243,0.15);
  border: 1px solid rgba(75,121,243,0.85);
  border-radius: 3px;
  display: flex;
  .status-num-item {
    margin-right: 32px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    i {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 6px;
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
    }
    .line {
      margin: 0 8px;
      color: #4b79f3;
    }
    .status-title {
      color: rgba(0,0,0,0.65);
    }
    .new-font-weight {
      font-weight: 700;
    }
  }
}
.status-icon {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 6px;
  vertical-align: middle;
  background: #2246a6;
}
.icon-red {
  background: #f26363;
}
.icon-green {
  background: #22c081;
}
.icon-blue {
  background: #4b79f3;
}
.status-num-box.new-box {
  margin-right: 32px;
}
.close-question-btn {
  float: left;
  height: 32px;
  line-height: 32px;
  padding: 0 13px;
  background: #c3c3c9;
  color: #ffffff;
  margin-top: 17px;
  border-radius: 2px;
  cursor: pointer;
}
.close-question-btn-active {
  background: #4b79f3;
}
.search {
  .search-item {
    display: inline-block;
    margin: 0 24px 8px 0;
    .custom-search {
      margin-right: 32px;
    }
    &.times {
      ::v-deep {
        .el-input__inner {
          width: 240px;
        }
        .el-range-input {
          width: 85px !important;
        }
      }
    }
  }
  .add-search {
    margin-left: -32px;
    span {
      color: #e9e9e9;
      margin: 0 8px;
    }
    i {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(../../assets/images/add-search.svg);
      vertical-align: middle;
      cursor: pointer;
    }
  }
}
.filter-select {
  ::v-deep(.el-dialog) {
    .el-dialog__body {
      padding: 20px;
      color: rgba(0,0,0,0.85);
    }
  }
}
.operation {
  background: none;
  margin-left: 2px;
  margin-right: 2px;
}
</style>

<style lang="less">
.is-dark {
  max-width: 400px !important;
  line-height: 20px;
  font-size: 14px;
  img {
    max-width: 100%;
  }
}
.is-message-box {
  .el-message-box__title {
    font-size: 16px;
  }
  .el-message-box__input {
    padding-top: 0;
  }
}
button {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  color: #4B79F3;
}
.el-select-dropdown {
  max-width: 400px;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item span {
  display: inline-block;
  max-width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.popover-item {
  position: relative;
  .el-dropdown{
    position: absolute;
    right: 10;
    top: 50%;
    height: 20px;
    width: 20px;
    transform: translateY(-50%);
    .el-dropdown-link{
      display: block;
      width: 100%;
      height: 100%;
      background-image: url('../../assets/images/more.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px 20px;
      cursor: pointer;
    }
  }
}
.select-box{
 .box{
   width: 100px;
   text-align: center;
   height: 28px;
   line-height: 28px;
   color: #4b79f3;
   font-size: 14px;
 }
}
.el-dialog.save-filter {
  .el-form-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .el-form-item__content {
      flex-grow: 1;
    }
  }
  .desc {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 24px;
  }
}
.problem-export-dialog {
  .title {
    font-size: 18px;
  }
  .desc {
    font-size: 16px;
    .total {
      margin: 0 5px;
      color: rgba(34,192,129,1);
      font-size: 20px;
    }
  }
  .info {
    margin-top: 16px;
    margin-bottom: 10px;
    font-size: 14px;
    color: #4b79f3;
    .file-name.ml {
      margin-left: 35px;
    }
  }
}
</style>
