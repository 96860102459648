import axios from '@/utils/request.js'
import { toLine } from '@/utils/utils'
import { BatchScheduling, EmergencyDuty, Order, SearchOps, ViewType } from '@/api/entity'

export * from '@/api/entity'

const app = '/unicorn-ticket'

/**
 * 批量排班
 * @param data BatchScheduling
 */
export function saveBatchScheduling (data: BatchScheduling) {
  const url = `${app}/duty/batch_save_employee_scheduling`
  return axios({
    url,
    method: 'post',
    data,
  })
}

export function updateScheduling (data: BatchScheduling) {
  const url = `${app}/duty/update_employee_scheduling`
  return axios({
    url,
    method: 'post',
    data,
  })
}

export function getEmployeeScheduling (year: number, month: number, date: number) {
  const url = `${app}/duty/employee_scheduling`
  return axios({
    url,
    method: 'get',
    params: {
      year: year,
      month: month,
      day: date
    },
  })
}

export function employeeSchedulingStatus (year: number, month: number) {
  const url = `${app}/duty/employee_scheduling_status`
  return axios({
    url,
    method: 'get',
    params: {
      year: year,
      month: month
    },
  })
}

export function getEmergencyDuty () {
  const url = `${app}/duty/emergency_duty`
  return axios({
    url,
    method: 'get',
    params: {
    },
  })
}

export function saveEmergencyDuty (data: EmergencyDuty) {
  const url = `${app}/duty/emergency_duty`
  return axios({
    url,
    method: 'post',
    data,
  })
}
