<template>
  <div style="height: 100%; width: 100%; display: block">
    <breadcrumb>
      <h2>配置模版</h2>
      <div @click="$router.push({ path: '/config/template/create' })" class="add-template">
        <i class="add-icon"></i>
        <span>新建模版</span>
      </div>
    </breadcrumb>
    <el-container class="template-configuration-view">
      <el-aside width="320px">
        <SelectTemplate
          :edit="true"
          :oriTemplateList="oriTemplateList"
          @select="goDetailTemplate"
          @delete="deleteTemplate"
        ></SelectTemplate>
      </el-aside>
      <el-main>
        <div class="content-wrapper">
          <router-view @updateTemplateList="updateTemplateList">
            <template #default="{ Component }">
              <component :is="Component" :key="$route.name"/>
            </template>
          </router-view>
<!--          <router-view :key="$route.path" v-slot="{ Component }">-->
<!--            <transition name="fade" mode="out-in">-->
<!--              <component :is="Component" />-->
<!--            </transition>-->
<!--          </router-view>-->
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import { loadTemplateList, deleteTemplateById } from '@/api/template'
import { Options, Vue } from 'vue-class-component'
import Breadcrumb from '@/components/Breadcrumb.vue'
import store from '@/store'
import SelectTemplate from '@/components/selectTemplate.vue'
@Options({
  name: 'ConfigurationTemplate',
  components: {
    Breadcrumb,
    SelectTemplate
  }
})
export default class ConfigurationTemplate extends Vue {
  hoverId = null
  oriTemplateList = []
  created () {
    const user = store.getters['auth/me']
    const admin = user.role
    let ticketAdmin = false
    admin.forEach(item => {
      if (item.roleName === 'ticket_admin') {
        ticketAdmin = true
      }
    })
    if (ticketAdmin) {
      this.updateTemplateList()
    } else {
      this.$message({
        type: 'error',
        message: '您不是管理员不可访问页面哦！'
      })
      this.$router.push({
        path: '/home'
      })
    }
  }

  updateTemplateList () {
    loadTemplateList().then((res) => {
      this.oriTemplateList = res
    }, (err) => {
      this.$message({
        type: 'error',
        message: err.errmsg
      })
      console.error(err)
    })
  }

  deleteTemplate (template) {
    this.$confirm(`此操作将永久删除模板${template.name}, 是否继续?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      deleteTemplateById(template.id).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        if (Number(this.$route.params.templateId) === template.id) {
          this.$router.push({ name: '配置模板' })
        }
      }, (err) => {
        console.error(err)
        this.$message({
          type: 'error',
          message: err.errmsg
        })
      })
    }, () => {
      console.log('cancel')
    })
  }

  goDetailTemplate (i) {
    this.$router.push({ name: '编辑模板', params: { templateId: i.id } })
  }
}
</script>
<style scoped lang="less">
.template-configuration-view {
  background-color: #f3f5f6;
  height: calc(100% - 76px);
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;

  & > ::v-deep(.el-main) {
    padding: 0;
    margin: 0;
  }

  & > ::v-deep(.el-aside) {
    padding: 0;
    margin: 0;

    .aside-area{
      margin-right: 2px;
      background-color: #ffffff;
      width: calc(100% - 2px);
      // height: 100%;
      padding-bottom: 8px;
    }

  }
  .aside-area ul {
    padding: 8px;
  }
  .aside-area li {
    height: 38px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0,0,0,0.65);
    padding: 0 16px;
    cursor: pointer;
    border-right: 3px solid transparent;
    > span {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .delete-icon {
      color: red;
      display: none;
      font-size: 16px;
    }

    &.current {
      background-color: rgba(75,121,243,0.16);
      color: #4b79f3;
      border-right: 3px solid #4b79f3;
    }
    &.hover {
      background-color: rgba(75,121,243,0.06);
      .delete-icon {
        display: block;
      }
    }
  }
}
.ticket-view {
  flex-grow: 1;
  //height: fill;
  background-color: #f3f5f6;
  display: flex;
  flex-direction: column;

  .header {
    height: 60px;
    background-color: #ffffff;
  }
  .body {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
    //width: fill;
  }
}
.content-wrapper {
  background-color: #ffffff;
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
}
.navs-wrapper {
  margin-right: 2px;
  display: flex;
  flex-flow: column;
}
.add-template {
  margin: 0 24px;
  background: #4b79f3;
  border-radius: 2px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  min-width: 100px;
  .add-icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: url(../../assets/images/add-icon.svg);
    vertical-align: middle;
    margin-right: 8px;
  }
  span {
    vertical-align: middle;
  }
}
</style>
