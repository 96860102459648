<template>
  <div class="page">
    <div class="header-nav">
      <span class="title">外部人员管理</span>
    </div>
    <div class="content1">
      <div class="work-order">
        <div class="search search-tag">
          <label>项目名称：</label>
          <el-select v-model="projectId" clearable filterable placeholder="请选择" class="site-project" @clear="seachFetchAgent">
            <el-option
              v-for="item in projectList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <label style="margin-left: 20px;">姓名：</label>
          <el-input
            style="width: 110px;"
            v-model="searchOfName"
            placeholder="输入姓名"
            @keyup.enter="seachFetchAgent"
            clearable
            @clear="seachFetchAgent"
          />
          <label style="margin-left: 20px;">邮箱状态：</label>
          <el-select v-model="isAdmin" clearable placeholder="请选择" class="select-admin" @clear="seachFetchAgent">
            <el-option
              v-for="item in agentTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <label style="margin-left: 20px;">邮箱：</label>
          <el-input
            style="width: 140px;"
            v-model="emailOfName"
            placeholder="根据邮箱搜索"
            @keyup.enter="seachFetchAgent"
            clearable
            @clear="seachFetchAgent"
          />
          <button @click="seachFetchAgent" class="search-btn">查 询</button>
          <button @click="addPersonTags" class="add-problem add-person-tags2">添加外部人员</button>
        </div>
      </div>
      <div class="data-table" style="margin:0;">
        <el-table
          fit
          border
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%">
          <el-table-column
            prop="siteIds"
            show-overflow-tooltip
            label="所属项目">
          </el-table-column>
          <el-table-column
            prop="name"
            label="客户名称"
            width="120">
          </el-table-column>
          <el-table-column
            prop="email"
            label="邮箱">
          </el-table-column>
          <el-table-column
            prop="emailConfirmed"
            label="邮箱状态"
            width="120">
            <template #default="{ row }">
              <div class="staus">
                <span>{{row.emailConfirmed ? '已确认' : '未确认' }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="creator"
            label="添加人"
            width="120">
          </el-table-column>
          <el-table-column
            label="操作"
            width="150">
            <template #default="{ row }">
              <div class="operation-box">
                <button @click="editPerson(row)" class="operation">编辑</button>
                <span>|</span>
                <button @click="delPerson(row)" class="operation un-operation">删除</button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-wrap" v-if="totalCount > 0">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="prev, pager, next, sizes, jumper, total"
            :total="totalCount"
            :page-count="numPages">
          </el-pagination>
        </div>
      </div>
      <AddOrEditWithoutPersons
        v-model:show="AddOrEditWithoutPersonsShow"
        v-if="AddOrEditWithoutPersonsShow"
        :edit-obj="editObj"
        :project-list="projectList"
        @update="seachFetchAgent"
      />
    </div>
  </div>
</template>

<script>
// import { loadDepartmentTree } from '@/api/orgCharts'
// import { fetchAgentByTags, setUpAdmin } from '@/api/person'
import { tagLabelSetName, tagLabelAllGuest, DelLabelAllGuest } from '@/api/label'
import { deepCopy } from '@/utils/utils'
import AddOrEditWithoutPersons from './AddOrEditWithoutPersons.vue'
import store from '@/store'

export default {
  name: 'WithoutPerson',
  data () {
    // const that = this
    return {
      tagList: [],
      tableData: [],
      tagsOptions: [],
      tagIds: [],
      searchOfName: undefined,
      searchOfDepartment: [],
      pageSize: 10,
      pageNum: 1,
      numPages: 10,
      totalCount: null,
      selectPerson: [],
      selectPersonCurPage: [],
      isAdmin: undefined,
      agentTypeOptions: [
        { label: '已确认', value: true },
        { label: '未确认', value: false }
      ],
      AddOrEditWithoutPersonsShow: false,
      editObj: null,
      projectList: [],
      projectId: undefined,
      emailOfName: undefined,
    }
  },
  created () {
    const user = store.getters['auth/me']
    const admin = user.role
    let ticketAdmin = false
    admin.forEach(item => {
      if (item.roleName === 'ticket_admin') {
        ticketAdmin = true
      }
    })
    const query = this.$route.query
    if (Object.keys(query).length > 0) {
      this.pageNum = Number(query.pageNum)
      this.pageSize = Number(query.pageSize)
    } else {
      this.pageNum = 1
      this.pageSize = 10
    }
    if (ticketAdmin) {
      this.fetchAgent()
    } else {
      this.$message({
        type: 'error',
        message: '您不是管理员不可访问页面哦！'
      })
      this.$router.push({
        path: '/home'
      })
    }
  },
  methods: {
    fetchAgent () {
      const data = {
        pageSize: this.pageSize,
        pageNumber: this.pageNum,
        siteId: this.projectId || undefined,
        name: this.searchOfName || undefined,
        emailStatus: this.isAdmin,
        email: this.emailOfName || undefined,
      }
      tagLabelAllGuest(data).then((rv) => {
        // console.log(rv)
        this.totalCount = rv.count
        rv.results.forEach(item => {
          item.siteIds = this.getSiteLoads(item.siteIds)
        })
        this.tableData = rv.results
      }, (err) => {
        this.$message({
          type: 'error',
          message: err.errmsg
        })
      })
    },
    addPersonTags () {
      this.editObj = null
      this.AddOrEditWithoutPersonsShow = true
    },
    editPerson (row) {
      this.editObj = deepCopy(row)
      this.AddOrEditWithoutPersonsShow = true
    },
    delPerson (row) {
      this.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          DelLabelAllGuest(row.id).then(
            () => {
              this.$message({
                type: 'success',
                message: '删除成功'
              })
              this.seachFetchAgent()
            },
            (err) => {
              this.$message({
                type: 'error',
                message: err.errmsg
              })
            },
          )
        })
    },
    handleCurrentChange (e) {
      this.pageNum = e
      this.fetchAgent()
    },
    handleSizeChange (e) {
      this.pageSize = e
      // this.pageNum = 1
      this.fetchAgent()
    },
    async loadTagLabelSetName () {
      const data = await tagLabelSetName('SiteID')
      this.projectList = data.map(res => {
        return {
          label: res.name,
          value: res.name,
        }
      })
    },
    getSiteLoads (data) {
      return data.join(',')
    },
    seachFetchAgent () {
      this.pageNum = 1
      this.fetchAgent()
    },
  },
  mounted () {
    this.loadTagLabelSetName()
  },
  components: {
    AddOrEditWithoutPersons,
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/style/home.less";
.operation {
  background: none;
  &.un-operation {
    color: #F34B4B!important;
  }
}
.search .site-project {
  width: 200px;
  :deep(.el-input__inner) {
    width: 100%!important;
  }
}
</style>

<style lang="less">
.el-cascader__search-input {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
}
button {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
}
</style>
