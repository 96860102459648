<template>
  <div class="page">
    <div class="header-nav">
      <span class="title">配置人员标签</span>
    </div>
    <div class="content">
      <div class="tags-list">
        <p>选择标签</p>
        <div class="tags-list-item" v-for="labelSet in labelSetList" :key="labelSet.id">
          <p>{{ labelSet.labelSetName }}</p>
          <el-select
            v-model="tagsValue[labelSet.id]"
            multiple
            clearable
            placeholder="请选择"
            :loading="Object.keys(tagsOptions).indexOf(labelSet.id) >= 0"
            @visible-change="getLabels(labelSet.id)"
            :disabled="labelSet.isDeleted"
          >
            <el-option label="全选" @click.stop="selectAllTag(labelSet.id)"/>
            <el-option
              v-for="item in tagsOptions[labelSet.id] || []"
              :key="item.id"
              :label="item.name + (item.extraName ? ('（' + item.extraName +'）') : '')"
              :value="item.id"
              :disabled="item.isDeleted && tagsValue[labelSet.id].indexOf(item.id) < 0"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="person-list">
        <div class="person-list-content">
          <p>人员姓名</p>
          <div class="person-name-list">
            <div v-for="person in personList" :key="person.id">
              <span>{{person.name}}</span>
              <i @click="deletePerson(person.id)"></i>
            </div>
          </div>
        </div>
        <div class="active-tags">
          <template v-for="(labelChoice, labelSetId) in tagsValue" :key="labelSetId">
            <div class="tags-type" v-if="labelChoice.length > 0">
              <div class="type">{{labelSetMap[labelSetId]}}</div>
              <div class="selected-tags">
                <div v-for="item in labelChoice" :key="`${labelSetId}-${item}`">
                  <span>{{labelMap[labelSetId][item]}}</span>
                  <i @click="closeTag(labelSetId, item)"></i>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="btn-box2">
          <button @click="$router.push({path: '/manage/person', query: { pageNum: pageNum, pageSize: pageSize }})">取 消</button>
          <button class="submit-btn" @click="submitForm">提 交</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { personLabelSet, getLabelsBySetId } from '@/api/label'
import { addTagBulk, getAgentTags, updateAgentTag } from '@/api/person'

export default {
  name: 'AllocationTags',
  data () {
    return {
      edit: false,
      pageSize: 10,
      pageNum: 1,
      labelSetList: [],
      personList: [],
      tagsOptions: {}, // 标签集 => 所有标签的映射表
      tagsValue: {} // 标签集 => 已选标签的映射表
    }
  },
  created () {
    const query = this.$route.query
    if (Object.keys(query).length > 0) {
      this.edit = false
      if (query.ldap) {
        this.edit = true
        this.getAgentTags(query.ldap)
      }
      this.personList = JSON.parse(query.personList)
      this.pageNum = query.pageNum
      this.pageSize = query.pageSize
      this.getLabelSets()
    } else {
      this.$router.push({
        path: '/manage/person'
      })
    }
  },
  methods: {
    selectAllTag (tagSetId) {
      this.tagsValue[tagSetId] = this.tagsOptions[tagSetId].filter(tag => tag.isDeleted === false).map(tag => tag.id)
    },
    submitForm () {
      const tagList = []
      Object.keys(this.tagsValue).forEach((key) => {
        tagList.push(...this.tagsValue[key])
      })
      const tagListUniq = tagList.reduce((res, cur) => {
        if (res.indexOf(cur) < 0) {
          res.push(cur)
        }
        return res
      }, [])
      const ldapList = this.personList.map(person => person.ldap)
      if (this.edit) {
        updateAgentTag(ldapList, tagListUniq).then(() => {
          this.$message({
            type: 'success',
            message: '编辑人员标签成功'
          })
          this.$router.push({
            name: '人员管理'
          })
        }, (err) => {
          this.$message({
            type: 'error',
            message: err.errmsg
          })
          console.error(err)
        })
      } else {
        addTagBulk(ldapList, tagListUniq).then(() => {
          this.$message({
            type: 'success',
            message: '批量添加成功'
          })
          this.$router.push({
            name: '人员管理'
          })
        }, (err) => {
          this.$message({
            type: 'error',
            message: err.errmsg
          })
          console.error(err)
        })
      }
    },
    getLabelSets () {
      personLabelSet().then((res) => {
        const enableList = res.filter(v => v.isDeleted === false)
        const disableList = res.filter(v => v.isDeleted)
        // this.labelSetList = [...enableList, ...disableList]
        this.labelSetList = [...enableList]
        this.labelSetList.forEach((tagSet) => {
          if (!this.tagsValue[tagSet.id]) {
            this.tagsValue[tagSet.id] = []
          }
        })
      })
    },
    getAgentTags (ldap) {
      getAgentTags(ldap).then((res) => {
        res.forEach((tagSet) => {
          if (!this.tagsValue[tagSet.tagSetId]) {
            this.tagsValue[tagSet.tagSetId] = []
          }
          if (!this.tagsOptions[tagSet.tagSetId]) {
            this.tagsOptions[tagSet.tagSetId] = []
          }
          if (this.labelSetList.findIndex(set => set.id === tagSet.tagSetId) < 0) {
            this.labelSetList.push({ id: tagSet.tagSetId, labelSetName: tagSet.tagSetName })
          }
          tagSet.tags.forEach((tag) => {
            this.tagsValue[tagSet.tagSetId].push(tag.tagId)
            this.tagsOptions[tagSet.tagSetId].push({
              // name: tag.tagName + (tag.tagExtraName ? ('(' + tag.tagExtraName + ')') : ''),
              name: tag.tagName,
              id: tag.tagId
            })
          })
          this.getLabels(tagSet.tagSetId, true)
        })
      })
    },
    getLabels (id, force = false) {
      if (!force && this.tagsOptions[id]) {
        return
      }
      getLabelsBySetId(id).then((res) => {
        this.tagsOptions[id] = res.filter((v) => {
          if (v.isDeleted === false) {
            return true
          }
          return this.tagsValue[id].indexOf(v.id) >= 0
        })
        // const enableList = res.filter(v => v.isDeleted === false)
        // const disableList = res.filter(v => v.isDeleted)
        // this.tagsOptions[id] = [...enableList, ...disableList]
      }, (err) => {
        console.error(err)
        this.$message({
          type: 'error',
          message: err.errmsg
        })
      })
    },
    deletePerson (id) {
      if (this.personList.length < 2) {
        this.$message({
          type: 'error',
          message: '最后一个不可删！'
        })
      } else {
        const nameList = this.personList.filter(item => {
          return item.id !== id
        })
        this.personList = nameList
      }
    },
    closeTag (labelSetId, labelId) {
      const index = this.tagsValue[labelSetId].indexOf(labelId)
      if (index < 0) {
        return
      }
      this.tagsValue[labelSetId].splice(index, 1)
    }
  },
  computed: {
    labelSetMap () {
      if (this.labelSetList.length === 0) {
        return {}
      }
      const data = {}
      this.labelSetList.forEach((labelSet) => {
        data[labelSet.id] = labelSet.labelSetName
      })
      return data
    },
    labelMap () {
      if (this.labelSetList.length === 0) {
        return {}
      }
      const data = {}
      this.labelSetList.forEach((labelSet) => {
        data[labelSet.id] = {}
        if (this.tagsOptions[labelSet.id]) {
          this.tagsOptions[labelSet.id].forEach((label) => {
            data[labelSet.id][label.id] = label.name + (label.extraName ? ('（' + label.extraName + '）') : '')
          })
        }
      })
      return data
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/style/home.less";
</style>
