
import { Options, Vue } from 'vue-class-component'
import * as labelApi from '@/api/label'
import store from '@/store'
import { Label, LabelSet } from '@/api/entity'
import { ticketLabelSet } from '@/api/label'

@Options({
  name: 'TicketAddTag',
  props: {
    cancel: Function,
    submit: Function,
    checkedTags: Array
  }
})
export default class TicketAddTag extends Vue {
  ticketLabelSetList: Array<LabelSet> = []
  checkedTags: Array<any> = []

  get selectedLabels () : Array<Label> {
    const ls: Array<Label> = []
    for (const s of this.ticketLabelSetList) {
      if (s.checkList!.length > 0) {
        for (const l of s.labels!) {
          if (s.checkList!.includes(l.id!)) {
            l.labelSetName = s.labelSetName
            ls.push(l)
          }
        }
      }
    }
    return ls
  }

  mounted () {
    console.log(this.checkedTags)
    let checkedTagIds: Array<any> = []
    if (this.checkedTags) {
      checkedTagIds = this.checkedTags.map(t => t.id)
    }
    labelApi.ticketLabelSet(0).then((res) => {
      const ticketLabelSetList = res.filter(e => e.useForTicket)
      ticketLabelSetList.forEach(e => {
        e.labels = []
        e.showCheckList = false
        e.checkList = []
      })
      this.ticketLabelSetList = ticketLabelSetList
      this.ticketLabelSetList.forEach((e: LabelSet) => {
        labelApi.getLabelsBySetId(e.id).then(res => {
          e.labels = res
          if (e.labels) {
            e.labels.forEach(t => {
              if (checkedTagIds.includes(t.id)) {
                e.checkList!.push(t.id!)
              }
            })
          }
        }).catch(res => {
          console.error('getLabelsBySetId error:', res)
        })
      })
    }).catch((res) => {
      console.error('listLabelSet error:', res)
    })
  }
}
