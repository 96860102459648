<template>
  <el-form
    label-width="80px"
  >
    <el-form-item label="必填">
      <el-switch v-model="required"></el-switch>
    </el-form-item>
  </el-form>
</template>

<script>
import { deepCopy } from '@/utils/utils'

export function generateData (originData) {
  if (!originData.rules) {
    originData.rules = []
  }
}

export function postTreatment (originData) {
  return originData
}

export default {
  name: 'RuleConfig',
  props: {
    fieldInfo: {
      type: Object,
      require: true
    }
  },
  emits: ['update:fieldInfo'],
  computed: {
    required: {
      get () {
        return this.fieldInfoLocal.rules.some((rule) => {
          if (rule.required && rule.required === true) {
            return true
          }
          return false
        })
      },
      set (val) {
        const index = this.fieldInfoLocal.rules.findIndex((rule) => {
          if (rule.required && rule.required === true) {
            return true
          }
          return false
        })
        if (val && index < 0) {
          this.fieldInfoLocal.rules.push({ required: true, message: '当前字段必填' })
        } else if (!val && index >= 0) {
          this.fieldInfoLocal.rules.splice(index, 1)
        }
      }
    }
  },
  data: () => {
    return {
      internalChange: false,
      fieldInfoLocal: {}
    }
  },
  watch: {
    fieldInfo: {
      handler (val) {
        if (this.internalChange) {
          return
        }
        this.fieldInfoLocal = deepCopy(val)
      },
      deep: true,
      immediate: true
    },
    fieldInfoLocal: {
      handler (val) {
        this.internalChange = true
        this.$emit('update:fieldInfo', val)
        this.$nextTick(() => {
          this.internalChange = false
        })
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
