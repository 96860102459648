
import { Options, Vue } from 'vue-class-component'
import * as agent from '@/api/agent'
import * as label from '@/api/label'
import TinymceEditor from '@/components/TinymceEditor.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { uploadFile } from '@/api/files'
import { checkImage, deepCopy, getValueFromObject } from '../utils/utils'
import TemplateGenerator from '@/components/TemplateMaking/TemplateGenerator.vue'
import { loadTemplateList, getTemplateById } from '@/api/template'
import { ticketDetail, getBatchTemplateBlankField } from '@/api/ticket'
import TicketTemplateChangeMulti from '@/components/TicketTemplateChangeMulti.vue'

@Options({
  name: 'BatchProcess',
  props: {
    cancel: Function,
    // proceed: Function,
    // solved: Function,
    delayed: Function,
    title: String,
    viewType: String,
    ticketsId: {
      type: Array,
      default: () => [],
    },
    // templateId: {
    //   type: Number,
    //   default: () => 1,
    // },
  },
  components: {
    TinymceEditor,
    TemplateGenerator,
    TicketTemplateChangeMulti,
  },
  emits: [
    'proceed',
    'solved',
  ],
})
export default class BatchProcess extends Vue {
  // props
  title!: string

  ruleForm = {
    nextProcess: null,
    nextHandler: null,
    newReply: '',
    templateData: {},
  }

  uploading = false
  showUploadFileList = false
  templateId: any = null
  templateName = ''
  template = {}
  templateRelationMap = {}
  templateValue = {}
  templateList: Array<any> = []
  processLabels: Array<any> = []
  handlers: Array<any> = []
  fileList: Array<any> = []
  ticketsId: Array<any> = []
  showChangeTemplate = false
  beforeTemplateName = ''

  mounted () {
    label.processLabels().then(res => {
      this.processLabels = res.filter(l => !l.isDeleted)
    }).catch(res => [
      console.log('processLabels error:', res)
    ])
    // loadTemplateList().then(res => {
    //   this.templateList = deepCopy(res)
    //   // console.log('templateList', this.templateList)
    // }, (err) => {
    //   console.error(err)
    //   ElMessage({
    //     type: 'error',
    //     message: err.errmsg
    //   })
    // })
    // getTemplateById(this.templateId).then((res: any) => {
    //   this.template = res.template
    //   this.templateRelationMap = res.relationMap
    //   this.templateName = res.name || ''
    // })
    // ticketDetail(this.ticketsId[0]).then((res: any) => {
    //   // console.log('工单详情', res)
    //   const list = res.ticket.fields?.templateList || []
    //   const currentT = list.find(t => t.isCurrent)
    //   this.template = currentT?.template?.fields?.template
    //   this.templateValue = currentT?.fieldInfo
    //   this.templateRelationMap = currentT?.template?.fields?.relationMap
    //   this.templateName = currentT?.template?.fields?.name || ''
    //   this.templateId = currentT?.template?.pk
    // })
    const ids = this.ticketsId.join(',')
    getBatchTemplateBlankField(ids).then((res: any) => {
      // console.log('工单详情hhhhh', res)
      this.template = res.template?.fields?.template
      this.templateValue = res?.fieldInfo
      this.templateRelationMap = res?.template?.fields?.relationMap
      this.templateName = res?.template?.fields?.name || ''
      this.templateId = res?.template?.pk
    })
  }

  get ruleFormData () {
    return {
      nextPhaseId: this.ruleForm.nextProcess,
      nextAgent: this.ruleForm.nextHandler,
      comment: this.ruleForm.newReply,
      attachments: this.fileList,
      extraTags: null,
      templateData: this.ruleForm.templateData,
    }
  }

  handleTemplateChange () {
    this.beforeTemplateName = this.templateName
    const ids = this.ticketsId.join(',')
    getBatchTemplateBlankField(ids).then((res: any) => {
      // console.log('工单详情hhhhh', res)
      this.template = res.template?.fields?.template
      this.templateValue = res?.fieldInfo
      this.templateRelationMap = res?.template?.fields?.relationMap
      this.templateName = res?.template?.fields?.name || ''
      this.templateId = res?.template?.pk
    })
  }

  changeNextProcess (e) {
    this.loadAgentsByTag(e)
    const isLevel3 = this.processLabels.some(l => {
      return l.id === e && l.name === 'LEVEL-3'
    })
    if (isLevel3) {
      ElMessageBox.alert('请按照操作文档进行问题排查，并在问题回复中添加排查截图。', '提示', {
        confirmButtonText: '确定',
        callback: (action) => {
          // ElMessage({
          //   type: 'info',
          //   message: `action: ${action}`,
          // })
        },
      })
    }
  }

  async loadAgentsByTag (tagId: number) : Promise<void> {
    this.handlers = []
    let res = await agent.agentsByTag(tagId)
    this.handlers.push(...res.results)
    while (res.next) {
      res = await agent.agentsByTag(tagId, res.page + 1)
      this.handlers.push(...res.results)
    }
  }

  handleRemove (file) {
    const index = this.fileList.findIndex((fileInfo) => fileInfo.name === file.name)
    if (index >= 0) {
      this.fileList.splice(index, 1)
    }
  }

  handlePreview (file) {
    console.log(file)
  }

  uploadFile (info) {
    // if (this.uploading) {
    //   ElMessage({
    //     type: 'error',
    //     message: `文件 ${info.file.name} 上传失败：其他文件正在上传`
    //   })
    //   info.onError(new Error('其他文件正在上传'))
    //   return
    // }
    this.uploading = true
    const { file } = info
    const formData = new FormData()
    formData.append('file', file, file.name)
    return uploadFile(formData).then((res) => {
      console.log('上传附件', res)
      this.uploading = false
      this.fileList.push({
        name: file.name,
        key: res.fileId,
        url: res.url
      })
      console.log('fileList', this.fileList)
    }, (err) => {
      this.uploading = false
      ElMessage({
        type: 'error',
        message: `文件 ${file.name} 上传失败：${err.errmsg}`
      })
      console.error(err)
      throw Error(`文件 ${file.name} 上传失败：${err.errmsg}`)
    })
  }

  checkImage (filename) {
    return checkImage(filename)
  }

  imageList (filelist) {
    return filelist.filter(file => checkImage(file.name))
  }

  notImageList (filelist) {
    return filelist.filter(file => !checkImage(file.name))
  }

  ticketContinue () {
    this.TemplateField('continue')
  }

  ticketResolved () {
    this.TemplateField('resolved')
  }

  TemplateField (type) {
    const $refs = this.$refs as any
    console.log('refs', $refs.templateGenerator)
    if ($refs.templateGenerator) {
      $refs.templateGenerator.getData().then((data) => {
        // 模版选择
        const value = deepCopy(data)
        const labelInfo = {}
        Object.keys(this.templateRelationMap).forEach((key) => {
          labelInfo[this.templateRelationMap[key].setId] = {
            required: this.templateRelationMap[key].required,
            defaultValue: this.templateRelationMap[key].defaultValue,
            value: getValueFromObject(value, key)
          }
        })
        const postData = {
          templateId: this.templateId,
          fieldInfo: value,
          relationMap: labelInfo,
        }
        this.ruleForm.templateData = postData
        // console.log('模版选择数据', this.ruleFormData)
        if (type === 'continue') {
          this.$emit('proceed', this.ruleFormData)
        } else {
          this.$emit('solved', this.ruleFormData)
        }
      }, () => {
        ElMessage({
          type: 'error',
          message: '模版必填项未选！'
        })
      }).finally(() => {
        // loading.close()
      })
    } else {
      if (type === 'continue') {
        this.$emit('proceed', this.ruleFormData)
      } else {
        this.$emit('solved', this.ruleFormData)
      }
    }
  }
}
