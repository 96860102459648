
import { Options, Vue } from 'vue-class-component'
import SchedulingPersonSelect from '@/components/SchedulingPersonSelect.vue'
import { twoDigits } from '@/utils/utils'
import { getEmployeeScheduling } from '@/api/scheduling'
import { createCommentVNode } from 'vue'

@Options({
  name: 'SchedulingDialog',
  components: {
    SchedulingPersonSelect,
  },
  props: {
    cancel: Function,
    submit: Function,
    dates: Array,
  }
})
export default class Scheduling extends Vue {
  // props
  dates: Array<any> = []
  cancel: any = null
  submit: any = null

  show = true
  showSchedulingPersonSelect = false
  personIdSelectedInit: Array<any> = []
  currentS: any = null
  ruleForm: any = {
    datesSelected: [],
    scheduleList: [
      // {
      //   startTime: '10:00',
      //   endTime: '20:00',
      //   persons: [
      //   ],
      // },
    ]
  }

  scheduleListItemValidator = (rule, value, callback) => {
    if (value.length === 0) {
      callback(new Error('请选择该时间段值班人'))
    } else {
      callback()
    }
  }

  resetTimeSelect (s) {
    s.startTime = '10:00'
    s.endTime = '20:00'
  }

  showSelectPersonDialog (s) {
    this.showSchedulingPersonSelect = true
    this.personIdSelectedInit = s.persons.map(p => p.ldap)
    this.currentS = s
  }

  cancelPersonSelect () {
    this.showSchedulingPersonSelect = false
    this.personIdSelectedInit = []
  }

  submitPersonSelect (e) {
    console.log(e)
    this.showSchedulingPersonSelect = false
    this.currentS.persons = e
    setTimeout(() => {
      const a = this.$refs.ruleFormRef as any
      a.validate()
    }, 0)
  }

  addItem () {
    this.ruleForm.scheduleList.push({
      startTime: '10:00',
      endTime: '20:00',
      persons: [
      ],
    })
  }

  submitSchedule () {
    const a = this.$refs.ruleFormRef as any
    a.validate((valid) => {
      if (valid) {
        this.submit(this.ruleForm)
      } else {
        console.log('error submit!!')
        return false
      }
    })
  }

  deletePerson (s, i) {
    s.persons.splice(i, 1)
    const a = this.$refs.ruleFormRef as any
    a.validate()
  }

  adjustEndTime (s) {
    if (s.startTime >= s.endTime) {
      s.endTime = twoDigits(Number(s.startTime.split(':')[0]) + 1) + ':00'
    }
  }

  mounted () {
    this.ruleForm.datesSelected.push(...this.dates)
    // TODO 修改排班
    if (this.dates.length === 1) {
      getEmployeeScheduling(this.dates[0].year, this.dates[0].month, this.dates[0].date).then(res => {
        console.log(res)
        this.ruleForm.scheduleList = res.map(e => ({
          startTime: twoDigits(e.startTime) + ':00',
          endTime: twoDigits(e.endTime) + ':00',
          persons: e.person.map(p => ({
            ldap: p.ldap,
            name: p.name,
            department: p.department?.[0]?.cName
          }))
        }))
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
