<template>
<div>
  <el-dialog
    v-model="dialogFormVisible"
    :title="!isEdit ? '添加外包人员' : '编辑外包人员'"
    width="400px"
    destroy-on-close
    :close-on-click-modal="false"
    center
  >
    <el-form v-loading="loading" :model="form" label-width="130px" ref="RefForms" :rules="rules" class="add-or-edit-without-persons-forms">
      <el-form-item label="姓名" prop="name">
        <el-input v-model="form.name" autocomplete="off" placeholder="请输入姓名" />
      </el-form-item>
      <el-form-item label="绑定微信手机号" prop="telephone">
        <el-input v-model="form.telephone" oninput="value=value.replace(/[^\d]/g,'')" autocomplete="off" placeholder="请输入手机号" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" v-if="!isEdit" @click="AddWithout('RefForms')">确定</el-button>
        <el-button type="primary" v-else @click="EditWithout('RefForms')">编辑</el-button>
      </span>
    </template>
  </el-dialog>
</div>
</template>
<script>
import { addOutsourcePerson, editOutsourcePerson } from '@/api/label'
import { deepCopy } from '@/utils/utils'
export default {
  props: {
    show: Boolean,
    editObj: Object,
  },
  name: 'AddOrEditOutsourcePersons',
  data () {
    const checkEmail = (rule, value, callback) => {
      const regEmail = /^([a-zA-Z0-9]+[_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\-|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
      if (!regEmail.test(value)) {
        callback(new Error('email格式不正确'))
      } else {
        callback()
      }
    }
    return {
      form: {
        name: null,
        telephone: null,
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入客户邮箱', trigger: 'blur' },
          { required: true, validator: checkEmail, trigger: 'blur' },
        ],
        telephone: [
          { required: true, message: '请输入绑定微信手机号', trigger: 'blur' }
        ],
      },
      loading: false,
      uuid: undefined,
    }
  },
  emits: ['update:show', 'update'],
  computed: {
    dialogFormVisible: {
      get () {
        return this.show
      },
      set (v) {
        this.$emit('update:show', v)
      }
    },
    isEdit () {
      return this.editObj !== null
    },
  },
  methods: {
    async AddWithout (RefForms) {
      // 添加外包人员
      await this.$refs[RefForms].validate()
      this.loading = true
      const obj = deepCopy(this.form)
      try {
        await addOutsourcePerson(obj)
        this.$emit('update')
        this.$message({
          type: 'success',
          message: '添加外包人员成功'
        })
        this.dialogFormVisible = false
      } catch (e) {
        this.$message({
          type: 'error',
          message: e.errmsg
        })
      } finally {
        this.loading = false
      }
    },
    async EditWithout (RefForms) {
      // 编辑
      await this.$refs[RefForms].validate()
      this.loading = true
      const obj = deepCopy(this.form)
      try {
        await editOutsourcePerson(this.uuid, obj)
        this.$emit('update')
        this.$message({
          type: 'success',
          message: '编辑外包人员成功'
        })
        this.dialogFormVisible = false
      } catch (e) {
        this.$message({
          type: 'error',
          message: e.errmsg
        })
      } finally {
        this.loading = false
      }
    },
  },
  mounted () {
    // console.log('edit外包', this.editObj)
    if (this.isEdit) {
      // const form_ = deepCopy(this.editObj)
      this.form = {
        name: this.editObj.name,
        telephone: this.editObj.telephone,
      }
      this.uuid = this.editObj.id
    } else {
      this.form = {
        name: null,
        telephone: null,
      }
      this.uuid = undefined
    }
  },
}
</script>
<style scoped lang="less">
.add-or-edit-without-persons-forms {
  ::v-deep(.el-form-item) {
    margin-bottom: 22px!important;
  }
  ::v-deep(.el-select) {
    width: 100%;
  }
}

</style>
