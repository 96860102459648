<template>
  <div class="page">
    <div class="header-nav infos-0329">
      <span @click="$router.back()" class="go-back">问题列表 / </span>
      <span class="title">{{pageTitle}}</span>
      <span class="em-tel">客服电话：400-061-7890</span>
    </div>
    <div class="content">
      <div class="process" v-if="questionType == 'info'">
        <p>流程进度</p>
        <div style="overflow-x: auto;padding-bottom: 10px;">
          <!-- 问题流程改版 -->
          <el-steps :active="activeProblem" align-center>
            <el-step v-for="(step, index) in stepsList" :key="index">
              <template #icon>
                <div class="icon"></div>
              </template>
              <template #title>
                <div class="step-title color-85">
                  <span class="title">{{formatTitle(step.title)}}</span>
                  <span class="add-name">{{step.addName}}</span>
                  <span class="cost-time">{{step.costTime}}</span>
                </div>
              </template>
              <template #description>
                <div class="step-description">
                  <p class="step-name">{{step.operator}}</p>
                  <p class="step-time">{{formatDate(step.createTime)}}</p>
                </div>
              </template>
            </el-step>
          </el-steps>
        </div>
      </div>
      <div class="frame">
        <!-- 查看问题详情 -->
        <div class="form form2" v-if="questionType == 'info'">
          <el-form label-position="right" label-width="120px" :model="replyForm">
            <el-form-item label="问题编号:">
              <div class="text">{{problemId}}</div>
            </el-form-item>
            <el-form-item label="标题:">
              <div class="text">{{replyForm.title}}</div>
            </el-form-item>
            <el-form-item label="问题描述:">
              <div class="text" v-html="replyForm.describe"></div>
            </el-form-item>
            <el-form-item label="客户联系电话:">
              <div class="text">{{replyForm.telephone}}</div>
            </el-form-item>
            <el-form-item label="" :class="{'last-message': commentsData.length > 0}">
              <div class="files-box" v-if="fileList.length > 0 && notImageList(fileList).length > 0" style="margin-top:10px;">
                <div v-for="(file) in fileList" :key="file.key">
                  <div  v-if="!checkImage(file.name)" class="flex-box">
                    <div class="flex-download">
                      <span style="margin-right: 10px;" class="file-name">{{file.name}}</span>
                      <el-link target="_blank" :href="file.url" :download="file.name">
                        <i class="icon-download"></i>
                      </el-link>
                    </div>
                    <!-- <button @click="deleteFile(index)">删除</button> -->
                  </div>
                </div>
              </div>
              <div v-if="fileList.length > 0 && imageList(fileList).length > 0" class="attach-img-box">
                <template v-for="(file) in fileList">
                  <div v-if="checkImage(file.name)"  :key="file.key" class="img-div">
                    <el-image
                      :src="file.url"
                      :preview-src-list="[file.url]"
                      style="width: 100px; height: 80px">
                    </el-image>
                    <!-- <span class="icon-del" @click="deleteFile(index)"></span> -->
                  </div>
                </template>
              </div>
            </el-form-item>
            <template v-if="commentsData.length > 0">
              <el-form-item label="客户评论:" v-for="(k, i) in commentsData" :key="i">
                <div class="text">{{k.content}}</div>
              </el-form-item>
            </template>
            <!-- <el-form-item label="客户评论:">
              <div class="text">{{replyForm.title}}</div>
            </el-form-item> -->
          </el-form>
        </div>
        <div class="message" v-if="activeProblem !== 2">
          <div class="label-message">客户评论：</div>
          <el-input v-model="messageDesc" type="textarea" placeholder="可填写对此问题的加急评论" />
          <el-button @click="submitMessage">提 &nbsp;交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  loadGuestListOnew, loadPostGuestComment, loadGetGuestComment
} from '@/api/problem'
import * as ticketApi from '@/api/ticket'
import store from '../../store'
import { urgentLevels } from '../../api/label'
import { hashCode, deepCopy, checkImage } from '../../utils/utils'
import { ElLoading } from 'element-plus'

export default {
  name: 'createQuestion',
  components: {
  },
  // data
  data () {
    const checkTitle = (rule, value, callback) => {
      const title = value.replace(/(^\s*)|(\s*$)/g, '')
      if (title === '') {
        callback(new Error('请输入标题'))
      } else {
        callback()
      }
    }
    return {
      questionType: 'create',
      questionSource: '',
      pageTitle: '',
      problemId: '',
      replyForm: {
        title: '',
        describe: '',
        reply: '',
        source: '',
        urgent: '',
        problemComments: '',
        relateProblemList: '',
        time: '',
        ticketId: '',
        file: {},
        subscribe: ''
      },
      uploading: false,
      fileList: [],
      urgentList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      messageDesc: null,
      rules: {
        title: [
          { required: true, validator: checkTitle, trigger: 'blur' }
        ],
        describe: [
          { required: true, message: '请描述一下问题', trigger: 'blur' }
        ],
        urgent: [
          { required: true, message: '请选择问题紧急程度', trigger: 'blur' }
        ]
        // source: [
        //   { validator: checkSource, trigger: 'blur' }
        // ]
      },
      stepsList: [
        {
          stepTitle: '创建问题',
          title: 'create',
          costTime: '',
          addName: '',
        },
        {
          stepTitle: '进行中',
          title: 'doing',
        },
        {
          stepTitle: '已完成',
          title: 'resolved',
        },
      ],
      ongoing: 0,
      problemProcessMap: {},
      // 问题流程改版
      stepsBarList: [],

      closeQuestionDialog: false,
      optionRelatedIssues: [],
      closeQuestionTitle: '',
      comments: '',
      commentsData: [],
      ticket: {},
      template: {},
      templateValue: {},
      selectLoading: false,
      problemStatus: null,
    }
  },
  computed: {
    userLdapHashCode () {
      const user = store.getters['auth/me']
      return hashCode(user.ldap)
    },
    getStyle () {
      if (this.replyForm.ticketId) {
        return 'width: 100%;padding-left: 50px'
      } else {
        return ''
      }
    },
    activeProblem () {
      console.log(this.problemStatus)
      if (this.problemStatus === 0 || this.problemStatus === 2) {
        return 1
      }
      if (this.problemStatus === 1 || this.problemStatus === -1) {
        return 2
      }
      return 0
    },
    userEmaril () {
      return store.getters['auth/me'].email
    },
  },
  created () {
    const user = store.getters['auth/me']
    console.log(user)
    this.questionSource = `${user?.group?.[0]?.groupName} - ${user?.username}`
    const query = this.$route.query
    if (query.problemId) {
      this.problemId = query.problemId
      this.questionType = query.type
      if (this.questionType === 'edit') {
        this.pageTitle = '修改问题'
      } else if (this.questionType === 'info') {
        this.pageTitle = '问题详情'
      }
      this.getProblemInfo()
    } else {
      this.pageTitle = '新建问题'
      this.questionType = 'create'
    }
  },
  mounted () {
    // this.commentFetch()
  },
  // methods
  methods: {
    getProblemInfo () {
      const id = this.problemId
      loadGuestListOnew(id).then((res) => {
        console.log(res, 'res')
        const date = res.createTime.split(' ')[0]
        const t = res.createTime.split(' ')[1].split(':').slice(0, 2).join(':')
        this.replyForm = {
          title: res.problemTitle,
          describe: res.problemDescribe,
          source: res.problemOrigin,
          urgent: res.urgentLevel || '',
          problemComments: res.problemComments || '',
          relateProblemList: res.relateProblemList || '',
          time: date + ' ' + t,
          ticketId: res.ticketId,
          file: res.problemFiles || [],
          subscribe: String(res.subscribe),
          telephone: res.telephone,
          creator: res.creator,
          creatorName: res.creatorName
        }
        this.fileList = res.problemFiles || []
        this.questionSource = res.problemOrigin || ''
        this.replyForm.relateProblemList = this.replyForm.relateProblemList.replace(/\[|]|'/g, '')
        this.problemStatus = res.problemStatus
        this.stepsList[0].costTime = this.replyForm.time
        this.stepsList[0].addName = this.replyForm.creatorName
        this.commentFetch()
        // this.loadTicketDetail()
      }, (err) => {
        this.$message({
          type: 'error',
          message: err.errmsg
        })
        console.error(err)
      })
    },
    handlePreview (file) {
      console.log(file)
    },
    switchForm () {
      this.$router.back()
    },
    formatDate (date) {
      if (date) {
        const two = (n) => n < 10 ? '0' + n : '' + n
        const time = new Date(date * 1000)
        return `${time.getFullYear()}-${two(time.getMonth() + 1)}-${two(time.getDate())} ${two(time.getHours())}:${two(time.getMinutes())}`
      } else {
        return ''
      }
    },
    formatTime (time) {
      let theTime = parseInt(time)// 秒
      let min = 0// 分
      let hour = 0// 小时
      if (theTime > 59) {
        min = parseInt(theTime / 60)
        theTime = parseInt(theTime % 60)
        if (min > 59) {
          hour = parseInt(min / 60)
          min = parseInt(min % 60)
        }
      } else {
        return theTime + '秒'
      }
      // let result = '' + parseInt(theTime) + '秒'
      let result = ''
      if (min > 0) {
        result = '' + parseInt(min) + '分钟' + result
      }
      if (hour > 0) {
        result = '' + parseInt(hour) + '小时' + result
      }
      return result
    },
    formatTitle (title) {
      switch (title) {
        case 'create':
          return '创建问题'
        case 'doing':
          return '进行中'
        case 'resolved':
          return '已完成'
        case 'closed':
          return '已关闭'
        default:
          return ''
      }
    },
    checkImage (filename) {
      return checkImage(filename)
    },
    imageList (filelist) {
      return filelist.filter(file => checkImage(file.name))
    },
    notImageList (filelist) {
      return filelist.filter(file => !checkImage(file.name))
    },
    submitMessage () {
      if (this.messageDesc === '' || this.messageDesc === null) {
        this.$message.warning('请输入您要评论的内容')
        return false
      } else {
        loadPostGuestComment({
          problemId: this.problemId,
          content: this.messageDesc,
          creator: this.userEmaril
        }).then(res => {
          console.log(res)
          this.$message.success('评论成功')
          this.commentFetch()
        }).catch((error) => {
          this.$message.error(error.errmsg)
        })
      }
    },
    commentFetch () {
      this.messageDesc = ''
      loadGetGuestComment({
        problemId: this.problemId,
        creator: this.userEmaril
      }).then(res => {
        console.log(res)
        this.commentsData = res
      })
    }
  },
}
</script>

<style lang="less" scoped>
@import "../../assets/style/home.less";
.ticket-add-tag-dialog {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 16px 24px;
  }
}
button {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
}
.urgent-select {
  width: 100%;
}
::v-deep {
  .tox-tinymce {
    border-color: #DCDFE6;
    border-radius: 3px;
  }
}
.content {
  flex-grow: 1;
  margin-top: -8px;
  justify-content: flex-start;
  flex-direction: column;
  .process {
    background: #fff;
    margin-bottom: 8px;
    padding: 8px 16px 0;
    font-size: 16px;
    ::v-deep(.el-steps) {
      .el-step {
        min-width: 130px;
        .el-step__line {
          height: 2px;
          top: 12px;
          background-color: #e9e9e9;
        }
      }
      .el-step__icon.is-text {
        border: 0;
      }
      .icon {
        width: 8px;
        height: 8px;
        background: #bfbfbf;
        border-radius: 50%;
      }
      .step-title {
        position: relative;
      }
      .step-title {
        font-size: 16px;
        color: rgba(0,0,0,0.45);
      }
      .step-name {
        font-size: 14px;
        color: rgba(0,0,0,0.65);
      }
      .step-time {
        font-size: 12px;
        color: rgba(0,0,0,0.45);
      }
      .is-process {
        .step-title {
          // color: rgba(0,0,0,0.85);
          color: #4b79f3;
        }
        .icon {
          background: #4b79f3;
        }
      }
      .is-finish {
        .step-title {
          color: rgba(0,0,0,0.65);
          .title {
            font-size: 16px;
            color: #4b79f3;
          }
          .add-name {
            color: #595959;
            font-size: 14px;
          }
          .cost-time {
            color: rgba(0, 0, 0, 0.45);
            font-size: 14px;
          }
        }
        .icon {
          background: #4b79f3;
        }
        .el-step__line {
          background-color: #4b79f3;
        }
      }
      #urge {
        height: 21px;
        line-height: 21px;
        padding: 0 6px;
        border-radius: 2px;
        background: #4b79f3;
        color: #fff;
        font-size: 12px;
      }
      .feishu-icon {
        display: inline-block;
        width: 13px;
        height: 13px;
        background: url(../../assets/images/feishu-icon.svg);
        position: relative;
        top: 2px;
        margin-left: 6px;
      }
      #urged {
        height: 21px;
        line-height: 20px;
        padding: 0 6px;
        border-radius: 2px;
        background: #c3c3c9;
        color: #ffffff;
        font-size: 12px;
      }
      .icon-gou {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: url(../../assets/images/gou.svg);
        vertical-align: middle;
        margin-left: 4px;
      }
    }
  }
  .frame {
    display: flex;
    flex-direction: column;
    // justify-content: flex-start;
    flex-grow: 1;
    > .form2 {
      width: 100%;
      overflow-y: auto;
    }
    .left {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-grow: 0;
      .tag {
        // width: 30vw;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 25px 16px 0 0;
        margin: 0px 6px 0px 0px;
        min-width: 279px;
        flex-grow: 1;
      }
    }
    .message {
      height: 100px;
      border-top: 1px solid #E8E8E8;
      background: #fff;
      width: 100%;
      // background: chocolate;
      padding: 10px;
      display: flex;
      flex-direction: row;
      .label-message {
        width: 20%;
        text-align: right;
        padding-right: 10px;
        line-height: 40px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 15px;
        font-weight: 400;
      }
      :deep(.el-textarea) {
        width: 60%;
        height: 100%;
        font-size: 15px;
        resize: none;
        .el-textarea__inner {
          resize: none;
          height: 100%;
        }
      }
      :deep(.el-button) {
        width: 70px;
        height: 32px;
        padding: 0;
        min-height: 32px;
        background: #4B79F3;
        color: #fff;
        font-size: 14px;
        margin-left: 20px;
        margin-top: 10px;
      }
    }

  }
  .detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div:first-child {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 110px;
      > :first-child {
        margin-right: 8px;
        font-size: 16px;
      }
    }
  }
  #btn-box .subscribe-btn {
    margin-left: 16px;
    background: rgba(53,73,224,0.10);
    border: 1px solid #4b79f3;
    color: #4b79f3;
  }
}
.infos-0329 {
  position: relative;
  .em-tel {
    position: absolute;
    right: 36px;
    top: 50%;
    transform: translateY(-50%);
    color: #000;
    font-size: 15px;
  }
}
.color-85 {
  // height: 80px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  line-height: 20px;
}
.last-message {
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(196, 196, 196, 0.32);
}
</style>

<style lang="less">
.el-select-dropdown__item.selected {
  color: #4b79f3;
}
</style>
