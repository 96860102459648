<template>
  <el-form
    size="mini"
    label-width="85px"
    :disabled="disabled"
    ref="generateForm"
    :rules="rules"
    :model="models"
  >
    <template-field-render
      style="margin-bottom: 15px"
      v-for="field in templateInfo.list"
      :field-detail="field"
      :key="field.options.model"
      v-model:modelValue="models[field.options.model]"
      v-bind="templateInfo.options"
      @update:model-value="$emit('change', models)"
    />
  </el-form>
</template>

<script>
import { deepCopy } from '@/utils/utils'
import { postTreatmentModules } from '@/components/TemplateMaking/TemplateField/TemplateFieldConfigForm'
import TemplateFieldRender from '@/components/TemplateMaking/TemplateField/TemplateFieldRender.vue'

export default {
  name: 'TemplateEditor',
  data () {
    return {
      templateInfo: {},
      models: {},
      rules: []
    }
  },
  emits: ['change', 'update:value'],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    templateData: {
      type: Object,
      require: true
    },
    value: {
      type: Object,
      require: false
    }
  },
  watch: {
    templateData: {
      handler (val) {
        this.initTemplate(val)
      },
      deep: true,
      immediate: true
    }
  },
  components: {
    TemplateFieldRender
  },
  methods: {
    initTemplate (templateData) {
      this.models = {}
      this.templateInfo = deepCopy(templateData)
      this.templateInfo.list.forEach((field) => {
        field.configList.forEach((config) => {
          postTreatmentModules[config].bind(this)(field)
        })
        if (!field.options.model) {
          return
        }
        if (this.value[field.options.model] !== undefined) {
          this.models[field.options.model] = this.value[field.options.model]
        } else {
          this.models[field.options.model] = field.options.defaultValue
        }
        if (field.rules && Array.isArray(field.rules) && field.rules.length > 0) {
          if (this.rules[field.options.model] && Array.isArray(this.rules[field.options.model])) {
            this.rules[field.options.model] = [...this.rules[field.options.model], ...field.rules]
          } else {
            this.rules[field.options.model] = field.rules
          }
        }
      })
    },
    getData () {
      return new Promise((resolve, reject) => {
        this.$refs.generateForm.validate(valid => {
          if (valid) {
            resolve(this.models)
          } else {
            reject(new Error('表单数据校验失败').message)
          }
        })
      })
    }
  },
  mounted () {
    this.$watch('models', (newValue) => {
      this.$emit('update:value', newValue)
    }, {
      deep: true,
    })
  }
}
</script>

<style scoped>

</style>
