<template>
  <el-menu :default-active="activatedTemplate" :default-openeds="openTemplate" class="el-menu-vertical-demo"
    :collapse="false">
    <el-submenu :index="template.id" v-for="(template) in templateList" :key="template.id">
      <template #title>
        <span>{{ template.name }}</span>
      </template>
      <el-submenu :index="String(item.id)" v-for="(item) in template?.child" :key="item.id">
        <template #title>{{ item.name }} ({{ item?.child?.length }})</template>
        <el-menu-item :index="String(i.id)" v-for="(i, idx) in item?.child" :key="i.id" @click="goDetailTemplate(i,idx)">
          <template #title>{{ i.name }}
            <i class="el-icon-delete delete-icon" @click.stop="deleteTemplate(i)" v-if="edit"></i>
          </template>
        </el-menu-item>
      </el-submenu>
    </el-submenu>
  </el-menu>
</template>

<script>
export default {
  props: {
    oriTemplateList: {
      type: Array
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  emits: ['select', 'delete'],
  data () {
    return {
      templateList: [],
      openTemplate: [],
      activatedTemplate: null
    }
  },
  activated () {
    if (localStorage.getItem('editTemplate') && localStorage.getItem('editTemplate') !== 'null') {
      this.activatedTemplate = localStorage.getItem('editTemplate')
    } else {
      this.activatedTemplate = '35'
    }
    if (this.edit) {
      this.$router.push({ name: '编辑模板', params: { templateId: this.activatedTemplate } })
    } else {
      this.$nextTick(() => {
        this.$emit('select', { id: this.activatedTemplate })
      })
    }
  },
  methods: {
    formateData (res) {
      const workflowList = res?.filter(i => i.hasWorkflow)
      const normalList = res?.filter(i => !i.hasWorkflow)
      const data = [
        {
          name: '工作流模版',
          id: '-1',
          child: [
            { name: '通用模版', id: '-10', child: workflowList?.filter(i => !i.productLine) },
            { name: 'Mall', id: '-11', child: workflowList?.filter(i => i.productLine === 'Mall') },
            { name: 'Store', id: '-12', child: workflowList?.filter(i => i.productLine === 'Store') },
            { name: 'Parking', id: '-13', child: workflowList?.filter(i => i.productLine === 'Parking') },
            { name: 'Airport', id: '-14', child: workflowList?.filter(i => i.productLine === 'Airport') },
            { name: 'Bank', id: '-15', child: workflowList?.filter(i => i.productLine === 'Bank') },
            { name: 'Gate', id: '-16', child: workflowList?.filter(i => i.productLine === 'Gate') },
            { name: 'Robot', id: '-17', child: workflowList?.filter(i => i.productLine === 'Robot') },
            { name: 'Screen', id: '-18', child: workflowList?.filter(i => i.productLine === 'Screen') },
            // { name: 'Other', id: -19, child: workflowList?.filter(i => i.productLine === 'Other') },
          ]
        },
        {
          name: '普通模版',
          id: '-2',
          child: [
            { name: '通用模版', id: '-20', child: normalList?.filter(i => !i.productLine) },
            { name: 'Mall', id: '-21', child: normalList?.filter(i => i.productLine === 'Mall') },
            { name: 'Store', id: '-22', child: normalList?.filter(i => i.productLine === 'Store') },
            { name: 'Parking', id: '-23', child: normalList?.filter(i => i.productLine === 'Parking') },
            { name: 'Airport', id: '-24', child: normalList?.filter(i => i.productLine === 'Airport') },
            { name: 'Bank', id: '-25', child: normalList?.filter(i => i.productLine === 'Bank') },
            { name: 'Gate', id: '-26', child: normalList?.filter(i => i.productLine === 'Gate') },
            { name: 'Robot', id: '-27', child: normalList?.filter(i => i.productLine === 'Robot') },
            { name: 'Screen', id: '-28', child: normalList?.filter(i => i.productLine === 'Screen') },
            // { name: 'Other', id: -19, child: workflowList?.filter(i => i.productLine === 'Other') },
          ]
        }]
      this.templateList = data
      if (localStorage.getItem('editTemplate') && localStorage.getItem('editTemplate') !== 'null') {
        this.activatedTemplate = localStorage.getItem('editTemplate')
      } else {
        this.activatedTemplate = '35'
      }
      if (this.edit) {
        this.$router.push({ name: '编辑模板', params: { templateId: this.activatedTemplate } })
      } else {
        this.$nextTick(() => {
          this.$emit('select', { id: this.activatedTemplate })
        })
      }
    },
    goDetailTemplate (i, idx) {
      this.$emit('select', i, idx)
      localStorage.setItem('editTemplate', i.id)
    },
    deleteTemplate (i) {
      this.$emit('delete', i)
    },
  },
  watch: {
    oriTemplateList: {
      handler (res) {
        if (res) {
          this.formateData(res)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
  max-width: 320px;
  // overflow: auto;
}
</style>
