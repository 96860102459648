<template>
  <div style="height: 100%; width: 100%; overflow-y: hidden;">
  <el-container style="height: 100%; width: 100%;">
    <!-- 组件列表 -->
    <el-aside style="width:105px;">
      <div style="width: 100%; height: 100%; box-sizing: border-box; border: #d9d9d9 1px solid">
        <div class="widget-list">
          <template-field-thumbnail
            class="widget-item"
            v-for="field in fieldList"
            :key="field.type"
            :field-info="field"
            draggable="true"
            @dragstart="dragStart($event, field)"
            @click="addFieldToTemplate(field)"
          />
        </div>
      </div>
    </el-aside>
    <!-- 预览区域 -->
    <el-container>
      <el-header height="20px" style="text-align: right;">
        <button @click="previewDialog = true" class="preview-btn"><i></i>预览</button>
        <!-- <el-button style="float: right" type="text" @click="previewDialog = true">预览</el-button> -->
      </el-header>
      <el-main>
        <div
          class="widget-container-area"
          @dragover.self="dragover"
          @drop.capture="drop"
        >
          <el-form
            size="mini"
            label-width="100px"
          >
            <div
              v-for="(field, index) in templateInfo.list"
              :key="index"
              class="widget-view-container"
              :class="{ active: selectWidget.key === field.key }"
              @click.stop="selectWidget = field"
              @dragover.self="dragoverBro($event, index)"
              draggable="true"
              @dragstart="move($event, field, index)"
            >
              <template-field-render
                :field-detail="field"
                :editable="true"
                @dragover.stop="dragover"
              />
              <div class="widget-view-action" v-if="selectWidget.key === field.key">
                <i class="el-icon-delete" @click.stop="handleWidgetDelete(index)"></i>
              </div>

              <div class="widget-view-drag" v-if="selectWidget.key === field.key">
                <i class="el-icon-plus"></i>
              </div>
            </div>
          </el-form>
        </div>
        <div style="height: 32px; margin-top: 8px; float: right">
          <slot></slot>
        </div>
      </el-main>
    </el-container>
    <!-- 右侧配置区域 -->
    <el-aside>
      <div class="widget-config-area">
        <span>字段配置</span>
        <template-field-config
          v-if="selectWidget"
          :config-list="selectWidget.configList"
          v-model:field-info="selectWidgetMirror"
        />
      </div>
    </el-aside>
    <el-dialog
      v-model="previewDialog"
      destroy-on-close
    >
      <template-generator :template-data="templateInfo" :value="{}" ref="generatorForm"/>
      <template #footer>
        <div style="text-align: center">
          <el-button type="primary" @click="previewGetData">获取数据</el-button>
        </div>
      </template>
    </el-dialog>
  </el-container>
  </div>
</template>

<script>
import fieldList from './TemplateField/TemplateFieldList'
import TemplateFieldRender from '@/components/TemplateMaking/TemplateField/TemplateFieldRender.vue'
import TemplateFieldThumbnail from '@/components/TemplateMaking/TemplateField/TemplateFieldThumbnail.vue'
import TemplateFieldConfig from '@/components/TemplateMaking/TemplateField/TemplateFieldConfig.vue'
import TemplateGenerator from '@/components/TemplateMaking/TemplateGenerator.vue'

export default {
  name: 'TemplateEditor',
  emits: ['update:templateData'],
  data () {
    return {
      fieldList,
      beforeGuide: null,
      newIndex: 0,
      parent: null,
      newParent: null,
      selectWidget: {},
      templateInfo: {},
      internalChange: false,
      previewDialog: false
    }
  },
  props: {
    templateData: {
      type: Object,
      default: () => ({
        list: []
      })
    }
  },
  computed: {
    selectWidgetMirror: {
      get () {
        return this.selectWidget
      },
      set (val) {
        Object.keys(val).forEach((key) => {
          this.selectWidget[key] = val[key]
        })
      }
    }
  },
  watch: {
    templateData: {
      handler (val) {
        if (this.internalChange) {
          return
        }
        if (this.templateInfo === val) {
          return
        }
        this.internalChange = true
        this.templateInfo = val
        this.selectWidget = {}
        this.$nextTick(() => {
          this.internalChange = false
        })
      },
      deep: true,
      immediate: true
    },
    templateInfo: {
      handler (val, oldVal) {
        if (this.internalChange) {
          return
        }
        if (val === oldVal) {
          return
        }
        debugger
        this.internalChange = true
        this.$emit('update:templateData', val)
        this.$nextTick(() => {
          this.internalChange = false
        })
      },
      deep: true,
      immediate: true
    }
  },
  components: {
    TemplateFieldThumbnail,
    TemplateFieldRender,
    TemplateFieldConfig,
    TemplateGenerator
  },
  methods: {
    dragStart (event, field) {
      event.dataTransfer.setData('type', field.type)
      event.dataTransfer.setData('data', JSON.stringify(field))
      event.dataTransfer.setData('action', 'add')
    },
    addFieldToTemplate (field) {
      const data = Object.assign({}, field)
      data.key = new Date().getTime() + '_' + Math.ceil(Math.random() * 99999)
      data.options.model = data.type + '_' + data.key
      if (!data.rules) {
        data.rules = []
      }
      this.selectWidget = data
      this.templateInfo.list.push(data)
    },
    dragover (event) {
      event.preventDefault()
    },
    dragoverBro (event, index, parent = null) {
      event.preventDefault()
      if (this.beforeGuide) {
        this.beforeGuide.classList.remove('guide-line-bottom')
        this.beforeGuide.classList.remove('guide-line-top')
      }
      const bounding = event.target.getBoundingClientRect()
      const offset = bounding.y + (bounding.height / 2)
      if (event.clientY - offset > 0) {
        this.newIndex = index + 1
        event.target.classList.add('guide-line-bottom')
        event.target.classList.remove('guide-line-top')
      } else {
        this.newIndex = index
        event.target.classList.remove('guide-line-bottom')
        event.target.classList.add('guide-line-top')
      }
      this.beforeGuide = event.target
      if (parent !== null) {
        this.newParent = parent
      } else {
        this.newParent = this.templateInfo.list
      }
    },
    drop (event) {
      if (this.beforeGuide) {
        this.beforeGuide.classList.remove('guide-line-bottom')
        this.beforeGuide.classList.remove('guide-line-top')
      }
      const action = event.dataTransfer.getData('action')
      const data = JSON.parse(event.dataTransfer.getData('data'))
      if (action === 'add') {
        data.key = new Date().getTime() + '_' + Math.ceil(Math.random() * 99999)
        data.options.model = data.type + '_' + data.key
        if (!data.options) {
          data.options = {}
        }
        const parent = this.newParent !== null ? this.newParent : this.templateInfo.list
        if (!data.rules) {
          data.rules = []
        }
        if (parent.length > 0 && this.newIndex !== null) {
          parent.splice(this.newIndex, 0, data)
        } else {
          parent.push(data)
        }
      } else if (action === 'sort') {
        const originIndex = event.dataTransfer.getData('index')
        if (this.parent === this.newParent) {
          if (originIndex === this.newIndex) {
            return
          }
          if (originIndex > this.newIndex) {
            this.parent.splice(originIndex, 1)
            this.parent.splice(this.newIndex, 0, data)
          } else {
            this.parent.splice(this.newIndex, 0, data)
            this.parent.splice(originIndex, 1)
          }
        } else {
          this.parent.splice(originIndex, 1)
          this.newParent.splice(this.newIndex, 0, data)
        }
      }
      this.selectWidget = data
      this.newIndex = null
      this.parent = null
      this.newParent = null
    },
    handleWidgetDelete (index) {
      this.templateInfo.list.splice(index, 1)
      this.selectWidget = {}
    },
    move (event, field, index, parent = null) {
      event.dataTransfer.setData('index', index)
      event.dataTransfer.setData('data', JSON.stringify(field))
      event.dataTransfer.setData('action', 'sort')
      if (parent !== null) {
        this.parent = parent
      } else {
        this.parent = this.templateInfo.list
      }
    },
    previewGetData () {
      this.$refs.generatorForm.getData().then((data) => {
        this.$alert(data)
      })
    }
  }
}
</script>

<style scoped>
  .widget-list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
  .widget-list ::v-deep(.widget-item) {
    margin-left: 10px;
    margin-top: 10px;
    cursor: move;
    -moz-user-select:none; /* Firefox私有属性 */
    -webkit-user-select:none; /* WebKit内核私有属性 */
    -ms-user-select:none; /* IE私有属性(IE10及以后) */
    user-select:none; /* CSS3属性 */
  }
  .widget-list ::v-deep(.widget-item:first-child) {
    margin-right: 0;
  }
  .guide-line-top {
    border-top: solid #1733cd 3px !important;
  }

  .guide-line-bottom {
    border-bottom: solid #1733cd 3px !important;
  }

  .widget-view-container {
    width: 100%;
    /* background-color: rgba(236,245,255, 3); */
    background-color: #f6f6f6;
    padding: 5px;
    margin-bottom: 10px;
    /* border: 1px black dashed; */
    position: relative;
    border-radius: 2px;
  }

  .widget-view-action {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 28px;
    line-height: 28px;
    z-index: 9;
  }

  .widget-view-drag {
    position: absolute;
    left: -2px;
    top: -2px;
    bottom: -18px;
    height: 28px;
    line-height: 28px;
    z-index: 9;
  }

  .widget-view-action i, .widget-view-drag i {
    font-size: 14px;
    margin: 0 5px;
  }

  .widget-view-action i:hover {
    color: blue;
    cursor: pointer;
  }

  .widget-view-drag i:hover {
    color: blue;
    cursor: move;
  }

  .active {
    /* outline: 1px blue solid; */
    background: rgba(75,121,243,0.16);
    border: 1px solid #4b79f3;
    border-radius: 3px;
  }

  .widget-config-area {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: #d9d9d9 1px solid;
    text-align: initial;
    padding: 5px;
    overflow-y: auto;
  }

  #app .widget-config-area ::v-deep(.el-form-item) {
    margin-bottom: 10px;
  }
  .widget-container-area {
    width: 100%;
    height: calc(100% - 40px);
    text-align: initial;
    border: 1px #d9d9d9 dashed;
    overflow-y: auto;
    padding: 6px;
  }

  ::v-deep(.el-main) {
    /* padding-bottom: 0; */
    padding: 10px 20px 0 20px;
  }
  .preview-btn {
    border: none;
    background: none;
    cursor: pointer;
    color: #4b79f3;
    font-size: 15px;
  }
  .preview-btn i {
    display: inline-block;
    height: 18px;
    width: 18px;
    background: url(../../assets/images/preview-icon.svg);
    margin-right: 8px;
    vertical-align: middle;
    position: relative;
    top: -1px;
  }
</style>
