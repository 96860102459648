<template>
    <div class="chart-item" v-loading="loading">
        <div style="display: flex;height: 47px; background: #fff;margin-bottom: 1px;padding: 12px; flex-direction: row;position: relative">
            <p
            id="handleAvg"
            class="changeColor"
            style=" margin-left: 16px;margin-right:16px;"
            @click="showBar1"
            >处理平均用时</p><hr class="hrColor" style="width:46px;height:2px;position:absolute;left: 47px;top: 45px">
            <p
            id="responseAvg"
            @click="showBar2"
            >平均响应用时</p><hr style="width:46px;height:2px;position:absolute;left: 147px; top:45px">
            <div id="checkBar" style="position: absolute;right:16px;display: flex" v-show="checkShow">
              <el-checkbox
              @change="allCheck"
              v-model="checkAll"
              style="margin-right: 16px"
              >全部工单</el-checkbox>
              <!-- <el-checkbox
              class="checkboxClicked1"
              :class="{ checkbox1: checkHandel }"
              v-model="checkHandel"
              @change="handleCheck"
              style="margin-right: 16px; display: none"
              >{{legend[1]}}</el-checkbox>
              <el-checkbox
              class="checkboxClicked2"
              :class="{ checkbox2: checkResponse }"
              v-model="checkResponse"
              @change="responseCheck"
              style="margin-right: 16px; display: none"
              >{{legend[2]}}</el-checkbox> -->
            </div>
        </div>
        <div style="overflow-x: auto;overflow-y: hidden;">
          <el-scrollbar>
          <div
          id="bar1-chart1"
          style="background: #fff;min-width:800px"
          v-show="showChart"></div>
          <!-- {
            type: 'value',
            name: `产品线耗时${avgData1} h`,
            nameTextStyle: {
              padding: [-10, 0, 10, 0],
              color: '#628AF4',
              fontSize: 13,
            },
          } -->
          <p class="pic3haoshi" v-show="showChart">产品线平均处理用时 {{ avgData1 }} h</p>
          </el-scrollbar>
        </div>
        <div style="overflow-x: auto;overflow-y: hidden;">
          <el-scrollbar>
          <div
          id="bar1-chart2"
          style="background: #fff;min-width:800px"
          v-show="!showChart"></div>
          <p class="pic3haoshi" v-show="!showChart">产品线平均响应用时 {{ avgData2 }} h</p>
          </el-scrollbar>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts'
import moment from 'moment'
import { dashboard } from '@/api/dashboard'

export default {
  name: 'DashboardBar1',
  props: {
    obj2: Object,
  },
  data () {
    return {
      loading: false,
      checkShow: true,
      showChart: true,
      checkedHandels: [],
      checkAll: false,
      isIndeterminate: true,
      // checkResponse: false,
      // checkHandel: false,
      pieStartTime: '',
      pieEndTime: '',
      newObj: null,
      legend: [],
      newLegend: [],
      xAxis1: [],
      xAxis2: [],
      data1: [],
      data2: [],
      data3: [],
      data4: [],
      data5: [],
      data6: [],
      extre: '',
      avgData1: '',
      avgData2: '',
    }
  },
  methods: {
    async loadData () {
      if (typeof this.newObj.extreme === 'string') {
        if (this.newObj.extreme.charAt(this.newObj.extreme.length - 1) === '%') {
          this.extre = this.newObj.extreme.substring(0, this.newObj.extreme.length - 1)
        }
      } else {
        this.extre = this.newObj.extreme
      }
      let data
      try {
        data = await dashboard({
          exportData: 0,
          ticketType: this.newObj.alarmValue,
          extremum: this.extre,
          startDate: this.pieStartTime,
          endDate: this.pieEndTime,
          channel: this.newObj.channel,
          businessLine: this.newObj.product,
          level: this.newObj.level,
          negationExtremum: this.newObj.negationExtremum,
          // otherTags: this.newObj.otherTags,
          searchType: 3,
        })
      } catch (e) {
        console.error(e)
        return
      }
      console.log('data', data)
      this.legend = data.legend
      for (let i = 0; i < data.legend.length; i++) {
        this.newLegend.push(data.legend[i + 1])
      }
      const dataArr = data.handlingAverageData.map(item => {
        return [item.data, ...item.counts.slice(1, 4)]
      })
      dataArr.forEach((r, index, arr) => {
        arr[index] = r.map((e, i) => {
          if (i > 0) {
            return Number((Number(e) / 60 / 60).toFixed(2))
          }
          return e
        })
      })
      this.data1 = dataArr
      this.xAxis1 = data.handlingAverageData.map(item => {
        return item.data
      })
      this.data2 = data.handlingSumData.map(item1 => {
        data.handlingAverageData.map(item2 => {
          if (item1.data === item2.data) {
            item1.maxHandlingTimeCost = item2.maxHandlingTimeCost.map(i => (Number(i) / 60 / 60).toFixed(2))
          }
          return item1
        })
        return item1
      })
      this.data5 = data.handlingAverageData.map(item => {
        if (Number(item.counts[0]) > 0) {
          item.counts[0] = Number((Number(item.counts[0]) / 60 / 60).toFixed(2))
          return item.counts[0]
        } else {
          item.counts[0] = 0
          return item.counts[0]
        }
      })
      this.data6 = data.responseAverageData.map(item => {
        if (Number(item.counts[0]) > 0) {
          item.counts[0] = Number((Number(item.counts[0]) / 60 / 60).toFixed(2))
          return item.counts[0]
        } else {
          item.counts[0] = 0
          return item.counts[0]
        }
      })
      console.log('this.data6', this.data6)
      this.xAxis2 = data.responseAverageData.map(item => {
        return item.data
      })
    },
    async barChart1 (avgData1, data2) {
      var chartDom = document.getElementById('bar1-chart1')
      if (!chartDom) return
      var myChart = echarts.getInstanceByDom(chartDom)
      if (myChart) {
        myChart.dispose()
      }
      myChart = echarts.init(chartDom)
      var option
      option = {
        title: {
          x: 16,
          y: 16,
          fontSize: '10'
        },
        legend: {
          // data: this.newLegend,
          icon: 'rect',
          top: '16px',
          left: '16px',
          itemWidth: 10,
          itemHeight: 10,
        },
        color: [
          '#4B79F3', '#37CBCB', '#2246A6'
        ],
        tooltip: {
          formatter: function (params) {
            const hoverData = params.data[0]
            const data = data2.filter(item => {
              if (item.data === hoverData) {
                return item.ticketCount
              }
            })
            const max = data2.filter(item => {
              if (item.data === hoverData) {
                return item.maxHandlingTimeCost
              }
            })
            const legend = params.dimensionNames
            const color = ['#4B79F3', '#37CBCB', '#2246A6']
            let str = ''
            for (let i = 1; i < legend.length - 1; i++) {
              str += `<div style="width:8px;height:8px;background:${color[i - 1]};border-radius:50%;display: inline-block;margin-right:9px"></div>` + legend[i] + ' ' + ':' + ' ' + data[0].ticketCount[i] + ' ' + '个工单' + ' ' + '|' + ' ' + `${params.data[i]} h` + ' ' + ' <br/>' + `&nbsp;&nbsp;&nbsp;&nbsp;最大处理时长 : ${max[0].maxHandlingTimeCost[i]} h <br/>`
            }
            str = params.value[0] + '</br>' + str + '<hr style="width: 200px;height:2px;background-color:#ccc;border-style:solid;margin:5px 5px"></hr>' + `<div style="width:8px;height:8px;background:${color[2]};border-radius:50%;display: inline-block;margin-right:9px"></div>` + legend[3] + ' ' + ':' + ' ' + data[0].ticketCount[3] + ' ' + '个工单' + ' ' + '|' + ' ' + `${params.data[3]} h` + ' ' + ' <br/>' + `&nbsp;&nbsp;&nbsp;&nbsp;最大处理时长 : ${max[0].maxHandlingTimeCost[3]} h <br/>`
            return str
          }
        },
        dataset: {
          source: [
            this.legend,
            ...this.data1
          ]
        },
        grid: {
          left: '13%',
          right: '10%',
          bottom: '15%',
          containLable: true
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            // axisLabel: {
            //   interval: 0,
            //   rotate: 40
            // }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '',
            nameTextStyle: {
              padding: [-10, 60, 10, 0],
              fontSize: 13,
            },
            min: 0,
            axisLabel: {
              formatter: '{value} h',
            }
          },
        ],
        series: [
          {
            type: 'bar',
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: 'top',
                  formatter: function (params) {
                    if (params.value[1] > 0) {
                      return params.value[1] + 'h'
                    } else {
                      return ''
                    }
                  },
                  textStyle: {
                    fontSize: 14
                  },
                },
              }
            },
          },
          {
            type: 'bar',
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: 'top',
                  formatter: function (params) {
                    if (params.value[2] > 0) {
                      return params.value[2] + 'h'
                    } else {
                      return ''
                    }
                  },
                  textStyle: {
                    fontSize: 14
                  },
                },
              }
            },
          },
          {
            type: 'bar',
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: 'top',
                  formatter: function (params) {
                    if (params.value[3] > 0) {
                      return params.value[3] + 'h'
                    } else {
                      return ''
                    }
                  },
                  textStyle: {
                    fontSize: 14
                  },
                },
              }
            },
          }
        ]
      }
      option && myChart.setOption(option)
      window.addEventListener('resize', function () {
        if (!myChart?.isDisposed()) {
          myChart.resize()
        }
      })
      this.loading = false
    },
    // async barChart2 (xAxis, data3, data4, avgData2) {
    //   var chartDom = document.getElementById('bar1-chart2')
    //   var myChart = echarts.getInstanceByDom(chartDom)
    //   if (myChart) {
    //     myChart.dispose()
    //   }
    //   myChart = echarts.init(chartDom)
    //   var option
    //   option = {
    //     title: {
    //       // text: '全部阶段',
    //       x: 16,
    //       y: 16,
    //       fontSize: '10'
    //     },
    //     grid: {
    //       left: '13%',
    //       right: '13%',
    //       bottom: '10%',
    //       containLable: true
    //     },
    //     tooltip: {
    //       trigger: 'axis'
    //     },
    //     calculable: true,
    //     xAxis: [
    //       {
    //         type: 'category',
    //         // prettier-ignore
    //         data: xAxis
    //       }
    //     ],
    //     yAxis: [
    //       {
    //         type: 'value',
    //         name: '全部阶段',
    //         nameTextStyle: {
    //           padding: [-10, 60, 10, 0],
    //           fontSize: 13,
    //         },
    //         min: 0,
    //         axisLabel: {
    //           formatter: '{value} min'
    //         }
    //       },
    //       {
    //         type: 'value',
    //         name: `产品线耗时${avgData2}min`,
    //         nameTextStyle: {
    //           padding: [-10, 0, 10, 0],
    //           color: '#628AF4',
    //           fontSize: 13,
    //         },
    //       }
    //     ],
    //     series: [
    //       {
    //         type: 'bar',
    //         itemStyle: {
    //           normal: {
    //             color: function (params) {
    //               const colorList = ['#4B79F3', '#4B79F3', '#4B79F3', '#4B79F3', '#4B79F3', '#4B79F3', '#4B79F3']
    //               if (params.dataIndex >= colorList.length) {
    //                 const index = params.dataIndex - colorList.length
    //                 return colorList[index]
    //               }
    //               return colorList[params.dataIndex]
    //             },
    //             label: {
    //               show: true,
    //               position: 'top',
    //               formatter: function (params) {
    //                 if (params.value > 0) {
    //                   return params.value + 'min'
    //                 } else {
    //                   return ''
    //                 }
    //               },
    //               textStyle: {
    //                 fontSize: 14
    //               },
    //             },
    //           }
    //         },
    //         data: data3,
    //       },
    //       {
    //         type: 'bar',
    //         itemStyle: {
    //           normal: {
    //             color: function (params) {
    //               const colorList = ['#37CBCB', '#37CBCB', '#37CBCB', '#37CBCB', '#37CBCB', '#37CBCB', '#37CBCB']
    //               if (params.dataIndex >= colorList.length) {
    //                 const index = params.dataIndex - colorList.length
    //                 return colorList[index]
    //               }
    //               return colorList[params.dataIndex]
    //             },
    //             label: {
    //               show: true,
    //               position: 'top',
    //               formatter: function (params) {
    //                 if (params.value > 0) {
    //                   return params.value + 'min'
    //                 } else {
    //                   return ''
    //                 }
    //               },
    //               textStyle: {
    //                 fontSize: 14
    //               },
    //             },
    //           }
    //         },
    //         data: data4,
    //       }
    //     ]
    //   }
    //   option && myChart.setOption(option)
    //   window.addEventListener('resize', function () {
    //     myChart.resize()
    //   })
    //   this.loading = false
    // },
    async barChart3 (xAxis, data, avgData1) {
      var chartDom = document.getElementById('bar1-chart1')
      if (!chartDom) return
      var myChart = echarts.getInstanceByDom(chartDom)
      if (myChart) {
        myChart.dispose()
      }
      myChart = echarts.init(chartDom)
      var option
      option = {
        title: {
          // text: '全部阶段',
          x: 16,
          y: 16,
          fontSize: '10'
        },
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '13%',
          right: '10%',
          bottom: '15%',
          containLable: true
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            data: xAxis,
            // axisLabel: {
            //   interval: 0,
            //   rotate: 40
            // }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '全部阶段',
            nameTextStyle: {
              padding: [-10, 60, 10, 0],
              fontSize: 13,
            },
            min: 0,
            axisLabel: {
              formatter: '{value} h'
            }
          },
          // {
          //   type: 'value',
          //   name: `产品线耗时${avgData1}h`,
          //   nameLocation: 'center',
          //   nameTextStyle: {
          //     padding: [-10, 0, 10, 0],
          //     color: '#628AF4',
          //     fontSize: 13,
          //   },
          // }
        ],
        series: [
          {
            type: 'bar',
            barWidth: 30,
            itemStyle: {
              normal: {
                color: function (params) {
                  const colorList = ['#1890FF', '#1890FF', '#1890FF', '#1890FF', '#1890FF', '#1890FF', '#1890FF']
                  if (params.dataIndex >= colorList.length) {
                    const index = params.dataIndex - colorList.length
                    return colorList[index]
                  }
                  return colorList[params.dataIndex]
                },
                label: {
                  show: true,
                  position: 'top',
                  formatter: function (params) {
                    if (params.value > 0) {
                      return params.value + 'h'
                    } else {
                      return ''
                    }
                  },
                  textStyle: {
                    fontSize: 14
                  },
                },
              }
            },
            data: data,
          },
        ]
      }
      option && myChart.setOption(option)
      window.addEventListener('resize', function () {
        if (!myChart?.isDisposed()) {
          myChart.resize()
        }
      })
      this.loading = false
    },
    async barChart4 (xAxis, data, avgData2) {
      console.log(xAxis, data)
      var chartDom = document.getElementById('bar1-chart2')
      if (!chartDom) return
      var myChart = echarts.getInstanceByDom(chartDom)
      if (myChart) {
        myChart.dispose()
      }
      myChart = echarts.init(chartDom)
      var option
      option = {
        title: {
          // text: '全部阶段',
          x: 16,
          y: 16,
          fontSize: '10'
        },
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '13%',
          right: '10%',
          bottom: '15%',
          containLable: true
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            data: xAxis,
            // axisLabel: {
            //   interval: 0,
            //   rotate: 40
            // }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '全部阶段',
            nameTextStyle: {
              padding: [-10, 60, 10, 0],
              fontSize: 13,
            },
            min: 0,
            axisLabel: {
              formatter: '{value} h'
            }
          },
          // {
          //   type: 'value',
          //   name: `产品线耗时${avgData2}min`,
          //   nameTextStyle: {
          //     padding: [-10, 0, 10, 0],
          //     color: '#628AF4',
          //     fontSize: 13,
          //   },
          // }
        ],
        series: [
          {
            type: 'bar',
            barWidth: 30,
            itemStyle: {
              normal: {
                color: function (params) {
                  const colorList = ['#1890FF', '#1890FF', '#1890FF', '#1890FF', '#1890FF', '#1890FF', '#1890FF']
                  if (params.dataIndex >= colorList.length) {
                    const index = params.dataIndex - colorList.length
                    return colorList[index]
                  }
                  return colorList[params.dataIndex]
                },
                label: {
                  show: true,
                  position: 'top',
                  formatter: function (params) {
                    if (params.value > 0) {
                      return params.value + 'h'
                    } else {
                      return ''
                    }
                  },
                  textStyle: {
                    fontSize: 14
                  },
                },
              }
            },
            data: data,
          },
        ]
      }
      option && myChart.setOption(option)
      window.addEventListener('resize', function () {
        if (!myChart?.isDisposed()) {
          myChart.resize()
        }
      })
      this.loading = false
    },
    loadAvg () {
      let sum1 = 0; let sum2 = 0; let tem1 = 0; let tem2 = 0
      if (this.data5.length > 0 && this.data6.length > 0) {
        for (let i = 0; i < this.data5.length; i++) {
          sum1 = sum1 + (this.data5[i] * 1)
        }
        for (let i = 0; i < this.data6.length; i++) {
          sum2 = sum2 + (this.data6[i] * 1)
        }
        this.data5.map(item => {
          if (item === 0) {
            tem1++
          }
        })
        this.data6.map(item => {
          if (item === 0) {
            tem2++
          }
        })
      }
      if (sum1 !== 0 && sum2 !== 0) {
        this.avgData1 = (sum1 / (this.data5.length - tem1)).toFixed(2)
        this.avgData2 = (sum2 / (this.data6.length - tem2)).toFixed(2)
      } else {
        this.avgData1 = 0
        this.avgData2 = 0
      }
    },
    async showBar2 (e) {
      this.checkShow = false
      // this.checkHandel = false
      // this.checkResponse = false
      this.showChart = false
      this.loading = true
      e.target.classList.add('changeColor')
      document.querySelectorAll('#handleAvg')[0].classList.remove('changeColor')
      document.querySelectorAll('hr')[1].classList.add('hrColor')
      document.querySelectorAll('hr')[0].classList.remove('hrColor')
      this.$nextTick(async () => {
        await this.barChart4(this.xAxis2, this.data6, this.avgData2)
        this.loading = false
      })
    },
    async showBar1 (e) {
      this.checkAll = false
      this.checkShow = true
      this.showChart = true
      this.loading = true
      e.target.classList.add('changeColor')
      document.querySelectorAll('#responseAvg')[0].classList.remove('changeColor')
      document.querySelectorAll('hr')[0].classList.add('hrColor')
      document.querySelectorAll('hr')[1].classList.remove('hrColor')
      this.$nextTick(async () => {
        await this.barChart1(this.avgData1, this.data2)
        this.loading = false
      })
    },
    pieStartEndTime () {
      [this.pieStartTime, this.pieEndTime] = this.getStartEndDate(this.newObj.dateRange)
    },
    getStartEndDate (dateRange1, type = '') {
      if (dateRange1.length === 0) {
        return []
      }
      const [date1, date2] = dateRange1
      if (type === 'month') {
        date1.setDate(1)
        date2.setDate(1)
        date2.setMonth(date2.getMonth() + 1)
        date2.setDate(date2.getDate() - 1)
      } else if (type === 'week') {
      // 注意：此处以周一为每周起始
        if (date1.getDay() > 1) {
          date1.setDate(date1.getDate() - (date1.getDay() - 1)) // 设置成周一
        } else if (date1.getDay() === 0) {
          date1.setDate(date1.getDate() - (7 - 1)) // 设置成周一
        }
        if (date2.getDay() !== 0) {
          date2.setDate(date2.getDate() + (7 - date2.getDay())) // 设置成周日
        }
      } else {
      // nothing to do
      }
      const f = 'YYYY-MM-DD'
      return [moment(date1).format(f), moment(date2).format(f)]
    },
    allCheck () {
      if (this.checkAll) {
        this.barChart3(this.xAxis1, this.data5, this.avgData1)
      } else {
        this.barChart1(this.avgData1, this.data2)
      }
    },
    // handleCheck () {
    //   if (this.checkHandel && !this.checkResponse) {
    //     this.checkAll = !this.checkHandel
    //     this.loadPie11()
    //     // this.loadPie21()
    //   } else if (this.checkHandel && this.checkResponse) {
    //     this.checkAll = !this.checkHandel
    //     this.loadPie1()
    //     this.loadPie2()
    //   } else if (!this.checkHandel && !this.checkResponse) {
    //     this.loadPie1()
    //     this.loadPie2()
    //   } else if (!this.checkHandel && !this.checkResponse) {
    //     this.loadPie1()
    //     this.loadPie2()
    //   } else if (!this.checkHandel && this.checkResponse) {
    //     this.loadPie12()
    //   }
    // },
    // responseCheck () {
    //   if (this.checkResponse && this.checkHandel) {
    //     this.checkAll = !this.checkResponse
    //     this.loadPie1()
    //     this.loadPie2()
    //   } else if (this.checkResponse && !this.checkHandel) {
    //     this.checkAll = !this.checkResponse
    //     this.loadPie12()
    //     // this.loadPie22()
    //   } else if (!this.checkResponse && !this.checkHandel && !this.checkAll) {
    //     this.loadPie1()
    //     this.loadPie2()
    //   } else if (!this.checkResponse && !this.checkHandel && this.checkAll) {
    //     this.loadPie13()
    //     // this.loadPie23()
    //   } else if (!this.checkResponse && this.checkHandel) {
    //     this.loadPie11()
    //     // this.loadPie21()
    //   }
    // },
    async loadPie1 () {
      await this.loadData()
      await this.loadAvg()
      await this.barChart1(this.avgData1, this.data2)
      this.loading = false
    },
    async loadPie11 () {
      await this.loadData()
      await this.loadAvg()
      await this.barChart1(this.xAxis1, this.data1, [], this.avgData1)
      this.loading = false
    },
    async loadPie12 () {
      await this.loadData()
      await this.loadAvg()
      await this.barChart1(this.xAxis1, [], this.data2, this.avgData1)
      this.loading = false
    },
    async loadPie13 () {
      this.checkAll = true
      await this.loadData()
      await this.loadAvg()
      await this.barChart3(this.xAxis1, this.data5, this.avgData1)
      this.loading = false
    },
    async loadPie2 () {
      await this.loadData()
      await this.loadAvg()
      await this.barChart2(this.xAxis2, this.data3, this.data4, this.avgData2, this.avgData2)
      this.loading = false
    },
    // async loadPie21 () {
    //   await this.loadData()
    //   await this.loadAvg()
    //   await this.barChart2(this.xAxis2, this.data3, [], this.avgData2)
    //   this.loading = false
    // },
    // async loadPie22 () {
    //   await this.loadData()
    //   await this.loadAvg()
    //   await this.barChart2(this.xAxis2, [], this.data4, this.avgData2)
    //   this.loading = false
    // },
    async loadPie24 () {
      await this.loadData()
      await this.loadAvg()
      await this.barChart4(this.xAxis2, this.data6, this.avgData2)
      this.loading = false
    },
  },
  mounted () {
    this.loading = true
    this.$watch(function () {
      return this.obj2
    }, async function (newV, oldV) {
      this.checkAll = false
      this.newObj = newV
      await this.pieStartEndTime()
      await this.loadPie1()
      await this.loadPie24()
      this.loading = false
    }, {
      deep: true
    })
  },
}
</script>
<style lang="less" scoped>
.chart-item {
      width: 100%;
      height: 400px;
      #bar1-chart1, #bar1-chart2 {
          width: 100%;
          height:350px
      }
}
.changeColor {
  color: #628AF4;
}
.hrColor {
  background-color:#628AF4;
}
.checkboxClicked1 {
  // ::v-deep(.el-checkbox__label) {
  //   color:#628AF4;
  // }
      ::v-deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
        background-color:#628AF4;
        border:#628AF4;
      }
}
.checkboxClicked2 {
  // ::v-deep(.el-checkbox__label) {
  //   color:#37CBCB;
  // }
      ::v-deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
        background-color:#37CBCB;
        border:#37CBCB;
      }
}
.checkbox1 {
  ::v-deep(.el-checkbox__label) {
    color: #628af4 !important;;
  }
}
.checkbox2 {
  ::v-deep(.el-checkbox__label) {
    color: #37cbcb !important;;
  }
}
.pic3haoshi {
  position: absolute;
  top: 16px;
  right: 28px;
  color: #628AF4;
  font-size: 13px;
}
</style>
