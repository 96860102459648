<template>
  <div class="page">
    <div class="header-nav">
      <span class="title">外包人员管理</span>
    </div>
    <div class="content1">
      <div class="work-order">
        <div class="search search-tag">
          <label>姓名：</label>
          <el-input
            style="width: 110px;"
            v-model="searchOfName"
            placeholder="输入姓名"
            clearable
            @change="seachFetchAgent"
          />
          <label style="margin-left: 20px;">手机号：</label>
          <el-input
            style="width: 150px;"
            v-model="telephone"
            placeholder="根据手机号搜索"
            clearable
            @change="seachFetchAgent"
          />
          <button @click="seachFetchAgent" class="search-btn">查 询</button>
          <button @click="addPersonTags" class="add-problem add-person-tags2">添加外包人员</button>
        </div>
      </div>
      <div class="data-table" style="margin:0;">
        <el-table
          fit
          border
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%">
          <el-table-column
            prop="name"
            label="姓名"
            min-width="120">
          </el-table-column>
          <el-table-column
            prop="telephone"
            label="手机号"
            min-width="120">
          </el-table-column>
          <el-table-column
            prop="creatorName"
            label="添加人"
            min-width="120">
          </el-table-column>
          <el-table-column
            label="操作"
            min-width="150">
            <template #default="{ row }">
              <div class="operation-box">
                <button @click="editPerson(row)" class="operation">编辑</button>
                <span>|</span>
                <button @click="delPerson(row)" class="operation un-operation">删除</button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-wrap" v-if="totalCount > 0">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="prev, pager, next, sizes, jumper, total"
            :total="totalCount"
            :page-count="numPages">
          </el-pagination>
        </div>
      </div>
      <AddOrEditOutsourcePersons
        v-model:show="AddOrEditOutsourcePersonsShow"
        v-if="AddOrEditOutsourcePersonsShow"
        :edit-obj="editObj"
        @update="seachFetchAgent"
      />
    </div>
  </div>
</template>

<script>
import { getOutsourcePerson, deleteOutsourcePerson } from '@/api/label'
import { deepCopy } from '@/utils/utils'
import AddOrEditOutsourcePersons from './AddOrEditOutsourcePersons.vue'
import store from '@/store'

export default {
  name: 'WithoutPerson',
  data () {
    // const that = this
    return {
      tableData: [],
      searchOfName: undefined,
      pageSize: 10,
      pageNum: 1,
      numPages: 10,
      totalCount: null,
      isAdmin: undefined,
      AddOrEditOutsourcePersonsShow: false,
      editObj: null,
      telephone: undefined,
    }
  },
  created () {
    const user = store.getters['auth/me']
    const admin = user.role
    let ticketAdmin = false
    admin.forEach(item => {
      if (item.roleName === 'ticket_admin') {
        ticketAdmin = true
      }
    })
    const query = this.$route.query
    if (Object.keys(query).length > 0) {
      this.pageNum = Number(query.pageNum)
      this.pageSize = Number(query.pageSize)
    } else {
      this.pageNum = 1
      this.pageSize = 10
    }
    if (ticketAdmin) {
      this.fetchAgent()
    } else {
      this.$message({
        type: 'error',
        message: '您不是管理员不可访问页面哦！'
      })
      this.$router.push({
        path: '/home'
      })
    }
  },
  methods: {
    fetchAgent () {
      const data = {
        pageSize: this.pageSize,
        pageNumber: this.pageNum,
        name: this.searchOfName || undefined,
        // emailStatus: this.isAdmin,
        telephone: this.telephone || undefined,
      }
      getOutsourcePerson(data).then((rv) => {
        // console.log(rv)
        this.totalCount = rv.count
        // rv.results.forEach(item => {
        //   item.siteIds = this.getSiteLoads(item.siteIds)
        // })
        this.tableData = rv.results
      }, (err) => {
        this.$message({
          type: 'error',
          message: err.errmsg
        })
      })
    },
    addPersonTags () {
      this.editObj = null
      this.AddOrEditOutsourcePersonsShow = true
    },
    editPerson (row) {
      this.editObj = deepCopy(row)
      this.AddOrEditOutsourcePersonsShow = true
    },
    delPerson (row) {
      this.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          deleteOutsourcePerson(row.id).then(
            () => {
              this.$message({
                type: 'success',
                message: '删除成功'
              })
              this.fetchAgent()
            },
            (err) => {
              this.$message({
                type: 'error',
                message: err.errmsg
              })
            },
          )
        })
    },
    handleCurrentChange (e) {
      this.pageNum = e
      this.fetchAgent()
    },
    handleSizeChange (e) {
      this.pageSize = e
      // this.pageNum = 1
      this.fetchAgent()
    },
    getSiteLoads (data) {
      return data.join(',')
    },
    seachFetchAgent () {
      this.pageNum = 1
      this.fetchAgent()
    },
  },
  mounted () {
    // this.loadTagLabelSetName()
  },
  components: {
    AddOrEditOutsourcePersons,
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/style/home.less";
.operation {
  background: none;
  &.un-operation {
    color: #F34B4B!important;
  }
}
.search .site-project {
  width: 200px;
  :deep(.el-input__inner) {
    width: 100%!important;
  }
}
</style>

<style lang="less">
.el-cascader__search-input {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
}
button {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
}
</style>
