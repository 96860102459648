<template>
  <div class="page">
    <div class="header-nav">
      <span class="title">{{pageTitle}}（{{totalProblemCount}}）</span>
      <img
        @click="refresh"
        class="btn-refresh"
        src="@/assets/images/icon-refresh.png"
        :class="{
          loading: refreshing
        }"
      >
      <div class="status-num-box">
        <div class="status-num-item"
             @click="clickSearch('noCreate')"
             :data-track-click="JSON.stringify({
                category: 'question_list-filter_not_create',
                action: '点击',
             })"
        >
          <i class="icon-red"></i>
          <span class="status-title">未创建</span>
          <span class="line">|</span>
          <span class="number">{{statusNum.uncreatedProblemCount || 0}}</span>
        </div>
        <div class="status-num-item"
             @click="clickSearch('doing')"
             :data-track-click="JSON.stringify({
                category: 'question_list-filter_doing',
                action: '点击',
             })"
        >
          <i class="icon-green"></i>
          <span class="status-title">进行中</span>
          <span class="line">|</span>
          <span class="number">{{statusNum.pendingProblemCount || 0}}</span>
        </div>
        <div class="status-num-item"
             @click="clickSearch('done')"
             :data-track-click="JSON.stringify({
                category: 'question_list-filter_done',
                action: '点击',
             })"
        >
          <i class="icon-blue"></i>
          <span class="status-title">已完成</span>
          <span class="line">|</span>
          <span class="number">{{statusNum.finishedProblemCount || 0}}</span>
        </div>
      </div>
    </div>
    <div class="content1">
      <div class="work-order">
        <div class="search" style="">
          <span class="search-item">
            <label>问题状态：</label>
            <el-select v-model="statusValue" filterable placeholder="请选择">
              <el-option
                v-for="item in orderStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </span>
          <span class="search-item">
            <label>关键字：</label>
            <el-input
              style="width: 241px;"
              v-model="keywords"
              placeholder="输入编号、标题或问题描述查询"
              @keyup.enter="searchTicket"
              clearable
              @clear="searchTicket"
            />
          </span>
          <span class="search-item times">
            <label>创建时间：</label>
            <el-date-picker
              :editable="false"
              prefix-icon=""
              v-model="dateRange"
              type="datetimerange"
              format="YYYY-MM-DD HH:mm"
              unlink-panels
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :shortcuts="shortcuts"
            >
            </el-date-picker>
          </span>
          <span class="search-item">
            <label>问题来源及渠道：</label>
            <el-cascader
              v-model="channel"
              popper-class="list-channel-cascader"
              style="width: 170px;"
              separator=" | "
              filterable
              clearable
              :options="channelOptions"
              :props="{ expandTrigger: 'hover' }">
            </el-cascader>
          </span>
          <span class="search-item">
            <label>创建人：</label>
            <el-select v-model="creator" filterable clearable placeholder="创建人可选">
              <el-option
                v-for="creatorName in creatorNames_"
                :key="creatorName.ldap"
                :label="creatorName.name"
                :value="creatorName.ldap">
                {{creatorName.name}}（{{creatorName.ldap}}）
              </el-option>
            </el-select>
          </span>
          <span class="search-item">
            <label>处理人：</label>
            <el-select v-model="updater" filterable clearable placeholder="处理人可选">
              <el-option
                v-for="updaterName in updaterNames"
                :key="updaterName.ldap"
                :label="updaterName.name"
                :value="updaterName.ldap">
                {{updaterName.name}}（{{updaterName.ldap}}）
              </el-option>
            </el-select>
          </span>
          <span class="search-item" style="margin-right: 0px;" v-for="set in extraFilters" :key="set.id">
            <span class="custom-search">
              <label>{{set.labelSetName}}：</label>
              <el-select v-model="set.setValue" multiple collapse-tags filterable clearable placeholder="请选择">
                <el-option
                  v-for="l in set.labels"
                  :key="l.id"
                  :label="l.name"
                  :value="l.id">
                </el-option>
              </el-select>
            </span>
          </span>
          <span class="add-search" style="margin-right: 19px;">
            <span>|</span>
            <el-tooltip content="点击可添加筛选条件" placement="top">
              <i @click="showQuestionFilterSelect = true"
                 :data-track-click="JSON.stringify({
                   category: '问题列表-添加筛选条件',
                   action: '点击',
                 })"
              ></i>
            </el-tooltip>
          </span>
          <el-button type="primary" @click="searchTicket" class="search-btn" style="margin:0;"
                     :data-track-click="JSON.stringify({
                         category: '问题列表-查询',
                         action: '点击',
                       })"
          >查 询</el-button>
          <el-button v-if="updateFilter" type="primary" plain @click="showSaveFilter = true"
               :data-track-click="JSON.stringify({
                 category: '问题列表-修改查询条件',
                 action: '点击',
               })"
          >
            <span>修改查询条件</span>
          </el-button>
          <el-button v-else type="primary" plain @click="showSaveFilter = true"
             :data-track-click="JSON.stringify({
                 category: '问题列表-保存查询条件',
                 action: '点击',
               })"
          >
            <span>保存查询条件</span>
          </el-button>
        </div>
      </div>
      <div class="data-table">
        <div class="ops-btns">
          <el-button type="primary" @click="createQuestion" class="add-problem"><i class="el-icon-plus" style="margin-right: 8px;"></i>新建问题</el-button>
          <el-button type="primary" plain @click="problemExport">导出查询数据</el-button>
        </div>
        <el-table
          class="question-list"
          v-loading="loading"
          :data="tableData"
          ref="questionTable"
          border
          @sort-change="sortChange"
          @selection-change="handleSelectionChange"
          :header-cell-class-name="handleHeaderCellClass"
          tooltip-effect="dark"
          style="width: 100%">
          <el-table-column
            type="selection"
            :selectable="selecTable"
            width="40">
          </el-table-column>
          <el-table-column
            width="105"
            prop="problemId"
            label="问题编号"
            sortable="custom"
          >
            <template #default="{row}">
              <span @click="getProblemInfo(row, 'info')" class="color-blue">{{row.problemId}}</span>
            </template>
          </el-table-column>
          <el-table-column
            width="105"
            prop="urgentLevel"
            label="紧急程度"
            sortable="custom"
          >
          </el-table-column>
          <el-table-column
            min-width="160"
            prop="problemTitle"
            show-overflow-tooltip
            label="标题">
            <template #default="{row}">
              <span @click="getProblemInfo(row, 'info')" class="color-blue">{{row.problemTitle}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="problemDescribe"
            min-width="120px"
            label="问题描述">
            <template #default="scope">
              <el-tooltip v-if="scope.row.problemDescribe"  placement="top">
                <template #content>
                  <div v-html="scope.row.problemDescribe"></div>
                </template>
                <div class="problem-describe" v-html="scope.row.problemDescribe"></div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="channel"
            show-overflow-tooltip
            min-width="120px"
            label="问题来源及渠道">
            <template #default="{row}">
              <span class="text">{{formtterChannel(row.channel)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="creator"
            show-overflow-tooltip
            :formatter="creatorFilter"
            label="创建人">
          </el-table-column>
          <el-table-column
            prop="ticketId"
            width="80"
            show-overflow-tooltip
            label="工单编号">
            <template #default="{row}">
              <span class="color-blue" @click="$router.push({name: '工单详情', params:{ id: row.ticketId}})">{{row.ticketId}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="problemStatus"
            width="105"
            label="问题状态"
            sortable="custom">
            <template #default="{row}">
              <div>
                <i class="status-icon" :class="{'icon-red':row.problemStatus == 0,'icon-green':row.problemStatus == 2,'icon-blue':row.problemStatus == 1}"></i>
                <span style="vertical-align: middle;">{{row.problemStatus == 0 ? '未创建' : (row.problemStatus == 1 ? '已完成' : (row.problemStatus == 2 ? '进行中' : (row.problemStatus == -1 ? '已关闭' : '-')))}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            width="150"
            sortable="custom"
            label="创建时间"
            :formatter="createTimeFilter">
          </el-table-column>
          <el-table-column
            prop="curPhaseName"
            show-overflow-tooltip
            min-width="120px"
            label="当前处理环节">
            <template #default="{ row }">
              <span v-if="row.curPhaseId">{{row.curPhaseName}}</span>
              <template v-else>
                <div v-for="(v, index) in (row.curPhases?.map(v => v.phaseName) || [])" :key="index">{{v}}</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="responsibleAgentName"
            min-width="100px"
            show-overflow-tooltip
            label="当前处理人">
            <template #default="{ row }">
              <span v-if="row.curPhaseId">{{row.responsibleAgentName || '-'}}</span>
              <template v-else>
                <div v-for="(v, index) in (row.curPhases?.map(v => v.responsibleAgentName || '-') || [])" :key="index">{{v}}</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            width="120"
            label="操作">
            <template #default="{row}">
              <!-- 未创建的时候 ｜｜ 创建工单 + ...  -->
              <div v-if="row.problemStatus == 0" class="popover-item">
                <button @click="createWorkOrder(row)" class="operation">创建工单</button>
                <span class="line-icon">|</span>
                <el-dropdown trigger="click" popper-class="select-box">
                  <span class="el-dropdown-link"></span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item @click="subQuestion(row)" class="box" v-if="row.subscribe == 0">订阅</el-dropdown-item>
                      <el-dropdown-item @click="subQuestion(row)" class="box" v-if="row.subscribe == 1">取消订阅</el-dropdown-item>
                      <el-dropdown-item @click="editQuestion(row, 'edit')" class="box">修改</el-dropdown-item>
                      <el-dropdown-item @click="closeQuestion(row, 'one')" class="box">关闭</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
              <!-- 已完成的时候 ｜｜ 关闭  -->
              <div v-else-if="row.problemStatus == 1">
                <button @click="closeQuestion(row, 'one')" class="operation">关闭</button>
              </div>
              <!-- 进行中的时候 ｜｜ 订阅 + 取消订阅 2选1  -->
              <div v-else-if="row.problemStatus == 2">
                <button @click="subQuestion(row, 'edit')" class="operation" v-if="row.subscribe == 0">订阅</button>
                <button @click="subQuestion(row, 'edit')" class="operation" v-else>取消订阅</button>
              </div>
              <!-- 已关闭的时候 ｜｜ - -->
              <div v-else>
                 <span class="operation">-</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div @click="batchClose('several')" v-if="selectedProblemCurPage.length > 0 || selectedProblem.length > 0" class="close-question-btn close-question-btn-active">批量关闭问题</div>
        <div v-else class="close-question-btn">批量关闭问题</div>
        <div class="pagination-wrap" v-if="totalCount > 0">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="prev, pager, next, sizes, jumper, total"
            :total="totalCount">
          </el-pagination>
        </div>
      </div>
    </div>

    <div class="close-question-dialog">
      <el-dialog
        title="关闭问题"
        v-model="closeQuestionDialog"
        width="40%"
        destroy-on-close
        :close-on-click-modal="false"
        center>
        <div>
          <div style="margin-bottom:20px;color: rgba(0, 0, 0, 0.85);">问题标题：<text v-html="closeQuestionTitle"></text></div>
          <div class="close-item">
            <label>原因：</label>
            <el-input v-model="comments" clearable placeholder="请输入反馈结果或关闭原因" style="margin-bottom:20px;"></el-input>
          </div>
          <div class="close-item">
            <label>关联问题：</label>
            <el-select
              v-model="associatedNumList"
              multiple
              clearable
              collapse-tags
              filterable
              remote
              :loading="selectLoading"
              :remote-method="remoteMethod"
              placeholder="输入问题编号或标题可查询问题">
              <el-option
                v-for="item in optionRelatedIssues"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <button @click="closeQuestionDialog = false" class="close-btn">取 消</button>
            <button @click="submitCloseQuestion" class="close-btn close-btn1">确 定</button>
          </span>
        </template>
      </el-dialog>
    </div>
    <div class="filter-select">
      <el-dialog
        :destroy-on-close="true"
        width="681px"
        :show-close="true"
        :before-close="onQuestionFilterSelectClose"
        v-model="showQuestionFilterSelect"
      >
          <QuestionFilterSelect
            :cancel="cancelQuestionFilterSelect"
            :submit="submitQuestionFilterSelect"
            :setIds="setIds"
          ></QuestionFilterSelect>
      </el-dialog>
    </div>
    <el-dialog
      destroy-on-close
      v-model="showSaveFilter"
      custom-class="save-filter"
      :show-close="false"
      center
    >
      <template #title>
        <h2 v-if="updateFilter">修改查询条件</h2>
        <h2 v-else>保存查询条件</h2>
      </template>
      <div>
        <div v-if="updateFilter" class="desc">修改查询条件后会修改您编辑的面板</div>
        <div v-else class="desc">保存查询条件后会保存到您的个人面板</div>
        <el-form :model="saveFilterForm" :rules="saveFilterFormRules" ref="saveFilterFormRef" action="javascript:void(0)">
          <el-form-item label="标题：" prop="title">
            <el-input
              v-model="saveFilterForm.title"
              placeholder="请输入标题"
              @input.once="trackEventInput"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div>
          <el-button @click="cancelSaveFilter">取消</el-button>
          <el-button @click="submitSaveFilter" type="primary"
                     :data-track-click="JSON.stringify({
                         category: updateFilter ? '问题列表-修改查询条件-确定' : '问题列表-保存查询条件-确定',
                         action: '点击',
                       })"
          >确定</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 导出数据弹窗 -->
    <el-dialog
      v-model="showExportDialog"
      center
      destroy-on-close
      custom-class="problem-export-dialog"
      :before-close="closeExportDialog"
    >
      <template #title>
        <div class="title">批量导出数据</div>
      </template>
      <div class="desc">批量导出<span class="total">{{ totalCount }}</span>条数据，正在生成Excel表格</div>
      <div class="info"><span class="keywords">{{ keywordsAndTags }}</span><span class="file-name" :class="{
        ml: keywordsAndTags?.length > 0
      }">{{ exportFileName }}</span></div>
      <el-progress :percentage="exportPercentage"></el-progress>
    </el-dialog>
  </div>
</template>
<script>
import { unref } from 'vue'
import {
  getProblemList,
  closeProblemById,
  loadProblemList,
  batchCloseProblem,
  loadListCount,
  getSubscribe,
  saveProblemFilters,
  updateProblemFilters,
  exportProblemList,
  ProblemSource,
} from '@/api/problem'
import { toLine, deepCopy } from '@/utils/utils'
import QuestionFilterSelect from '@/components/QuestionFilterSelect.vue'
import * as labelApi from '../../api/label'
import { ElMessage } from 'element-plus'
import { agentsByTag } from '@/api/agent.ts'
import * as _ from 'lodash'

export default {
  name: 'questionList',
  components: {
    QuestionFilterSelect,
  },
  beforeRouteUpdate (to, from) {
    this.displayPageName()
  },
  activated () {
    // 问题列表： 先去判断是否有需要更新列表的操作 没有就判断问题列表长度是否有变化 没有就不更新 有就更新
    const needUpdateList = localStorage.getItem('updateProblemList')
    if (needUpdateList === 'true') {
      this.getList()
      localStorage.setItem('updateProblemList', false)
    } else {
      getProblemList().then(res => {
        if (res?.count !== this.totalProblemCount) {
          this.getList()
        }
      })
    }
  },
  // data
  data () {
    return {
      channelOptions: ProblemSource,
      pageTitle: '问题列表',
      loading: false,
      selectLoading: false,
      showQuestionFilterSelect: false,
      showSaveFilter: false,
      saveFilterForm: {
        title: '',
      },
      saveFilterFormRules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
      },
      showExportDialog: false,
      exportPercentage: 0,
      orderStatus: [
        {
          value: 'all',
          label: '全部'
        },
        {
          value: 'noCreate',
          label: '未创建'
        },
        {
          value: 'doing',
          label: '进行中'
        },
        {
          value: 'done',
          label: '已完成'
        },
        {
          value: 'close',
          label: '已关闭'
        }
        // {
        //   value: 'replied',
        //   label: '已回复'
        // },
      ],
      statusMap: {
        all: '',
        noCreate: 0,
        done: 1,
        doing: 2,
        close: -1,
        replied: 3
      },
      hackTableFlag: false,
      orderArray: [
        { prop: 'problemStatus', order: 'ascending' },
        { prop: 'createTime', order: 'descending' }
      ],
      statusValue: 'all',
      keywords: '',
      dateRange: [],
      create_or_subscribe: '',
      shortcuts: [{
        text: '最近7天',
        value: (() => {
          const end = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1) // 当天23:59
          const start = new Date(new Date(new Date().toLocaleDateString()).getTime()) // 当天0点
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
          return [start, end]
        })()
      }, {
        text: '最近30天',
        value: (() => {
          const end = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1) // 当天23:59
          const start = new Date(new Date(new Date().toLocaleDateString()).getTime()) // 当天0点
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 29)
          return [start, end]
        })()
      }, {
        text: '最近90天',
        value: (() => {
          const end = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1) // 当天23:59
          const start = new Date(new Date(new Date().toLocaleDateString()).getTime()) // 当天0点
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 89)
          return [start, end]
        })()
      }],
      pageSize: 10,
      pageNumber: 1,
      totalCount: 0,
      tableData: [],
      refreshing: false,
      closeQuestionDialog: false,
      closeQuestionTitle: '',
      closeProblemStatus: null,
      associatedNumList: [],
      relatedIssues: [],
      comments: '',
      optionRelatedIssues: [],
      number: 1,
      closeProblemId: undefined,
      selectedProblem: [],
      selectedProblemCurPage: [],
      selectedProblemInternalChange: false,
      closeType: '',
      batchCloseIds: [],
      statusNum: {
        ceratedProblemCount: '', // 创建的工单个数
        subscribeProblemCount: '', // 订阅的工单个数
        uncreatedProblemCount: '',
        pendingProblemCount: '',
        finishedProblemCount: '',
        closedProblemCount: '',
      },
      extraFilters: [],
      productValue: '',
      productList: [
        {
          id: 5,
          name: 'Mall'
        },
        {
          id: 6,
          name: 'Store'
        },
        {
          id: 7,
          name: 'Parking'
        },
        {
          id: 8,
          name: 'Gate'
        },
        {
          id: 9,
          name: 'Business'
        },
      ],
      exportFileName: '',
      filterId: '',
      filterInfo: null,
      updateFilter: false,
      creatorNames: [],
      creatorWithoutNames: [],
      creator: '',
      updaterNames: [],
      updater: '',
      channel: null,
    }
  },
  mounted () {
    this.displayPageName()
    this.loadCretorNames()
    this.withOutList()
    let query = this.$route.query
    // console.log('query=', query)

    if (this.$route.redirectedFrom) {
      const redirectQuery = this.$route.redirectedFrom.query
      // console.log('redirectQuery=', redirectQuery)

      if (Object.keys(query).length === 0) {
        query = redirectQuery
      }
    }

    if (Object.keys(query).length === 0) {
      const c = localStorage.getItem('__ticket_questionList_query_criteria')
      if (c) {
        const q = JSON.parse(c)
        if (Object.keys(q).length > 0) {
          delete q.create_or_subscribe
          query = q
        }
      }
    }
    if (query.pageNumber && query.pageSize) {
      this.pageNumber = Number(query.pageNumber)
      this.pageSize = Number(query.pageSize)
    } else {
      this.pageNumber = 1
      this.pageSize = 10
    }
    if (query.problemStatus) {
      const status = Object.keys(this.statusMap).find(k => this.statusMap[k] === Number(query.problemStatus))
      if (status) {
        this.statusValue = status
      }
    }
    if (query.keywords) {
      this.keywords = query.keywords
    }
    if (query.create_or_subscribe) {
      this.create_or_subscribe = query.create_or_subscribe
    }
    if (query.startTime && query.endTime) {
      this.dateRange = [new Date(Number(query.startTime) * 1000), new Date(Number(query.endTime) * 1000)]
    } else {
      const end = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1) // 当天23:59
      const start = new Date(new Date(new Date().toLocaleDateString()).getTime()) // 当天0点
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
      this.dateRange = [start, end]
    }
    if (query.channel) {
      const q = query.channel.split(',')
      this.channel = q.map(Number)
    }
    if (query.creator) {
      this.creator = query.creator
    }
    if (query.updater) {
      this.updater = query.updater
    }
    if (query.filterId) {
      this.filterId = Number(query.filterId)
    }
    let setIds = []
    let setIdValueMap = {}
    if (query.tagFilter) {
      // 形如 2-5_6,3-10,14-63
      let setIdValueArray = query.tagFilter.split(',').map(e => e.split('-'))
      setIdValueArray = setIdValueArray.filter(e => { return e[1] && e[1] !== '' })
      // console.log('setIdValueArray=', setIdValueArray)
      setIds = setIdValueArray.map(e => Number(e[0]))
      setIdValueMap = setIdValueArray.reduce((ac, cu) => {
        ac[cu[0]] = cu[1].split('_').map(e => Number(e))
        return ac
      }, {})
      this.extraFilters = setIdValueArray.map(e => ({
        id: Number(e[0]),
        setValue: e[1].split('_').map(e => Number(e)),
      }))
    }
    const s = localStorage.getItem('__ticket_questionList_setIds')
    if (s) {
      const setIds2 = JSON.parse(s)
      if (setIds2.length > 0) {
        setIds = [...new Set(setIds.concat(setIds2))]
      }
    }
    // console.log('setIdValueMap:', setIdValueMap)
    this.loadSetValues(setIds, setIdValueMap)

    this.getList()
    this.loadRelatedIssues()

    window.addEventListener('resize', () => {
      this.fixElTableColumnWidth()
    })

    const filter = window.sessionStorage.getItem('__ticket__updateFilter')
    if (filter) {
      this.filterInfo = JSON.parse(filter)
      this.updateFilter = true
      window.sessionStorage.removeItem('__ticket__updateFilter')
      this.saveFilterForm.title = this.filterInfo.filterName
    }
    // 埋点
    window._trackEvent('问题详情页', '曝光')
  },
  methods: {
    loadCretorNames () {
      agentsByTag().then(res => {
        const arr = _.uniqBy(res.results, 'name')
        this.creatorNames = arr.map(item => {
          return {
            name: item.name,
            ldap: item.ldap
          }
        })
        this.updaterNames = this.creatorNames
      })
    },
    displayPageName () {
      const p = this.$route.query
      const cs = p.create_or_subscribe
      if (cs === 'create') {
        this.pageTitle = '我创建的问题'
      } else if (cs === 'subscribe') {
        this.pageTitle = '我订阅的问题'
      } else {
        this.pageTitle = '问题列表'
      }
    },
    fixElTableColumnWidth () {
      const elTableClass = 'question-list'
      const index = 5
      const w = document.querySelector(`.${elTableClass} table th:nth-child(${index})`)?.getBoundingClientRect().width
      document.querySelectorAll(`.${elTableClass} table td:nth-child(${index}) > div`).forEach(e => {
        e.style.width = w + 'px'
      })
    },
    loadExtraFilters () {
      const s = localStorage.getItem('__ticket_questionList_setIds')
      if (s) {
        const setIds = JSON.parse(s)
        if (setIds.length > 0) {
          const setIdValueMap = this.extraFilters.reduce((ac, cu) => {
            ac[cu.id] = cu.setValue
            return ac
          }, {})
          this.loadSetValues(setIds, setIdValueMap)
        } else {
          this.extraFilters = []
        }
      }
    },
    async loadSetValues (setIds, setIdValueMap = {}) {
      try {
        const res = await labelApi.ticketLabelSet(0)
        const ticketLabelSetList = res.filter(e => e.useForTicket && setIds.includes(e.id))
        this.extraFilters = ticketLabelSetList
        for (const e of this.extraFilters) {
          labelApi.getLabelsBySetId(e.id).then(res => {
            e.labels = res
            e.setValue = setIdValueMap[e.id]
          }).catch(err => {
            console.error(err)
          })
        }
      } catch (e) {
        console.error('listLabelSet error:', e)
        this.extraFilters = []
      }
    },
    handleHeaderCellClass ({ row, column, rowIndex, columnIndex }) {
      this.orderArray.forEach(element => {
        if (column.property === element.prop) {
          column.order = element.order
        }
      })
    },
    loadRelatedIssues (keywords) {
      loadProblemList(keywords).then((res) => {
        this.relatedIssues = res.results
        this.optionRelatedIssues = this.relatedIssues.map(item => {
          return { value: `${item.problemId}`, label: `[${item.problemId}]${item.problemTitle}` }
        })
        this.selectLoading = false
      }, (err) => {
        this.$message({
          type: 'error',
          message: err.errmsg
        })
        console.error(err)
      })
    },
    remoteMethod (query) {
      this.selectLoading = true
      this.loadRelatedIssues(query)
    },
    // loadRelatedIssues (number) {
    //   loadProblemList(number).then((res) => {
    //     this.relatedIssues.push(...res.results)
    //     this.optionRelatedIssues = this.relatedIssues.map(item => {
    //       return { value: `${item.problemId}`, label: `[${item.problemId}]${item.problemTitle}` }
    //     })
    //     if (res.next) {
    //       this.loadRelatedIssues(number + 1)
    //     } else {
    //       return false
    //     }
    //   }, (err) => {
    //     this.$message({
    //       type: 'error',
    //       message: err.errmsg
    //     })
    //     console.error(err)
    //   })
    // },
    getList () {
      const ordering = this.orderArray.reduce((res, cur) => {
        let temp = res
        if (res !== '') {
          temp += ','
        }
        if (cur.order === 'ascending') {
          temp += toLine(cur.prop)
        } else {
          temp += `-${toLine(cur.prop)}`
        }
        return temp
      }, '')
      const list = this.extraFilters.map(el => {
        if (el.setValue) {
          if (el.setValue instanceof Array) {
            return `${el.id}-${el.setValue.join('_')}`
          } else {
            return `${el.id}-${el.setValue}`
          }
        }
      })
      const tagFilter = list.filter(item => item)
      const [startTime, endTime] = this.getStartEndTime(this.dateRange)
      const data = {
        problemStatus: this.statusMap[this.statusValue],
        ordering,
        keywords: this.keywords ? this.keywords : undefined,
        startTime,
        endTime,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        tagFilter: tagFilter.join(','),
        create_or_subscribe: this.create_or_subscribe ? this.create_or_subscribe : undefined,
        creator: this.creator ? this.creator : undefined,
        updater: this.updater ? this.updater : undefined,
        channel: (this.channel && this.channel.length > 0) ? this.channel[1] : undefined,
      }
      if (this.loading) return
      this.loading = true
      const promiseGetProblemList = getProblemList(data).then((res) => {
        this.totalCount = res.count
        this.tableData = res.results
        this.handleSelectPersonMerge()
        return true
      }, (err) => {
        this.$message({
          type: 'error',
          message: '获取问题列表失败: ' + err.errmsg
        })
        console.error('获取问题列表失败:', err)
        return false
      }).finally(() => {
        this.loading = false
        // 处理问题描述的显示问题
        setTimeout(() => {
          this.fixElTableColumnWidth()
          document.querySelectorAll('.problem-describe').forEach((e, i, a) => {
            if (e.innerText?.length > 10) {
              e.innerHTML = e.innerText
            }
          })
        }, 0)
        const q = { ...data }
        if (this.filterId) {
          q.filterId = this.filterId
        }
        if (this.channel && this.channel.length > 0) {
          q.channel = this.channel.join(',')
        }
        this.$router.replace({
          query: q
        })
        localStorage.setItem('__ticket_questionList_query_criteria', JSON.stringify(q))
      })
      const promiseLoadListCount = loadListCount().then((res) => {
        this.statusNum = res
        return true
      }, (err) => {
        this.$message({
          type: 'error',
          message: err.errmsg
        })
        console.error(err)
        return false
      })
      return Promise.all([promiseGetProblemList, promiseLoadListCount])
    },
    createTimeFilter (row) {
      const date = row.createTime.split(' ')[0]
      const time = row.createTime.split(' ')[1].split(':').slice(0, 2).join(':')
      return date + ' ' + time
    },
    creatorFilter (row) {
      if (row.channel === '外部用户' || row.channel === 5) {
        return row.creatorName
      }
      if (row.creatorObj) {
        return row.creatorObj.name
      } else {
        return row.creator
      }
    },
    updaterFilter (row) {
      if (row.updater) {
        return row.updater
      } else {
        return ''
      }
    },
    statusFilter (row) {
      const status = row.problemStatus
      if (status === 0) {
        return '未创建'
      } else if (status === 1) {
        return '已完成'
      } else if (status === 2) {
        return '进行中'
      } else if (status === 3) {
        return '已回复'
      } else if (status === -1) {
        return '已关闭'
      } else {
        return '-'
      }
    },
    createQuestion () {
      this.$router.push({
        name: '创建问题'
      })
    },
    sortChange ({ prop, order }) {
      if (this.hackTableFlag === false) {
        this.$refs.questionTable.store.mutations.changeSortCondition = (states, options) => {
          const { sortingColumn: column, sortProp: prop, sortOrder: order } = states
          const columnValue = unref(column)
          const propValue = unref(prop)
          const orderValue = unref(order)
          if (unref(order) === null) {
            states.sortingColumn.value = null
            states.sortProp.value = null
          }
          const ingore = { filter: true }
          this.$refs.questionTable.store.execQuery(ingore)

          if (!options || !(options.silent || options.init)) {
            this.$refs.questionTable.$emit('sort-change', {
              column: columnValue,
              prop: propValue,
              order: orderValue,
            })
          }
        }
        this.hackTableFlag = true
      }
      if (order) { // 参与排序
        let flagIsHave = false
        this.orderArray.forEach(element => {
          if (element.prop === prop) {
            element.order = order
            flagIsHave = true
          }
        })
        if (!flagIsHave) {
          this.orderArray.push({
            prop: prop,
            order: order
          })
        }
      } else { // 不参与排序
        let orderIndex = 0
        this.orderArray.forEach((element, index) => {
          if (element.prop === prop) {
            orderIndex = index
          }
        })
        this.orderArray.splice(orderIndex, 1)
      }
      this.getList(this.statusMap[this.statusValue])
    },
    editQuestion (row, type) {
      this.$router.push({
        name: '修改问题',
        query: { problemId: row.problemId, type: type }
      })
    },
    createWorkOrder (row) {
      this.$router.push({
        name: '创建工单',
        query: { problemId: row.problemId }
      })
    },
    getProblemInfo (row, type) {
      const href = this.$router.resolve({
        name: '问题详情',
        query: { problemId: row.problemId, type: type }
      }).href
      window.open(href, '_blank')
    },
    closeQuestion (row, type) {
      this.closeType = type
      this.closeQuestionDialog = true
      this.comments = ''
      this.associatedNumList = []
      this.selectedProblemCurPage = []
      this.selectedProblem = []
      this.closeProblemId = row.problemId
      this.closeProblemStatus = row.problemStatus
      this.closeQuestionTitle = row.problemTitle
    },
    // 订阅or 取消订阅
    subQuestion (row) {
      let prompt = ''
      let num = 0
      if (row.subscribe === 0) {
        prompt = '您已成功订阅' + row.problemTitle
        num = 1
      } else {
        prompt = '您已取消订阅' + row.problemTitle
        num = 0
      }
      const param = {
        problem_id: row.problemId,
        subscribe: num
      }
      getSubscribe(param).then(res => {
        // console.log(res)
        this.$message({
          type: 'success',
          message: prompt
        })
        this.getList()
      }).catch(err => {
        console.log(err)
      })
    },
    submitCloseQuestion () {
      // 单个关闭问题
      if (this.closeType === 'one') {
        closeProblemById(this.closeProblemId, this.comments, this.associatedNumList).then(() => {
          this.closeQuestionDialog = false
          this.$message({
            type: 'success',
            message: '关闭问题成功！'
          })
          this.getList()
        }, (err) => {
          this.$message({
            type: 'error',
            message: err.errmsg
          })
          console.error(err)
        })
      } else { // 多个关闭问题
        const ids = this.batchCloseIds.join(',')
        const relationIds = this.associatedNumList.join(',')
        const data = {
          ids: ids,
          problemComments: this.comments,
          relationIds: relationIds
        }
        batchCloseProblem(data).then(() => {
          this.closeQuestionDialog = false
          this.$message({
            type: 'success',
            message: '批量关闭问题成功！'
          })
          this.getList()
        }, (err) => {
          this.$message({
            type: 'error',
            message: err.errmsg
          })
          console.error(err)
        })
      }
    },
    searchTicket () {
      this.pageNumber = 1
      this.pageSize = 10
      this.getList(this.statusMap[this.statusValue])
    },
    clickSearch (type) {
      this.pageNumber = 1
      this.statusValue = type
      this.getList(this.statusMap[this.statusValue])
    },
    clickCreatOrSubscr (type) {
      this.pageNumber = 1
      this.statusValue = 'all'
      this.getList(this.statusMap[this.statusValue], type)
    },
    handleCurrentChange (e) {
      this.selectedProblemInternalChange = true
      this.pageNumber = e
      this.$router.push({
        name: this.$route.name,
        query: {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize
        },
      })
      this.getList(this.statusMap[this.statusValue])
    },
    refresh () {
      this.refreshing = true
      this.orderArray = [
        { prop: 'problemStatus', order: 'ascending' },
        { prop: 'createTime', order: 'descending' }
      ]
      this.pageNumber = 1
      this.pageSize = 10
      this.statusValue = 'all'
      this.keywords = ''
      const end = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1) // 当天23:59
      const start = new Date(new Date(new Date().toLocaleDateString()).getTime()) // 当天0点
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
      this.dateRange = [start, end]
      // this.dateRange = []
      this.channel = null
      this.creator = ''
      this.updater = ''
      this.extraFilters = this.extraFilters.map(el => {
        return {
          ...el,
          setValue: ''
        }
      })
      localStorage.removeItem('__ticket_questionList_query_criteria')
      this.getList().then((res) => {
        if (res.some(v => !v)) {
          return
        }
        this.refreshing = false
        this.$message({
          showClose: true,
          message: '刷新成功',
          type: 'success'
        })
      })
      // window.location.reload()
    },
    handleSizeChange (e) {
      this.selectedProblemInternalChange = true
      this.pageSize = e
      this.pageNumber = 1
      this.$router.push({
        name: this.$route.name,
        query: {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize
        },
      })
      this.getList(this.statusMap[this.statusValue])
    },
    handleSelectionChange (val) {
      if (this.selectedProblemInternalChange) {
        return
      }
      this.selectedProblemCurPage = val
    },
    handleSelectPersonMerge () {
      if (this.selectedProblemCurPage.length > 0) {
        this.selectedProblemCurPage.forEach((person) => {
          if (this.selectedProblem.find(v => v.problemId === person.problemId) !== undefined) {
            return
          }
          this.selectedProblem.push(deepCopy(person))
        })
      }
      // 清空数组
      this.selectedProblemCurPage = []
      this.selectedProblem = this.selectedProblem.filter((person) => {
        const res = this.tableData.find(v => v.problemId === person.problemId)
        if (res) {
          this.selectedProblemCurPage.push(res)
          this.$nextTick(() => {
            this.$refs.questionTable.toggleRowSelection(res)
          })
          return false
        }
        return true
      })
      this.selectedProblemInternalChange = false
    },
    selecTable (row, index) {
      return row.problemStatus === 1 || row.problemStatus === 0
    },
    batchClose (type) {
      this.closeQuestionTitle = ''
      const selectedProblemList = [...this.selectedProblem, ...this.selectedProblemCurPage]
      const selectedTitle = []
      const selectedId = []
      selectedProblemList.forEach(item => {
        selectedTitle.push(item.problemTitle)
        selectedId.push(item.problemId)
      })
      this.batchCloseIds = selectedId.map(Number)
      this.closeQuestionTitle = selectedTitle.join('<span style="margin: 0 8px;">|</span>')
      this.closeType = type
      this.closeQuestionDialog = true
    },
    onQuestionFilterSelectClose () {
      this.showQuestionFilterSelect = false
    },
    cancelQuestionFilterSelect () {
      this.showQuestionFilterSelect = false
    },
    submitQuestionFilterSelect (e) {
      // console.log('submitQuestionFilterSelect:', e)
      // TODO 把setIds存储到本地
      const setIds = e.map(s => s.id)
      localStorage.setItem('__ticket_questionList_setIds', JSON.stringify(setIds))
      this.showQuestionFilterSelect = false
      this.loadExtraFilters()
    },
    getStartEndTime () {
      if (this.dateRange?.length !== 2) return []
      const d1 = this.dateRange[0]
      const d2 = this.dateRange[1]
      // d1.setHours(0)
      // d1.setMinutes(0)
      d1.setSeconds(0)
      d1.setMilliseconds(0)
      // d2.setHours(23)
      // d2.setMinutes(59)
      d2.setSeconds(59)
      d2.setMilliseconds(999)
      const start = Math.floor(d1.getTime() / 1000)
      const end = Math.floor(d2.getTime() / 1000)
      return [start, end]
    },
    submitSaveFilter () {
      this.$refs.saveFilterFormRef.validate(async (valid) => {
        if (valid) {
          if (this.updateFilter) {
            try {
              await updateProblemFilters(this.filterId, {
                filterName: this.saveFilterForm.title,
                problemFilter: window.location.search.slice(1)
              })
              ElMessage({
                showClose: true,
                message: '修改成功',
                type: 'success'
              })
            } catch (e) {
              ElMessage({
                showClose: true,
                message: '修改失败',
                type: 'error'
              })
            }
          } else {
            try {
              await saveProblemFilters({
                filterName: this.saveFilterForm.title,
                problemFilter: window.location.search.slice(1)
              })
              ElMessage({
                showClose: true,
                message: '保存成功',
                type: 'success'
              })
              this.saveFilterForm.title = ''
            } catch (e) {
              ElMessage({
                showClose: true,
                message: '保存失败',
                type: 'error'
              })
            }
          }
          this.showSaveFilter = false
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    animateExportPercentage (done) {
      this.exportPercentage = 0
      const s = Date.now()
      const len = 1000
      const move = () => {
        if (this.showExportDialog) {
          const p = Math.floor((Date.now() - s) / len * 100)
          if (p <= 100) {
            this.exportPercentage = p
            window.requestAnimationFrame(move)
          } else {
            this.exportPercentage = 100
            done && done()
          }
        }
      }
      window.requestAnimationFrame(move)
    },
    async problemExport () {
      this.showExportDialog = true
      const name = await this.exportQuestions()
      // console.log('name', name)
      this.animateExportPercentage()
      this.exportFileName = name
    },
    closeExportDialog (done) {
      this.showExportDialog = false
      this.exportPercentage = 0
      done()
    },
    cancelSaveFilter () {
      this.showSaveFilter = false
      // this.saveFilterForm.title = ''
    },
    formtterChannel (type) {
      switch (Number(type)) {
        case 1:
          return '内部来源 | 内部用户直接录入'
        case 2:
          return '内部来源 | Atlas'
        case 3:
          return '内部来源 | API'
        case 4:
          return '外部来源 | 400'
        case 5:
          return '外部来源 | 外部用户直接录入'
        case 6:
          return '内部来源 | 邮件'
        case 7:
          return '内部来源 | 小程序'
        case 8:
          return '外部来源 | 微信'
        case 9:
          return '外部来源 | Webhook'
        case 10:
          return '内部来源 | 客服小程序'
        default:
          return type
      }
    },
    async exportQuestions () {
      const ordering = this.orderArray.reduce((res, cur) => {
        let temp = res
        if (res !== '') {
          temp += ','
        }
        if (cur.order === 'ascending') {
          temp += toLine(cur.prop)
        } else {
          temp += `-${toLine(cur.prop)}`
        }
        return temp
      }, '')
      const list = this.extraFilters.map(el => {
        if (el.setValue) {
          if (el.setValue instanceof Array) {
            return `${el.id}-${el.setValue.join('_')}`
          } else {
            return `${el.id}-${el.setValue}`
          }
        }
      })
      const tagFilter = list.filter(item => item)
      const [startTime, endTime] = this.getStartEndTime(this.dateRange)
      const data = {
        problemStatus: this.statusMap[this.statusValue],
        ordering,
        keywords: this.keywords,
        startTime,
        endTime,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        tagFilter: tagFilter.join(','),
        create_or_subscribe: this.create_or_subscribe,
        creator: this.creator ? this.creator : undefined,
        updater: this.updater ? this.updater : undefined,
        channel: (this.channel && this.channel.length > 0) ? this.channel[1] : undefined,
      }
      return exportProblemList(data)
    },
    trackEventInput () {
      const category = this.updateFilter ? '问题列表-修改查询条件-输入框' : '问题列表-保存查询条件-输入框'
      window._trackEvent(category, '输入')
    },
    withOutList () {
      labelApi.tagLabelAllGuest().then(res => {
        this.creatorWithoutNames = res.results.map(item => {
          return {
            name: item.name,
            ldap: item.email
          }
        })
      })
    }
  },
  // computed
  computed: {
    setIds () {
      return this.extraFilters.map(e => e.id)
    },
    totalProblemCount () {
      if (!this.statusNum) {
        return 0
      }
      // 未创建 + 进行中 + 已完成 + 已关闭
      return (this.statusNum.uncreatedProblemCount || 0) + (this.statusNum.pendingProblemCount || 0) +
        (this.statusNum.finishedProblemCount || 0) + (this.statusNum.closedProblemCount || 0)
    },
    keywordsAndTags () {
      let s = ''
      if (this.keywords) {
        s += `${this.keywords} | `
      }
      this.extraFilters.forEach(tagsSet => {
        const labelSelected = tagsSet.labels.find(l => {
          return l.id === tagsSet.setValue
        })?.name
        if (labelSelected) {
          s += labelSelected + ' | '
        }
      })
      if (s.length > 0) {
        s = s.slice(0, -3)
      }
      return s
    },
    creatorNames_ () {
      return this.creatorNames.concat(this.creatorWithoutNames)
    }
  },
  watch: {
  },
}
</script>

<style lang="less" scoped>
@import "../../assets/style/home.less";
.title {
  vertical-align: middle;
}
.text {
  cursor: default;
  pointer-events: none;
  &::before {
    content: '';
    display: block;
  }
}
.problem-describe {
  width: 100%;
  height:23px;
  overflow:hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
  &::before {
    content: '';
    display: block;
  }
}
.btn-refresh {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  cursor: pointer;
  &.loading {
    animation: refresh 1s;
  }
}
@keyframes refresh
{
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.status-num-box {
  float: right;
  margin-top: 15px;
  height: 30px;
  line-height: 28px;
  padding: 0 24px;
  background: rgba(75,121,243,0.15);
  border: 1px solid rgba(75,121,243,0.85);
  border-radius: 3px;
  display: flex;
  .status-num-item {
    margin-right: 32px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    i {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 6px;
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
    }
    .line {
      margin: 0 8px;
      color: #4b79f3;
    }
    .status-title {
      color: rgba(0,0,0,0.65);
    }
    .new-font-weight {
      font-weight: 700;
    }
  }
}
.status-icon {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 6px;
  vertical-align: middle;
  background: #2246a6;
}
.icon-red {
  background: #f26363;
}
.icon-green {
  background: #22c081;
}
.icon-blue {
  background: #4b79f3;
}
.status-num-box.new-box {
  margin-right: 32px;
}
.close-question-btn {
  float: left;
  height: 32px;
  line-height: 32px;
  padding: 0 13px;
  background: #c3c3c9;
  color: #ffffff;
  margin-top: 17px;
  border-radius: 2px;
  cursor: pointer;
}
.close-question-btn-active {
  background: #4b79f3;
}
.search {
  .search-item {
    display: inline-block;
    margin: 0 24px 8px 0;
    .custom-search {
      margin-right: 32px;
      ::v-deep(.el-select) {
        .el-input__inner {
          width: auto!important;
        }
      }
    }
    &.times {
      ::v-deep {
        .el-input__inner {
          width: 320px;
        }
        .el-range-input {
          width: 120px !important;
        }
        .el-range-separator {
          line-height: 20px;
        }
      }
    }
  }
  .add-search {
    margin-left: -32px;
    span {
      color: #e9e9e9;
      margin: 0 8px;
    }
    i {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(../../assets/images/add-search.svg);
      vertical-align: middle;
      cursor: pointer;
    }
  }
}
.filter-select {
  ::v-deep(.el-dialog) {
    .el-dialog__body {
      padding: 20px;
      color: rgba(0,0,0,0.85);
    }
  }
}
.operation {
  background: none;
}
</style>

<style lang="less">
.is-dark {
  max-width: 400px !important;
  line-height: 20px;
  font-size: 14px;
  img {
    max-width: 100%;
  }
}
.is-message-box {
  .el-message-box__title {
    font-size: 16px;
  }
  .el-message-box__input {
    padding-top: 0;
  }
}
button {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  color: #4B79F3;
}
.el-select-dropdown {
  max-width: 400px;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item span {
  display: inline-block;
  max-width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.popover-item {
  position: relative;
  .el-dropdown{
    position: absolute;
    right: 10;
    top: 50%;
    height: 20px;
    width: 20px;
    transform: translateY(-50%);
    .el-dropdown-link{
      display: block;
      width: 100%;
      height: 100%;
      background-image: url('../../assets/images/more.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px 20px;
      cursor: pointer;
    }
  }
}
.select-box{
 .box{
   width: 100px;
   text-align: center;
   height: 28px;
   line-height: 28px;
   color: #4b79f3;
   font-size: 14px;
 }
}
.el-dialog.save-filter {
  .el-form-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .el-form-item__content {
      flex-grow: 1;
    }
  }
  .desc {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 24px;
  }
}
.problem-export-dialog {
  .title {
    font-size: 18px;
  }
  .desc {
    font-size: 16px;
    .total {
      margin: 0 5px;
      color: rgba(34,192,129,1);
      font-size: 20px;
    }
  }
  .info {
    margin-top: 16px;
    margin-bottom: 10px;
    font-size: 14px;
    color: #4b79f3;
    .file-name.ml {
      margin-left: 35px;
    }
  }
}
.el-cascader__dropdown.list-channel-cascader {
  .el-cascader-menu__wrap {
    height: 210px;
  }
}
</style>
