<template>
  <div class="workflowTaskDrawer">
    <el-drawer v-model="taskAppointVisible" title="工作流任务设定" direction="rtl" size="1100px">
      <div class="table">
        <el-table :data="taskAppointTableData" border style="width: 100%" height="100%" stripe fit v-loading="loading">
          <el-table-column prop="tagName" label="工作流任务" width="180" />
          <el-table-column prop="ldapList" label="默认可认领人员" min-width="480" v-if="showType==='template'">
            <template #default="{ row, $index }">
              <el-tag
                v-for="tag in row?.ldapList?.map(i => ({ldapName:i.ldapName,ldap:i.ldap}))"
                :key="tag.ldap"
                class="agent-tag"
                closable
                :disable-transitions="false"
                @close="delTemplatePerson(tag.ldap, row, $index)"
              >
                {{ tag.ldapName }}
              </el-tag>
              <el-input
                v-if="inputVisible"
                ref="InputRef"
                v-model="inputValue"
                class="ml-1 w-20 appoint-agent"
                size="small"
                @keyup.enter="handleInputConfirm"
                @blur="handleInputConfirm"
              />
              <el-button v-else class="button-new-tag ml-1" plain type="primary" size="small"  @click="addPerson(row, $index)">
                +添加人员
              </el-button>
              <!-- <span  class="appoint-agent"  v-for="item in scope.row.ldapList?.map(i => i.ldapName)" :key="item">{{item}}</span> -->
            </template>
          </el-table-column>
          <el-table-column prop="ldapList" label="默认可认领人员" min-width="480" v-else>
            <template #default="scope">
              <span   v-for="(item, idx) in scope.row.ldapList?.map(i => i.ldapName)" :key="idx">{{ idx !== 0?', ':'' }}{{item}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="ldapList" label="节点说明" min-width="100" v-if="showType==='template'">
            <template #default="{ row }">
              <span @click="showAddRemark(row, 'add')"  class="appoint-agent" v-if="!row?.formInfo?.remark">+添加</span>
              <span @click="showAddRemark(row)" class="appoint-agent remark-line" v-else >编辑</span>
            </template>
          </el-table-column>
          <el-table-column prop="agentLdap" label="指派人员" v-if="showType==='ticket'">
            <template #default="scope">
              <div @click="showChangePerson(scope.row)" v-if="edit" class="appoint-agent"> {{ scope.row.agentLdap ?
                scope.row.agentName : '指派' }}</div>
              <div @click="showChangePerson(scope.row)" class="appoint-agent" v-else-if="!scope.row.readonly"> {{ scope.row.agentLdap ?
                scope.row.agentName : '指派' }}</div>
                 <div v-else> {{ scope.row.agentLdap ?
                scope.row.agentName : '已执行' }}</div>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="planAt" label="计划结束时间">
            <template #default="scope">
              <el-date-picker v-model="scope.row.planAt" type="date" placeholder="请选择时间" :default-time="defaultTime"
                @change="changeWorkflowItem(scope.row)" />
            </template>
          </el-table-column> -->
          <el-table-column prop="isSkip" label="自定义表单" width="100" v-if="showType==='template'&&edit">
                <template #default="scope">
                    <span @click="showAddOpt(scope.row)"  class="appoint-agent" v-if="!scope.row?.formInfo?.inFields?.length && !scope.row?.formInfo?.outFields?.length">+添加</span>
                    <span @click="showAddOpt(scope.row)" class="appoint-agent" v-else>编辑</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="button-group" v-if="edit">
          <el-button @click="cancelWorkflowAppoint" >取 消</el-button>
          <el-button type="primary" @click="saveWorkflowAppoint">保 存</el-button>
        </div>
        <div class="button-group" v-else>
          <el-button @click="cancelWorkflowAppoint" >关 闭</el-button>
          <el-button type="primary" @click="saveWorkflowAppoint">保 存</el-button>
        </div>
      </div>
    </el-drawer>
    <el-dialog v-model="changePersonVisible" :show-close="false" destroy-on-close :width="400"
      custom-class="person-select-dialog">
      <PersonSelect :cancel="cancelPersonSelect" :submit="submitPersonSelect" :addPerson="addManagerList"></PersonSelect>
    </el-dialog>
    <el-dialog v-model="changeTemplatePersonVisible" :show-close="false" destroy-on-close :width="400"
      custom-class="person-select-dialog">
      <PersonSelect
      :cancel="cancelTemplatePersonSelect"
      :submit="submitTemplatePersonSelect"
      selectType="checkbox"
      :addPerson="addManagerList"
       ></PersonSelect>
    </el-dialog>
    <checkStandDialog
      v-model:show="addRemarkVisible"
      :title="'修改 '+activeRemark?.tagName+' 说明'"
      :confirmFn="()=>addRemarkVisible=false"
      >
      <TinymceEditor
      :height="200"
      placeholder="填写问题说明"
      v-model:value="activeRemark.formInfo.remark"
      ></TinymceEditor>
      <!-- <el-input
      v-model="activeRemark.formInfo.remark" placeholder="请输入任务节点说明"
      /> -->
    </checkStandDialog>
  </div>
</template>

<script>
import TinymceEditor from '@/components/TinymceEditor.vue'
import PersonSelect from '@/components/PersonSelect.vue'
import checkStandDialog from '@/components/dialog/checkStandDialog.vue'
export default {
  components: {
    PersonSelect,
    checkStandDialog,
    TinymceEditor,
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    taskAppointTable: {
      type: Array,
      default: () => [],
    },
    showType: {
      type: String,
      default: 'ticket'
    },
    edit: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['workflowAgentList', 'changeList', 'update:show', 'showAppoint', 'activeAppoint'],
  data () {
    return {
      changeTemplatePersonVisible: false,
      changePersonVisible: false,
      addRemarkVisible: false,
      inputVisible: false,
      activeAppoint: {},
      activeRemark: {},
      addManagerList: [
        { name: '项目经理', ldap: 'project_manager', id: 'project_manager' },
        { name: '交付经理', ldap: 'delivery_manager', id: 'delivery_manager' }]
    }
  },
  methods: {
    showAddRemark (row, type) {
      this.activeRemark = row
      if (type === 'add') {
        this.activeRemark.formInfo.remark = ''
      }
      this.addRemarkVisible = true
    },
    delTemplatePerson (val, list, index) {
      const personIdx = this.taskAppointTableData[index].ldapList.findIndex(i => i.ldap === val)
      this.taskAppointTableData[index].ldapList.splice(personIdx, 1)
    },
    addPerson (val, index) {
      this.changeTemplatePersonVisible = true
      this.activeAppoint.index = index
    },
    saveWorkflowAppoint () {
      const arr = this.taskAppointTableData.filter(i => i.agentName || i.planAt)
      this.taskWorkflowList = arr?.map(i => ({
        tagId: i.tagId,
        agentLdap: i.agentLdap,
        agentName: i.agentName,
        planAt: i.planAt ? new Date(i.planAt).getTime() / 1000 : null
      }))
      if (this.taskWorkflowList.length > 0) {
        localStorage.setItem('workflowAppoint', JSON.stringify(this.taskWorkflowList))
      }
      this.$emit('changeList', this.taskWorkflowList)
      this.taskAppointVisible = false
    },
    cancelWorkflowAppoint () {
      this.taskAppointVisible = false
    },

    submitTemplatePersonSelect (val) {
      console.log(val)
      const data = val?.map(i => ({
        ldap: i?.ldap,
        ldapName: i?.name
      }))
      this.taskAppointTableData[this.activeAppoint?.index].ldapList.push(...data)
      this.changeTemplatePersonVisible = false
    },
    cancelTemplatePersonSelect () {
      this.changeTemplatePersonVisible = false
    },
    submitPersonSelect (val) {
      const index = this.taskAppointTableData.findIndex(i => i.tagId === this.activeAppointItem.tagId)
      this.taskAppointTableData[index].agentLdap = val?.ldap ? val?.ldap : null
      this.taskAppointTableData[index].agentName = val?.name ? val?.name : null
      this.changePersonVisible = false
    },
    cancelPersonSelect () {
      this.changePersonVisible = false
    },
    showChangePerson (row) {
      this.changePersonVisible = true
      this.activeAppointItem = {
        tagId: row.tagId
      }
    },
    changeWorkflowItem (row) {
      // console.log(row)
    },
    showAddOpt (val) {
      this.$emit('showAppoint', true)
      this.$emit('activeAppoint', val)
      this.activeAppoint = val
    },
  },
  computed: {
    taskAppointVisible: {
      set (val) {
        this.$emit('update:show', val)
      },
      get () {
        return this.show
      }
    },
    taskAppointTableData: {
      get () {
        return this.taskAppointTable
      },
      set (val) {
        // console.log('修改了', val)
        this.$emit('changeList', val)
      },
    }
  }

}
</script>

<style lang="less" scoped>
.workflowTaskDrawer{
  .table {
    width: 1050px;
    margin: 20px auto;
    height: calc(100% - 110px);

    .appoint-agent {
      color: #4B79F3;

      &:hover {
        cursor: pointer;
      }
    }

    .button-group {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;

      button {
        width: 120px;
        height: 40px;
        margin: 0 20px;
      }
    }
  }
  :deep(.el-drawer__header){
    border-bottom:1px solid #EBEEF5 !important;
    margin-bottom: 0;
    padding-bottom: 10px;
    padding-left: 25px;
    font-size:16px;
  }
}
.agent-tag{
  margin:5px;
}
.remark-line{
  width: 100%;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
</style>
