
import { Options, Vue } from 'vue-class-component'
import SchedulingPersonSelect from '@/components/SchedulingPersonSelect.vue'
import { getEmergencyDuty } from '../api/scheduling'
@Options({
  name: 'UrgencyPeople',
  components: {
    SchedulingPersonSelect,
  },
  props: {
    cancel: Function,
    submit: Function,
  }
})
export default class Scheduling extends Vue {
  dates: Array<any> = []
  show = true
  showSchedulingPersonSelect = false
  personIdSelectedInit: Array<any> = []
  currentS: any
  urgencyDataL1: Array<any> = []
  urgencyDataL2: Array<any> = []
  urgencyDataL3: Array<any> = []

  get datesSelected (): Array<any> {
    return this.dates
  }

  get validScheduleList () {
    const request = {
      L1: this.urgencyDataL1.map(e => e.ldap),
      L2: this.urgencyDataL2.map(e => e.ldap),
      L3: this.urgencyDataL3.map(e => e.ldap),
    }
    return request
  }

  scheduleList: Array<any> = [
    {
      startTime: '10:00',
      endTime: '20:00',
      persons: [
      ],
    },
  ]

  showSelectPersonDialog (val) {
    this.showSchedulingPersonSelect = true
    this.currentS = val
    const newArr = val.map(item => item.ldap)
    this.personIdSelectedInit = newArr
  }

  cancelPersonSelect () {
    this.showSchedulingPersonSelect = false
    this.personIdSelectedInit = []
  }

  submitPersonSelect (e) {
    this.showSchedulingPersonSelect = false
    this.currentS.splice(0)
    console.log(e)
    this.currentS.push(...e)
    console.log(this.urgencyDataL1, this.currentS)
  }

  mounted () {
    this.loadAgentsAll()
  }

  async loadAgentsAll () {
    const item = await getEmergencyDuty()
    this.urgencyDataL1 = item?.L1 || []
    this.urgencyDataL2 = item?.L2 || []
    this.urgencyDataL3 = item?.L3 || []
  }
}
