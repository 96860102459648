<template>
<div class="ticket-template-change">
  <el-dialog
    v-model="dialogFormVisible"
    title="批量变更工单模版"
    width="640px"
    destroy-on-close
    :show-close="false"
    custom-class="other-dialog"
  >
    <div class="ticket-body">
      <div class="left">
        <ul class="templates-list">
          <li v-for="(temp,index) in templateList"
              :key="index"
              @click="selectTemplate(index, temp)"
              :class="{'actived':isCheckedTemp===index, 'is-disabled': temp.id === currentTemplateId}"
          >{{temp.name}}</li>
        </ul>
      </div>
      <div class="right">
        <div class="right-box" v-if="Object.keys(template).length > 0">
          <template-generator
            ref="templateGenerator"
            v-if="Object.keys(template).length > 0"
            :template-data="template"
            v-model:value="templateValue"
          />
        </div>
        <div v-else class="tips right-box">请选择模版</div>
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogFormVisible = false" style="margin-right: 22px;">取 消</el-button>
        <el-button type="primary" @click="confirmChange('RefForms')">确 定</el-button>
      </div>
    </template>
  </el-dialog>
</div>
</template>

<script>
import { deepCopy, getValueFromObject } from '@/utils/utils'
import { loadTemplateList, getTemplateById, changeTemplateMulti } from '@/api/template'
import TemplateGenerator from '@/components/TemplateMaking/TemplateGenerator'

export default {
  name: 'TicketTemplateChangeMulti',
  props: {
    show: Boolean,
    ticketIdList: Array,
    currentTemplateId: Number,
    // relationMap: {},
    // value: {},
  },
  components: {
    TemplateGenerator,
  },
  data () {
    return {
      isCheckedTemp: -1,
      template: {},
      templateMap: {},
      templateValue: {},
      templateList: [],
    }
  },
  emits: ['update:show', 'update'],
  computed: {
    dialogFormVisible: {
      get () {
        return this.show
      },
      set (v) {
        this.$emit('update:show', v)
      }
    },
    // valueMap () {
    //   const res = {}
    //   Object.keys(this.relationMap).forEach(key => {
    //     res[this.relationMap[key].setId] = this.value[key]
    //   })
    //   return res
    // },
  },
  created () {
    loadTemplateList().then((res) => {
      this.templateList = res
      // console.log('templateList', this.templateList)
    }, (err) => {
      console.error(err)
      this.$message({
        type: 'error',
        message: err.errmsg
      })
    })
  },
  methods: {
    makeValue (template) {
      const res = {}
      // template.list.forEach(filed => {
      //   const key = filed.options.model
      //   const multiple = filed.options.multiple
      //   if (filed.options.valueList.type !== 'label') {
      //     return
      //   }
      //   const labelSetId = filed.options.valueList.labelSet
      //   if (!this.valueMap[labelSetId]) {
      //     return
      //   }
      //   const v = this.valueMap[labelSetId]
      //   if (Array.isArray(v)) {
      //     if (multiple) {
      //       res[key] = v
      //     }
      //   } else {
      //     if (multiple) {
      //       res[key] = [v]
      //     } else {
      //       res[key] = v
      //     }
      //   }
      // })
      return res
    },
    selectTemplate (index, temp) {
      if (temp.id === this.currentTemplateId) {
        return this.$message({
          type: 'warning',
          message: '当前使用的此模版！请选择其他模版！',
        })
      }
      this.isCheckedTemp = index
      const templateId = this.templateList[index]?.id
      if (this.templateMap[templateId]) {
        if (this.template !== this.templateMap[templateId]) {
          this.template = this.templateMap[templateId]
          this.templateValue = this.makeValue(this.template)
        }
      } else {
        getTemplateById(templateId).then((res) => {
          this.templateMap[templateId] = res.template
          this.template = this.templateMap[templateId]
          this.templateValue = this.makeValue(this.template)
        })
      }
    },
    confirmChange () {
      if (this.isCheckedTemp < 0 || Object.keys(this.template).length === 0 || !this.$refs.templateGenerator) {
        this.$message({
          type: 'error',
          message: '请先选择模板'
        })
        // window._trackEvent('ticket_create-tip_select_template', '弹出')
        return
      }
      const loading = this.$loading({
        lock: true,
        text: '正在变更工单模版...',
        spinner: 'el-icon-loading',
        // background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$refs.templateGenerator.getData().then((data) => {
        const value = deepCopy(data)
        const labelInfo = {}
        Object.keys(this.templateList[this.isCheckedTemp].relationMap).forEach((key) => {
          labelInfo[this.templateList[this.isCheckedTemp].relationMap[key].setId] = {
            required: this.templateList[this.isCheckedTemp].relationMap[key].required,
            defaultValue: this.templateList[this.isCheckedTemp].relationMap[key].defaultValue,
            value: getValueFromObject(value, key)
          }
        })
        const postData = {
          ticketList: this.ticketIdList,
          templateId: this.templateList[this.isCheckedTemp].id,
          fieldInfo: value,
          relationMap: labelInfo,
        }
        changeTemplateMulti(postData).then((res) => {
          this.$message({
            type: 'success',
            message: '修改成功'
          })
          this.dialogFormVisible = false
          this.$emit('update')
        }, (err) => {
          console.error(err)
          this.$message({
            type: 'error',
            message: err.errmsg
          })
        }).finally(() => {
          loading.close()
        })
      }, () => {
        loading.close()
        this.$message({
          type: 'error',
          message: '模版必填项未选！'
        })
      }).finally(() => {
        // loading.close()
      })
      // try {
      //   await NewPassword()
      //   this.$message({
      //     type: 'success',
      //     message: '修改成功'
      //   })
      //   this.dialogFormVisible = false
      // } catch (e) {
      //   this.$message({
      //     type: 'error',
      //     message: e.errmsg
      //   })
      // } finally {
      //   loading.close()
      // }
    },
  },
}
</script>

<style scoped lang="less">
.ticket-template-change {
  :deep(.el-dialog) {
    .el-dialog__title {
      font-size: 15px;
    }
    .el-dialog__body {
      border-bottom: 1px solid #F3F5F6;
      border-top: 1px solid #F3F5F6;
      padding: 0 20px;
    }
  }
  .ticket-body {
    display: flex;
    .left, .right {
      width: 50%;
      height: 350px;
      // overflow-y: scroll;
    }
    .left {
      overflow-y: scroll;
      border-right: 1px solid #F3F5F6;
      padding-right: 20px;
      padding-bottom: 16px;
    }
    .right {
      padding: 16px 0 16px 16px;
    }
    .right-box {
      width: 100%;
      height: 100%;
      background: rgba(75, 121, 243, 0.06);
      border-radius: 4px;
      overflow-y: scroll;
      padding: 10px 0 0 10px;
    }
    .tips {
      font-size: 14px;
      color: #ddd;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .templates-list {
    li {
      cursor: pointer;
      height: 36px;
      display: flex;
      align-items: center;
      margin-top: 12px;
      padding: 0 16px;
      border-radius: 4px;
      color: rgba(0, 0, 0, 0.85);
    }
    .actived {
      background: rgba(75, 121, 243, 0.25);
      color: #4B79F3;
    }
    .is-disabled {
      background: #fff;
      color: rgba(0, 0, 0, 0.45);
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
