import { DashboardParams } from '@/api/entity'
import axios, { fileDownloader } from '@/utils/request'

export * from '@/api/entity'

const app = '/unicorn-ticket'

export function dashboard (params) {
  const url = `${app}/data_panel/data_panel`
  return axios({
    url,
    method: 'GET',
    params
  })
}

export function loadDashboard (params) {
  const url = `${app}/data_panel/data_panel`
  return fileDownloader({
    url,
    method: 'GET',
    params
  })
}
