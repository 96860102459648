<template>
<header>
  <div class="user-name">
    <img v-if="avatar" :src="avatar" alt="">
    <i v-else class="user-head"></i>
    <el-dropdown v-if="!isWidthDone">
      <span class="el-dropdown-link" style="cursor: pointer;">
        {{userName}}
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <!-- <el-dropdown-item @click="editPassWord">修改密码</el-dropdown-item> -->
          <el-dropdown-item @click="goIndividuation">界面个性化</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <!-- <span v-if="!isWidthDone">{{userName}}</span> -->
    <el-dropdown v-if="isWidthDone">
      <span class="el-dropdown-link" style="cursor: pointer;">
        {{userName}}
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item @click="editPassWord">修改密码</el-dropdown-item>
          <el-dropdown-item @click="goIndividuation">界面个性化</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</header>
<editPassWordDiasg
  v-if="editPassWordShow"
  v-model:show="editPassWordShow"
/>
</template>
<script>
import store from '../store'
import editPassWordDiasg from './editPassWordDiasg.vue'
export default {
  name: 'TheHeader',
  components: {
    editPassWordDiasg,
  },
  data () {
    return {
      userName: '',
      avatar: '',
      editPassWordShow: false,
    }
  },
  computed: {
    isWidthDone () {
      const uu = store.getters['auth/me']?.thirdPartyUser
      return uu
    },
  },
  mounted () {
    const user = store.getters['auth/me']
    console.log(user)
    this.userName = user.username
    this.avatar = user.avatar ? user.avatar : ''
  },
  methods: {
    editPassWord () {
      this.editPassWordShow = true
    },
    goIndividuation () {
      this.$router.push({
        path: '/individuation',
        name: '用户自定义',
      })
    }
  },
}
</script>
<style lang="less" scoped>
header {
  z-index: 999;
  height: 48px;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(0,21,41,0.12);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  left: 130px;
  width: calc(100% - 130px);
}
.user-name {
  margin-right: 60px;
  span {
    margin-left: 10px;
    vertical-align: middle;
    color: rgba(0,0,0,0.65);
  }
  img {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    vertical-align: middle;
  }
  .user-head {
    display: inline-block;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background: url(../assets/images/icon-user.png);
    background-size: 27px;
    vertical-align: middle;
  }
}
</style>
