
import { Options, Vue } from 'vue-class-component'
import PersonSelect from '@/components/PersonSelect.vue'
import TicketViewSearchForm from '@/components/TicketViewSearchForm.vue'
import * as ticketApi from '@/api/ticket'
import { ElMessage, ElLoading } from 'element-plus'
import store from '@/store'
import * as agent from '@/api/agent'
import * as label from '@/api/label'
import { dateFormat, getSearchOpsFormFormData, urgentLevelFormat, deepCopy } from '@/utils/utils'
import EdgePersonSelect from '@/components/EdgePersonSelect.vue'

// safari 对 es2020的模块机制支持有问题（不支持导出函数作为事件回调），故此copy一份两个函数在此
function fixElTableColumnWidth (elTableClass, index) {
  // const elTableClass = 'ticket-table'
  // const index = 3
  const w = document.querySelector(`.${elTableClass} table th:nth-child(${index})`)?.getBoundingClientRect().width
  document.querySelectorAll(`.${elTableClass} table td:nth-child(${index}) > div`).forEach((e: any) => {
    e.style.width = w! - 1 + 'px'
  })
}

function fixDescDisplay () {
  document.querySelectorAll('.ticket-desc').forEach((e: any) => {
    if (e.innerText?.length > 10) {
      e.innerHTML = e.innerText
    }
  })
}

@Options({
  name: 'TicketsClaimWaiting',
  components: {
    PersonSelect,
    TicketViewSearchForm,
    EdgePersonSelect
  },
  beforeRouteUpdate (to, from, next) {
    this.loadTicketList()
    next()
  },
})
export default class TicketsClaimWaiting extends Vue {
  loading = false
  urgentLevel = 0
  tableData: Array<any> = []
  currentPage = 1
  pageSize = 10
  total = 0
  showPersonSelectDialog = false
  showEdgePersonSelectDialog = false
  ticketRowClicked: any = null
  searchFormData = {}
  orderField: any = {
    prop: 'urgentLevel',
    order: 'descending'
  }

  showBatchProcessDialog = false
  selectedTickets: Array<any> = []
  ticketsId: Array<any> = []
  selectedTicketCurPage: Array<any> = []
  selectedTicketInternalChange = false
  title = '批量指派并处理工单'

  ruleForm = {
    nextProcess: null,
    nextHandler: null,
  }

  processLabels: Array<any> = []
  handlers: Array<any> = []

  savedQueryData: any = null

  multiPersonSelect (val) {
    const promiseList = Object.keys(val).filter(v => val[v].ldap).map(v => {
      return ticketApi.ticketsAssignUpdate(this.ticketRowClicked?.id, val[v].ldap, val[v].name, v)
    })
    if (promiseList.length === 0) {
      this.showEdgePersonSelectDialog = false
    } else {
      Promise.all(promiseList).then(() => {
        ElMessage({
          showClose: true,
          message: '指派成功',
          type: 'success'
        })
        this.showEdgePersonSelectDialog = false
        this.loadTicketList()
        ticketApi.ticketCount().then((res) => {
          store.commit('updateTicketCount', res)
        })
      }, (err) => {
        console.log(err)
        this.showEdgePersonSelectDialog = false
      })
    }
  }

  sortChange (data) {
    console.log('sortChange:', data)
    if (data.order) {
      this.orderField = data
    } else {
      this.orderField = null
    }
    this.loadTicketList()
  }

  ticketViewSearchFormSubmit (data) {
    this.searchFormData = data
    console.log('ticketViewSearchFormSubmit:', data)
    this.loadTicketList()
  }

  cancelPersonSelect (e) {
    this.showPersonSelectDialog = false
  }

  submitPersonSelect (agent) {
    if (!agent) {
      ElMessage({
        showClose: true,
        message: '请选择指派人',
        type: 'warning'
      })
      return
    }
    console.log(agent)
    this.showPersonSelectDialog = false
    ticketApi.ticketsAssignUpdate(this.ticketRowClicked?.id, agent.ldap, agent.name).then(res => {
      ElMessage({
        showClose: true,
        message: '指派成功',
        type: 'success'
      })
      this.loadTicketList()
      ticketApi.ticketCount().then((res) => {
        console.log(res)
        store.commit('updateTicketCount', res)
      })
    }).catch(res => {
      console.log(res)
    })
  }

  ticketClickAssignMe (row) {
    const me = store.getters['auth/me']
    ticketApi.ticketsAssignUpdate(row.id, me.ldap, me.username).then(res => {
      ElMessage({
        showClose: true,
        message: '认领成功',
        type: 'success'
      })
      this.loadTicketList()
      ticketApi.ticketCount().then((res) => {
        store.commit('updateTicketCount', res)
      })
    }).catch(res => {
      console.log(res)
    })
  }

  ticketClickAssign (row) {
    this.ticketRowClicked = row
    if (row.curPhaseId) {
      this.showPersonSelectDialog = true
    } else {
      this.showEdgePersonSelectDialog = true
    }
  }

  priorityFormatter (row, column) {
    return urgentLevelFormat(row.urgentLevel)
  }

  createTimeFormatter (row, column) {
    return dateFormat(row.createdAt)
  }

  created () {
    this.savedQueryData = localStorage.getItem('__ticket_AllTicketsAdminSos_query_criteria')
  }

  mounted () {
    if (this.$route.query.pageSize) {
      this.pageSize = Number(this.$route.query.pageSize)
    }
    if (this.$route.query.page) {
      this.currentPage = Number(this.$route.query.page)
    }
    if (this.savedQueryData) {
      const q = JSON.parse(this.savedQueryData)
      this.searchFormData = q
      if (q.orderField) {
        this.orderField = q.orderField
      }
    }
    this.loadTicketList()
    window.addEventListener('resize', () => {
      fixElTableColumnWidth('ticket-table', 3)
    })
  }

  loadTicketList () {
    const searchOps = getSearchOpsFormFormData(this.searchFormData)
    if (this.orderField?.order) {
      searchOps.orderField = this.orderField
    }
    this.loading = true
    ticketApi.ticketList(ticketApi.ViewType.ADMIN_SOS, this.currentPage, this.pageSize, searchOps).then(res => {
      this.tableData.splice(0)
      this.tableData.push(...res.results)
      this.total = res.count
      this.handleSelectPersonMerge()
    }).catch(res => {
      console.log(res)
    }).finally(() => {
      this.loading = false
      // fix safari表头错位
      setTimeout(() => {
        fixElTableColumnWidth('ticket-table', 3)
        fixDescDisplay()
      }, 0)
      this.saveCriteria(searchOps)
    })
  }

  saveCriteria (data) {
    const q = { ...data }
    console.log('AllTicketsAdminSos saveCriteria=', q)
    localStorage.setItem('__ticket_AllTicketsAdminSos_query_criteria', JSON.stringify(q))
  }

  handleCurrentChange (e) {
    console.log('handleCurrentChange', e)
    this.selectedTicketInternalChange = true
    this.currentPage = e
    this.$router.push({
      name: this.$route.name!,
      query: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
    })
  }

  handleSizeChange (e) {
    console.log('handleSizeChange', e)
    this.selectedTicketInternalChange = true
    this.pageSize = e
    this.$router.push({
      name: this.$route.name!,
      query: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
    })
  }

  handleSelectionChange (data) {
    if (this.selectedTicketInternalChange) {
      return
    }
    this.selectedTicketCurPage = data
  }

  handleSelectPersonMerge () {
    const $refs = this.$refs as any
    if (this.selectedTicketCurPage.length > 0) {
      this.selectedTicketCurPage.forEach((person) => {
        if (this.selectedTickets.find(v => v.id === person.id) !== undefined) {
          return
        }
        this.selectedTickets.push(deepCopy(person))
      })
    }
    // 清空数组
    this.selectedTicketCurPage = []
    this.selectedTickets = this.selectedTickets.filter((person) => {
      const res = this.tableData.find(v => v.id === person.id)
      if (res) {
        this.selectedTicketCurPage.push(res)
        this.$nextTick(() => {
          $refs.ticketTable.toggleRowSelection(res)
        })
        return false
      }
      return true
    })
    this.selectedTicketInternalChange = false
  }

  showBatchProcess () {
    const selectedList = [...this.selectedTickets, ...this.selectedTicketCurPage]
    this.ticketsId = selectedList.map(t => {
      return t.id
    })
    this.showBatchProcessDialog = true
    label.processLabels().then(res => {
      this.processLabels = res.filter(l => !l.isDeleted)
    }).catch(res => [
      console.log('processLabels error:', res)
    ])
  }

  changeNextProcess (e) {
    this.loadAgentsByTag(e)
    // const isLevel3 = this.processLabels.some(l => {
    //   return l.id === e && l.name === 'LEVEL-3'
    // })
    // if (isLevel3) {
    //   ElMessageBox.alert('请按照操作文档进行问题排查，并在问题回复中添加排查截图。', '提示', {
    //     confirmButtonText: '确定',
    //     callback: (action) => {
    //       // ElMessage({
    //       //   type: 'info',
    //       //   message: `action: ${action}`,
    //       // })
    //     },
    //   })
    // }
  }

  async loadAgentsByTag (tagId: number) : Promise<void> {
    this.handlers = []
    let res = await agent.agentsByTag(tagId)
    this.handlers.push(...res.results)
    while (res.next) {
      res = await agent.agentsByTag(tagId, res.page + 1)
      this.handlers.push(...res.results)
    }
  }

  getHandlerName (handlerId) {
    let handlerName = ''
    this.handlers.forEach(p => {
      if (p.ldap === handlerId) {
        handlerName = p.name
      }
    })
    return handlerName
  }

  proceedBatchProcess () {
    if (!this.ruleForm.nextHandler) {
      ElMessage({
        showClose: true,
        message: '处理人不可为空',
        type: 'error'
      })
    } else {
      const loadingInstance = ElLoading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading'
      })
      const data = {
        ticketList: this.ticketsId,
        responsible_agent: this.ruleForm.nextHandler,
        responsible_agent_name: this.getHandlerName(this.ruleForm.nextHandler),
      }
      ticketApi.ticketBatchAssign(data).then(() => {
        this.selectedTickets = []
        this.selectedTicketCurPage = []
        this.showBatchProcessDialog = false
      }, (err) => {
        console.error('批量指派工单-继续:', err)
      }).finally(() => {
        this.loadTicketList()
        loadingInstance.close()
        ticketApi.ticketCount().then((res) => {
          console.log(res)
          store.commit('updateTicketCount', res)
        })
      })
    }
  }

  cancelBatchProcess () {
    this.showBatchProcessDialog = false
  }
}
