<template>
  <el-form
    label-width="80px"
  >
    <el-form-item label="多选">
      <el-switch v-model="fieldInfoLocal.options.multiple" @change="handleMultipleChange"></el-switch>
    </el-form-item>
    <el-form-item label="允许搜索">
      <el-switch v-model="fieldInfoLocal.options.filterable"></el-switch>
    </el-form-item>
    <el-form-item label="数据来源" v-show="false">
      <el-radio-group v-model="fieldInfoLocal.options.valueList.type">
        <el-radio label="static" disabled>静态数据</el-radio>
        <el-radio label="remote" disabled>远端数据</el-radio>
        <el-radio label="label">标签集</el-radio>
      </el-radio-group>
    </el-form-item>
    <template v-if="fieldInfoLocal.options.valueList.type === 'label'">
      <el-form-item label="标签集">
        <el-select
          v-model="fieldInfoLocal.options.valueList.labelSet"
          placeholder="选择标签集"
          @change="handleLabelSetIdChange"
        >
          <el-option
            v-for="item in labelSets"
            :key="item.id"
            :label="item.labelSetName"
            :value="item.id"
            :disabled="item.isDeleted"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="默认值">
        <h-select v-bind="fieldInfoLocal.options" :item-list="labelList" v-model="fieldInfoLocal.options.defaultValue"/>
      </el-form-item>
    </template>
  </el-form>
</template>

<script>
import { deepCopy } from '@/utils/utils'
import { getLabelsBySetId, ticketLabelSet } from '@/api/label'
import HSelect from '@/components/TemplateMaking/TemplateField/TemplateFieldRenderList/HSelect'

function filterDisableTag (tagList, valueList) {
  return tagList.filter((v) => {
    if (v.isDeleted === false) {
      return true
    }
    if (Array.isArray(valueList)) {
      return valueList.indexOf(v.value) >= 0
    } else if (valueList === v.value) {
      return true
    }
    return false
  })
}

export function generateData (originData) {
  const options = {
    multiple: false,
    clearable: true,
    filterable: true,
    defaultValue: null,
    valueList: {
      type: 'label',
      arr: [],
      remote: {
        api: '',
        label: '',
        value: '',
        headers: {},
        params: {},
        data: {}
      },
      labelSet: null
    }
  }
  if (!originData.options) {
    originData.options = options
  } else {
    originData.options = Object.assign({}, options, originData.options)
  }
}

export function postTreatment (originData) {
  originData.options.itemList = []
  if (originData.options.valueList.type === 'static') {
    originData.options.itemList = originData.options.valueList.arr
  } else if (originData.options.valueList.type === 'label' && originData.options.valueList.labelSet) {
    getLabelsBySetId(originData.options.valueList.labelSet).then((res) => {
      const temp = res.map(t => ({
        value: t.id,
        label: t.name + (t.extraName ? ('（' + t.extraName + '）') : ''),
        isDeleted: t.isDeleted
      }))
      // console.log('postTreatment()')
      // const enableList = temp.filter(v => v.isDeleted === false)
      originData.options.itemList = filterDisableTag(temp, originData.options.defaultValue)
      // originData.options.itemList = [...enableList, ...disableList]
    }, (err) => {
      this.$message({
        type: 'error',
        message: err.errmsg
      })
      console.error(err)
    })
  }
}

export default {
  name: 'SelectConfig',
  props: {
    fieldInfo: {
      type: Object,
      require: true
    }
  },
  emits: ['update:fieldInfo'],
  components: {
    HSelect
  },
  data: () => {
    return {
      internalChange: false,
      fieldInfoLocal: {},
      labelSets: [],
      labelList: [],
      labelMap: {}
    }
  },
  watch: {
    fieldInfo: {
      handler (val) {
        if (this.internalChange) {
          return
        }
        if (val.options.valueList.type === 'label' && val.options.valueList.labelSet) {
          this.loadLabelSetById(val.options.valueList.labelSet)
        }
        this.fieldInfoLocal = deepCopy(val)
      },
      deep: true,
      immediate: true
    },
    fieldInfoLocal: {
      handler (val) {
        this.internalChange = true
        this.$emit('update:fieldInfo', val)
        this.$nextTick(() => {
          this.internalChange = false
        })
      },
      deep: true
    }
  },
  created () {
    ticketLabelSet().then((res) => {
      const enableList = res.filter(v => v.isDeleted === false)
      const disableList = res.filter(v => v.isDeleted && this.fieldInfoLocal.options.valueList.labelSet === v.id)
      this.labelSets = [...enableList, ...disableList]
    })
  },
  methods: {
    handleMultipleChange (val) {
      if (val && !Array.isArray(this.fieldInfoLocal.options.defaultValue) && this.fieldInfoLocal.options.defaultValue !== null) {
        this.fieldInfoLocal.options.defaultValue = [this.fieldInfoLocal.options.defaultValue]
      }
      if (!val && Array.isArray(this.fieldInfoLocal.options.defaultValue) && this.fieldInfoLocal.options.defaultValue.length > 0) {
        this.fieldInfoLocal.options.defaultValue = this.fieldInfoLocal.options.defaultValue[0]
      }
    },
    loadLabelSetById (id) {
      if (this.labelMap[id]) {
        this.labelList = this.labelMap[id]
      } else {
        this.labelList = []
        getLabelsBySetId(id).then((res) => {
          const temp = res.map(t => ({
            value: t.id,
            label: t.name + (t.extraName ? ('(' + t.extraName + ')') : ''),
            isDeleted: t.isDeleted
          }))
          // console.log('selectConfig.vue')
          // const enableList = temp.filter(v => v.isDeleted === false)
          this.labelList = filterDisableTag(temp, this.fieldInfoLocal.options.defaultValue)
          this.labelMap[id] = this.labelList
          // this.labelList = [...enableList, ...disableList]
          // this.labelMap[id] = [...enableList, ...disableList]
        }, (err) => {
          this.$message({
            type: 'error',
            message: err.errmsg
          })
          console.error(err)
        })
      }
    },
    handleLabelSetIdChange (id) {
      this.fieldInfoLocal.options.defaultValue = null
      this.loadLabelSetById(id)
    }
  }
}
</script>

<style scoped>

</style>
