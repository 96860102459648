import axios from '@/utils/request'
import { LabelSet } from '@/api/entity'

const app = '/unicorn-ticket'

const labelUrls = {
  labelSet: `${app}/tags_fields/label_set`,
  personLabelSet: `${app}/tags_fields/person_label_set`,
  ticketLabelSet: `${app}/tags_fields/ticket_label_set`,
  label: `${app}/tags_fields/labels`,
  guest: `${app}/tags_fields/guest`,
  outsource: `${app}/tags_fields/outsourcing`,
}

export function listLabelSet () {
  const url = labelUrls.labelSet
  return axios({
    url,
    method: 'get'
  })
}

export function personLabelSet (isDeleted = null) {
  const url = labelUrls.personLabelSet
  return axios({
    url,
    method: 'get',
    params: {
      isDeleted
    }
  })
}

export function ticketLabelSet (isDeleted?) {
  const url = labelUrls.ticketLabelSet
  const params: any = {}
  if (isDeleted !== undefined) {
    params.isDeleted = isDeleted
  }
  return axios({
    url,
    method: 'get',
    params,
  })
}

export function getLabelsBySetId (id: number) {
  const url = labelUrls.label
  const params = {
    labelSet: id
  }
  return axios({
    url,
    method: 'get',
    params
  })
}

export function updateLabelSet (data: LabelSet) {
  const id = data.id
  const url = `${app}/tags_fields/label_set/${id}`
  return axios({
    url,
    method: 'put',
    data
  })
}

export function saveLabelSet (data: LabelSet) {
  const url = `${app}/tags_fields/label_set_label`
  return axios({
    url,
    method: 'post',
    data
  })
}

export function deleteLabelSet (id: number) {
  const url = `${app}/tags_fields/label_set/${id}`
  return axios({
    url,
    method: 'delete'
  })
}

export function processLabels () {
  const url = `${app}/tags_fields/process_labels`
  return axios({
    url,
    method: 'get'
  })
}

export function urgentLevels () {
  const url = `${app}/tags_fields/urgentlevel_labels`
  return axios({
    url,
    method: 'get'
  })
}

export function selfTags (ldap: string) {
  const url = `${app}/agents/self_tags`
  return axios({
    url,
    method: 'get',
    params: {
      ldap,
    },
  })
}

export function tagLabelSetName (val: string) {
  const url = labelUrls.label
  return axios({
    url,
    method: 'get',
    params: {
      label_set_name: val,
    },
  })
}
// 获取外部人员
export function tagLabelAllGuest (params) {
  const url = labelUrls.guest
  return axios({
    url,
    method: 'get',
    params,
  })
}
// 添加外部人员
export function PostLabelAllGuest (data) {
  const url = labelUrls.guest
  return axios({
    url,
    method: 'post',
    data,
  })
}
// 编辑外部人员
export function PutLabelAllGuest (data, id) {
  const url = `${labelUrls.guest}/${id}`
  return axios({
    url,
    method: 'put',
    data,
  })
}
// 删除外部人员
export function DelLabelAllGuest (id) {
  const url = `${labelUrls.guest}/${id}`
  return axios({
    url,
    method: 'delete',
  })
}

// 获取外包人员
export function getOutsourcePerson (params) {
  const url = labelUrls.outsource
  return axios({
    url,
    method: 'get',
    params,
  })
}

// 添加外包人员
export function addOutsourcePerson (data) {
  const url = labelUrls.outsource
  return axios({
    url,
    method: 'post',
    data,
  })
}

// 编辑外包人员
export function editOutsourcePerson (id, data) {
  const url = `${labelUrls.outsource}/${id}`
  return axios({
    url,
    method: 'put',
    data,
  })
}

// 删除外包人员
export function deleteOutsourcePerson (id) {
  const url = `${labelUrls.outsource}/${id}`
  return axios({
    url,
    method: 'delete',
  })
}
