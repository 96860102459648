
import { Options, Vue } from 'vue-class-component'
import * as ticketApi from '@/api/ticket'
import { TicketStatus } from '@/api/ticket'
import { curStatusFormat, dateFormat, getSearchOpsFormFormData, urgentLevelFormat } from '@/utils/utils'
import TicketViewSearchForm from '@/components/TicketViewSearchForm.vue'
import ExportTickets from '@/components/ExportTickets/ExportTickets.vue'
import store from '@/store'
import { ElMessage } from 'element-plus'
import { selfTags } from '@/api/label'

// safari 对 es2020的模块机制支持有问题（不支持导出函数作为事件回调），故此copy一份两个函数在此
function fixElTableColumnWidth (elTableClass, index) {
  // const elTableClass = 'ticket-table'
  // const index = 3
  const w = document.querySelector(`.${elTableClass} table th:nth-child(${index})`)?.getBoundingClientRect().width
  document.querySelectorAll(`.${elTableClass} table td:nth-child(${index}) > div`).forEach((e: any) => {
    e.style.width = w! - 1 + 'px'
  })
}

function fixDescDisplay () {
  document.querySelectorAll('.ticket-desc').forEach((e: any) => {
    if (e.innerText?.length > 10) {
      e.innerHTML = e.innerText
    }
  })
}

@Options({
  name: 'TicketsDoing',
  components: {
    TicketViewSearchForm,
    ExportTickets,
  },
  beforeRouteUpdate (to, from, next) {
    this.loadTicketList()
    next()
  },
})
export default class TicketsDoing extends Vue {
  loading = false
  tableData: Array<any> = []
  total = 0
  currentPage = 1
  pageSize = 10
  filterField = {}
  searchFormData: any = {}
  orderField: any = {
    prop: 'curStatus',
    order: 'descending'
  }

  selfTagList: Array<string> = []
  savedQueryData: any = null
  showPhaseSelectDialog = false
  phaseList: Array<any> = []
  selectedPhase = ''
  selectedTicketId = 0

  keywords = ''
  canHandle (row: any) :boolean {
    if (row.curPhaseId) {
      return this.selfTagList.includes(row.curPhase) && row.curStatus === TicketStatus.UNASSIGNED
    }
    return row.claimablePhases?.length > 0
  }

  goBlankRouter (scope) {
    const href = this.$router.resolve({
      name: '工单详情',
      params: {
        id: scope.row.id
      }
    }).href
    window.open(href, '_blank')
  }

  assignMe (row) {
    if (row.curPhaseId) {
      return this.ticketClickAssignMe(row)
    } else if (row.claimablePhases?.length > 1) {
      this.showPhaseSelectDialog = true
      this.phaseList = row.claimablePhases
      this.selectedPhase = ''
      this.selectedTicketId = row.id
    } else {
      this.ticketClickAssignMeWithPhaseId(row.id, row.claimablePhases?.[0]?.phaseId)
    }
  }

  assignWithPhase () {
    if (!this.selectedPhase) {
      ElMessage({
        type: 'warning',
        message: '请选择一个处理节点',
      })
      return
    }
    this.showPhaseSelectDialog = false
    this.ticketClickAssignMeWithPhaseId(this.selectedTicketId, this.selectedPhase)
  }

  ticketClickAssignMeWithPhaseId (id, phaseId) {
    const me = store.getters['auth/me']
    this.loading = true
    ticketApi.ticketsAssignUpdate(id, me.ldap, me.username, phaseId).then(() => {
      ElMessage({
        showClose: true,
        message: '认领成功',
        type: 'success'
      })
    }).catch(res => {
      console.error('认领失败：', res)
    }).finally(() => {
      this.loading = false
      this.loadTicketList()
      ticketApi.ticketCount().then((res) => {
        store.commit('updateTicketCount', res)
      })
    })
  }

  ticketClickAssignMe (row) {
    const me = store.getters['auth/me']
    this.loading = true
    ticketApi.ticketsAssignUpdate(row.id, me.ldap, me.username).then(() => {
      ElMessage({
        showClose: true,
        message: '认领成功',
        type: 'success'
      })
    }).catch(res => {
      console.error('认领失败：', res)
    }).finally(() => {
      this.loading = false
      this.loadTicketList()
      ticketApi.ticketCount().then((res) => {
        store.commit('updateTicketCount', res)
      })
    })
  }

  sortChange (data) {
    if (data.order) {
      this.orderField = data
    } else {
      this.orderField = null
    }
    this.loadTicketList()
  }

  ticketViewSearchFormSubmit (data) {
    this.searchFormData = data
    this.filterField = data.curStatus ? { cur_status: data.curStatus } : {}
    // console.log(this.filterField)
    this.loadTicketList()
  }

  onSubmit (e) {
    console.log(e)
  }

  curStatusFormatter (row, column) {
    return curStatusFormat(row.curStatus, row.suspended)
  }

  priorityFormatter (row, column) {
    return urgentLevelFormat(row.urgentLevel)
  }

  createTimeFormatter (row, column) {
    return dateFormat(row.createdAt)
  }

  updatedTimeFormatter (row, column) {
    return dateFormat(row.updatedAt)
  }

  created () {
    this.savedQueryData = localStorage.getItem('__ticket_AllTickets_query_criteria')
  }

  mounted () {
    const me = store.getters['auth/me']
    if (this.$route.query.pageSize) {
      this.pageSize = Number(this.$route.query.pageSize)
    }
    if (this.$route.query.page) {
      this.currentPage = Number(this.$route.query.page)
    }
    console.log('searchFormData=', this.searchFormData)
    // let searchOps = getSearchOpsFormFormData(this.searchFormData)
    console.log('savedQueryData=', this.savedQueryData)
    if (this.savedQueryData) {
      const q = JSON.parse(this.savedQueryData)
      console.log('q=', q)
      this.searchFormData = q
      if (q.orderField) {
        this.orderField = q.orderField
      }
    }
    this.loadTicketList()

    selfTags(me.ldap).then(res => {
      this.selfTagList = res
    }).catch(res => {
      console.error(res)
    })
    window.addEventListener('resize', () => {
      fixElTableColumnWidth('ticket-table', 3)
    })
  }

  loadTicketList () {
    const searchOps = getSearchOpsFormFormData(this.searchFormData)
    if (this.orderField?.order) {
      searchOps.orderField = this.orderField
    }
    this.loading = true
    ticketApi.ticketList(ticketApi.ViewType.PERSONAL_ALL_TICKETS, this.currentPage, this.pageSize, searchOps, this.filterField).then(res => {
      this.tableData.splice(0)
      this.tableData.push(...res.results)
      this.total = res.count
    }).catch(res => {
      console.error(res)
    }).finally(() => {
      this.loading = false
      // fix safari表头错位
      setTimeout(() => {
        fixElTableColumnWidth('ticket-table', 3)
        fixDescDisplay()
      }, 0)
      this.saveCriteria(searchOps)
    })
  }

  saveCriteria (data) {
    const q = { ...data }
    if (Object.keys(this.searchFormData).length > 0) {
      q.curStatus = this.searchFormData.curStatus
    }

    console.log('AllTickets saveCriteria=', q)
    localStorage.setItem('__ticket_AllTickets_query_criteria', JSON.stringify(q))
  }

  async exportTicketList () {
    const searchOps = getSearchOpsFormFormData(this.searchFormData)
    if (this.orderField?.order) {
      searchOps.orderField = this.orderField
    }
    return await ticketApi.exportTicketList(ticketApi.ViewType.PERSONAL_ALL_TICKETS, this.currentPage, this.pageSize, searchOps)
  }

  handleCurrentChange (e) {
    this.currentPage = e
    this.$router.push({
      name: this.$route.name!,
      query: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
    })
  }

  handleSizeChange (e) {
    this.pageSize = e
    this.$router.push({
      name: this.$route.name!,
      query: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
    })
  }
}
