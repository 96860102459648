<template>
  <div class="page">
    <div class="header-nav">
      <span @click="$router.back()" class="go-back">问题列表 / </span>
      <span class="title">{{pageTitle}}</span>
    </div>
    <div class="content">
      <!-- 创建问题 -->
      <div class="form" v-if="questionType == 'create' || questionType == 'edit'">
        <el-form label-position="right" label-width="130px" :model="questionForm" ref="questionForm" :rules="rules">
          <el-form-item label="标题:" prop="title">
            <el-input v-model="questionForm.title" placeholder="请输入标题" clearable></el-input>
          </el-form-item>
          <el-form-item label="问题描述:" prop="describe">
<!--            <el-input v-model="questionForm.describe" v-html="questionForm.describe" type="textarea" :autosize="{ minRows: 5, maxRows: 12}" clearable placeholder="问题描述模板：比如什么时间，什么问题"></el-input>-->
            <TinymceEditor :height="200" placeholder="问题描述模板：比如什么时间，什么问题" ref="refTinymceEditor" v-model:value="questionForm.describe"></TinymceEditor>
          </el-form-item>
          <el-form-item label="紧急程度:" prop="urgent">
            <el-select class="urgent-select" v-model="questionForm.urgent" placeholder="请选择问题紧急程度">
              <el-option v-for="urgent in urgentList" :key="urgent.name"
                :label="urgent.name"
                :value="urgent.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属工单类型:" prop="problemType">
            <el-select class="urgent-select" v-model="questionForm.problemType" placeholder="请选择所属工单类型">
              <el-option v-for="t in problemTypeList" :key="t.value"
                :label="t.label"
                :value="t.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="问题订阅:">
            <el-radio v-model="questionForm.subscribe" label="1">订阅</el-radio>
            <el-radio v-model="questionForm.subscribe" label="0">不订阅</el-radio>
          </el-form-item>
          <el-form-item label="问题来源及渠道:" prop="channel" class="channel-select">
            <el-cascader
              v-model="questionForm.channel"
              style="width: 100%;"
              popper-class="cascader-channel-select"
              separator=" | "
              filterable
              :options="channelOptions"
              :props="{ expandTrigger: 'hover' }">
            </el-cascader>
          </el-form-item>
          <el-form-item label="创建人:" prop="source">
            <!-- <el-input v-model="questionForm.source" placeholder="问题来源是什么地点什么来源" clearable></el-input> -->
            <div>{{ questionSource }}</div>
          </el-form-item>
          <el-form-item class="upload-box">
            <el-upload
              multiple
              :show-file-list="false"
              class="upload-demo"
              action="https://jsonplaceholder.typicode.com/posts/"
              :http-request="uploadFile"
              :on-preview="handlePreview"
              :on-remove="handleRemove">
              <el-button class="upload-btn" size="small" v-loading.fullscreen.lock="uploading"><i class="upload-icon"></i>上传附件</el-button>
            </el-upload>
            <div class="files-box" v-if="fileList.length > 0 && notImageList(fileList).length > 0" style="margin-top:10px;">
              <div v-for="(file, index) in fileList" :key="file.key" >
                <div v-if="!checkImage(file.name)" class="flex-box">
                  <div class="flex-download">
                    <span style="margin-right: 10px;" class="file-name">{{file.name}}</span>
                    <el-link target="_blank" :href="file.url" :download="file.name">
                      <i class="icon-download"></i>
                    </el-link>
                  </div>
                  <button @click="deleteFile(index)">删除</button>
                </div>
              </div>
            </div>
            <div v-if="fileList.length > 0 && imageList(fileList).length > 0" class="attach-img-box">
              <template v-for="(file, index) in fileList" >
                <div v-if="checkImage(file.name)" :key="file.key" class="img-div">
                  <el-image
                    :src="file.url"
                    :preview-src-list="[file.url]"
                    style="width: 100px; height: 80px"
                  >
                  </el-image>
                  <span class="icon-del" @click="deleteFile(index)"></span>
                </div>
              </template>
            </div>
          </el-form-item>
        </el-form>
        <div class="btn-box">
          <button @click="switchForm">取 消</button>
          <button class="submit-btn" @click="submitQuestion('questionForm', 'add')" v-if="questionType == 'create'">提 交</button>
          <button class="submit-btn" @click="submitQuestion('questionForm', 'edit')" v-if="questionType == 'edit'">修 改</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ProblemSource, addProblem, editProblem, getProblemInfo, getSubscribe } from '@/api/problem'
import { uploadFile } from '@/api/files'
import store from '../../store'
import { urgentLevels } from '../../api/label'
import TinymceEditor from '@/components/TinymceEditor.vue'
import { hashCode, checkImage } from '../../utils/utils'

export default {
  name: 'createQuestion',
  components: {
    TinymceEditor,
  },
  // data
  data () {
    const checkTitle = (rule, value, callback) => {
      const title = value.replace(/(^\s*)|(\s*$)/g, '')
      if (title === '') {
        callback(new Error('请输入标题'))
      } else {
        callback()
      }
    }
    return {
      questionType: 'create',
      questionSource: '',
      pageTitle: '',
      problemId: '',
      questionForm: {
        title: '',
        describe: '',
        source: '',
        status: '',
        urgent: '',
        subscribe: '1',
        problemType: '',
        channel: [-1, 1],
      },
      replyForm: {
        title: '',
        describe: '',
        reply: '',
        source: '',
        urgent: '',
        problemComments: '',
        relateProblemList: '',
        time: '',
        ticketId: '',
        file: {},
        subscribe: ''
      },
      uploading: false,
      fileList: [],
      urgentList: [],
      problemTypeList: [
        {
          value: 'task',
          label: '任务'
        },
        {
          value: 'warning',
          label: '告警'
        },
        {
          value: 'after_sale',
          label: '售后'
        },
        {
          value: 'test',
          label: '测试'
        },
      ],
      channelOptions: ProblemSource,
      dialogImageUrl: '',
      dialogVisible: false,
      rules: {
        title: [
          { required: true, validator: checkTitle, trigger: 'blur' }
        ],
        describe: [
          { required: true, message: '请描述一下问题', trigger: 'blur' }
        ],
        urgent: [
          { required: true, message: '请选择问题紧急程度', trigger: 'blur' }
        ],
        problemType: [
          { required: true, message: '请选择所属工单类型', trigger: 'blur' }
        ],
        channel: [
          { required: true, message: '请选择问题来源及渠道', trigger: 'blur' }
        ],
        // source: [
        //   { validator: checkSource, trigger: 'blur' }
        // ]
      },
    }
  },
  computed: {
    userLdapHashCode () {
      const user = store.getters['auth/me']
      return hashCode(user.ldap)
    }
  },
  created () {
    const user = store.getters['auth/me']
    this.questionSource = `${user?.group?.[0]?.groupName} - ${user?.username}`
    const query = this.$route.query
    if (query.problemId) {
      this.problemId = query.problemId
      this.questionType = query.type
      if (this.questionType === 'edit') {
        this.pageTitle = '修改问题'
      } else if (this.questionType === 'info') {
        this.pageTitle = '问题详情'
      }
      this.getProblemInfo()
    } else {
      this.pageTitle = '新建问题'
      this.questionType = 'create'
    }
    urgentLevels().then(res => {
      this.urgentList = res
    }).catch(res => {
      console.error(res)
    })
  },
  // methods
  methods: {
    getProblemInfo () {
      const id = this.problemId
      getProblemInfo(id).then((res) => {
        const date = res.createTime.split(' ')[0]
        const t = res.createTime.split(' ')[1].split(':').slice(0, 2).join(':')
        this.replyForm = {
          title: res.problemTitle,
          describe: res.problemDescribe,
          source: res.problemOrigin,
          urgent: res.urgentLevel || '',
          problemComments: res.problemComments || '',
          relateProblemList: res.relateProblemList || '',
          time: date + ' ' + t,
          ticketId: res.ticketId,
          file: res.problemFiles || [],
          subscribe: String(res.subscribe),
        }
        this.fileList = res.problemFiles || []
        this.questionSource = Number(res.channel) === 5 ? res.creatorName : (res?.creatorObj?.name || res.creator)
        this.questionForm = {
          title: res.problemTitle,
          describe: res.problemDescribe,
          source: res.problemOrigin,
          status: res.problemStatus,
          urgent: res.urgentLevel || '',
          subscribe: String(res.subscribe),
          problemType: res.problemType || '',
          channel: [],
        }
        this.replyForm.relateProblemList = this.replyForm.relateProblemList.replace(/\[|]|'/g, '')
      }, (err) => {
        this.$message({
          type: 'error',
          message: err.errmsg
        })
        console.error(err)
      })
    },
    uploadFile (info) {
      // if (this.uploading) {
      //   this.$message({
      //     type: 'error',
      //     message: `文件 ${info.file.name} 上传失败：其他文件正在上传`
      //   })
      //   info.onError(new Error('其他文件正在上传'))
      //   return
      // }
      this.uploading = true
      const { file } = info
      const formData = new FormData()
      formData.append('file', file, file.name)
      return uploadFile(formData).then((res) => {
        this.uploading = false
        this.fileList.push({
          name: file.name,
          key: res.fileId,
          url: res.url
        })
        this.fileList.concat(this.replyForm.file)
      }, (err) => {
        this.uploading = false
        this.$message({
          type: 'error',
          message: `文件 ${file.name} 上传失败：${err.errmsg}`
        })
        console.error(err)
        throw Error(`文件 ${file.name} 上传失败：${err.errmsg}`)
      })
    },
    deleteFile (index) {
      this.fileList.splice(index, 1)
    },
    handleRemove (file) {
      const index = this.fileList.findIndex((fileInfo) => fileInfo.name === file.name)
      if (index >= 0) {
        this.fileList.splice(index, 1)
      }
    },
    handlePreview (file) {
      console.log(file)
    },
    switchForm () {
      this.$router.back()
    },
    subscribeThis (type) {
      const num = Number(type)
      let tip = ''
      if (num === 0) {
        tip = '取消订阅'
      } else {
        tip = '订阅'
      }
      const loading = this.$loading({
        lock: true,
        text: `正在${tip}中...`,
        spinner: 'el-icon-loading',
        // background: 'rgba(0, 0, 0, 0.7)'
      })
      const param = {
        problemId: this.problemId,
        subscribe: num
      }
      getSubscribe(param).then(res => {
        loading.close()
        this.$message({
          type: 'success',
          message: `您已${tip}该问题`
        })
        this.getProblemInfo()
      }).catch(err => {
        loading.close()
        console.log(err)
      })
    },
    createWorkOrder () {
      this.$router.push({
        name: '创建工单',
        query: { problemId: this.problemId }
      })
    },
    submitQuestion (formName, type) {
      if (this.uploading) {
        this.$message({
          type: 'error',
          message: '附件正在上传中，无法提交问题，请耐心等待，稍后重试'
        })
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (type === 'add') {
            const loading = this.$loading({
              lock: true,
              text: '问题创建中...',
              spinner: 'el-icon-loading',
              // background: 'rgba(0, 0, 0, 0.7)'
            })
            const postData = {
              problemTitle: this.questionForm.title,
              problemDescribe: this.questionForm.describe,
              problemOrigin: this.questionSource,
              // problemOrigin: this.questionForm.source,
              problemFiles: this.fileList,
              urgentLevel: this.questionForm.urgent,
              subscribe: Number(this.questionForm.subscribe),
              problemType: this.questionForm.problemType,
              channel: this.questionForm.channel[1],
            }
            addProblem(postData).then(() => {
              loading.close()
              this.$message({
                type: 'success',
                message: '问题提交成功'
              })
              this.$router.push({
                path: '/home'
              })
            }, (err) => {
              this.$message({
                type: 'error',
                message: err.errmsg
              })
              console.error(err)
            })
          } else {
            const loading = this.$loading({
              lock: true,
              text: '问题修改中...',
              spinner: 'el-icon-loading',
              // background: 'rgba(0, 0, 0, 0.7)'
            })
            const postData = {
              problemTitle: this.questionForm.title,
              problemDescribe: this.questionForm.describe,
              problemOrigin: this.questionSource,
              // problemOrigin: this.questionForm.source,
              problemFiles: this.fileList,
              problemStatus: this.questionForm.status,
              urgentLevel: this.questionForm.urgent,
              subscribe: Number(this.questionForm.subscribe),
              problemType: this.questionForm.problemType,
              channel: this.questionForm.channel[1],
            }
            editProblem(this.problemId, postData).then(() => {
              loading.close()
              this.$message({
                type: 'success',
                message: '问题修改成功'
              })
              this.$router.back()
            }, (err) => {
              this.$message({
                type: 'error',
                message: err.errmsg
              })
              console.error(err)
            })
          }
        } else {
          return false
        }
      })
    },
    formatDate (date) {
      if (date) {
        const two = (n) => n < 10 ? '0' + n : '' + n
        const time = new Date(date * 1000)
        return `${time.getFullYear()}-${two(time.getMonth() + 1)}-${two(time.getDate())} ${two(time.getHours())}:${two(time.getMinutes())}`
      } else {
        return ''
      }
    },
    formatTime (time) {
      let theTime = parseInt(time)// 秒
      let min = 0// 分
      let hour = 0// 小时
      if (theTime > 59) {
        min = parseInt(theTime / 60)
        theTime = parseInt(theTime % 60)
        if (min > 59) {
          hour = parseInt(min / 60)
          min = parseInt(min % 60)
        }
      } else {
        return theTime + '秒'
      }
      // let result = '' + parseInt(theTime) + '秒'
      let result = ''
      if (min > 0) {
        result = '' + parseInt(min) + '分钟' + result
      }
      if (hour > 0) {
        result = '' + parseInt(hour) + '小时' + result
      }
      return result
    },
    checkImage (filename) {
      return checkImage(filename)
    },
    imageList (filelist) {
      return filelist.filter(file => checkImage(file.name))
    },
    notImageList (filelist) {
      return filelist.filter(file => !checkImage(file.name))
    },
  }
  // computed
}
</script>

<style lang="less" scoped>
@import "../../assets/style/home.less";
button {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
}
.urgent-select {
  width: 100%;
}

::v-deep {
  .tox-tinymce {
    border-color: #DCDFE6;
    border-radius: 3px;
  }
  .channel-select .el-form-item__label {
    line-height: 40px !important;
  }
}
.content {
  margin-top: -8px;
  justify-content: flex-start;
  flex-direction: column;
  .process {
    background: #fff;
    margin-bottom: 8px;
    padding: 8px 16px;
    font-size: 16px;
    ::v-deep(.el-steps) {
      .el-step {
        .el-step__line {
          height: 2px;
          top: 12px;
          background-color: #e9e9e9;
        }
      }
      .el-step__icon.is-text {
        border: 0;
      }
      .icon {
        width: 8px;
        height: 8px;
        background: #bfbfbf;
        border-radius: 50%;
      }
      .step-title {
        position: relative;
        .cost-time {
          font-size: 12px;
          color: #4b79f3;
          position: absolute;
          top: -10px;
          left: -40px;
          .cost-icon {
            width: 12px;
            height: 12px;
            background: url(../../assets/images/cost-time.svg);
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      .step-title {
        font-size: 16px;
        color: rgba(0,0,0,0.45);
      }
      .step-name {
        font-size: 14px;
        color: rgba(0,0,0,0.65);
      }
      .step-time {
        font-size: 12px;
        color: rgba(0,0,0,0.45);
      }
      .is-process {
        .step-title {
          // color: rgba(0,0,0,0.85);
          color: #4b79f3;
        }
        .icon {
          background: #4b79f3;
        }
      }
      .is-finish {
        .step-title {
          color: rgba(0,0,0,0.65);
        }
        .icon {
          background: #4b79f3;
        }
        .el-step__line {
          background-color: #4b79f3;
        }
      }
      #urge {
        height: 21px;
        line-height: 21px;
        padding: 0 6px;
        border-radius: 2px;
        background: #4b79f3;
        color: #fff;
        font-size: 12px;
      }
      .feishu-icon {
        display: inline-block;
        width: 13px;
        height: 13px;
        background: url(../../assets/images/feishu-icon.svg);
        position: relative;
        top: 2px;
        margin-left: 6px;
      }
      #urged {
        height: 21px;
        line-height: 20px;
        padding: 0 6px;
        border-radius: 2px;
        background: #c3c3c9;
        color: #ffffff;
        font-size: 12px;
      }
      .icon-gou {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: url(../../assets/images/gou.svg);
        vertical-align: middle;
        margin-left: 4px;
      }
    }
  }
  .detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div:first-child {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 110px;
      > :first-child {
        margin-right: 8px;
        font-size: 16px;
      }
    }
  }
  #btn-box .subscribe-btn {
    margin-left: 16px;
    background: rgba(53,73,224,0.10);
    border: 1px solid #4b79f3;
    color: #4b79f3;
  }
}
</style>

<style lang="less">
.el-select-dropdown__item.selected {
  color: #4b79f3;
}
.el-cascader__dropdown.cascader-channel-select {
  .el-popper__arrow {
    left: 80px !important;
  }
  .el-cascader-menu__wrap {
    height: 190px;
  }
}
</style>
