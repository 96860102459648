
import { Options, Vue } from 'vue-class-component'
import { agentsWithLevelTags } from '@/api/agent'

@Options({
  name: 'SchedulingPersonSelect',
  props: {
    cancel: Function,
    submit: Function,
    personIds: Array,
  }
})
export default class SchedulingPersonSelect extends Vue {
  // props
  personIds: Array<any> = []

  searchInput = ''
  personIdSelected: Array<any> = []
  person: Array<any> = [
  ]

  loading = true

  get agentSelected () {
    return this.person.filter(p => this.personIdSelected.includes(p.ldap))
  }

  get agents () {
    const key = this.searchInput.trim()
    if (key.length === 0) {
      return this.person
    }
    const a: Array<any> = []
    for (const p of this.person) {
      if (p.name.includes(key) || p.department?.includes(key)) {
        a.push(p)
      }
    }
    return a
  }

  mounted () {
    this.personIdSelected.push(...this.personIds)
    this.loadAgentsAll()
  }

  async loadAgentsAll () {
    try {
      let res = await agentsWithLevelTags()
      this.person.push(...res.results)
      while (res.next) {
        res = await agentsWithLevelTags(res.page + 1)
        this.person.push(...res.results)
      }
    } finally {
      this.loading = false
    }
  }
}
