export default [
  {
    _name: 'CSidebarNav',
    _children: [
      // {
      //   _name: 'CSidebarNavItem',
      //   name: '问题',
      //   to: '/home',
      //   icon: 'nav-icon-1.png',
      //   isAdmin: true,
      //   iconHigh: 'nav-icon-high-1.png',
      //   badge: {
      //   },
      //   _children: [
      //     {
      //       _name: 'CSidebarNavItem',
      //       name: '问题面板',
      //       _show: true,
      //       to: '/home/panel'
      //     },
      //     {
      //       _name: 'CSidebarNavItem',
      //       name: '问题列表',
      //       _show: true,
      //       to: '/home/list'
      //     },
      //   ]
      // },
      {
        _name: 'CSidebarNavItem',
        name: '问题列表',
        to: '/home/list',
        icon: 'nav-icon-1.png',
        iconHigh: 'nav-icon-high-1.png',
        badge: {
        }
      },
      {
        _name: 'CSidebarNavItem',
        name: '问题面板',
        to: '/home/panel',
        icon: 'nav-icon-5.png',
        iconHigh: 'nav-icon-high-5.png',
        badge: {
        }
      },
      {
        _name: 'CSidebarNavItem',
        name: '工单视图',
        to: '/tickets',
        icon: 'nav-icon-2.png',
        iconHigh: 'nav-icon-high-2.png',
        badge: {
        }
      },
      {
        _name: 'CSidebarNavItem',
        name: '值班信息',
        to: '/Onduty',
        icon: 'nav-icon-7.svg',
        iconHigh: 'nav-icon-high-7.svg',
      },
      {
        _name: 'CSidebarNavItem',
        name: '配置',
        to: '/config',
        icon: 'nav-icon-3.png',
        isAdmin: true,
        iconHigh: 'nav-icon-high-3.png',
        _children: [
          {
            _name: 'CSidebarNavItem',
            name: '配置标签',
            _show: true,
            to: '/config/tag'
          },
          {
            _name: 'CSidebarNavItem',
            name: '配置模版',
            _show: true,
            to: '/config/template'
          },
          {
            _name: 'CSidebarNavItem',
            name: '配置报警',
            _show: true,
            to: '/config/alarm'
          },
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: '人员管理',
        to: '/manage',
        icon: 'nav-icon-4.png',
        isAdmin: true,
        iconHigh: 'nav-icon-high-4.png',
        _children: [
          {
            _name: 'CSidebarNavItem',
            name: '人员标签',
            _show: true,
            to: '/manage/person'
          },
          {
            _name: 'CSidebarNavItem',
            name: '配置人员标签',
            _show: false,
            to: '/manage/AllocationTags'
          },
          {
            _name: 'WithoutPerson',
            name: '外部人员',
            _show: true,
            to: '/manage/WithoutPerson'
          },
          {
            _name: 'outsource',
            name: '外包人员',
            _show: true,
            to: '/manage/outsource'
          },
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: '数据面板',
        to: '/dashboard',
        icon: 'nav-icon-6.png',
        iconHigh: 'nav-icon-high-6.png',
        isAdmin: false,
        badge: {
        }
      }
    ]
  },
  {
    _name: 'outCsidebarNan',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: '问题列表',
        to: '/out/home/list',
        icon: 'nav-icon-1.png',
        iconHigh: 'nav-icon-high-1.png',
        badge: {
        }
      },
    ]
  }
]
