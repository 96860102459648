import axios, { fileDownloader } from '@/utils/request'

const app = '/unicorn-ticket'

const apiUrl = {
  list: '/problem/list',
  closeProblem: '/problem/close/',
  batchCloseProblem: '/problem/batch_close',
  addProblem: '/problem/add',
  editProblem: '/problem/update/',
  processLadels: '/tags_fields/process_labels',
  problemInfo: '/problem/get/',
  agentTags: '/agents/tags',
  listCount: '/problem/list_count',
  generate: '/tickets/generate',
  subscribe: '/problem/subscribe_problem',
  problemProcess: '/problem/problem_process',
  guestAddProblem: '/problem/guest_add',
  guestListProblem: '/problem/guest_list',
  guestListOnew: '/problem/guest_get',
  guestListComment: '/problem/guest_comment',
}

Object.keys(apiUrl).forEach((uri) => {
  apiUrl[uri] = app + apiUrl[uri]
})

// 问题来源/渠道
export const ProblemSource = [
  {
    value: -1,
    label: '内部来源',
    children: [
      { value: 1, label: '内部用户直接录入' },
      { value: 2, label: 'Atlas' },
      { value: 3, label: 'API' },
      { value: 6, label: '邮件' },
      { value: 7, label: '小程序' },
      { value: 10, label: '客服小程序' },
    ],
  },
  {
    value: -2,
    label: '外部来源',
    children: [
      { value: 5, label: '外部用户直接录入' },
      { value: 4, label: '400' },
      { value: 8, label: '微信' },
      { value: 9, label: 'Webhook' },
    ],
  },
]

export function getProblemList (params) {
  const url = apiUrl.list
  return axios({
    url,
    method: 'get',
    params
  })
}

export function exportProblemList (params) {
  params.export = 1
  const url = apiUrl.list
  return fileDownloader({
    url,
    method: 'get',
    params
  })
}

export function loadProblemList (keywords = '', pageSize = 20) {
  const url = apiUrl.list
  return axios({
    url,
    method: 'get',
    params: {
      pageSize: pageSize,
      pageNumber: 1,
      ordering: '-problem_id',
      keywords
    },
  })
}

export function closeProblemById (id, comments, ids) {
  const url = apiUrl.closeProblem + id
  return axios({
    url,
    method: 'put',
    data: {
      problemComments: comments,
      relationIds: ids
    }
  })
}

export function batchCloseProblem (data) {
  const url = apiUrl.batchCloseProblem
  return axios({
    url,
    method: 'post',
    data
  })
}

export function addProblem (data) {
  const url = apiUrl.addProblem
  return axios({
    url,
    method: 'post',
    data
  })
}

export function editProblem (id, data) {
  const url = apiUrl.editProblem + id
  return axios({
    url,
    method: 'put',
    data
  })
}

export function getProblemInfo (id) {
  const url = apiUrl.problemInfo + id
  return axios({
    url,
    method: 'get'
  })
}

export function getProcessLadels (id) {
  const url = apiUrl.processLadels
  return axios({
    url,
    method: 'get'
  })
}

export function getAgentTags (params) {
  const url = apiUrl.agentTags
  return axios({
    url,
    method: 'get',
    params
  })
}

export function loadListCount () {
  const url = apiUrl.listCount
  return axios({
    url,
    method: 'get'
  })
}

export function postTicket (data) {
  const url = apiUrl.generate
  return axios({
    url,
    method: 'post',
    data
  })
}

export function postJira (data, ticketId) {
  const url = `${app}/problem/${ticketId}/trans-to-jira`
  return axios({
    url,
    method: 'put',
    data
  })
}

export function getSubscribe (params) {
  const url = apiUrl.subscribe
  return axios({
    url,
    method: 'get',
    params
  })
}

export function problemProcess (id) {
  const url = apiUrl.problemProcess
  return axios({
    url,
    method: 'get',
    params: {
      problemId: id
    }
  })
}

export function problemProcessUrge (data) {
  const url = apiUrl.problemProcess
  return axios({
    url,
    method: 'put',
    data
  })
}

/**
 * 问题中心title数据统计
 * @returns {*}
 */
export function getProblemCenterCount () {
  const url = `${app}/problem/problem_center_count`
  return axios({
    url,
    method: 'get',
    params: {
    }
  })
}

/**
 * 问题中心个人过滤器列表
 * @returns {*}
 */
export function getProblemFilters () {
  const url = `${app}/problem/problem_filter`
  return axios({
    url,
    method: 'get',
    params: {
    }
  })
}

/**
 * 保存问题中心个人过滤器
 * @returns {*}
 */
export function saveProblemFilters (data) {
  const url = `${app}/problem/problem_filter`
  return axios({
    url,
    method: 'post',
    data
  })
}

/**
 * 保存问题中心个人过滤器
 * @returns {*}
 */
export function updateProblemFilters (id, data) {
  const url = `${app}/problem/problem_filter/${id}`
  return axios({
    url,
    method: 'PUT',
    data
  })
}

/**
 * 删除个人过滤器
 * @returns {*}
 */
export function deleteProblemFilter (id) {
  const url = `${app}/problem/problem_filter/${id}`
  return axios({
    url,
    method: 'delete',
  })
}

/**
 * 获取filter的count数据
 * @returns {*}
 */
export function getProblemFilterCount (params) {
  const url = `${app}/problem/problem_count`
  return axios({
    url,
    method: 'get',
    params,
  })
}

// time 2022-03-30 外部用户
export function guestAddProblem (data) {
  const url = apiUrl.guestAddProblem
  return axios({
    url,
    method: 'post',
    data
  })
}

export function guestGetProblem (params) {
  const url = apiUrl.guestListProblem
  return axios({
    url,
    method: 'get',
    params,
  })
}

export function loadGuestListOnew (id) {
  const url = `${apiUrl.guestListOnew}/${id}`
  return axios({
    url,
    method: 'get',
  })
}

export function loadPostGuestComment (data) {
  const url = apiUrl.guestListComment
  return axios({
    url,
    method: 'post',
    data
  })
}
export function loadGetGuestComment (params) {
  const url = apiUrl.guestListComment
  return axios({
    url,
    method: 'get',
    params
  })
}

export function getJiraProjectAndErrorEnv (ticketId) {
  const url = `${app}/problem/${ticketId}/trans-to-jira`
  return axios({
    url,
    method: 'get',
  })
}

export function ticketRelationJira (data, ticketId) {
  const url = `${app}/problem/${ticketId}/trans-to-jira`
  return axios({
    url,
    method: 'post',
    data
  })
}

export function agentsForJira (page = 1, pageSize = 700) {
  const url = `${app}/agents/tags`
  return axios({
    url,
    method: 'get',
    params: {
      page,
      limit: pageSize
    }
  })
}
