
import { Options, Vue } from 'vue-class-component'
import * as ticketApi from '@/api/ticket'
import * as utils from '@/utils/utils'
import TicketViewSearchForm from '@/components/TicketViewSearchForm.vue'

// safari 对 es2020的模块机制支持有问题（不支持导出函数作为事件回调），故此copy一份两个函数在此
function fixElTableColumnWidth (elTableClass, index) {
  // const elTableClass = 'ticket-table'
  // const index = 3
  const w = document.querySelector(`.${elTableClass} table th:nth-child(${index})`)?.getBoundingClientRect().width
  document.querySelectorAll(`.${elTableClass} table td:nth-child(${index}) > div`).forEach((e: any) => {
    e.style.width = w! - 1 + 'px'
  })
}

function fixDescDisplay () {
  document.querySelectorAll('.ticket-desc').forEach((e: any) => {
    if (e.innerText?.length > 10) {
      e.innerHTML = e.innerText
    }
  })
}

@Options({
  name: 'TicketsDone',
  components: {
    TicketViewSearchForm
  },
  beforeRouteUpdate (to, from, next) {
    this.loadTicketList()
    next()
  },
})
export default class TicketsDone extends Vue {
  loading = false
  tableData: Array<any> = []
  total = 0
  currentPage = 1
  pageSize = 10
  urgentLevel = 0
  searchFormData = {}
  orderField: any = {
    prop: 'urgentLevel',
    order: 'descending'
  }

  savedQueryData: any = null

  sortChange (data) {
    console.log('sortChange:', data)
    if (data.order) {
      this.orderField = data
    } else {
      this.orderField = null
    }
    this.loadTicketList()
  }

  ticketViewSearchFormSubmit (data) {
    this.searchFormData = data
    console.log('ticketViewSearchFormSubmit:', data)
    this.loadTicketList()
  }

  onSubmit (e) {
    console.log(e)
  }

  priorityFormatter (row, column) {
    return utils.urgentLevelFormat(row.urgentLevel)
  }

  createTimeFormatter (row, column) {
    return utils.dateFormat(row.createdAt)
  }

  updatedTimeFormatter (row, column) {
    return utils.dateFormat(row.updatedAt)
  }

  created () {
    this.savedQueryData = localStorage.getItem('__ticket_AllTicketsDone_query_criteria')
  }

  mounted () {
    if (this.$route.query.pageSize) {
      this.pageSize = Number(this.$route.query.pageSize)
    }
    if (this.$route.query.page) {
      this.currentPage = Number(this.$route.query.page)
    }
    if (this.savedQueryData) {
      const q = JSON.parse(this.savedQueryData)
      this.searchFormData = q
      if (q.orderField) {
        this.orderField = q.orderField
      }
    }
    this.loadTicketList()
    window.addEventListener('resize', () => {
      fixElTableColumnWidth('ticket-table', 3)
    })
  }

  loadTicketList () {
    const searchOps = utils.getSearchOpsFormFormData(this.searchFormData)
    if (this.orderField?.order) {
      searchOps.orderField = this.orderField
    }
    this.loading = true
    ticketApi.ticketList(ticketApi.ViewType.ALL_CLOSED, this.currentPage, this.pageSize, searchOps).then(res => {
      this.tableData.splice(0)
      this.tableData.push(...res.results)
      this.total = res.count
    }).catch(res => {
      console.log(res)
    }).finally(() => {
      this.loading = false
      // fix safari表头错位
      setTimeout(() => {
        fixElTableColumnWidth('ticket-table', 3)
        fixDescDisplay()
      }, 0)
      this.saveCriteria(searchOps)
    })
  }

  saveCriteria (data) {
    const q = { ...data }
    console.log('AllTicketsDone saveCriteria=', q)
    localStorage.setItem('__ticket_AllTicketsDone_query_criteria', JSON.stringify(q))
  }

  handleCurrentChange (e) {
    console.log('handleCurrentChange', e)
    this.currentPage = e
    this.$router.push({
      name: this.$route.name!,
      query: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
    })
  }

  handleSizeChange (e) {
    console.log('handleSizeChange', e)
    this.pageSize = e
    this.$router.push({
      name: this.$route.name!,
      query: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
    })
  }
}
