<template>
  <div class="tinymce-editor">
    <editor
      v-model="myValue"
      :init="init"
      :disabled="disabled"
      @onClick="onClick"
    />
  </div>
</template>
<script>
import { uploadFile } from '@/api/files'
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/themes/silver'
import 'tinymce/icons/default/icons'
// 编辑器插件plugins
// 更多插件参考：https://www.tiny.cloud/docs/plugins/
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/autosave'
import 'tinymce/plugins/code'
import 'tinymce/plugins/codesample'
import 'tinymce/plugins/colorpicker'
import 'tinymce/plugins/contextmenu'
import 'tinymce/plugins/directionality'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/image'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/media'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/noneditable'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/print'
import 'tinymce/plugins/save'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/spellchecker'
import 'tinymce/plugins/tabfocus'
import 'tinymce/plugins/table'
import 'tinymce/plugins/template'
import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/textpattern'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/visualchars'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/help'
export default {
  components: {
    Editor
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 360
    },
    // 基本路径，默认为空根目录，如果你的项目发布后的地址为目录形式，
    // 即abc.com/tinymce，baseUrl需要配置成tinymce，不然发布后资源会找不到
    baseUrl: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    plugins: {
      type: [String, Array],
      default: 'help advlist anchor autolink autosave code codesample colorpicker contextmenu directionality fullscreen hr image imagetools insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount'
    },
    toolbar: {
      type: [String, Array],
      default: 'help searchreplace bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample hr bullist numlist link image charmap anchor pagebreak insertdatetime media fullscreen backcolor forecolor fontsizeselect fontselect table preview'
    },
    setup: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
  },
  data () {
    return {
      init: {
        setup: this.setup,
        paste_data_images: true,
        placeholder: this.placeholder,
        language_url: '/tinymce/langs/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/tinymce/skins/ui/oxide',
        content_css: '/tinymce/skins/content/default/content.css',
        content_style: 'img {max-width: 370px}',
        // skin_url: `${this.baseUrl}/tinymce/skins/ui/oxide-dark`, // 暗色系
        // content_css: `${this.baseUrl}/tinymce/skins/content/dark/content.css`, // 暗色系
        min_height: this.height,
        plugins: this.plugins,
        // toolbar: this.toolbar,
        toolbar: false,
        statusbar: false,
        branding: false,
        menubar: false,
        fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt 48pt 72px',
        file_picker_types: 'file media',
        file_picker_callback: function (callback, value, meta) {
          let fileType = '.pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4'
          // 后端接收上传文件的地址
          // 为不同插件指定文件类型及后端地址
          switch (meta.filetype) {
            case 'image':
              fileType = '.jpg, .jpeg, .png, .gif'
              break
            case 'media':
              fileType = '.mp3, .mp4'
              break
            case 'file':
            default:
          }
          const input = document.createElement('input')
          input.setAttribute('type', 'file')
          input.setAttribute('accept', fileType)
          input.click()
          input.onchange = () => {
            const file = input.files[0]
            const formData = new FormData()
            formData.append('file', file, file.name)
            uploadFile(formData)
              .then((res) => {
                callback(res.url, { text: file.name })
              })
              .catch((error) => {
                console.log('tinymce-uploadImage', error)
                this.$message.error(error.errmsg)
              })
          }
        },
        images_upload_handler: (blobInfo, success, failure) => {
          const formData = new FormData()
          formData.set('file', blobInfo.blob())
          uploadFile(formData)
            .then((res) => {
              success(res.url)
            })
            .catch((error) => {
              console.log('tinymce-uploadImage', error)
              this.$message.error(error.errmsg)
              failure(error.errmsg)
            })
        },
      },
      myValue: this.value
    }
  },
  watch: {
    value (newValue) {
      this.myValue = newValue
    },
    myValue (newValue) {
      this.$emit('update:value', newValue)
    }
  },
  mounted () {
    tinymce.init({})
  },
  methods: {
    // 添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
    // 需要什么事件可以自己增加
    onClick (e) {
      this.$emit('onClick', e, tinymce)
    },
    // 可以添加一些自己的自定义事件，如清空内容
    clear () {
      this.myValue = ''
    }
  }
}
</script>
<style scoped lang="less">
.tinymce-editor{
  color:red
}
</style>
