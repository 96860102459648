import { TicketStatus, UrgentLevel } from '@/api/entity'

function find (list, f) {
  return list.filter(f)[0]
}

export function deepCopy (obj, cache = []) {
  // just return if obj is immutable value
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  // if obj is hit, it is in circular structure
  const hit = find(cache, (c) => c.original === obj)
  if (hit) {
    return hit.copy
  }

  const copy = Array.isArray(obj) ? [] : {}
  // put the copy into cache at first
  // because we want to refer it in recursive deepCopy
  cache.push({
    original: obj,
    copy
  })

  Object.keys(obj).forEach((key) => {
    copy[key] = deepCopy(obj[key], cache)
  })

  return copy
}

export function isNumber (number) {
  if (number instanceof Number || typeof number === 'number') {
    return true
  }
  if (number instanceof String || typeof number === 'string') {
    const re = /^[0-9]+\.?[0-9]*$/
    return re.test(number)
  }
  return false
}

export function toHump (name) {
  return name.replace(/_([a-z])/g, (all, letter) => letter.toUpperCase())
}

export function toLine (name) {
  return name.replace(/([A-Z])/g, '_$1').toLowerCase()
}

export function objectKeyToLine (obj, formatNumber = false) {
  if (!obj) {
    return obj
  }
  if (typeof obj !== 'object') {
    if (formatNumber && typeof obj === 'string' && isNumber(obj)) {
      return Number(obj)
    }
    return obj
  }
  if (Array.isArray(obj)) {
    const res = []
    obj.forEach((val) => {
      res.push(objectKeyToLine(val, formatNumber))
    })
    return res
  }
  const newObj = {}
  const keys = Object.keys(obj)
  keys.forEach((key) => {
    newObj[toLine(key)] = objectKeyToLine(obj[key], formatNumber)
  })
  return newObj
}

export function objectKeyToHump (obj, formatNumber = false) {
  if (!obj) {
    return obj
  }
  if (typeof obj !== 'object') {
    if (formatNumber && typeof obj === 'string' && isNumber(obj)) {
      return Number(obj)
    }
    return obj
  }
  if (Array.isArray(obj)) {
    const res = []
    obj.forEach((val) => {
      res.push(objectKeyToHump(val, formatNumber))
    })
    return res
  }
  const newObj = {}
  const keys = Object.keys(obj)
  keys.forEach((key) => {
    newObj[toHump(key)] = objectKeyToHump(obj[key], formatNumber)
  })
  return newObj
}

export function deepObjectMerge (FirstOBJ, SecondOBJ) {
  for (const key in SecondOBJ) {
    FirstOBJ[key] = FirstOBJ[key] && FirstOBJ[key].toString() === '[object Object]'
      ? deepObjectMerge(FirstOBJ[key], SecondOBJ[key]) : FirstOBJ[key] = SecondOBJ[key]
  }
  return FirstOBJ
}

export function getValueFromObject (obj, property, defaultValue = undefined) {
  if (property in obj) {
    return obj[property]
  }
  if (toHump(property) in obj) {
    return obj[toHump(property)]
  }
  if (toLine(property) in obj) {
    return obj[toLine(property)]
  }
  return defaultValue
}

export function dateFormat (timeInSeconds) {
  const two = (n) => n < 10 ? '0' + n : '' + n
  const d = new Date(timeInSeconds * 1000)
  return `${d.getMonth() + 1}月${d.getDate()}日 ${two(d.getHours())}:${two(d.getMinutes())}`
}

export function urgentLevelFormat (urgentLevel) {
  // let text = ''
  // switch (urgentLevel) {
  //   case UrgentLevel.HIGHEST:
  //     text = 'HIGHEST'
  //     break
  //   case UrgentLevel.HIGH:
  //     text = 'HIGH'
  //     break
  //   case UrgentLevel.NORMAL:
  //     text = 'NORMAL'
  //     break
  //   case UrgentLevel.LOW:
  //     text = 'LOW'
  //     break
  // }
  return urgentLevel
}

// export function curStatusFormat (curStatus, suspended) {
//   let text = ''
//   switch (curStatus) {
//     case TicketStatus.UNASSIGNED:
//       text = '待认领'
//       break
//     case TicketStatus.PENDING:
//       text = '处理中'
//       break
//     case TicketStatus.CLOSED:
//       text = '已解决'
//       break
//   }
//   return text
// }

export function curStatusFormat (curStatus, suspended) {
  let text = ''
  if (suspended) {
    text = '已挂起'
  } else if (curStatus === 'pending') {
    text = '处理中'
  } else if (curStatus === 'unassigned') {
    text = '待认领'
  } else if (curStatus === 'closed') {
    text = '已解决'
  }
  return text
}

export function getSearchOpsFormFormData (searchFormData) {
  const searchOps = {}
  if (searchFormData.keywords?.trim()?.length > 0) {
    searchOps.keywords = searchFormData.keywords.trim()
  }
  if (searchFormData.urgentLevel) {
    searchOps.urgentLevel = searchFormData.urgentLevel
  }
  if (searchFormData.workType) {
    searchOps.workType = searchFormData.workType
  }
  if (searchFormData.templateId) {
    searchOps.templateId = searchFormData.templateId
  }
  if (searchFormData.processDuration) {
    searchOps.processDuration = searchFormData.processDuration
  }
  if (searchFormData.workflow) {
    searchOps.workflow = searchFormData.workflow
  }
  if (searchFormData.tagFilter) {
    searchOps.tagFilter = searchFormData.tagFilter
  }
  if (searchFormData.dateRange?.length > 0) {
    const date1 = searchFormData.dateRange[0]
    const date2 = searchFormData.dateRange[1]
    // date1.setHours(0)
    // date1.setMinutes(0)
    date1.setSeconds(0)
    date1.setMilliseconds(0)
    // date2.setHours(23)
    // date2.setMinutes(59)
    date2.setSeconds(59)
    date2.setMilliseconds(999)
    searchOps.startTime = Math.floor(date1.getTime() / 1000)
    searchOps.endTime = Math.floor(date2.getTime() / 1000)
  }
  if (searchFormData.startTime) {
    searchOps.startTime = searchFormData.startTime
  }
  if (searchFormData.endTime) {
    searchOps.endTime = searchFormData.endTime
  }
  return searchOps
}

export const twoDigits = (n) => n < 10 ? '0' + n : '' + n

/**
 * 计算字符串的 hash code
 * @param str
 * @returns {number}
 */
export function hashCode (str) {
  let hash = 0
  if (str.length === 0) return hash
  for (let i = 0; i < str.length; i++) {
    const chr = str.charCodeAt(i)
    hash = ((hash << 5) - hash) + chr
    hash |= 0 // Convert to 32bit integer
  }
  return Math.abs(hash)
}

export function checkImage (filename) {
  const filename1 = filename.toLowerCase()
  // 用文件名name后缀判断文件类型
  if (!/\.(jpg|jpeg|png|gif|bmp|tif|svg)$/.test(filename1)) {
    return false
  } else {
    return true
  }
}
