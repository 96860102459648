<template>
    <div :class="['checkDialog',center?'centerTitle':'']">
      <el-dialog v-model="visibleDialog" :title="title" :width="width" center :top="top">
          <slot name="default"></slot>
          <template #footer v-if="footer">
          <span class="dialog-footer">
              <el-button @click="visibleDialog = false">取消</el-button>
              <el-button type="primary" @click="confirmFn">确定</el-button>
          </span>
          </template>
      </el-dialog>
    </div>
</template>
<script>
import { reactive, computed, toRefs } from 'vue'
export default {
  name: 'checkStandDialog',
  props: {
    show: Boolean,
    title: {
      type: String,
      default: '标题',
    },
    confirmFn: Function,
    width: {
      type: [String, Number],
      default: '40%',
    },
    center: {
      type: Boolean,
      default: false,
    },
    footer: {
      type: Boolean,
      default: true,
    },
    top: {
      type: String,
      default: '15vh',
    },
  },
  setup (props, { emit }) {
    const data = reactive({
      dialogVisible: false,
    })
    const { show } = toRefs(props)
    const visibleDialog = computed({
      get () {
        return show.value
      },
      set (values) {
        return emit('update:show', values)
      },
    })

    return {
      ...toRefs(data),
      visibleDialog,
    }
  },
}
</script>
<style lang="less" scoped>
.checkDialog{
    :deep(.el-dialog__header){
      width: 100%;
      border-bottom: 1px solid #EEEAE9;
      text-align: left;
      .el-dialog__title{
        font-size: 16px;
        color:#000000;
      }
    }
    :deep(.el-dialog__body){
      padding-top: 15px;
      padding-bottom: 10px;
      max-height: 500px;
      overflow: auto;
    }
  .dialog-footer{
    :deep(.el-button){
      width:100px;
    }
  }
}
.centerTitle{
    :deep(.el-dialog__header){
      text-align: center !important;
    }
  }

</style>
