
import { Options, Vue } from 'vue-class-component'
import Breadcrumb from '@/components/Breadcrumb.vue'
import * as labelApi from '@/api/label'
import store from '@/store'
import { ElMessageBox, ElMessage } from 'element-plus'

@Options({
  name: 'Tag',
  components: {
    Breadcrumb
  }
})
export default class Tag extends Vue {
  get tagSetList () {
    return store.state.tagSetList.sort((a, b) => {
      return -(a.labelSetType.length - b.labelSetType.length)
    })
  }

  isSystemType (labelSetType: string) :boolean {
    return labelSetType === 'Process' || labelSetType === 'UrgentLevel'
  }

  editTagSet (tagSet) {
    this.$router.push({
      name: '配置标签-编辑',
      params: {
        tagSetId: tagSet.id
      }
    })
  }

  deleteTagSet (tagSet) {
    ElMessageBox.confirm('此操作将删除该标签集, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      labelApi.deleteLabelSet(tagSet.id).then(res => {
        ElMessage({
          type: 'success',
          message: '删除成功!'
        })
        if (this.$route.name === '配置标签') {
          this.loadAllLabelSet()
        } else if (this.$route.name === '配置标签-编辑' && tagSet.id === Number(this.$route.params.tagSetId)) {
          this.$router.push({
            name: '配置标签'
          })
          this.loadAllLabelSet()
        } else {
          this.loadAllLabelSet()
        }
      }).catch(res => {
        ElMessage({
          type: 'error',
          message: '删除失败!'
        })
      })
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: '已取消删除'
      })
    })
  }

  mounted () {
    const user = store.getters['auth/me']
    const admin = user.role
    let ticketAdmin = false
    admin.forEach(item => {
      if (item.roleName === 'ticket_admin') {
        ticketAdmin = true
      }
    })
    if (ticketAdmin) {
      this.loadAllLabelSet()
    } else {
      ElMessage.error({
        type: 'error',
        message: '您不是管理员不可访问页面哦！'
      })
      this.$router.push({
        path: '/home'
      })
    }
  }

  loadAllLabelSet () {
    labelApi.listLabelSet().then((res) => {
      const temp = res.filter(v => v.isDeleted === false)
      store.commit('updateTagSetList', temp)
    }).catch((res) => {
      console.log('listLabelSet error:', res)
    })
  }
}
