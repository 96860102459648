<template>
  <TheSidebar/>
  <CWrapper>
    <TheHeader/>
    <div class="c-body">
      <main class="c-main">
<!--        <transition name="fade" mode="out-in">-->
<!--          <router-view :key="$route.path"></router-view>-->
<!--        </transition>-->
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <!-- <keep-alive :exclude="['TicketDetail','createQuestion','createTicket']"> -->
              <component  :is="Component"  />
            <!-- </keep-alive> -->
          </transition>
        </router-view>
      </main>
    </div>
  <!-- <TheFooter/> -->
  </CWrapper>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
// import TheFooter from './TheFooter'
import CWrapper from './CWrapper'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    // TheFooter,
    CWrapper
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
<style lang="less">
.c-body {
  display: flex;
  flex-grow: 1;
  width: 1px;
  > .c-main {
    display: flex;
    flex-grow: 1;
    width: 1px;
    > * {
      flex-grow: 1;
      width: 1px;
    }
  }
}
</style>
