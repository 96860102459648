<template>
  <div class="tag" id="tag">
    <Breadcrumb>
      <h2>Echarts 测试</h2>
    </Breadcrumb>
    <div class="main">
      <div id="test-1" style="width: 500px; height: 500px;"></div>
    </div>
  </div>
</template>
<script>
import { Options, Vue } from 'vue-class-component'
import Breadcrumb from '@/components/Breadcrumb.vue'
import * as labelApi from '@/api/label'
import store from '@/store'
import { ElMessageBox, ElMessage } from 'element-plus'
import * as echarts from 'echarts'

@Options({
  name: 'Tag',
  components: {
    Breadcrumb
  }
})
export default class Tag extends Vue {
  mounted () {
    var chartDom = document.getElementById('test-1')
    var myChart = echarts.init(chartDom)
    var option

    option = {
      title: {
        text: '漏斗图',
        subtext: '纯属虚构'
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c}%'
      },
      toolbox: {
        feature: {
          dataView: { readOnly: false },
          restore: {},
          saveAsImage: {}
        }
      },
      legend: {
        data: ['展现', '点击', '访问', '咨询', '订单']
      },

      series: [
        {
          name: '漏斗图',
          type: 'funnel',
          left: '10%',
          top: 60,
          // x2: 80,
          bottom: 60,
          width: '80%',
          // height: {totalHeight} - y - y2,
          min: 0,
          max: 100,
          minSize: '0%',
          maxSize: '100%',
          sort: 'descending',
          gap: 2,
          label: {
            show: true,
            position: 'inside'
          },
          labelLine: {
            length: 10,
            lineStyle: {
              width: 1,
              type: 'solid'
            }
          },
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 1
          },
          emphasis: {
            label: {
              fontSize: 20
            }
          },
          data: [
            { value: 60, name: '访问' },
            { value: 40, name: '咨询' },
            { value: 20, name: '订单' },
            { value: 80, name: '点击' },
            { value: 100, name: '展现' }
          ]
        }
      ]
    }

    option && myChart.setOption(option)
  }
}
</script>
<style lang="less" scoped>
#tag {

}
</style>
