import axios from '@/utils/request'
import da from 'element-plus/packages/locale/lang/da'

const app = '/unicorn-ticket'

const agentUrl = {
  agents: '/agents/',
  agentsTag: '/agents/tags',
  attachTags: '/agents/tags/attach',
  setAdmin: '/agents/set_admin/',
  labels: '/tags_fields/labels/',
  tagSet: '/tags_fields/label_set',
  agentTags: '/agents/agent_tags',
  editTags: '/agents/tags/update'
}

Object.keys(agentUrl).forEach((uri) => {
  agentUrl[uri] = app + agentUrl[uri]
})

export function fetchAgentByTags (params) {
  const url = agentUrl.agentsTag
  return axios({
    url,
    method: 'get',
    params
  })
}

export function getLabels (params) {
  const url = agentUrl.labels
  return axios({
    url,
    method: 'get',
    params
  })
}

export function setUpAdmin (data) {
  const url = agentUrl.setAdmin
  return axios({
    url,
    method: 'put',
    data: data
  })
}

export function getTagSet (data) {
  const url = agentUrl.tagSet
  return axios({
    url,
    method: 'get'
  })
}

export function addTagBulk (ldapList, tagList) {
  const url = agentUrl.attachTags
  return axios({
    url,
    method: 'put',
    data: {
      agents: ldapList,
      tags: tagList
    }
  })
}

export function updateAgentTag (ldapList, tagList) {
  const url = agentUrl.editTags
  return axios({
    url,
    method: 'put',
    data: {
      agents: ldapList,
      tags: tagList
    }
  })
}

export function getAgentTags (ldap) {
  const url = agentUrl.agentTags
  return axios({
    url,
    method: 'get',
    params: {
      ldap
    }
  })
}
