<template>
    <div class="chart-item" style="width: 100%" v-loading='loading'>
      <div class="item-title"
      style="height: 47px; background: #fff;margin-bottom: 1px;padding: 12px">
      <p style="display: block; margin-left: 16px;">工单数量</p>
      </div>
      <div id="pie-chart" style="width: 100%;height: 350px;background: #fff;"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
import moment from 'moment'
import { dashboard } from '@/api/dashboard'

export default {
  name: 'DashboardPie',
  props: {
    obj2: Object
  },
  data () {
    return {
      extre: '',
      loading: false,
      newObj: null,
      pieStartTime: '',
      pieEndTime: '',
    }
  },
  methods: {
    async pieChart () {
      let pieData = []
      // debugger
      if (typeof this.newObj.extreme === 'string') {
        if (this.newObj.extreme.charAt(this.newObj.extreme.length - 1) === '%') {
          this.extre = this.newObj.extreme.substring(0, this.newObj.extreme.length - 1)
        }
      } else {
        this.extre = this.newObj.extreme
      }
      try {
        const obj = {
          exportData: 0,
          ticketType: this.newObj.alarmValue,
          extremum: this.extre,
          startDate: this.pieStartTime,
          endDate: this.pieEndTime,
          channel: this.newObj.channel,
          businessLine: this.newObj.product,
          level: this.newObj.level,
          negationExtremum: this.newObj.negationExtremum,
          // otherTags: this.newObj.otherTags,
          searchType: 1,
        }
        pieData = await dashboard(obj)
      } catch (e) {
        console.error(e)
      }
      const newPieData = pieData.map(item => {
        return {
          value: item.value,
          name: item.name,
        }
      })
      const newData = pieData.map(item => {
        return {
          name: item.name,
          value1: item.percentage,
          value2: item.value
        }
      })
      var chartDom = document.getElementById('pie-chart')
      if (!chartDom) return
      var myChart = echarts.getInstanceByDom(chartDom)
      if (myChart) {
        myChart.dispose()
      }
      myChart = echarts.init(chartDom)

      var option
      option = {
        title: {
        // text: '某站点用户访问来源',
        // subtext: '纯属虚构',
          left: 'center'
        },
        legend: {
          orient: 'vertical',
          top: 'middle',
          right: '5%',
          itemWidth: 10,
          itemHeight: 10,
          icon: 'circle',
          itemGap: 18,
          textStyle: {
            fontSize: 14,
            color: '#D9D9D9',
            rich: {
              oneone: {
                width: 60,
                color: '#2C3542',
                fontSize: 14,
                fontWeight: 'bolder'
              },
              twotwo: {
                width: 50,
                color: '#2C3542',
                fontSize: 14,
                fontWeight: 'bolder'
              },
              threethree: {
                width: 30,
                color: '#2C3542',
                fontSize: 14,
                fontWeight: 'bolder',
                // right: '40px'
              },
            },
          },
          formatter: (name) => {
            let total = 0
            let target1, target2
            for (let i = 0; i < newData.length; i++) {
              total += newData[i].value2
              if (newData[i].name === name) {
                target1 = newData[i].value1
                target2 = newData[i].value2
              }
            }
            return `{oneone|${name}} | {twotwo|${target1}}   {threethree|${target2}}`
          }
        },
        tooltip: {
          trigger: 'item',
          // alwaysShowContent: true,
        },
        series: [
          {
            name: '工单数量',
            type: 'pie',
            // hoverAnimation: false,
            // silent: true,
            color: [
              '#1890FF',
              '#13C2C2',
              '#2FC25B',
              '#FACC14',
              '#F04864',
              '#8543E0',
              '#4B79F3'
            ],
            radius: ['40%', '70%'],
            // data: pieData,
            center: ['30%', '50%'],
            data: newPieData,
            label: {
              show: true,
              position: 'center',
              formatter: () => {
                let sum = 0
                for (let i = 0; i < newData.length; i++) {
                  sum += newData[i].value2
                }
                return `总数量\n${sum}`
              },
              fontSize: 30
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }

      option && myChart.setOption(option)
      window.addEventListener('resize', function () {
        if (!myChart?.isDisposed()) {
          myChart.resize()
        }
      })
    },
    pieStartEndTime () {
      [this.pieStartTime, this.pieEndTime] = this.getStartEndDate(this.newObj.dateRange)
    },
    getStartEndDate (dateRange1, type = '') {
      if (dateRange1.length === 0) {
        return []
      }
      const [date1, date2] = dateRange1
      if (type === 'month') {
        date1.setDate(1)
        date2.setDate(1)
        date2.setMonth(date2.getMonth() + 1)
        date2.setDate(date2.getDate() - 1)
      } else if (type === 'week') {
      // 注意：此处以周一为每周起始
        if (date1.getDay() > 1) {
          date1.setDate(date1.getDate() - (date1.getDay() - 1)) // 设置成周一
        } else if (date1.getDay() === 0) {
          date1.setDate(date1.getDate() - (7 - 1)) // 设置成周一
        }
        if (date2.getDay() !== 0) {
          date2.setDate(date2.getDate() + (7 - date2.getDay())) // 设置成周日
        }
      } else {
      // nothing to do
      }
      const f = 'YYYY-MM-DD'
      return [moment(date1).format(f), moment(date2).format(f)]
    },
  },
  mounted () {
    this.loading = true
    this.$watch(function () {
      return this.obj2
    }, async function (newV, oldV) {
      this.newObj = newV
      await this.pieStartEndTime()
      await this.pieChart()
      this.loading = false
    }, {
      deep: true
    })
  }
}
</script>
