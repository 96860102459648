<template>
  <el-dialog v-model="innerVisible" :show-close="false" custom-class="edge-person-select" width="623px">
    <div class="container">
      <div class="title">
        请按工作流节点选择指派人
      </div>
      <div class="container-body">
        <div class="left-part">
          <div class="user-group" v-for="phase in phaseList" :key="phase.phaseId" @click="choosePhase(phase)">
            <div class="group-item">
              <label>节点名称：</label>
              <el-input disabled :model-value="phase.phaseName" />
            </div>
            <div class="group-item">
              <label>处理人：</label>
              <el-input readonly :model-value="assignMap[phase.phaseId]?.name" />
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="right-part">
          <div class="search-input">
            <el-input
              placeholder="请输入姓名"
              prefix-icon="el-icon-search"
              v-model="searchInput">
            </el-input>
          </div>
          <div class="persons">
            <el-radio-group :model-value="assignMap[curNodeId]?.ldap" v-if="curNodeId" @change="handleUserSelect">
              <div class="person-item" v-for="p in agents" :key="p.ldap">
                <el-radio :label="p.ldap">
                  <span class="name">{{ p.name }}</span><span>{{ p.department }}</span>
                </el-radio>
              </div>
            </el-radio-group>
          </div>
        </div>
      </div>
      <div class="container-footer">
        <div class="cancel-button" @click="innerVisible = false">取消</div>
        <div class="confirm-button" @click="assgin">指派</div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { agentsAll } from '@/api/agent'

export default {
  name: 'EdgePersonSelect',
  props: {
    visible: Boolean,
    phaseList: Array,
  },
  data: () => ({
    curNodeId: '',
    searchInput: '',
    personIdSelected: '',
    person: [],
    assignMap: {},
  }),
  emits: ['update:visible', 'assign'],
  computed: {
    innerVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      },
    },
    agents () {
      const key = this.searchInput.trim()
      if (key.length === 0) {
        return this.person
      }
      const a = []
      for (const p of this.person) {
        if (p.name.includes(key)) {
          a.push(p)
        }
      }
      return a
    },
  },

  mounted () {
    this.loadAgentsAll()
  },
  watch: {
    visible (val) {
      if (!val) {
        this.curNodeId = ''
        this.searchInput = ''
        this.assignMap = {}
      }
    }
  },
  methods: {
    assgin () {
      this.$emit('assign', this.assignMap)
    },
    choosePhase (phase) {
      this.curNodeId = phase.phaseId
      this.searchInput = ''
      if (!this.assignMap[this.curNodeId]) {
        this.assignMap[this.curNodeId] = {
          ldap: null,
        }
      }
    },
    handleUserSelect (val) {
      this.assignMap[this.curNodeId] = {
        ldap: val,
      }
      this.assignMap[this.curNodeId].name = this.person.find(v => v.ldap === val)?.name
    },
    async loadAgentsAll () {
      let res = await agentsAll()
      this.person.push(...res.results)
      while (res.next) {
        res = await agentsAll(res.page + 1)
        this.person.push(...res.results)
      }
    }
  },
}
</script>
<style lang="less">
.edge-person-select {
  height: 474px;
  width: 623px;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
    height: 100%;
  }
}
//.person-select-dialog {
//  flex-grow: 1;
//  height: 70%;
//  max-height: 536px;
//  overflow: hidden;
//  display: flex;
//  flex-direction: column;
//  .el-dialog__body {
//    display: flex;
//    flex-direction: column;
//    justify-content: space-between;
//    overflow: hidden;
//    padding: 24px 32px 11px 32px;
//    .person-select-dialog-content {
//      overflow: hidden;
//      display: flex;
//      flex-flow: column;
//    }
//  }
//  .el-dialog__header {
//    padding: 0;
//  }
//  .dialog-footer {
//    margin-top: 6px;
//    display: flex;
//    justify-content: center;
//    align-items: center;
//  }
//}
</style>
<style lang="less" scoped>
  .container {
    width: 623px;
    height: 100%;
    .title {
      padding-top: 16px;
      padding-bottom: 17px;
      height: 58px;
      line-height: 25px;
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.85);
    }
    .container-body {
      padding-left: 15px;
      padding-right: 15px;
      height: calc(100% - 113px);
      display: flex;
      border-bottom: 1px solid #E8E8E8;

      .left-part, .right-part {
        width: calc(50% - 0.5px);
        height: 100%;
        overflow-y: scroll;
      }

      .left-part {
        padding-left: 10px;
        .user-group {
          margin-bottom: 24px;
          .group-item {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            label {
              width: 70px;
              font-weight: 400;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.85);
            }

            :deep(.el-input) {
              width: 130px;
            }
          }
        }
      }

      .right-part {
        display: flex;
        flex-direction: column;
        padding-left: 26px;
        .persons {
          margin: 8.5px 0;
          overflow-y: auto;
          flex-grow: 1;
          :deep(.el-radio-group) {
            width: 100%;
          }
          .person-item {
            overflow: hidden;
            width: 100%;
          }
          :deep(.el-radio) {
            padding: 0 8px;
            overflow: hidden;
            width: 100%;
            height: 35px;
            display: flex;
            align-items: center;
            .name {
              width: 156px;
            }
            .el-radio__label {
              display: flex;
              flex-grow: 1;
              overflow: hidden;
              > span {
                width: 50%;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }

      .line {
        width: 1px;
        height: calc(100% - 5px);
        background-color: #E8E8E8;
      }
    }
    .container-footer {
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;

      .cancel-button {
        background-color: #FFFFFF;
        border: 1px solid #D9D9D9;
        width: 65px;
        height: 31px;
        font-weight: 400;
        font-size: 14px;
        line-height: 31px;
        color: rgba(0, 0, 0, 0.65);
        text-align: center;
        cursor: pointer;
      }

      .confirm-button {
        margin-left: 6px;
        background-color: #4B79F3;
        width: 65px;
        height: 31px;
        font-weight: 400;
        font-size: 14px;
        line-height: 31px;
        color: #FFFFFF;
        text-align: center;
        cursor: pointer;
      }
    }
  }
</style>
