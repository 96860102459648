<template>
  <div class="page question-panel">
    <Breadcrumb>
      <h2>问题面板</h2>
      <img
        @click="refresh"
        class="btn-refresh"
        src="@/assets/images/icon-refresh.png"
        :class="{
          loading: refreshing
        }"
      >
    </Breadcrumb>
    <div class="filters-build-in">
      <div
        class="filter"
      >
        <div class="head">
          <img class="icon" src="@/assets/images/icon-my-questions.png">
          <span class="filter-name">
            <router-link :to="{
              name: '问题列表',
              query: {
                create_or_subscribe: 'create'
              }
            }">我新建的问题</router-link>
          </span>
        </div>
        <div class="statistic">
          <div>
            <div><i class="dot not-created"></i><span>未创建</span></div>
            <div><span>{{centerCount.myProblem?.uncreated || 0}}</span></div>
          </div>
          <div>
            <div><i class="dot doing"></i><span>进行中</span></div>
            <div><span>{{centerCount.myProblem?.pending || 0}}</span></div>
          </div>
          <div>
            <div><i class="dot done"></i><span>已完成</span></div>
            <div><span>{{centerCount.myProblem?.finished || 0}}</span></div>
          </div>
        </div>
      </div>
      <div
        class="filter"
      >
        <div class="head">
          <img class="icon" src="@/assets/images/icon-subcribed-questions.png">
          <span class="filter-name">
            <router-link :to="{
              name: '问题列表',
              query: {
                create_or_subscribe: 'subscribe'
              }
            }">我订阅的问题</router-link>
          </span>
        </div>
        <div class="statistic">
          <div>
            <div><i class="dot not-created"></i><span>未创建</span></div>
            <div><span>{{centerCount.mySubscribed?.uncreated || 0}}</span></div>
          </div>
          <div>
            <div><i class="dot doing"></i><span>进行中</span></div>
            <div><span>{{centerCount.mySubscribed?.pending || 0}}</span></div>
          </div>
          <div>
            <div><i class="dot done"></i><span>已完成</span></div>
            <div><span>{{centerCount.mySubscribed?.finished || 0}}</span></div>
          </div>
        </div>
      </div>
      <div
        class="filter"
      >
        <div class="head">
          <img class="icon" src="@/assets/images/icon-questions-no-ticket.png">
          <span class="filter-name">
            <router-link :to="{
              name: '问题列表',
              query: {
                problemStatus: 0
              }
            }">未创建工单的问题</router-link>
          </span>
        </div>
        <div class="statistic">
          <div>
            <div><i class="dot not-created"></i><span>未创建</span></div>
            <div><span>{{centerCount.allUncreated || 0}}</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="filters-from-user">
      <div v-for="f in personalFilters" :key="f.id" class="wrapper">
        <div class="content" @click="$router.push({
                name: '问题列表',
                query: filter2QueryObject(f)
        })" style="cursor: pointer;">
          <div class="head">
            <img class="icon" src="@/assets/images/icon-filter.png" />
            <span class="title">
              <a>{{f.filterName}}</a>
            </span>
            <el-popover
              popper-class="popover-filter-ops"
              placement="bottom"
              :width="88"
              trigger="click"
            >
              <div class="ops">
                <div class="op" @mouseenter="toggleHoverClass" @mouseleave="toggleHoverClass" @click="deleteFilter(f)">删除</div>
                <div
                  class="op"
                  @mouseenter="toggleHoverClass"
                  @mouseleave="toggleHoverClass"
                  @click="updateFilter(f)"
                  :data-track-click="JSON.stringify({
                     category: '问题面板-三个点-修改',
                     action: '点击',
                   })"
                >修改</div>
              </div>
              <template #reference>
                <img :data-track-click="JSON.stringify({
                   category: '问题面板-三个点',
                   action: '点击',
                 })" class="more" src="@/assets/images/icon-more.png" />
              </template>
            </el-popover>
          </div>
          <div class="body">
            <div class="keywords"><span>{{ f.keywords }}</span></div>
            <div class="statistic">
              <div>
                <div class="name"><i class="dot not-created"></i><span>未创建</span></div>
                <div class="value"><span>{{ f.uncreated }}</span></div>
              </div>
              <div>
                <div class="name"><i class="dot doing"></i><span>进行中</span></div>
                <div class="value"><span>{{ f.pending }}</span></div>
              </div>
              <div>
                <div class="name"><i class="dot done"></i><span>已完成</span></div>
                <div class="value"><span>{{ f.finished }}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Options, Vue } from 'vue-class-component'
import Breadcrumb from '@/components/Breadcrumb.vue'
import { ElMessage } from 'element-plus'
import { getProblemCenterCount, getProblemFilters, getProblemFilterCount, deleteProblemFilter } from '@/api/problem'

@Options({
  name: 'QuestionPanel',
  components: {
    Breadcrumb,
  }
})
export default class TicketDetail extends Vue {
  loading = false
  refreshing = false

  // 内置过滤器
  centerCount = {}
  async loadCenterCount () {
    const data = await getProblemCenterCount()
    this.centerCount = data
  }

  // 个人过滤器
  personalFilters = []
  async loadPersonalFilters () {
    const data = await getProblemFilters()
    this.personalFilters = data
    this.personalFilters.forEach(async (f) => {
      const counts = await getProblemFilterCount(this.filter2QueryObject(f))
      f.uncreated = counts.uncreated
      f.pending = counts.pending
      f.finished = counts.finished
      f.closed = counts.closed
    })
  }

  getKeywords (filter) {
    const p = new URLSearchParams(filter.problemFilter)
    return p.get('keywords')
  }

  filter2QueryObject (f) {
    const init = {}
    init.filterId = f.id
    return Array.from(new URLSearchParams(f.problemFilter).entries()).reduce((accumulator, currentValue) => {
      accumulator[currentValue[0]] = currentValue[1]
      return accumulator
    }, init)
  }

  updateFilter (f) {
    this.$router.push({
      name: '问题列表',
      query: this.filter2QueryObject(f)
    })
    window.sessionStorage.setItem('__ticket__updateFilter', JSON.stringify(f))
  }

  toggleHoverClass (e) {
    e.target.classList.toggle('hover')
  }

  async deleteFilter (f) {
    try {
      await deleteProblemFilter(f.id)
      ElMessage({
        type: 'success',
        message: '删除成功'
      })
    } catch (e) {
      ElMessage({
        type: 'error',
        message: '删除失败'
      })
    } finally {
      await this.loadPersonalFilters()
    }
  }

  refresh () {
    this.refreshing = true
    this.loadCenterCount()
    this.loadPersonalFilters()
    setTimeout(() => {
      this.refreshing = false
      ElMessage({
        showClose: true,
        message: '刷新成功',
        type: 'success'
      })
    }, 1000)
  }

  mounted () {
    this.loadCenterCount()
    this.loadPersonalFilters()

    window._trackEvent('问题详情页', '曝光')
  }
}
</script>
<style lang="less">
.page.question-panel {
  @keyframes refresh
  {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .btn-refresh {
    width: 18px;
    height: 18px;
    margin-left: 12px;
    cursor: pointer;

    &.loading {
      animation: refresh 1s;
    }
  }

  .filters-build-in {
    background-color: #FFFFFF;
    margin: 16px;
    height: 132px;
    display: flex;
    justify-content: space-between;
    .filter {
      flex-grow: 1;
      padding: 16px 40px 0 40px;
      .head {
        display: flex;
        align-items: center;
        .icon {
          width: 32px;
          height: 32px;
        }
        .filter-name {
          flex-grow: 1;
          margin: 0 8px;
          font-size: 15px;
          font-weight: 500;
          display: flex;
          a {
            flex-grow: 1;
          }
        }
      }
      .statistic {
        margin-top: 16px;
        display: flex;
        justify-content: flex-start;
        > div {
          flex-grow: 1;
          display: flex;
          flex-flow: column;
          > div:first-child {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 12px;
          }
          > div:nth-child(2) {
            font-size: 24px;
            font-weight: 500;
            padding-left: 10px;
          }
        }
        i.dot {
          margin-right: 4px;
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          &.not-created {
            background-color: #f26363;
          }
          &.doing {
            background-color: #22c081;
          }
          &.done {
            background-color: #4b79f3;
          }
        }
      }
    }
  }
  .filters-from-user {
    margin: -8px 8px 8px 8px;
    display: flex;
    justify-content: flex-start;
    flex-flow: wrap;
    > div.wrapper {
      flex-shrink: 0;
      width: 25%;
      padding: 8px;
      > div.content {
        background-color: #FFFFFF;
        height: 171px;
        padding: 16px;
        .head {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .title {
            margin: 0 8px;
            font-size: 15px;
            font-weight: 500;
            flex-grow: 1;
            display: flex;
            a {
              flex-grow: 1;
            }
          }
          .more {
            width: 22px;
            height: 22px;
            justify-self: flex-end;
          }
        }
        .body {
          margin-top: 16px;
          border-top: 1px solid #f3f5f6;;
          padding-top: 10px;
          display: flex;
          flex-flow: column;
          .keywords {
            font-size: 13px;
            font-weight: 500;
            height: 20px;
            display: flex;
            align-items: center;
          }
          .statistic {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            > div {
              width: 50px;
              display: flex;
              flex-flow: column;

              > div:first-child {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 12px;
              }
              > div:nth-child(2) {
                font-size: 24px;
                font-weight: 500;
                padding-left: 10px;
              }
              i.dot {
                margin-right: 4px;
                display: block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                &.not-created {
                  background-color: #f26363;
                }
                &.doing {
                  background-color: #22c081;
                }
                &.done {
                  background-color: #4b79f3;
                }
              }
              .value {
              }
            }
          }
        }
        .icon {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
}
.el-popover.popover-filter-ops {
  padding: 5px;
  .ops {
    .op {
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #4b79f3;
      cursor: pointer;
      &.hover {
        background-color: #e4ebfd;
      }
    }
  }
}
</style>
