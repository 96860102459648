<template>
<div class="dashboard" id="dashboard">
    <Breadcrumb>
      <h2>数据面板</h2>
    </Breadcrumb>
    <div class="main">
      <div class="top" v-loading="loading">
        <div class="search">
          <span class="search-int" style="display: none;">
            <el-select v-model="statistic" placeholder="请选择">
              <el-option
                v-for="item in statisticTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </span>
          <span class="search-int">
            <el-select v-model="obj.alarmValue" placeholder="全部工单类型">
              <el-option
                v-for="item in alarmType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </span>
          <span class="search-int">
            <el-select v-model="obj.extreme" filterable placeholder="100%极值" @clear="clearData" @change="changeNegation">
              <el-option
                v-for="extreme in extremeValue"
                :key="extreme.label"
                :label="extreme.value"
                :value="extreme.label">
              </el-option>
            </el-select>
          </span>
          <span
          style="margin:0 8px 0px -6px"
          ><img
          src="@/assets/images/select-check.svg"
          v-show="checked"
          @click="clickCheck">
          <img
          src="@/assets/images/select-checked.svg"
          v-show="!checked"
          @click="clickChecked">
          </span>
          <span class="date-range">
            <label>创建时间：</label>
            <el-date-picker v-if="statistic === 'day'"
              :editable="false"
              prefix-icon=""
              v-model="obj.dateRange"
              type="daterange"
              @change="pieStartEndTime"
              unlink-panels
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :shortcuts="shortcuts"
              :clearable="false"
            >
            </el-date-picker>
            <el-date-picker v-if="statistic === 'month'"
              :editable="false"
              prefix-icon=""
              v-model="monthRange"
              type="monthrange"
              unlink-panels
              range-separator="-"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
              :shortcuts="shortcuts2"
              :clearable="false"
            >
            </el-date-picker>
          </span>
          <span class="search-int">
            <label>问题来源: </label>
            <el-select v-model="obj.channel" filterable clearable placeholder="问题来源可选">
              <el-option
                v-for="channelSource in channelSources"
                :key="channelSource.value"
                :label="channelSource.label"
                :value="channelSource.value">
              </el-option>
            </el-select>
          </span>
          <span class="search-int">
            <label>产品线：</label>
            <el-select v-model="obj.product" filterable clearable placeholder="产品线可选">
              <el-option
                v-for="item in productLine"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </span>
          <span class="search-int">
            <label>技术支持级别：</label>
            <el-select v-model="obj.level" filterable placeholder="技术支持级别" clearable>
              <el-option
                v-for="label in ladelsList"
                :key="label.id"
                :label="label.name"
                :value="label.id">
              </el-option>
            </el-select>
          </span>
          <!-- @change="loadTagFilters" -->
          <!-- <span class="search-int"  v-for="set in extraFilters" :key="set.id">
            <span class="custom-search">
              <label>{{set.labelSetName}}：</label>
              <el-select v-model="set.setValue" filterable clearable placeholder="请选择">
                <el-option
                  v-for="l in set.labels"
                  :key="l.id"
                  :label="l.name"
                  :value="l.id">
                </el-option>
              </el-select>
            </span>
          </span> -->
          <span class="add-search" style="margin-right:19px;display: none;">
            <span>|</span>
            <el-tooltip content="点击可添加筛选条件" placement="top">
              <i @click="showQuestionFilterSelect = true"
                 :data-track-click="JSON.stringify({
                   category: '问题列表-添加筛选条件',
                   action: '点击',
                 })"
              ></i>
            </el-tooltip>
          </span>
          <el-button @click="search1" type="primary">查 询</el-button>
          <el-button @click="exportResult">导出查询结果</el-button>
          <div class="filter-select">
            <el-dialog
              :destroy-on-close="true"
              width="681px"
              :show-close="true"
              :before-close="onQuestionFilterSelectClose"
              v-model="showQuestionFilterSelect"
            >
            <QuestionFilterSelect
              :cancel="cancelQuestionFilterSelect"
              :submit="submitQuestionFilterSelect"
              :setIds="setIds"
            ></QuestionFilterSelect>
            </el-dialog>
          </div>
        </div>
        <div class="echart">
          <DashboardPie
          class="dashboard-pie"
          ref = "pie"
          :obj2="obj2"
          style="width: 50%;"
          >
          </DashboardPie>
          <DashboardLine
          class="dashboard-line"
          ref = "line"
          :obj2="obj2"
          style="width: 50%;"
          >
          </DashboardLine>
        </div>
        <div class="echart">
          <DashboardBar1
          class="dashboard-bar1"
          ref="bar1"
          :obj2="obj2"
          style="width: 40%; overflow: hidden;"
          >
          </DashboardBar1>
          <DashboardBar2
          class="dashboard-bar2"
          ref="bar2"
          :obj2="obj2"
          style="width: 60%; overflow: hidden;"
          >
          </DashboardBar2>
        </div>
      <!-- <div class="bottom" v-loading="loading2"> -->
        <!-- <div class="search">
          <span class="search-int">
            <el-select v-model="product" placeholder="选择产品线">
              <el-option
                v-for="item in productLine"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </span>
          <span class="search-int">
            <el-select v-model="statistic1" placeholder="请选择">
              <el-option
                v-for="item in statisticTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </span>
          <span class="date-range">
            <el-date-picker v-if="statistic1 === 'day'"
              :clearable="false"
              :editable="false"
              prefix-icon=""
              v-model="dateRange1"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :shortcuts="shortcuts"
            >
            </el-date-picker>
            <DateWeekRange v-if="statistic1 === 'week'"
              :clearable="false"
              :editable="false"
              prefix-icon=""
              v-model="weekRange1"
              align="right"
              unlink-panels
              range-separator="-"
              start-placeholder="开始周"
              end-placeholder="结束周"
              :shortcuts="shortcuts1"
            >
            </DateWeekRange>
            <el-date-picker v-if="statistic1 === 'month'"
              :clearable="false"
              :editable="false"
              prefix-icon=""
              v-model="monthRange1"
              type="monthrange"
              align="right"
              unlink-panels
              range-separator="-"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
              :shortcuts="shortcuts2"
            >
            </el-date-picker>
          </span>
          <span class="search-int">
            <el-select v-model="alarmValue1" placeholder="报警类型">
              <el-option
                v-for="item in alarmType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </span>
          <el-button @click="search2" type="primary">查 询</el-button>
          <el-button @click="reset2">重 置</el-button>
        </div> -->
        <!-- <div class="echart"> -->
        <!-- </div> -->
        <!-- <div class="item">
          <p>
            <span @click="showTime = true">平均处理</span>
            <span @click="showTime = false">响应</span>
          </p>
          <div class="item1" v-show='showTime'></div>
          <div class="item2" v-show='!showTime'></div>
        </div>
        <div class="item">中间一个</div>
        <div class="item">右边一个</div> -->
      <!-- </div> -->
    <!-- </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { Options, Vue } from 'vue-class-component'
import { deepCopy } from '@/utils/utils'
import Breadcrumb from '@/components/Breadcrumb.vue'
import * as labelApi from '@/api/label'
import {
  getProcessLadels,
} from '@/api/problem'
// import { toLine } from '@/utils/utils'
// import * as echarts from 'echarts'
import { dashboard, loadDashboard } from '@/api/dashboard'
import moment from 'moment'
import { DateWeekRange } from 'date-week-range'
import DashboardPie from '@/views/DashboardPie.vue'
import DashboardLine from '@/views/DashboardLine.vue'
import DashboardBar1 from '@/views/DashboardBar1.vue'
import DashboardBar2 from '@/views/DashboardBar2.vue'
import QuestionFilterSelect from '@/components/QuestionFilterSelect.vue'

@Options({
  name: 'Dashboard',
  components: {
    Breadcrumb,
    DateWeekRange,
    DashboardPie,
    DashboardLine,
    DashboardBar1,
    DashboardBar2,
    QuestionFilterSelect
  }
})
export default class Dashboard extends Vue {
  statisticTypes = [
    { value: 'day', label: '按天统计' },
    { value: 'week', label: '按周统计' },
    { value: 'month', label: '按月统计' },
  ]

  alarmType = [
    { value: '', label: '全部工单类型' },
    { value: 'warning', label: '告警' },
    { value: 'task', label: '任务' },
  ]

  shortcuts = [{
    text: '最近7天',
    value: (() => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
      return [start, end]
    })()
  }, {
    text: '最近30天',
    value: (() => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 29)
      return [start, end]
    })()
  }, {
    text: '最近90天',
    value: (() => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 89)
      return [start, end]
    })()
  }]

  shortcuts1 = [{
    text: '本周',
    value: [new Date(), new Date()],
  }, {
    text: '最近三周',
    value: (() => {
      const end = new Date()
      // const start = new Date(new Date().getFullYear(), 0)
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7 * 2)
      return [start, end]
    })(),
  }, {
    text: '最近六周',
    value: (() => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7 * 5)
      return [start, end]
    })(),
  }]

  shortcuts2 = [{
    text: '本月',
    value: [new Date(), new Date()],
  }, {
    text: '今年至今',
    value: (() => {
      const end = new Date()
      const start = new Date(new Date().getFullYear(), 0)
      return [start, end]
    })(),
  }, {
    text: '最近六个月',
    value: (() => {
      const end = new Date()
      const start = new Date()
      start.setMonth(start.getMonth() - 5)
      return [start, end]
    })(),
  }]

  productLine = [
    { value: '', label: '全部' },
  ]

  extremeValue = [
    { value: '100%', label: 100 },
    { value: '95%', label: 95 },
    { value: '90%', label: 90 },
  ]

  channelSources = [
    { value: 1, label: '内部用户' },
    { value: 2, label: 'atlas' },
    { value: 3, label: 'API' },
    { value: 4, label: '400' },
    { value: 5, label: '外部用户' },
    { value: 6, label: '邮件' },
    { value: 7, label: '小程序' },
  ]

  loading = false
  statistic = 'day'
  // alarmValue = ''
  // dateRange = []
  monthRange = []
  statistic1 = 'day'
  alarmValue1 = ''
  // product = ''
  dateRange1 = []
  monthRange1 = []
  weekRange1 = []
  loading2 = false
  pieStartTime = ''
  pieEndTime = ''
  // TODO 此处命名为 unchecked 比较合适
  checked = true
  // extreme = '95%'
  // labelList = ''
  labelsList = []
  // channel = ''
  showQuestionFilterSelect = false
  extraFilters = []
  obj = {
    alarmValue: '',
    extreme: '100%',
    dateRange: [],
    channel: '',
    product: '',
    level: '',
    negationExtremum: 0
    // otherTags: ''
  }

  obj2 = {
    alarmValue: '',
    extreme: '100%',
    dataRange: [],
    channel: '',
    product: '',
    level: '',
    negationExtremum: 0
    // otherTags: ''
  }

  ladelsList = []

  mounted () {
    const date1 = new Date()
    const date2 = new Date()
    date1.setDate(date1.getDate() - 6)
    this.obj.dateRange = [date1, date2]
    this.obj2.dateRange = [date1, date2]
    this.dateRange1 = [date1, date2]
    const date3 = new Date()
    date3.setMonth(date3.getMonth() - 2)
    this.monthRange = [date3, date2]
    this.monthRange1 = [date3, date2]
    const date4 = new Date()
    date4.setDate(date4.getDate() - 7 * 2)
    this.weekRange1 = [date4, date2]
    const query = this.$route.query
    let setIds = []
    let setIdValueMap = {}
    if (query.tagFilter) {
      // 形如 2-5,3-10,14-63
      const setIdValueArray = query.tagFilter.split(',').map(e => e.split('-'))
      setIds = setIdValueArray.map(e => Number(e[0]))
      setIdValueMap = setIdValueArray.reduce((ac, cu) => {
        ac[cu[0]] = Number(cu[1])
        return ac
      }, {})
      this.extraFilters = setIdValueArray.map(e => ({
        id: Number(e[0]),
        setValue: Number(e[1]),
      }))
    }
    const s = localStorage.getItem('__ticket_dashboardList_setIds')
    if (s) {
      const setIds2 = JSON.parse(s)
      if (setIds2.length > 0) {
        setIds = [...new Set(setIds.concat(setIds2))]
      }
    }
    this.loadSetValues(setIds, setIdValueMap)
    this.loadProductLines()
    this.pieStartEndTime()
    this.getProcessLadels()
  }

  loadTagFilters () {
    const list = this.extraFilters.map(el => {
      if (el.setValue) {
        return `${el.id}-${el.setValue}`
      }
    })
    const othertags = list.filter(item => item)
    this.obj.otherTags = othertags.join(',')
    return this.obj.otherTags
  }

  changeNegation () {
    if (this.checked === false) {
      this.checked = true
      this.obj.changeNegation = 0
    }
  }

  async search1 () {
    if (this.checked === false) {
      this.obj.negationExtremum = 1
    } else {
      this.obj.negationExtremum = 0
    }
    this.loading = true
    this.obj2 = deepCopy(this.obj)
    this.obj2.dateRange[0] = new Date(this.obj.dateRange[0])
    this.obj2.dateRange[1] = new Date(this.obj.dateRange[1])
    // try {
    //   await Promise.allSettled([
    //     this.$refs.pie.pieChart(),
    //     this.$refs.line.lineChart(),
    //     this.$refs.bar1.loadPie1(),
    //     this.$refs.bar1.loadPie2(),
    //     this.$refs.bar2.barChart1(),
    //     this.$refs.bar2.barChart2()])
    // } catch (e) {
    //   console.log(e)
    // }
    this.loading = false
  }

  async exportResult () {
    // let data = []
    if (typeof this.obj.extreme === 'string') {
      if (this.obj.extreme.charAt(this.obj.extreme.length - 1) === '%') {
        this.extre = this.obj.extreme.substring(0, this.obj.extreme.length - 1)
      }
    } else {
      this.extre = this.obj.extreme
    }
    try {
      const list = this.extraFilters.map(el => {
        if (el.setValue) {
          return `${el.id}-${el.setValue}`
        }
      })
      const otherTags = list.filter(item => item)
      const objData = {
        exportData: 1,
        ticketType: this.obj.alarmValue,
        extremum: this.extre,
        startDate: this.pieStartTime,
        endDate: this.pieEndTime,
        channel: this.obj.channel,
        businessLine: this.obj.product,
        level: this.obj.labelList,
        // otherTags: otherTags,
        searchType: 1,
        negationExtremum: this.checked ? 0 : 1,
      }
      const data = await loadDashboard(objData)
      console.log('data...', data)
    } catch (e) {
      console.error('====', e)
    }
    // const blob = new Blob(data, { type: 'application/vnd.ms-excel' })
    // console.log('blob', blob)
    // const herf = URL.createObjectURL(blob)
    // const a = document.createElement('a')
    // a.style.display = 'none'
    // a.herf = herfa.download = this.
    // data.download()
    // this.statistic = 'day'
    // this.obj2.alarmValue = ''
    // this.obj2.dateRange = []
    // this.obj2.extreme = '95%'
    // this.obj2.channel = ''
    // this.obj2.product = ''
    // this.obj2.level = ''
    // this.monthRange = []
    // this.obj.alarmValue = ''
    // this.obj.dateRange = []
    // this.obj.extreme = '95%'
    // this.obj.channel = ''
    // this.obj.product = ''
    // this.obj.level = ''

    // 默认 7天，3月， 3周
    // const date1 = new Date()
    // const date2 = new Date()
    // date1.setDate(date1.getDate() - 6)
    // this.obj.dateRange = [date1, date2]
    // this.obj2.dateRange = [date1, date2]
    // const date3 = new Date()
    // date3.setMonth(date3.getMonth() - 2)
    // this.monthRange = [date3, date2]
    // const date4 = new Date()
    // date4.setDate(date4.getDate() - 7 * 2)
    // this.weekRange1 = [date4, date2]
  }

  clearData () {
    this.obj.extreme = '95%'
  }

  reset2 () {
    this.statistic1 = 'day'
    this.alarmValue1 = ''
    this.obj.product = ''
    this.dateRange1 = []
    this.monthRange1 = []

    // 默认 7天，3月， 三周
    const date1 = new Date()
    const date2 = new Date()
    date1.setDate(date1.getDate() - 6)
    this.dateRange1 = [date1, date2]
    const date3 = new Date()
    date3.setMonth(date3.getMonth() - 2)
    this.monthRange1 = [date3, date2]
    const date4 = new Date()
    date4.setDate(date4.getDate() - 7 * 2)
    this.weekRange1 = [date4, date2]

    this.search2()
  }

  async loadProductLines () {
    try {
      const res = await labelApi.ticketLabelSet(0)
      const productLineSet = res.find(e => e.labelSetName === '产品线')
      if (productLineSet) {
        labelApi.getLabelsBySetId(productLineSet.id).then(res => {
          // console.log('res', res)
          const values = res.map(e => ({
            value: e.id,
            label: e.name,
          }))
          this.productLine.push(...values)
        }).catch(err => {
          console.error(err)
        })
      }
    } catch (e) {
      console.error('ticketLabelSet error:', e)
    }
  }

  pieStartEndTime () {
    [this.pieStartTime, this.pieEndTime] = this.getStartEndDate(this.obj.dateRange)
    if (this.statistic === 'month') {
      [this.pieStartTime, this.pieEndTime] = this.getStartEndDate(this.monthRange)
    }
  }

  onQuestionFilterSelectClose () {
    this.showQuestionFilterSelect = false
  }

  cancelQuestionFilterSelect () {
    this.showQuestionFilterSelect = false
  }

  submitQuestionFilterSelect (e) {
    console.log('submitQuestionFilterSelect:', e)
    // TODO 把setIds存储到本地
    const setIds = e.map(s => s.id)
    localStorage.setItem('__ticket_dashboardList_setIds', JSON.stringify(setIds))
    this.showQuestionFilterSelect = false
    this.loadExtraFilters()
  }

  loadExtraFilters () {
    const s = localStorage.getItem('__ticket_dashboardList_setIds')
    if (s) {
      const setIds = JSON.parse(s)
      if (setIds.length > 0) {
        const setIdValueMap = this.extraFilters.reduce((ac, cu) => {
          ac[cu.id] = cu.setValue
          return ac
        }, {})
        this.loadSetValues(setIds, setIdValueMap)
      } else {
        this.extraFilters = []
      }
    }
  }

  async loadSetValues (setIds, setIdValueMap = {}) {
    try {
      const res = await labelApi.ticketLabelSet(0)
      const ticketLabelSetList = res.filter(e => (e.useForTicket && setIds.includes(e.id)) || (e.useForTicket && e.labelSetName === '技术支持级别'))
      this.extraFilters = ticketLabelSetList
      for (const e of this.extraFilters) {
        labelApi.getLabelsBySetId(e.id).then(res => {
          e.labels = res
          e.setValue = setIdValueMap[e.id]
        }).catch(err => {
          console.error(err)
        })
      }
    } catch (e) {
      console.error('listLabelSet error:', e)
      this.extraFilters = []
    }
  }

  getProcessLadels () {
    getProcessLadels().then((res) => {
      this.ladelsList = res.filter(l => !l.isDeleted)
    }, (err) => {
      this.$message({
        type: 'error',
        message: err.errmsg
      })
      console.error(err)
    })
  }

  clickCheck () {
    this.checked = false
    if (typeof this.obj.extreme === 'number') {
      this.obj.extreme = (100 - this.obj.extreme) + '%'
    } else if (typeof this.obj.extreme === 'string') {
      if (this.obj.extreme.charAt(this.obj.extreme.length - 1) === '%') {
        this.obj.extreme = (100 - this.obj.extreme.substring(0, this.obj.extreme.length - 1) * 1) + '%'
      } else {
        this.obj.extreme = (100 - this.obj.extreme) + '%'
      }
    }
  }

  async problemExport () {
    this.showExportDialog = true
    const name = await this.exportQuestions()
    this.animateExportPercentage()
    this.exportFileName = name
  }

  clickChecked () {
    this.checked = true
    this.obj.extreme = (100 - this.obj.extreme.substring(0, this.obj.extreme.length - 1) * 1) + '%'
  }

  getStartEndDate (dateRange1, type = '') {
    if (dateRange1.length === 0) {
      return []
    }
    const [date1, date2] = dateRange1
    if (type === 'month') {
      date1.setDate(1)
      date2.setDate(1)
      date2.setMonth(date2.getMonth() + 1)
      date2.setDate(date2.getDate() - 1)
    } else if (type === 'week') {
      // 注意：此处以周一为每周起始
      if (date1.getDay() > 1) {
        date1.setDate(date1.getDate() - (date1.getDay() - 1)) // 设置成周一
      } else if (date1.getDay() === 0) {
        date1.setDate(date1.getDate() - (7 - 1)) // 设置成周一
      }
      if (date2.getDay() !== 0) {
        date2.setDate(date2.getDate() + (7 - date2.getDay())) // 设置成周日
      }
    } else {
      // nothing to do
    }
    const f = 'YYYY-MM-DD'
    return [moment(date1).format(f), moment(date2).format(f)]
  }

  get setIds () {
    return this.extraFilters.map(e => e.id)
  }
}
</script>
<style lang="less" scoped>
#dashboard {
  .main {
    padding: 10px 16px 16px 16px;
  }
  .top {
    // background: #fff;
    width: 100%;
    padding: 0;
    margin: 0;
    .search {
      min-height: 64px;
      width: 100%;
      background: #fff;
      padding: 14px;
      .title, .search-int, .date-range {
        margin-right: 16px;
        // margin-left: 16px;
      }
      button {
        margin-right: 24px;
      }
      .el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered {
        margin-left: 0;
      }
      ::v-deep(.el-select) {
        margin-bottom: 8px;
      }
      .add-search {
        margin-left: -32px;
        span {
          color: #e9e9e9;
          margin: 0 8px 0 25px;
        }
        i {
          display: inline-block;
          width: 24px;
          height: 24px;
          background: url('../assets/images/add-search.svg');
          vertical-align: middle;
          cursor: pointer;
        }
      }
      .filter-select {
        ::v-deep(.el-dialog) {
          .el-dialog__body {
            padding: 20px;
            color: rgba(0,0,0,0.85);
          }
        }
      }
    }
  }
  .echart {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .dashboard-pie {
      margin-top: 16px;
    }
    .dashboard-line {
      margin-top: 16px;
      margin-left: 16px
    }
    .dashboard-bar1 {
      margin-top: 15px;
      margin-right: 16px;
    }
    .dashboard-bar2 {
      margin-top: 15px;
    }
    .chart-item > p {
      text-align: center;
    }
    .chart-item {
      width: 50%;
      height: 400px;
    }
  }
  .color84 {
    color: #848484;
    margin-top: 16px;
  }
  .title {
    span {
      color: #848484;
    }
  }
  .bottom {
    margin-top: 8px;
  }
  .search-int {
    ::v-deep(.el-input__inner) {
      width: 140px;
    }
  }
  .date-range {
    ::v-deep(.el-date-editor) {
      width: 253px;
      .el-range-separator {
        line-height: 25px;
      }
    }
  }
}
</style>
