<template>
<div>
  <el-dialog
    v-model="dialogFormVisible"
    title="修改密码"
    width="400px"
    destroy-on-close
    :close-on-click-modal="false"
    custom-class="addOrEditWithoutPersonsAll"
    center
  >
    <el-form v-loading="loading" :model="form" label-width="98px" ref="RefForms" :rules="rules" class="add-or-edit-without-persons-forms">
      <el-form-item label="旧密码" prop="oldName">
        <el-input v-model="form.oldName" autocomplete="off" placeholder="请输入旧密码" />
      </el-form-item>
      <el-form-item label="新密码" prop="newName">
        <el-input v-model="form.newName" type="password" autocomplete="off" placeholder="请输入新密码" />
      </el-form-item>
      <el-form-item label="确认新密码" prop="submitNewName">
        <el-input v-model="form.submitNewName" type="password" autocomplete="off" placeholder="请确认新密码" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="EditPassWord('RefForms')">保存</el-button>
      </span>
    </template>
  </el-dialog>
</div>
</template>
<script>
import { NewPassword } from '@/api/auth.js'
import { deepCopy } from '@/utils/utils'
import store from '../store'
export default {
  props: {
    show: Boolean,
    editObj: Object,
    projectList: Array,
  },
  name: 'editPassWordDiasg',
  data () {
    return {
      form: {
        oldName: null,
        newName: null,
        submitNewName: null,
      },
      rules: {
        oldName: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        newName: [
          { required: true, message: '请输入新密码', trigger: 'blur' }
        ],
        submitNewName: [
          { required: true, message: '请确认新密码', trigger: 'blur' }
        ],
      },
      loading: false,
    }
  },
  emits: ['update:show'],
  computed: {
    dialogFormVisible: {
      get () {
        return this.show
      },
      set (v) {
        this.$emit('update:show', v)
      }
    },
  },
  methods: {
    async EditPassWord (RefForms) {
      // 编辑
      if (this.form.newName !== this.form.submitNewName) {
        this.$message({
          type: 'error',
          message: '请重新确认密码'
        })
        return false
      }
      await this.$refs[RefForms].validate()
      this.loading = true
      try {
        await NewPassword({
          password: this.form.oldName,
          newPassword: this.form.newName,
        })
        this.$message({
          type: 'success',
          message: '修改成功'
        })
        this.dialogFormVisible = false
      } catch (e) {
        this.$message({
          type: 'error',
          message: e.errmsg
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style scoped lang="less">
.add-or-edit-without-persons-forms {
  ::v-deep(.el-form-item) {
    margin-bottom: 22px!important;
  }
  ::v-deep(.el-select) {
    width: 100%;
  }
}

</style>
<style lang="less">
.addOrEditWithoutPersonsAll {
  margin: 0!important;
  // width: 100vw;
  // height: 100vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
