import { createStore } from 'vuex'
import { LabelSet } from '@/api/entity'
import auth from './modules/auth'

export interface State {
  tagSetList: Array<LabelSet>
  ticketCount: any
}

export default createStore<State>({
  state: {
    tagSetList: [
    ],
    ticketCount: {
    }
  },
  getters: {
    getTagSetById: (state) => (id) => {
      return state.tagSetList.find(set => set.id === id)
    }
  },
  mutations: {
    updateTagSetList (state, tagSetList) {
      state.tagSetList = tagSetList
    },
    updateTicketCount (state, ticketCount) {
      state.ticketCount = ticketCount
    }
  },
  actions: {
  },
  modules: {
    auth: Object.assign({ namespaced: true }, auth)
  }
})
