<template>
  <div class="page">
    <div class="header-nav">
      <span @click="$router.back()" class="go-back">问题列表 / </span>
      <span class="title">{{pageTitle}}</span>
    </div>
    <div class="content">
      <!-- 创建问题 -->
      <div class="form" v-if="questionType == 'create' || questionType == 'edit'">
        <el-form label-position="right" label-width="115px" :model="questionForm" ref="questionForm" :rules="rules">
          <el-form-item label="标题:" prop="problemTitle">
            <el-input v-model="questionForm.problemTitle" placeholder="请输入标题" clearable></el-input>
          </el-form-item>
          <el-form-item label="问题描述:" prop="problemDescribe">
            <TinymceEditor :height="200" placeholder="您可以输入问题的详细描述。比如：xxx市xxx商场xxx设备出现问题" ref="refTinymceEditor" v-model:value="questionForm.problemDescribe"></TinymceEditor>
          </el-form-item>
          <el-form-item label="客户联系电话:" prop="telephone">
            <el-input v-model="questionForm.telephone" oninput="value=value.replace(/[^\d]/g,'')" placeholder="请留下您的电话号码" clearable></el-input>
          </el-form-item>
          <el-form-item class="upload-box">
            <el-upload
              multiple
              :show-file-list="false"
              class="upload-demo"
              action=""
              :http-request="uploadFile"
              :on-preview="handlePreview"
              :on-remove="handleRemove">
              <el-button class="upload-btn" size="small" v-loading.fullscreen.lock="uploading"><i class="upload-icon"></i>上传附件</el-button>
            </el-upload>
            <div class="files-box" v-if="fileList.length > 0 && notImageList(fileList).length > 0" style="margin-top:10px;">
              <div v-for="(file, index) in fileList" :key="file.key" >
                <div v-if="!checkImage(file.name)" class="flex-box">
                  <div class="flex-download">
                    <span style="margin-right: 10px;" class="file-name">{{file.name}}</span>
                    <el-link target="_blank" :href="file.url" :download="file.name">
                      <i class="icon-download"></i>
                    </el-link>
                  </div>
                  <button @click="deleteFile(index)">删除</button>
                </div>
              </div>
            </div>
            <div v-if="fileList.length > 0 && imageList(fileList).length > 0" class="attach-img-box">
              <template v-for="(file, index) in fileList" >
                <div v-if="checkImage(file.name)" :key="file.key" class="img-div">
                  <el-image
                    :src="file.url"
                    :preview-src-list="[file.url]"
                    style="width: 100px; height: 80px"
                  >
                  </el-image>
                  <span class="icon-del" @click="deleteFile(index)"></span>
                </div>
              </template>
            </div>
          </el-form-item>
        </el-form>
        <div class="btn-box">
          <button @click="switchForm">取 消</button>
          <button class="submit-btn" @click="submitQuestion('questionForm', 'add')" v-if="questionType == 'create'">提 交</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { guestAddProblem } from '@/api/problem'
import { uploadFile } from '@/api/files'
import { tagLabelAllGuest } from '@/api/label'
import store from '../../store'
// import { urgentLevels } from '../../api/label'
import TinymceEditor from '@/components/TinymceEditor.vue'
import { hashCode, checkImage } from '../../utils/utils'

export default {
  name: 'createQuestion1',
  components: {
    TinymceEditor,
  },
  // data
  data () {
    const checkTitle = (rule, value, callback) => {
      const title = value.replace(/(^\s*)|(\s*$)/g, '')
      if (title === '') {
        callback(new Error('请输入标题'))
      } else {
        callback()
      }
    }
    return {
      questionType: 'create',
      questionSource: '',
      pageTitle: '创建问题',
      problemId: '',
      questionForm: {
        problemTitle: null,
        problemDescribe: null,
        telephone: null,
      },
      replyForm: {
        title: '',
        describe: '',
        reply: '',
        source: '',
        urgent: '',
        problemComments: '',
        relateProblemList: '',
        time: '',
        ticketId: '',
        file: {},
        subscribe: ''
      },
      uploading: false,
      fileList: [],
      urgentList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      rules: {
        problemTitle: [
          { required: true, validator: checkTitle, trigger: 'blur' }
        ],
        problemDescribe: [
          { required: true, message: '请描述一下问题', trigger: 'blur' }
        ],
        telephone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' }
        ]
      },
    }
  },
  methods: {
    uploadFile (info) {
      this.uploading = true
      const { file } = info
      const formData = new FormData()
      formData.append('file', file, file.name)
      return uploadFile(formData).then((res) => {
        this.uploading = false
        const url1 = res.url.split('//')
        const url2 = url1[1].split('/api')
        res.url.replace(url2[0], window.location.host)
        this.fileList.push({
          name: file.name,
          key: res.fileId,
          url: res.url.replace(url2[0], window.location.host)
        })
        console.log(this.fileList)
        this.fileList.concat(this.replyForm.file)
      }, (err) => {
        this.uploading = false
        this.$message({
          type: 'error',
          message: `文件 ${file.name} 上传失败：${err.errmsg}`
        })
        console.error(err)
        throw Error(`文件 ${file.name} 上传失败：${err.errmsg}`)
      })
    },
    deleteFile (index) {
      this.fileList.splice(index, 1)
    },
    handleRemove (file) {
      const index = this.fileList.findIndex((fileInfo) => fileInfo.name === file.name)
      if (index >= 0) {
        this.fileList.splice(index, 1)
      }
    },
    handlePreview (file) {
      console.log(file)
    },
    switchForm () {
      this.$router.back()
    },
    submitQuestion (formName, type) {
      if (this.uploading) {
        this.$message({
          type: 'error',
          message: '附件正在上传中，无法提交问题，请耐心等待，稍后重试'
        })
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (type === 'add') {
            console.log('one')
            const loading = this.$loading({
              lock: true,
              text: '问题创建中...',
              spinner: 'el-icon-loading',
            })
            console.log('two')
            const postData = {
              problemTitle: this.questionForm.problemTitle,
              problemDescribe: this.questionForm.problemDescribe,
              problemFiles: this.fileList,
              telephone: this.questionForm.telephone,
            }
            guestAddProblem(postData).then(() => {
              console.log('three')
              loading.close()
              this.$message({
                type: 'success',
                message: '问题提交成功'
              })
              this.$router.push({
                path: '/home'
              })
            }, (err) => {
              loading.close()
              this.$message({
                type: 'error',
                message: err.errmsg
              })
              console.error(err)
            })
          } else {}
        } else {
          return false
        }
      })
    },
    checkImage (filename) {
      return checkImage(filename)
    },
    imageList (filelist) {
      return filelist.filter(file => checkImage(file.name))
    },
    notImageList (filelist) {
      return filelist.filter(file => !checkImage(file.name))
    },
  },
  created () {
    const email = store.getters['auth/me'].email
    tagLabelAllGuest({ email }).then(uu => {
      this.questionForm.telephone = uu.results[0].telephone
    })
  },
  // computed
}
</script>

<style lang="less" scoped>
@import "../../assets/style/home.less";
button {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
}
.urgent-select {
  width: 100%;
}
::v-deep {
  .tox-tinymce {
    border-color: #DCDFE6;
    border-radius: 3px;
  }
}
.content {
  margin-top: -8px;
  justify-content: flex-start;
  flex-direction: column;
  .process {
    background: #fff;
    margin-bottom: 8px;
    padding: 8px 16px;
    font-size: 16px;
    ::v-deep(.el-steps) {
      .el-step {
        .el-step__line {
          height: 2px;
          top: 12px;
          background-color: #e9e9e9;
        }
      }
      .el-step__icon.is-text {
        border: 0;
      }
      .icon {
        width: 8px;
        height: 8px;
        background: #bfbfbf;
        border-radius: 50%;
      }
      .step-title {
        position: relative;
        .cost-time {
          font-size: 12px;
          color: #4b79f3;
          position: absolute;
          top: -10px;
          left: -40px;
          .cost-icon {
            width: 12px;
            height: 12px;
            background: url(../../assets/images/cost-time.svg);
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      .step-title {
        font-size: 16px;
        color: rgba(0,0,0,0.45);
      }
      .step-name {
        font-size: 14px;
        color: rgba(0,0,0,0.65);
      }
      .step-time {
        font-size: 12px;
        color: rgba(0,0,0,0.45);
      }
      .is-process {
        .step-title {
          // color: rgba(0,0,0,0.85);
          color: #4b79f3;
        }
        .icon {
          background: #4b79f3;
        }
      }
      .is-finish {
        .step-title {
          color: rgba(0,0,0,0.65);
        }
        .icon {
          background: #4b79f3;
        }
        .el-step__line {
          background-color: #4b79f3;
        }
      }
      #urge {
        height: 21px;
        line-height: 21px;
        padding: 0 6px;
        border-radius: 2px;
        background: #4b79f3;
        color: #fff;
        font-size: 12px;
      }
      .feishu-icon {
        display: inline-block;
        width: 13px;
        height: 13px;
        background: url(../../assets/images/feishu-icon.svg);
        position: relative;
        top: 2px;
        margin-left: 6px;
      }
      #urged {
        height: 21px;
        line-height: 20px;
        padding: 0 6px;
        border-radius: 2px;
        background: #c3c3c9;
        color: #ffffff;
        font-size: 12px;
      }
      .icon-gou {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: url(../../assets/images/gou.svg);
        vertical-align: middle;
        margin-left: 4px;
      }
    }
  }
  .detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div:first-child {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 110px;
      > :first-child {
        margin-right: 8px;
        font-size: 16px;
      }
    }
  }
  #btn-box .subscribe-btn {
    margin-left: 16px;
    background: rgba(53,73,224,0.10);
    border: 1px solid #4b79f3;
    color: #4b79f3;
  }
}
</style>

<style lang="less">
.el-select-dropdown__item.selected {
  color: #4b79f3;
}
</style>
