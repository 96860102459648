// import app from '@/vue'
import axios from 'axios'
import { deepCopy, objectKeyToHump, objectKeyToLine } from './utils'

const requests = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || '/api',
  timeout: 60000
})

const resolve = (response) => {
  const { data } = response
  if (data instanceof Object) {
    if (data.errno !== 0) {
      return Promise.reject(data)
    }
    return objectKeyToHump(data.data)
  }
  return Promise.reject(data)
}

const err = (error) => {
  if (error.response) {
    const { data } = error.response
    if (data.errno === 29001) {
      // TODO USER_TOKEN_INVALID
      // app.$message(data.errmsg)
    }
  }
  return Promise.reject(error)
}

const requestInterceptors = (request) => {
  const token = localStorage.getItem('token')
  const requestCopy = deepCopy(request)
  if (token) {
    requestCopy.headers.Authorization = token
  }
  if (request.hump === true) {
    return requestCopy
  }
  requestCopy.headers['Unicorn-Timezone'] = (0 - new Date().getTimezoneOffset() / 60)
  if (request.params) {
    requestCopy.params = objectKeyToLine(request.params)
  }
  if (request.data) {
    if (Object.prototype.toString.call(request.data) === '[object FormData]') {
      requestCopy.data = request.data
    } else {
      requestCopy.data = objectKeyToLine(request.data)
    }
  }
  return requestCopy
}

requests.interceptors.request.use(requestInterceptors, (error) => Promise.reject(error))

requests.interceptors.response.use(resolve, err)

export default requests

const fileDownloader = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || '/api',
  timeout: 60000,
  responseType: 'blob'
})

fileDownloader.interceptors.request.use(requestInterceptors, (error) => Promise.reject(error))

const fileDownloadHandler = async (res) => {
  let data = res.data
  if (!data) {
    return
  }
  if (data instanceof Object && !(data instanceof Blob)) {
    if (data.errno !== 0) {
      return Promise.reject(data)
    }
  }
  if (data.type === 'application/json') {
    const reader = new FileReader()
    reader.readAsText(data, 'utf-8')
    const jsonData = await new Promise((resolve) => {
      reader.onload = function () {
        data = JSON.parse(reader.result)
        resolve(data)
      }
    })
    if (jsonData.error !== 0) {
      return Promise.reject(jsonData)
    }
  }
  const { headers } = res
  const contentDisposition = headers['content-disposition']
  let fileName = 'unKnownFile'
  if (contentDisposition && contentDisposition.indexOf('filename=') >= 0) {
    fileName = decodeURI(contentDisposition.split('filename=')[1].trim())
    // console.log('filename = ', fileName)
  }
  const type = headers['content-type']
  if (type === 'text/csv') {
    data = '\ufeff' + data
  }
  const url = window.URL.createObjectURL(new Blob([data], { type }))
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.setAttribute('download', fileName)
  document.body.appendChild(a)
  a.click() // 执行下载
  window.URL.revokeObjectURL(a.href) // 释放url
  document.body.removeChild(a) // 释放标签
  return fileName
}

fileDownloader.interceptors.response.use(fileDownloadHandler, (err) => Promise.reject(err))
export {
  fileDownloader
}
