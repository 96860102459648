<template>
  <div>
  <div style="display:flex;align-items: center;">
    <div class="workflow-area">
      <span class="workflow-area__title">选择工作流</span>
      <el-select v-model="workflows" multiple value-key="key" collapse-tags popper-class="workflow-select-options">
        <el-option v-for="workflow in workflowList" :key="workflow.key" :label="workflow.name" :value="workflow">
          <div style="display: flex; justify-content: space-between">
            <span>{{workflow.name}}</span>
            <div class="option-suffix" @click.stop="previewWorkflow(workflow)">
              <img src="../../svg/workflow-preview.svg" />
              <span class="label">预览图</span>
            </div>
          </div>
        </el-option>
      </el-select>
    </div>
    <div class="workflow-area">
      <span class="workflow-area__title">选择里程碑关键节点</span>
      <span class="milestone-tags" @click="milestoneVisible=true">
        <el-tag type="info" class="first-tag" size="small"  v-if="milestoneTags?.length>0">{{milestoneTags[0].name}}</el-tag>
        <span class="null-tag" v-else>请选择</span>
        <el-tag type="info" class="length-tag" size="small" v-show="milestoneTags?.length>1">+{{milestoneTags?.length - 1}}</el-tag>
      </span>
      <!-- <el-select v-model="workflows" multiple value-key="key" collapse-tags popper-class="workflow-select-options" >
        <el-option v-for="workflow in milestoneList" :key="workflow.key" :label="workflow.name" :value="workflow">
          <div style="display: flex; justify-content: space-between" >
            <span>{{workflow.name}}</span>
            <div class="option-suffix" @click.stop="previewWorkflow(workflow)">
              <img src="../../svg/workflow-preview.svg" />
              <span class="label">预览图</span>
            </div>
          </div>
        </el-option>
      </el-select> -->
    </div>
    <div>
      <el-button type="primary" @click="showTaskAppointVisible">工作流任务设定</el-button>
    </div>
  </div>
  <div style="height: 3px; width: 100%; background-color: #F3F5F6"></div>
  <div style="display: block; height: calc(100% - 57px); width: 100%; padding: 0 16px 16px;">
    <div style="height: 30px; margin: 16px 0;">
      <!-- {{name}} -->
      <el-input v-model="name" class="template-name" placeholder="请输入模版名字" clearable></el-input>
    </div>
    <div style="height: calc(100% - 62px)">
      <template-editor v-model:templateData="templateData">
        <el-button type="primary" @click="createOrUpdateTemplate">保存</el-button>
      </template-editor>
    </div>
    <el-dialog :title="previewDialogTitle" v-model="previewDialogVisible" ref="dialog">
      <!-- <img :src="previewDialogImg" style="width: 100%"/> -->
      <el-image style="width: 100%"
        :src="previewDialogImg"
        :preview-src-list="[previewDialogImg]"
      >
      </el-image>
    </el-dialog>
  </div>
  <select-mil-stone
  :milestoneVisible="milestoneVisible"
  :milestone="milestone"
  @milestoneVisible="(val)=>milestoneVisible=val"
  @milestone="upMilestone"
  @selectNodeData="upWorkflows"
  :selectData="workflows"
  ></select-mil-stone>
  <WorkflowTaskAppiontDrawer
    :loading="loadingWorkflow"
    :edit="true"
    showType="template"
    v-model:show="taskAppointVisible"
    :taskAppointTable = "taskAppointTable"
    @changeList="changeAppointList"
    @showAppoint="changeAppointVisible=true"
    @activeAppoint="getActiveAppoint"
    ></WorkflowTaskAppiontDrawer>
    <optInputOutputDrawer
      v-model:show="changeAppointVisible"
      :appointInfo="activeAppoint"
      :taskList="taskList"
      @update:inputData="updateInputData"
    />
    <checkStandDialog
    v-model:show="showSelectWorkFlowVisible"
    title="请选择修改的工作流"
    :confirmFn="confirmWorkflowEdit"
    >
    <div style="width:100%;display:flex;justify-content: center;">
      <el-select  v-model="selectedWorkflow"  size="large">
        <el-option
          v-for="item in workflows"
          :key="item.key"
          :label="item.name"
          :value="item.key"
        ></el-option>
      </el-select>
    </div>
    </checkStandDialog>
</div>
</template>

<script>
import { getWorkflowAgent } from '@/api/agent.ts'
import WorkflowTaskAppiontDrawer from '@/components/dialog/WorkflowTaskAppiontDrawer.vue'
import checkStandDialog from '@/components/dialog/checkStandDialog.vue'
import optInputOutputDrawer from '@/components/dialog/optInputOutputDrawer.vue'
import { getTemplateById, createTemplate, updateTemplateById, getWorkflowList, getAppointListData } from '@/api/template'
import { deepCopy } from '@/utils/utils'
import TemplateEditor from '@/components/TemplateMaking/TemplateEditor.vue'
import SelectMilStone from './SelectMilStone.vue'
export default {
  name: 'EditTemplate.vue',
  emits: ['updateTemplateList'],
  props: {
    templateId: {
      type: [Number, String],
      default: -1
    }
  },
  components: {
    TemplateEditor,
    SelectMilStone,
    WorkflowTaskAppiontDrawer,
    optInputOutputDrawer,
    checkStandDialog
  },
  data: () => ({
    loadingWorkflow: false,
    taskList: [],
    activeAppoint: {
      formInfo: {
        inFields: [],
        outFields: [],
      },
    },
    changeAppointVisible: false,
    taskAppointTable: [],
    realTemplateId: -1,
    mode: 'create',
    workflows: [],
    workflowList: [],
    name: '',
    templateData: {
      list: []
    },
    previewDialogVisible: false,
    previewDialogTitle: '',
    previewDialogImg: '',
    signal: null,
    milestone: [],
    milestoneList: [],
    milestoneVisible: false,
    workSelectList: [],
    nowWorkflow: null,
    milestoneTags: [],
    taskAppointVisible: false,
    showSelectWorkFlowVisible: false,
    selectedWorkflow: null
  }),
  watch: {
    previewDialogVisible (val) {
      if (val) {
        this.signal = new AbortController()
        document.addEventListener('mouseup', (e) => {
          e.stopPropagation()
          const popper = this.$refs.dialog.dialogRef
          if (e.target !== popper && !e.composedPath().includes(popper)) {
            this.previewDialogVisible = false
          }
        }, {
          capture: true,
          signal: this.signal.signal,
        })
      } else {
        this.signal.abort()
      }
    },
    workflows: {
      handler (val) {
        let arr = []
        const a = val?.forEach(i => { arr = i.milestone ? arr.concat(i.milestone) : arr })
        if (val[0]?.name) {
          this.getTaskAppointList(val[0]?.name)
        }
        this.milestoneTags = arr
      },
      immediate: true,
      deep: true,
    },
    activeAppoint: {
      handler (val) {
        const index = this.taskList.findIndex(i => i.tagId === val.tagId)
        if (index === -1) {
          this.taskList.push(val)
        } else {
          this.taskList[index] = val
        }
        const tableIndex = this.taskAppointTable.findIndex(i => i.tagId === val.tagId)
        if (tableIndex !== -1) {
          this.taskAppointTable[tableIndex] = val
        }
      },
      deep: true
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.templateId) {
      this.realTemplateId = Number(to.params.templateId)
      this.mode = 'edit'
      getTemplateById(this.realTemplateId).then((res) => {
        this.templateData = res.template
        this.name = res.name
        this.workflows = res.workflows?.list || []
        this.loadingWorkflow = true
        getAppointListData({
          templateId: this.realTemplateId,
          wfName: this.workflows[0].name,
          wfKey: this.workflows[0]?.key,
        }).then(res => {
          this.taskAppointTable = res?.taskList
          this.taskList = res?.taskList
        }).finally(() => {
          this.loadingWorkflow = false
        })
      }, (err) => {
        this.$message({
          type: 'error',
          message: err.errmsg
        })
        console.error(err)
      })
    } else {
      this.mode = 'create'
      this.name = ''
      this.templateData = {
        list: []
      }
      this.realTemplateId = -1
    }
    next()
  },
  created () {
    if (this.templateId > -1) {
      this.realTemplateId = Number(this.templateId)
      this.mode = 'edit'
      getTemplateById(this.realTemplateId).then((res) => {
        if (res?.wfTaskSettings instanceof Array) {
          this.taskAppointTable = res?.wfTaskSettings[0]?.taskList
          this.taskList = res?.wfTaskSettings[0]?.taskList
        }
        this.templateData = res.template
        this.name = res.name
        this.workflows = res.workflows?.list || []
        this.selectedWorkflow = this.workflows[0]?.key
        // getAppointListData({
        //   templateId: this.realTemplateId,
        //   wfName: this.workflows[0].name,
        //   wfKey: this.workflows[0]?.key,
        // }).then(res => {
        //   this.taskAppointTable = res?.taskList
        //   this.taskList = res?.taskList
        // })
      }, (err) => {
        this.$message({
          type: 'error',
          message: err.errmsg
        })
        console.error(err)
      })
    }
    this.loadWorkflows()
  },
  methods: {
    updateInputData (val) {
      this.activeAppoint.formInfo.inFields = val
    },
    confirmWorkflowEdit () {
      this.loadingWorkflow = true
      this.showSelectWorkFlowVisible = false
      this.taskAppointVisible = true
      const workflow = this.workflows.find(i => i.key === this.selectedWorkflow)
      getAppointListData({
        templateId: this.realTemplateId,
        wfName: workflow?.name,
        wfKey: workflow?.key,
      }).then(res => {
        this.taskAppointTable = res?.taskList
        this.taskList = res?.taskList
      }).finally(() => {
        this.loadingWorkflow = false
      })
    },
    getActiveAppoint (info) {
      const blankFormInfo = {
        formInfo: {
          inFields: [],
          outFields: [],
        }
      }
      if (Object.keys(info?.formInfo).length === 0) {
        this.activeAppoint = { ...info, ...blankFormInfo }
      } else {
        this.activeAppoint = { ...blankFormInfo, ...info }
      }
    },
    changeAppointList (val) {
      let workflow
      if (this.workflows?.length > 1) {
        workflow = this.workflowList.find(i => this.selectedWorkflow === i.key)
      } else {
        workflow = this.workflowList.find(i => this.workflows[0].key === i.key)
      }
      const params = {
        wfTaskSettings: [{
          wfKey: workflow.key,
          wfName: workflow.name,
          wfVersion: workflow.version,
          taskList: this.taskAppointTable
        }],
      }
      console.log(this.realTemplateId, params)
      updateTemplateById(this.realTemplateId, params).then(res => {
        this.$message({
          type: 'success',
          message: '保存工作流任务设定成功'
        })
      }).catch((err) => {
        this.$message({
          type: 'error',
          message: err.errmsg
        })
        console.error(err)
      })
    },
    getTaskAppointList (val) {
      console.log(val)
    },
    showTaskAppointVisible () {
      // this.taskAppointVisible = true
      if (this.workflows?.length > 1) {
        this.showSelectWorkFlowVisible = true
      } else {
        this.taskAppointVisible = true
        this.loadingWorkflow = true
        getAppointListData({
          templateId: this.realTemplateId,
          wfName: this.workflows[0]?.name,
          wfKey: this.workflows[0]?.key,
        }).then(res => {
          this.taskAppointTable = res?.taskList
          this.taskList = res?.taskList
        }).finally(() => {
          this.loadingWorkflow = false
        })
      }
    },
    previewWorkflow (workflow) {
      this.previewDialogTitle = workflow.name
      this.previewDialogImg = workflow.description
      this.previewDialogVisible = true
    },
    loadWorkflows () {
      getWorkflowList().then((res) => {
        this.workflowList = res
      }, (err) => {
        this.$message({
          type: 'error',
          message: err.errmsg
        })
        console.error(err)
      })
    },
    createOrUpdateTemplate () {
      const name = this.name.replace(/(^\s*)|(\s*$)/g, '')
      if (!name) {
        this.$message({
          type: 'error',
          message: '请输入模版名字'
        })
      } else {
        const template = deepCopy(this.templateData)
        const relationMap = {}
        template.list.forEach((field) => {
          if (field.options.valueList && field.options.valueList.type === 'label') {
            relationMap[field.options.model] = {
              setId: field.options.valueList.labelSet,
              defaultValue: field.options.defaultValue,
              required: field.rules && Array.isArray(field.rules) && field.rules.some(rule => rule.required === true)
            }
          }
        })
        let postData = {
          name,
          template,
          relationMap,
          workflows: {
            list: this.workflows,
          },
          description: ''
        }
        if (Object.values(this.workflows)?.length) {
          const workflow = this.workflowList?.find(i => this.workflows[0]?.key === i?.key)
          const wfTaskSettings = {
            wfTaskSettings: [{
              wfKey: workflow.key,
              wfName: workflow.name,
              wfVersion: workflow.version,
              taskList: this.taskAppointTable
            }]
          }
          postData = { ...postData, ...wfTaskSettings }
        }
        if (this.realTemplateId > -1) {
          updateTemplateById(this.realTemplateId, postData).then(() => {
            this.$message({
              type: 'success',
              message: '更新模板成功'
            })
            this.$emit('updateTemplateList')
          }, (err) => {
            this.$message({
              type: 'error',
              message: err.errmsg
            })
            console.error(err)
          })
        } else {
          createTemplate(postData).then((res) => {
            this.$message({
              type: 'success',
              message: '新建模板成功'
            })
            this.$emit('updateTemplateList')
            this.realTemplateId = res.id
            this.$router.push({ name: '编辑模板', params: { templateId: res.id } })
          }, (err) => {
            this.$message({
              type: 'error',
              message: err.errmsg
            })
            console.error(err)
          })
        }
      }
    },
    changeMilStone (workflow) {
      this.previewDialogImg = this.workflows[0]?.description
    },
    upMilestone (val) {
      this.milestone = val
    },
    upWorkflows (val) {
      this.workflows = val
    }
  },
}
</script>

<style lang="less" scoped>
.milestone-tags{
  width: 200px;
  height: 30px;
  border-radius: 3px;
  line-height: 30px;
  padding-left: 12px;
  font-family: PingFangSC, PingFangSC-Medium;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #DCDFE6;
  background-color: #FFF;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  padding-left: 2px;
  position: relative;
  &:hover{
    cursor: pointer;
  }
  .first-tag{
    margin:0 2px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .null-tag{
    color: #C0C4CC;
    padding-left: 5px;
  }
}
.workflow-area {
  height: 54px;
  // width: 100%;
  padding: 11px 16px;

  .workflow-area__title {
    font-weight: 500;
    font-size: 15px;
    color: #000000;
    margin-right: 16px;
    line-height: 30px;
  }
}
.template-name {
  width: 300px !important;
  :deep(.el-input__inner) {
    color: rgba(0,0,0,0.85);
    width: 300px;
  }
}
</style>
<style lang="less">
@import "../../../assets/style/workflow.less";
</style>
