/**
 * 此处定义了各种实体的前端渲染用到的最小接口
 */

export interface Label {
  id?: number,
  name: string,
  labelSetName?: string,
  extraName?: string,
}

export interface LabelSet {
  id: number
  labelSetName: string
  labelSetType: string
  useForTicket: boolean
  useForUser: boolean
  useForRepository: boolean
  labels?: Array<Label>,
  checkList?: Array<number>,
  editable?: boolean,
}

export enum ViewType {
  ADMIN_SOS = 'admin_sos', // admin_sos
  UNASSIGNED = 'unassigned', // 待认领 or 未指派
  PENDING = 'pending', // 待处理
  PROCESSING = 'processing', // 进行中
  CLOSED = 'closed', // 已结束 or 已关闭
  ALL_UNASSIGNED = 'all_unassigned', // 所有待认领
  ALL_PENDING = 'all_pending', // 所有待处理
  ALL_PROCESSING = 'all_processing', // 所有进行中
  ALL_CLOSED = 'all_closed', // 所有已结束 or 已关闭
  PERSONAL_ALL_TICKETS = 'personal_all_tickets', // 员工视图所有工单
  ALL_DELAYED = 'all_suspended', // 管理员 已挂起视图
  DELAYED = 'suspended', // 个人 已挂起视图
}
//
// export enum UrgentLevel {
//   HIGHEST = 0,
//   HIGH = 1,
//   NORMAL = 2,
//   LOW = 3
// }

// """工单状态"""
export enum TicketStatus {
  UNASSIGNED = 'unassigned', // 待认领 or 未指派
  PENDING = 'pending', // 待处理
  // PROCESSING = 'processing', // 进行中
  CLOSED = 'closed', // 已结束 or 已关闭
  SUSPENDED = 'suspended' // 已结束 or 已关闭
}

export enum Order {
  ASCENDING = 'ascending',
  DESCENDING = 'descending'
}

export interface OrderField {
  prop: string
  order: Order
}

export interface SearchOps {
  keywords?: string
  urgentLevel?: string
  startTime?: number
  workType?: string
  endTime?: number
  orderField?: OrderField
  tagFilter?: string
  templateId?: number
  processDuration?: number
  workflow?: string
}

export interface EmergencyDuty {
  L1?: Array<string>
  L2?: Array<string>
  L3?: Array<string>
}

export interface TimeArrangement {
  startTime: number
  endTime: number
  ldaps: Array<string>
}

export interface BatchScheduling {
  schedulingDates: Array<string>
  timeArrangements: Array<TimeArrangement>
}

export enum FilterType {
  P = 'P', // 产品线
  S = 'S' // 项目
}

export enum TicketType {
  WARNING = 'warning', // 报警
  TASK = 'task' // 任务
}

/**
 * 统计数据业务含义
 */
export enum SearchType {
  TYPE_1 = 1, // 产品线工单分布
  TYPE_2 = 2, // 阶段处理耗时与平均处理耗时
  TYPE_3 = 3, // 不同阶段工单处理数量
  TYPE_4 = 4, // 不同阶段工单流转耗时
}

/**
 * 统计类型
 */
export enum StatisticsType {
  month = 'month', // 按月统计
  day = 'day', // 按天统计
}

export interface DashboardParams {
  filterType: FilterType
  startDate: string
  endDate: string
  ticketType: TicketType
  searchType: SearchType
  product?: number, // 产品线id
  statisticsType: StatisticsType
}
