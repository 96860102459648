<template>
  <div class="output-List-com">
    <el-form :model="form" label-width="100px" label-position="left" ref="form">
      <template v-for="(item, idx) in form.test" :key="item">
        <el-form-item :label="item.label" :prop="''+idx"
        :rules="[
        { validator: validatePass, trigger: 'change',required: item.required }]"
      >
          <!-- 输入 -->
          <el-input v-model="item.value" v-if="item.type == 'input'" :placeholder="item.placeholder" :disabled="disable"/>
          <!-- 截图 -->
          <template  v-if="item.type == 'img'">
          <el-input v-model="image" placeholder="请在此处粘贴截图 文字无效"   @paste="(e)=>pasteImage(e,idx)"  :disabled="disable"/>
          <div class="image-box" v-for="(img, imgIdx) in item.value" :key="img.key">
            <el-image  :src="img.url" :preview-src-list="[img.url]" fit  style="width: 100px; height: 100px"/>
            <img v-if="!disable" src="@/assets/images/close-icon.svg" style="height:20px;width:20px" class="close-icon" @click="delImage(idx,imgIdx)"/>
          </div>
          </template>
          <!-- 文件 -->
          <div v-if="item.type == 'file'">
            <el-upload v-if="!disable||type==='overview'" multiple :show-file-list="false" ref="fileUploader" class="upload-file" :http-request="(info)=>uploadFile(info, item, idx)"
              :on-preview="handlePreview" :on-remove="handleRemove" action=" " :disabled="disable">
              <el-button class="upload-btn" size="small" v-loading.fullscreen.lock="uploading">
                <img src="@/assets/images/upload.svg" />
                <span>上传附件</span>
                <template>
                  <i @click.stop="showUploadFileList = false" v-if="showUploadFileList"
                    class="icon-arrow el-icon-arrow-up"></i>
                  <i @click.stop="showUploadFileList = true" v-else class="icon-arrow el-icon-arrow-down"></i>
                </template>
              </el-button>
            </el-upload>
            <template v-for="(file, i) in item.value">
              <div v-if="checkImage(file.name)" :key="file.key" class="img-div">
                <el-image
                    :src="file.url"
                    :preview-src-list="[file.url]"
                    style="width: 100px; height: 80px">
                  </el-image>
                <span class="icon-del" @click="item.value.splice(i, 1)" v-if="!disable"></span>
              </div>
              <div v-if="!checkImage(file.name)" :key="file.key" class="files-div">
                <div class="feishuFile" @click.stop="goFileDetail(file.url)">
                  <span class="file-name">{{ file.name }}</span>
                  <span class="icon-del" @click.stop="item.value.splice(i, 1)" v-if="!disable"></span>
                </div>
              </div>
            </template>
            <span v-if="!item.value?.length&&disable" style="color:#888">暂未上传附件</span>
          </div>
          <!-- 飞书链接 -->
          <el-input v-model="item.value" type="url" v-if="item.type == 'url'&&(!disable || type==='overview')" placeholder="请粘贴飞书文档链接" :disabled="disable"/>
          <div v-if="item.type == 'url'&&item.value" :key="item.value" class="files-div">
            <div class="feishuFile" @click.stop="goFileDetail(item.value)" style="marginTop:10px">
              <span class="file-name">链接：{{ item.value }}</span>
              <span class="icon-del" @click.stop="fileList.splice(i, 1)" v-if="!disable"></span>
            </div>
          </div>
          <div v-if="item.type == 'url'&&!item.value&&disable" style="color:#888">暂未上传飞书链接</div>
          <!-- 选择 -->
          <el-select v-model="item.value" v-if="item.type == 'select'" :disabled="disable">
            <el-option label="Zone one" value="shanghai" />
            <el-option label="Zone two" value="beijing" />
          </el-select>
          <!-- 日期 -->
          <el-date-picker v-if="item.type == 'date'" v-model="item.value" type="date" :placeholder="item.placeholder" :disabled="disable"
            style="width: 100%" />
          <!--  单选-->
          <el-radio-group v-model="item.value" class="ml-4" v-if="item.type == 'radio'" :disabled="disable">
            <el-radio size="large" v-for="opt in item?.options" :key="opt" :label="opt.label" style="width:auto">{{ opt.label }}</el-radio>
          </el-radio-group>
          <!-- 复选 -->
          <el-checkbox-group v-model="item.value" v-if="item.type == 'checkbox'" :disabled="disable">
            <el-checkbox v-for="opt in item?.options" :key="opt.label" :label="opt.label" style="width:auto"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div class="remark-item" v-if="item.remark">
          {{ item.remark ? '备注：' + item.remark : '' }}
        </div>
        <div class="remark-bottom"></div>
      </template>
    </el-form>
  </div>
</template>

<script>
import { uploadFile } from '@/api/files'
import { ElMessage } from 'element-plus'
import { checkImage } from '@/utils/utils'
import TinymceEditor from '@/components/TinymceEditor.vue'
export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'output'
    },
    disable: {
      type: Boolean,
      default: false
    }
  },
  components: {
    // TinymceEditor,
  },
  data () {
    return {
      form: [],
      form1: [],
      uploading: false,
      showUploadFileList: false,
      fileList: [],
      image: ''
    }
  },
  emits: ['update:list', 'update:dataList'],
  mounted () {
    // this.setPasteImg()
  },
  methods: {
    delImage (idx, imgIdx) {
      const data = this.dataList
      data[idx].value && data[idx].value.splice(imgIdx, 1)
      this.$emit('update:list', data)
    },
    pasteImage (event, idx) {
      if (event.clipboardData || event.originalEvent) {
        var clipboardData = (event.clipboardData || event.originalEvent.clipboardData)
        if (clipboardData.items) {
          var blob
          for (var i = 0; i < clipboardData.items.length; i++) {
            if (clipboardData.items[i].type.indexOf('image') !== -1) {
              blob = clipboardData.items[i].getAsFile()
            }
          }
          if (!blob) {
            this.image = ''
            return ElMessage({
              type: 'error',
              message: '请检查照片格式 文字无效'
            })
          }
          this.uploading = true
          try {
            var render = new FileReader()
            render.readAsDataURL(blob)
            render.onload = (evt) => {
              // 输出base64编码
              var base64 = evt.target.result
              const blob = this.dataURLtoFile(base64, 'image/png')
              var formData = new FormData()
              const fileOfBlob = new File([blob], new Date().getTime() + '.png')
              formData.append('file', fileOfBlob)
              uploadFile(formData).then(res => {
                this.uploading = false
                const data = this.dataList
                if (data[idx].value) {
                  data[idx].value.push({
                    name: res.name,
                    key: res.fileId,
                    url: res.url
                  })
                } else {
                  data[idx].value = []
                  data[idx].value.push({
                    name: res.name,
                    key: res.fileId,
                    url: res.url
                  })
                }

                this.$emit('update:list', data)
              })
            }
          } catch (e) {
            this.uploading = false
            ElMessage({
              type: 'error',
              message: '请检查照片格式 文字无效'
            })
          }
        }
      }
    },
    dataURLtoFile (dataURI, type) {
      const binary = atob(dataURI.split(',')[1])
      const array = []
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      return new Blob([new Uint8Array(array)], { type: type })
    },
    goFileDetail (url) {
      window.open(url, '_blank')
    },
    async validateAll () {
      return this.$refs.form.validate()
    },
    validatePass (rule, value, callback) {
      const val = this.form.test[rule.field].value
      if (rule.required && (!val || !val.length)) {
        return callback(new Error('请填写必填项'))
      }
      return callback()
    },
    checkImage (fileName) {
      return checkImage(fileName)
    },
    uploadFile (info, item, idx) {
      this.uploading = true
      const { file } = info
      const formData = new FormData()
      formData.append('file', file, file.name)
      return uploadFile(formData).then(
        (res) => {
          this.uploading = false
          const data = this.dataList
          data[idx].value.push({
            name: file.name,
            key: res.fileId,
            url: res.url
          })
          this.$emit('update:list', data)
        },
        (err) => {
          this.uploading = false
          ElMessage({
            type: 'error',
            message: `文件 ${file.name} 上传失败：${err.errmsg}`
          })
          console.error(err)
          throw Error(`文件 ${file.name} 上传失败：${err.errmsg}`)
        }
      )
    },
    handlePreview (val) {
      console.log(val)
    },
    handleRemove (val) {
      console.log(val)
    },
  },
  watch: {
    dataList: {
      handler (val) {
        const data = val?.map(i => {
          if (i?.type === 'date' && i.value instanceof Date) {
            i.value = i.value?.getTime() || null
          }
          if (i?.type === 'checkbox' || i?.type === 'file') {
            i.value = i.value || []
          }
          return i
        })
        // this.$emit('dataList', data)
        this.$emit('update:list', data)
        this.form = { test: val }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style  lang="less" scoped>
.image-box{
  position: relative;
  margin: 10px;
  margin-left: 0;
  .close-icon{
    position: absolute;
    top: -7px;
    left: 90px;
  }
}

.require {
  color: red;
  position: absolute;
  left: -100px;
  top: -10px;
}

.upload-file {
  .upload-btn {
    background: rgba(53, 73, 224, 0.1);
    border: 1px solid #4b79f3;
    border-radius: 3px !important;
    padding: 0 8px !important;
    color: #4b79f3;
    font-size: 14px;

    > ::v-deep(span) {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-right: 7px;
      }

      .icon-arrow {
        margin-left: 8px;
      }
    }
  }

  ::v-deep(.el-upload) {
    position: relative;
  }

  .file-list-box {
    z-index: 10000;
    position: absolute;
    top: 34px;
    left: 0;
    // width: 321px;
    background: #eaecfb;
    border: 1px solid #4b79f3;
    border-radius: 3px;
    padding: 0px 16px;
    color: #4b79f3;
    //overflow: scroll;
    font-weight: 400;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;

      ::v-deep(button) {
        padding: 0;
        min-height: auto;
        color: #4b79f3;
        font-weight: 400;
      }

      & {
        margin: 12px 0;
      }
    }
  }

  .attach-img-box {
    max-width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 10px;

    .img-box {
      flex-grow: 1;
      max-width: 32%;
      margin: 4px;
    }
  }
}

.remark-item {
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: #888;
  margin-bottom: 5px;
  line-height: 20px;
}
.remark-bottom{
  height: 20px;
}
.file-download{
  color: #4b79f3;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  span{
    max-width: 100%;
  }
  .file-name{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &:hover{
    cursor: pointer;
  }
}
.feishuFile{
    height: 34px;
    max-width: 100%;
    max-width: 600px;
    background: rgba(53, 73, 224, 0.1);
    border: 1px solid #4b79f3;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 400;
    color: #4b79f3;
    padding: 0 30px 0 16px;
    overflow: hidden;
    position:relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 10px;

    .icon-del {
      display: inline-block;
      width: 14px;
      height: 14px;
      position: absolute;
      top: 10px;
      right: 10px;
      background: url("../assets/images/delete-icon.svg") center center;
      border-radius: 50%;
      z-index: 100;
      cursor: pointer;
    }
    &:hover{
      cursor: pointer;
    }
  }
  .files-div{
    max-width: 100%;
    overflow: hidden;
  }
</style>
<style lang="less">
.output-List-com {
  .el-form-item {
    // margin-bottom: 15px !important;
  }
}
</style>
