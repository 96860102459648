import router, { routes, newRoutes } from '@/router'
import store from './store'

router.beforeEach((to, from, next) => {
  const user = store.getters['auth/me']
  const token = store.getters['auth/token']
  // 已登录用户
  if (user && user.ldap) {
    next()
  } else if (to.path.startsWith('/feishu/')) {
    if (token) {
      store.dispatch('auth/readme').then(() => {
        if (store.getters['auth/me'].thirdPartyUser) {
          newRoutes.forEach(v => router.addRoute(v))
        } else {
          routes.forEach(v => router.addRoute(v))
        }
        next({
          ...to,
          replace: true
        })
      }).catch(() => {
        store.dispatch('auth/redirectFeishuAuth', to.fullPath)
      })
    } else {
      store.dispatch('auth/redirectFeishuAuth', to.fullPath)
    }
  } else if (to.path === '/login' && to.query.code) {
    console.log('--> 2 to:', to)
    store.dispatch('auth/larkLogin', to.query.code).then(
      () => {
        if (store.getters['auth/me'].thirdPartyUser) {
          newRoutes.forEach(v => router.addRoute(v))
        } else {
          routes.forEach(v => router.addRoute(v))
        }
        if (to.query.state) {
          // next({
          //   path: to.query.state,
          // })
          next(to.query.state)
        } else {
          next({
            name: '首页',
          })
        }
      },
      (err) => {
        console.error(err)
        next({
          path: '/error-page/401',
        })
      },
    )
  } else {
    console.log('--> 3 to:', to)
    console.log(to, 'topath')
    store.dispatch('auth/readmeFromAuth').then(() => {
      store.dispatch('auth/readme').then(() => {
        if (store.getters['auth/me'].thirdPartyUser) {
          newRoutes.forEach(v => router.addRoute(v))
        } else {
          routes.forEach(v => router.addRoute(v))
        }
        const redirect = decodeURIComponent(from.query.redirect || to.fullPath)
        console.log(redirect, 'redirect')
        if (to.fullPath === redirect) {
          next({
            ...to,
            replace: true
          })
        } else {
          next({
            fullPath: redirect
          })
        }
      }, () => {
        store.dispatch('auth/redirectAuth', to.fullPath)
      })
    }).catch((err) => {
      console.error(err)
      store.dispatch('auth/redirectAuth', to.fullPath)
    })
  }
})
