<template>
    <div class="chart-item" style="display: flex; overflow: hidden;" v-loading="loading">
        <div class="bar-item1" style="width:50%; margin-right: 16px; overflow: hidden;">
            <div class="item-title1"
              style="height: 47px; background: #fff;margin-bottom: 1px;padding: 12px;">
                <p style=" margin-left: 16px;margin-right:16px">工单处理量</p>
            </div>
        <div style="overflow-x: auto;overflow-y: hidden">
          <el-scrollbar>
          <div id="bar2-chart1"
          style="background: #fff;margin-right: 16px;">
        </div>
        </el-scrollbar>
        </div>
    </div>
    <div class="bar-item2" style="width:50%; overflow: hidden;">
        <div class="item-title2"
          style="height: 47px; background: #fff;margin-bottom: 1px;padding: 12px;">
            <p style=" margin-left: 16px;margin-right:16px">工单流转耗时</p>
        </div>
        <div style="overflow-x: auto;overflow-y: hidden">
          <el-scrollbar>
          <div id="bar2-chart2"
          style="background: #fff;margin-right: 16px;">
        </div>
        </el-scrollbar>
        </div>
    </div>
    </div>
</template>
<script>
import * as echarts from 'echarts'
import moment from 'moment'
import { dashboard } from '@/api/dashboard'

export default {
  name: 'DashboardBar2',
  props: {
    obj2: Object
  },
  data () {
    return {
      extre: '',
      newObj: null,
      pieStartTime: '',
      pieEndTime: '',
      loading: false
    }
  },
  methods: {
    async barChart1 () {
      if (typeof this.newObj.extreme === 'string') {
        if (this.newObj.extreme.charAt(this.newObj.extreme.length - 1) === '%') {
          this.extre = this.newObj.extreme.substring(0, this.newObj.extreme.length - 1)
        }
      } else {
        this.extre = this.newObj.extreme
      }
      let data
      try {
        data = await dashboard({
          exportData: 0,
          ticketType: this.newObj.alarmValue,
          extremum: this.extre,
          startDate: this.pieStartTime,
          endDate: this.pieEndTime,
          channel: this.newObj.channel,
          businessLine: this.newObj.product,
          level: this.newObj.level,
          negationExtremum: this.newObj.negationExtremum,
          // otherTags: this.newObj.otherTags,
          searchType: 4,
        })
      } catch (e) {
        console.error(e)
        return
      }
      data.legend.unshift('product')
      const newData = data.data.map(item => {
        const newCounts = item.counts.map(count => {
          return count.toFixed(0)
        })
        return [item.date, ...newCounts]
      })
      var chartDom = document.getElementById('bar2-chart1')
      if (!chartDom) return
      var myChart = echarts.getInstanceByDom(chartDom)
      if (myChart) {
        myChart.dispose()
      }
      myChart = echarts.init(chartDom)
      var option
      option = {
        legend: {
          top: '10px',
          left: '16px',
          itemWidth: 16,
          itemHeight: 10,
        },
        color: ['#628AF4', '#55D3D3', '#2246A6', '#8543E0', '#1890FF'],
        tooltip: {
          formatter: function (params) {
            const legend = params.dimensionNames
            const color = ['#628AF4', '#55D3D3', '#2246A6', '#8543E0', '#1890FF']
            let str = ''
            for (let i = 1; i < legend.length; i++) {
              str += `<div style="width:8px;height:8px;background:${color[i - 1]};border-radius:50%;display: inline-block;margin-right:9px"></div>` + legend[i] + ' ' + ':' + ' ' + params.value[i] + ' ' + '条' + '</br>'
            }
            str = params.value[0] + '</br>' + str
            return str
          }
        },
        // dataZoom: [
        //   {
        //     show: true,
        //     start: 0,
        //     end: 100
        //   },
        // ],
        dataset: {
          source: [
            data.legend,
            ...newData,
          ]
        },
        grid: {
          left: '5%',
          right: '3%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          axisLabel: {
            formatter: '{value}'
          }
        },
        series: [
          {
            type: 'bar',
            barMinWidth: '5',
            barMaxWidth: '28',
            // itemStyle: {
            //   normal: {
            //     label: {
            //       show: true,
            //       position: 'top',
            //       formatter: function (params) {
            //         if (params.value[1] && params.value[1] * 1 > 0) {
            //           return params.value[1] * 1
            //         } else {
            //           return ''
            //         }
            //       },
            //       textStyle: {
            //         fontSize: 14
            //       },
            //     },
            //   }
            // },
            // itemStyle: {
            //   label: {
            //     normal: {
            //       show: true,
            //       position: 'top',
            //       formatter: '{c}'
            //     }
            //   }
            // }
            // itemStyle: {
            //   normal: {
            //     color: function (params) {
            //       var colorList = ['#628AF4', '#628AF4', '#628AF4', '#628AF4', '#628AF4', '#628AF4', '#628AF4']
            //       return colorList[params.dataIndex]
            //     }
            //   }
            // }
          }, {
            type: 'bar',
            barMinWidth: '5',
            barMaxWidth: '28',
            // itemStyle: {
            //   normal: {
            //     label: {
            //       show: true,
            //       position: 'top',
            //       formatter: function (params) {
            //         if (params.value[2] && params.value[2] * 1 > 0) {
            //           return params.value[2] * 1
            //         } else {
            //           return ''
            //         }
            //       },
            //       textStyle: {
            //         fontSize: 14
            //       },
            //     },
            //   }
            // },
          }, {
            type: 'bar',
            barMinWidth: '5',
            barMaxWidth: '28',
            // itemStyle: {
            //   normal: {
            //     label: {
            //       show: true,
            //       position: 'top',
            //       formatter: function (params) {
            //         if (params.value[3] && params.value[3] * 1 > 0) {
            //           return params.value[3] * 1
            //         } else {
            //           return ''
            //         }
            //       },
            //       textStyle: {
            //         fontSize: 14
            //       },
            //     },
            //   }
            // },
          }, {
            type: 'bar',
            barMinWidth: '5',
            barMaxWidth: '28',
            // itemStyle: {
            //   normal: {
            //     label: {
            //       show: true,
            //       position: 'top',
            //       formatter: function (params) {
            //         if (params.value[4] && params.value[4] * 1 > 0) {
            //           return params.value[4] * 1
            //         } else {
            //           return ''
            //         }
            //       },
            //       textStyle: {
            //         fontSize: 14
            //       },
            //     },
            //   }
            // },
          }, {
            type: 'bar',
            barMinWidth: '5',
            barMaxWidth: '28',
            // itemStyle: {
            //   normal: {
            //     label: {
            //       show: true,
            //       position: 'top',
            //       formatter: function (params) {
            //         if (params.value[5] && params.value[5] * 1 > 0) {
            //           return params.value[5] * 1
            //         } else {
            //           return ''
            //         }
            //       },
            //       textStyle: {
            //         fontSize: 14
            //       },
            //     },
            //   }
            // },
          }]
      }
      option && myChart.setOption(option)
      window.addEventListener('resize', function () {
        if (!myChart?.isDisposed()) {
          myChart.resize()
        }
      })
    },
    async barChart2 () {
      if (typeof this.newObj.extreme === 'string') {
        if (this.newObj.extreme.charAt(this.newObj.extreme.length - 1) === '%') {
          this.extre = this.newObj.extreme.substring(0, this.newObj.extreme.length - 1)
        }
      } else {
        this.extre = this.newObj.extreme
      }
      let data
      try {
        data = await dashboard({
          exportData: 0,
          ticketType: this.newObj.alarmValue,
          extremum: this.extre,
          startDate: this.pieStartTime,
          endDate: this.pieEndTime,
          channel: this.newObj.channel,
          businessLine: this.newObj.product,
          level: this.newObj.level,
          negationExtremum: this.newObj.negationExtremum,
          // otherTags: this.newObj.otherTags,
          searchType: 5,
        })
        // console.log('databar2', data)
      } catch (e) {
        console.error(e)
        return
      }
      data.legend.unshift('product')
      const newData = data.data.map(item => {
        const newCounts = item.counts.map(count => {
          count = count / (60 * 60)
          return count.toFixed(2)
        })
        return [item.date, ...newCounts]
      })
      var chartDom = document.getElementById('bar2-chart2')
      if (!chartDom) return
      var myChart = echarts.getInstanceByDom(chartDom)
      if (myChart) {
        myChart.dispose()
      }
      myChart = echarts.init(chartDom)
      var option
      option = {
        legend: {
          top: '10px',
          left: '16px',
          itemWidth: 16,
          itemHeight: 10,
        },
        color: ['#628AF4', '#55D3D3', '#2246A6', '#8543E0', '#1890FF'],
        tooltip: {
          formatter: function (params) {
            const legend = params.dimensionNames
            const color = ['#628AF4', '#55D3D3', '#2246A6', '#8543E0', '#1890FF']
            let str = ''
            for (let i = 1; i < legend.length; i++) {
              str += `<div style="width:8px;height:8px;background:${color[i - 1]};border-radius:50%;display: inline-block;margin-right:9px"></div>` + legend[i] + ' ' + ':' + ' ' + params.value[i] + ' ' + 'h' + '</br>'
            }
            str = params.value[0] + '</br>' + str
            return str
          }
        },
        dataset: {
          source: [
            data.legend,
            ...newData,
            // ['ccc', 10, 20, 22, 24, 18]
          ]
        },
        xAxis: { type: 'category' },
        yAxis: {
          axisLabel: {
            formatter: '{value} h',
          }
        },
        // dataZoom: [
        //   {
        //     show: true,
        //     start: 0,
        //     end: 100,
        //   },
        // ],
        grid: {
          left: '5%',
          right: '3%',
          bottom: '10%',
          containLabel: true
        },
        series: [
          {
            type: 'bar',
            barMinWidth: '5',
            barMaxWidth: '28',
            // itemStyle: {
            //   normal: {
            //     label: {
            //       show: true,
            //       position: 'top',
            //       formatter: function (params) {
            //         if (params.value[1] && params.value[1] * 1 > 0) {
            //           return params.value[1] * 1
            //         } else {
            //           return ''
            //         }
            //       },
            //       textStyle: {
            //         fontSize: 14
            //       },
            //     },
            //   }
            // },
          }, {
            type: 'bar',
            barMinWidth: '5',
            barMaxWidth: '28',
            // itemStyle: {
            //   normal: {
            //     label: {
            //       show: true,
            //       position: 'top',
            //       formatter: function (params) {
            //         if (params.value[2] && params.value[2] * 1 > 0) {
            //           return params.value[2] * 1
            //         } else {
            //           return ''
            //         }
            //       },
            //       textStyle: {
            //         fontSize: 14
            //       },
            //     },
            //   }
            // },
          }, {
            type: 'bar',
            barMinWidth: '5',
            barMaxWidth: '28',
            // itemStyle: {
            //   normal: {
            //     label: {
            //       show: true,
            //       position: 'top',
            //       formatter: function (params) {
            //         if (params.value[3] && params.value[3] * 1 > 0) {
            //           return params.value[3] * 1
            //         } else {
            //           return ''
            //         }
            //       },
            //       textStyle: {
            //         fontSize: 14
            //       },
            //     },
            //   }
            // },
          }, {
            type: 'bar',
            barMinWidth: '5',
            barMaxWidth: '28',
            // itemStyle: {
            //   normal: {
            //     label: {
            //       show: true,
            //       position: 'top',
            //       formatter: function (params) {
            //         if (params.value[4] && params.value[4] * 1 > 0) {
            //           return params.value[4] * 1
            //         } else {
            //           return ''
            //         }
            //       },
            //       textStyle: {
            //         fontSize: 14
            //       },
            //     },
            //   }
            // },
          }, {
            type: 'bar',
            barMinWidth: '5',
            barMaxWidth: '28',
            // itemStyle: {
            //   normal: {
            //     label: {
            //       show: true,
            //       position: 'top',
            //       formatter: function (params) {
            //         if (params.value[5] && params.value[5] * 1 > 0) {
            //           return params.value[5] * 1
            //         } else {
            //           return ''
            //         }
            //       },
            //       textStyle: {
            //         fontSize: 14
            //       },
            //     },
            //   }
            // },
          }]
      }
      option && myChart.setOption(option)
      window.addEventListener('resize', function () {
        if (!myChart?.isDisposed()) {
          myChart.resize()
        }
      })
    },
    pieStartEndTime () {
      [this.pieStartTime, this.pieEndTime] = this.getStartEndDate(this.newObj.dateRange)
    },
    getStartEndDate (dateRange1, type = '') {
      if (dateRange1.length === 0) {
        return []
      }
      const [date1, date2] = dateRange1
      if (type === 'month') {
        date1.setDate(1)
        date2.setDate(1)
        date2.setMonth(date2.getMonth() + 1)
        date2.setDate(date2.getDate() - 1)
      } else if (type === 'week') {
      // 注意：此处以周一为每周起始
        if (date1.getDay() > 1) {
          date1.setDate(date1.getDate() - (date1.getDay() - 1)) // 设置成周一
        } else if (date1.getDay() === 0) {
          date1.setDate(date1.getDate() - (7 - 1)) // 设置成周一
        }
        if (date2.getDay() !== 0) {
          date2.setDate(date2.getDate() + (7 - date2.getDay())) // 设置成周日
        }
      } else {
      // nothing to do
      }
      const f = 'YYYY-MM-DD'
      return [moment(date1).format(f), moment(date2).format(f)]
    },
  },
  mounted () {
    this.loading = true
    this.$watch(function () {
      return this.obj2
    }, async function (newV, oldV) {
      this.newObj = newV
      await this.pieStartEndTime()
      await this.barChart1()
      await this.barChart2()
      this.loading = false
    }, {
      deep: true
    })
  }
}
</script>
<style lang="less" scoped>
.chart-item {
  width: 100%;
      height: 400px;
      #bar2-chart1 {
          min-width: 1000px;
          height:350px
      }
      #bar2-chart2 {
          min-width: 1000px;
          height:350px
      }
    }
</style>
