const requireFieldList = require.context('.', true, /\.vue$/)
const componentModules = {}
export const generateFuncModules = {}
export const postTreatmentModules = {}

requireFieldList.keys().forEach((fileName) => {
  const path = fileName.replace(/(\.\/|\.vue)/g, '')
  componentModules[path] = requireFieldList(fileName).default
  generateFuncModules[path] = requireFieldList(fileName).generateData
  if (requireFieldList(fileName).postTreatment) {
    postTreatmentModules[path] = requireFieldList(fileName).postTreatment
  }
})
export default componentModules
