
import { Options, Vue } from 'vue-class-component'
import { agentsAll } from '@/api/agent'

@Options({
  name: 'PersonSelect',
  props: {
    cancel: Function,
    submit: Function,
    selectType: {
      type: String,
      default: 'radio'
    },
    addPerson: {
      type: Array,
      default: () => []
    },
  }
})
export default class PersonSelect extends Vue {
  searchInput = ''
  personIdSelected = ''
  checkboxSelect = []
  person: Array<any> = [
  ]

  addPerson = []

  get agentSelected () {
    return this.person.find(p => p.id === this.personIdSelected)
  }

  get agentsSelected () {
    return this.person.filter(p => this.checkboxSelect?.findIndex((i:any) => p.id === i) !== -1)
  }

  get agents () {
    const key = this.searchInput.trim()
    if (key.length === 0) {
      return this.person
    }
    const a: Array<any> = []
    for (const p of this.person) {
      if (p.name.includes(key)) {
        a.push(p)
      }
    }
    return a
  }

  mounted () {
    this.loadAgentsAll()
  }

  async loadAgentsAll () {
    let res:any = await agentsAll()
    if (this.addPerson && this.addPerson.length > 0) {
      this.person.push(...this.addPerson)
    }
    this.person.push(...res.results)
    while (res.next) {
      res = await agentsAll(res.page + 1)
      this.person.push(...res.results)
    }
  }
}
