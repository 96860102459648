export function getToken () {
  let resolveFunc
  let rejectFunc
  const promiseObj = new Promise((resolve, reject) => {
    resolveFunc = resolve
    rejectFunc = reject
  })
  const timer = setTimeout(() => {
    rejectFunc(new Error('超时'))
  }, 3000)
  const iframe = document.createElement('iframe')
  iframe.src = process.env.VUE_APP_AUTH_REDIRECT_URL
  const urlObj = new URL(process.env.VUE_APP_AUTH_REDIRECT_URL)
  const handler = (e) => {
    if (e.origin !== urlObj.origin) {
      return
    }
    const token = e.data
    if (token) {
      localStorage.setItem('token', token)
      resolveFunc(token)
    } else {
      rejectFunc(new Error('token为空'))
    }
    window.removeEventListener('message', handler)
    document.body.removeChild(iframe)
    clearTimeout(timer)
  }

  if (iframe.attachEvent) {
    iframe.attachEvent('onload', function () {
      window.addEventListener('message', handler)
      window.frames[0].postMessage('getToken', urlObj.origin)
    })
  } else {
    iframe.onload = function () {
      window.addEventListener('message', handler)
      window.frames[0].postMessage('getToken', urlObj.origin)
    }
  }

  document.body.appendChild(iframe)
  return promiseObj
}

export async function getTokenSync () {
  const res = await getToken().then(token => token)
  return res
}
