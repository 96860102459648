<template>
  <div id="task-create" class="task-create" style="height:100%;">
    <el-form :rules="rules" ref="formRef" :model="form" label-width="130px">
      <el-form-item label="问题标题：" prop="title" style="margin-bottom: 10px">
        <el-input
          maxlength="100"
          clearable
          resize="none"
          placeholder=""
          v-model="form.title">
        </el-input>
      </el-form-item>
      <el-form-item label="问题描述：" prop="desc" style="margin-bottom: 10px">
        <el-input
          type="textarea"
          resize="none"
          placeholder=""
          v-model="form.desc">
        </el-input>
      </el-form-item>
      <el-form-item label="紧急程度：" prop="urgent">
        <el-select class="urgent-select" v-model="form.urgent" placeholder="请选择问题紧急程度" style="width:100%;">
          <el-option
            v-for="urgent in urgentList" :key="urgent.name"
            :label="urgent.name"
            :value="urgent.name">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属工单类型：" prop="problemType">
        <el-select class="urgent-select" v-model="form.problemType" placeholder="所属工单类型" style="width:100%;">
          <el-option
            v-for="type in problemTypeList"
            :key="type.value"
            :label="type.label"
            :value="type.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="问题订阅：">
        <el-radio v-model="form.subscribe" label="1">订阅</el-radio>
        <el-radio v-model="form.subscribe" label="0">不订阅</el-radio>
      </el-form-item>
      <el-form-item label="问题来源及渠道：" class="channel-select">
        <el-cascader
          v-model="form.channel"
          style="width: 100%;"
          popper-class="cascader-feishu-select"
          separator=" | "
          filterable
          :options="channelOptions"
          :props="{ expandTrigger: 'hover' }">
        </el-cascader>
      </el-form-item>
      <el-form-item label="创建人：" prop="questionSource">
        <div>{{ form.questionSource }}</div>
      </el-form-item>
      <el-form-item class="buttons" label-width="0">
        <el-button @click="onCancel">取消</el-button>
        <el-button class="submit" type="primary" @click="onSubmit('formRef')">确认</el-button>
      </el-form-item>
    </el-form>
  </div>

  <el-dialog
    title="提示"
    v-model="dialogVisible"
    width="100%"
  >
    <span>{{msg}}</span>
    <template #footer>
    <span class="dialog-footer">
<!--      <el-button @click="dialogVisible = false">取 消</el-button>-->
      <el-button type="primary" @click="closeMsg">确 定</el-button>
    </span>
    </template>
  </el-dialog>
</template>

<script>
import { closeWindow, h5sdkConfig } from '@/larkH5SDK'
import moment from 'moment'
import { addProblem, ProblemSource } from '@/api/problem'
import store from '../../store'
import { urgentLevels } from '../../api/label'

export default {
  name: 'createQuickly',
  data () {
    return {
      form: {
        title: '',
        desc: '',
        urgent: 'NORMAL',
        questionSource: '',
        problemType: 'task',
        subscribe: '1',
        channel: [-1, 1],
      },
      rules: {
        title: [
          { required: true, message: '请输入问题标题', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入问题描述', trigger: 'blur' }
        ],
        urgent: [
          { required: true, message: '请选择问题紧急程度', trigger: 'blur' }
        ],
        problemType: [
          { required: true, message: '请选择所属工单类型', trigger: 'blur' }
        ],
      },
      dialogVisible: false,
      msg: '',
      urgentList: [],
      problemTypeList: [
        {
          value: 'task',
          label: '任务'
        },
        {
          value: 'warning',
          label: '告警'
        },
        {
          value: 'after_sale',
          label: '售后'
        },
        {
          value: 'test',
          label: '测试'
        },
      ],
      channelOptions: ProblemSource,
    }
  },
  methods: {
    alertMessage (msg) {
      this.msg = msg
      this.dialogVisible = true
    },
    closeMsg () {
      // console.log('handleClose...')
      this.dialogVisible = false
      closeWindow()
    },
    onSubmit (form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.createQuestion()
          // closeWindow()
        } else {
          console.error('validate fail')
          return false
        }
      })
    },
    onCancel () {
      closeWindow()
    },
    reminder (dateStr) {
      return moment(dateStr).add(1, 'd').subtract(1, 'seconds').unix()
    },
    createQuestion () {
      const postData = {
        problemTitle: this.form.title,
        problemDescribe: this.form.desc,
        problemOrigin: this.form.questionSource,
        urgentLevel: this.form.urgent,
        problemType: this.form.problemType,
        subscribe: Number(this.form.subscribe),
        channel: this.form.channel[1],
      }
      const loading = this.$loading({
        lock: true,
        text: '问题创建中...',
        spinner: 'el-icon-loading',
        // background: 'rgba(0, 0, 0, 0.7)'
      })
      addProblem(postData).then(() => {
        loading.close()
        this.alertMessage('创建问题成功')
      }, (err) => {
        this.alertMessage('创建问题失败：' + err)
        console.error(err)
        loading.close()
      })
    },
    getTextFromPostContent (post) {
      let text = ''
      if (post.title) {
        text += post.title + '。'
      }
      if (post.content) {
        for (const attrsObj of post.content) {
          for (const o of attrsObj.attrs) {
            if (o.tag === 'text') {
              text += o.text
            } else if (o.tag === 'a') {
              text += o.text + ' ' + o.href
            } else if (o.tag === 'at') {
              text += `<at user_id="${o.open_id}">${o.text}</at>`
            }
          }
        }
      }
      return text
    },
    getMessageContent () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this
      return new Promise((resolve, reject) => {
        // 从页面 URL 中获取 triggerCode
        // console.log('location.search:' + location.search)
        let launchQuery = new URLSearchParams(location.search).get('bdp_launch_query')
        if (!launchQuery) {
          console.log('bdp_launch_query not found in URL')
          return
        }
        // console.log('launchQuery:' + launchQuery)
        launchQuery = JSON.parse(launchQuery)
        const triggerCode = launchQuery.__trigger_id__
        // 调用方法，传入 triggerCode，获取消息内容
        window.h5sdk.ready(() => {
          window.tt.getBlockActionSourceDetail({
            triggerCode: triggerCode,
            success (res) {
              // console.log('getBlockActionSourceDetail success:' + JSON.stringify(res))
              const messages = res.content.messages
              const messageType = messages[0].messageType
              if (messageType === 'text') {
                const content = JSON.parse(messages[0].content)
                resolve(content.text)
              } else if (messageType === 'interactive') {
                const content = JSON.parse(messages[0].content)
                if (content.elements) {
                  const texts = []
                  for (const es of content.elements) {
                    for (const e of es) {
                      if (e.tag === 'text') {
                        texts.push(e.text)
                      } else if (e.tag === 'a') {
                        texts.push(e.text)
                        texts.push(e.href)
                      }
                    }
                  }
                  resolve(texts.join(' '))
                }
              } else if (messageType === 'post') {
                const content = JSON.parse(messages[0].content)
                const text = that.getTextFromPostContent(content)
                resolve(text)
              } else {
                console.log('不支持的消息类型：' + messageType)
                // this.dialogVisible = true
                that.alertMessage('暂时只支持文本类型消息')
              }
            },
            fail (res) {
              that.alertMessage('暂时只支持文本类型')
              // console.log('fail', JSON.stringify(res))
              reject(res)
            }
          })
        })
      })
    },
    clearContent (content) {
      return content.replaceAll(/<at.*?>(.*?)<\/at>/g, '$1').replace(/<p>(.*?)<\/p>/g, '$1')
    },
  },
  mounted () {
    // console.log('version: 2021年07月21日10:06:34')
    // console.log('url:', window.location.href)
    const user = store.getters['auth/me']
    // console.log('user:', user)
    this.form.questionSource = `${user?.group?.[0]?.groupName} - ${user?.username}`
    const loading = this.$loading({
      lock: true,
      text: '加载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.1)'
    })
    // 解析消息内容
    h5sdkConfig().then(() => {
      this.getMessageContent().then((content) => {
        loading.close()
        const c = this.clearContent(content)
        // this.form.title = c.slice(0, 100)
        this.form.desc = c
      }, (reason) => {
        console.error('getMessageContent error:', reason)
        loading.close()
      })
    }, (reason) => {
      console.error('H5SDK not ready, reason: ', reason)
      loading.close()
    })
    urgentLevels().then(res => {
      this.urgentList = res
    }).catch(res => {
      console.error(res)
    })
  }
}
</script>
<style lang="less" scoped>
#task-create {
  flex-grow: 1;
  background: #f4f4f4;

  ::v-deep(textarea), ::v-deep(input) {
    color: #2B3037;
  }
  ::v-deep(input) {
    // color: #999999;
  }
  ::v-deep(textarea) {
    height: 130px;
  }
  ::v-deep(label) {
    color: #2b3037;
  }
  ::v-deep {
    .channel-select .el-form-item__label {
      line-height: 40px !important;
    }
  }
  form {
    height: 100%;

    .buttons {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 8px;
      display: flex;
      justify-content: center;
      button {
        box-sizing: border-box;
        width: 84px;
        height: 34px;
        opacity: 1;
        border: 1px solid #d8d8d8;
        border-radius: 3px;
        color: #909399;
        padding: 0;
        &.submit {
          color: #ffffff;
          margin-left: 16px;
        }
      }
    }
    .el-form-item:not(.buttons) {
      background-color: #FFFFFF;
      padding: 12px;
    }

  }
}
</style>
<style lang="less">
#app {
  min-width: auto;
}
.el-cascader__dropdown.cascader-feishu-select {
  .el-cascader-menu {
    min-width: auto;
  }
  .el-cascader-menu__wrap {
    height: 190px;
  }
}
</style>
