
import { Options, Vue } from 'vue-class-component'
import * as ticketApi from '@/api/ticket'
import { deepCopy, checkImage } from '@/utils/utils'
import TemplateGenerator from '@/components/TemplateMaking/TemplateGenerator.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import TicketAddTag from '@/components/TicketAddTag.vue'
import * as label from '@/api/label'
import * as agent from '@/api/agent'
import { uploadFile } from '@/api/files'
import { ElMessage, ElLoading, ElMessageBox } from 'element-plus'
import { TicketStatus, putMilestoneTime, getTicketFlowDetail } from '@/api/ticket'
import { postJira, getJiraProjectAndErrorEnv, agentsForJira, ticketRelationJira } from '@/api/problem'
import store from '@/store'
import TinymceEditor from '@/components/TinymceEditor.vue'
import TicketTemplateChange from '@/components/TicketTemplateChange.vue'
import checkStandDialog from '@/components/dialog/checkStandDialog.vue'
import { selfTags } from '@/api/label'
import PersonSelect from '@/components/PersonSelect.vue'
import WorkflowTaskAppiontDrawer from '@/components/dialog/WorkflowTaskAppiontDrawer.vue'
import { getWorkflowAppointList, updateWorkflowAgent } from '@/api/agent'
import OutputListCom from '@/components/OutputListCom.vue'
import CustomViewer from '@/api/custom-viewer'

@Options({
  name: 'TicketDetail',
  components: {
    Breadcrumb,
    TicketAddTag,
    TemplateGenerator,
    TinymceEditor,
    TicketTemplateChange,
    PersonSelect,
    checkStandDialog,
    WorkflowTaskAppiontDrawer,
    OutputListCom,
  }
})
export default class TicketDetail extends Vue {
  replyShowType = null
  activeAppointType = null
  activeManager = ''
  showManagePersonalVisible = false
  replyDisable = false
  outputParams:Array<any> = []
  inputData:Array<any> = []
  outputData:Array<any> = []
  activeFeishuFile = ''
  addFeishuFileVisible = false
  me = store.getters['auth/me']
  showUploadFileList = false
  showTicketAddTag = false
  processLabels:Array<any> = []
  handlers :Array<any> = []
  userName = ''
  contJiraType = ''
  ticket :Record<string, any> = {}
  currentTemplateInfo :Record<string, any> = {}
  template = {}
  transactions = []
  templateUsedRecord = []
  templateValue = {}
  uploading = false
  detialShowTickets = false
  relationJiraBtn = false
  showChangeTemplate = false
  problemId = null
  fileList: Array<any> = []
  selfTagList: Array<string> = []
  extraTags: Array<any> = []
  jiraProject = null
  jiraProjectList = []
  errorEnvList = []
  errorEnv = null
  jiraHandlers :Array<any> = []
  handlerJira = null
  workflowId = null
  workflowName = null
  workflowDiagram = ''
  workflowDiagramList :Array<any> = []
  workflowXml = null
  workflowInstanceId = null
  previewDialogVisible = false
  milestoneList :Array<any> = []
  claimablePhases: Array<any> = []
  processablePhases: Array<any> = []
  projectBasieInfo: any = {}
  showPhaseSelectDialog = false
  selectedPhase = ''
  ruleForm = {
    nextProcess: null,
    nextHandler: null,
    newReply: '',
  }

  showCurBox = true
  loadedWorkflowMap = false

  planTime = {
    start: null,
    end: null
  }

  nodeRemark = ''

  transferPersonVisible = false
  taskVariables :Record<string, any> = {}
  taskVariablesOptions :any = [{ value: true, name: '通过' }]

  ticketAdmin = false
  phaseId = null
  taskAppointVisible = false
  taskAppointTable = []

  bpmnViewer = null
  container = null

  changeAppointList () {
    updateWorkflowAgent({
      ticketId: Number(this.$route.params.id),
      taskAssign: this.taskAppointTable,
    }).then(res => {
      ElMessage({
        message: '修改工作流任务设定成功',
        type: 'success'
      })
    }).catch(e => {
      ElMessage({
        message: '修改工作流任务设定失败' + e.message,
        type: 'error'
      })
    })
  }

  handlePhaseChange (phaseId) {
    this.taskVariables = this.processablePhases.find(v => v.phaseId === phaseId)?.taskVariables || {}
    const obj = this.ticket.fields.curPhases.find(i => i.phaseId === phaseId)
    this.taskVariablesOptions = obj ? Object.values(obj?.taskVariables)[0] : null
  }

  addFeishuConfirmFn () {
    this.fileList.push({
      name: '飞书文档:' + this.activeFeishuFile,
      type: 'url',
      key: 'feishu',
      url: this.activeFeishuFile
    })
    this.addFeishuFileVisible = false
  }

  async upMilestoneTime (val:any) {
    const id = Number(this.$route.params.id)
    const projectTime :any = {
      start: this.planTime?.start ? Math.floor((new Date(this.planTime?.start || new Date()).getTime() / 1000)) : null,
      end: this.planTime.end ? Math.floor((new Date(this.planTime?.end || new Date()).getTime() / 1000)) : null,
    }
    const obj = this.milestoneList?.map(i => ({ ...i, time: i.time ? Math.floor((new Date(i.time).getTime()) / 1000) : null }))
    try {
      await putMilestoneTime(id, { wfPlan: projectTime, wfMilestone: obj })
      ElMessage({
        message: '更新时间成功',
        type: 'success'
      })
    } catch (e:any) {
      ElMessage({
        message: '更改时间失败' + e.errmsg,
        type: 'error'
      })
    }
  }

  async updateManager (val:any) {
    const id = Number(this.$route.params.id)
    try {
      await putMilestoneTime(id, val)
      this.projectBasieInfo = Object.assign(this.projectBasieInfo, val)
      ElMessage({
        message: '更新项目经理成功',
        type: 'success'
      })
    } catch (e:any) {
      ElMessage({
        message: '更改时间失败' + e.errmsg,
        type: 'error'
      })
    }
  }

  getFlowDetail (val, type = null) {
    this.showCurBox = true
    // 加入数组 如果在当前的列表里面找的了这个phaseId 就将selectedPhase换成选择的那个 通过选项更换为那个
    if (this.ticket.fields.processablePhases.find(i => i.phaseId === val.phaseId)) {
      // 如果是通过查看完成进来的 并且点击的是当前节点 并且之前没有点击过 就把信息push进入tab数组
      if (type === 'add' &&
      this.ticket.fields.curPhases.findIndex(i => i.phaseId === val.phaseId) !== -1 &&
      this.processablePhases.findIndex(i => i?.wfHistoryId === val?.wfHistoryId) === -1) {
        this.processablePhases.push({
          phaseId: val?.phaseId,
          phaseName: val?.record?.notesComponents?.phaseName,
          wfHistoryId: val?.wfHistoryId
        })
      }
      const obj = this.ticket.fields.curPhases.find(i => i.phaseId === val.phaseId)
      this.selectedPhase = val?.phaseId
      // 更换通过里面的选项
      this.taskVariables = this.processablePhases.find(v => v.phaseId === val?.phaseId)?.taskVariables || {}
      this.taskVariablesOptions = obj ? Object.values(obj?.taskVariables)[0] : null
    } else {
      this.processablePhases.push({
        phaseId: val?.phaseId,
        phaseName: val?.record?.notesComponents?.phaseName,
        wfHistoryId: val?.wfHistoryId
      })
      this.selectedPhase = val?.phaseId
    }

    // 是否当前回复
    this.replyDisable = !val.curStatus
    if (this.replyDisable) {
      const reply:any = this.transactions?.filter((i:any) => i.transactionType === 'handle' || i.transactionType === 'finish')
      this.ruleForm.newReply = reply?.find((i:any) => i.id === val?.id)?.record?.comment || reply?.find((i:any) => i.wfHistoryId === val?.wfHistoryId)?.record?.comment
    } else {
      // 如果在是当前执行 我们直接将 output input 取出
      const obj = this.ticket.fields.curPhases.find(i => i.phaseId === val.phaseId)
      this.ruleForm.newReply = ''
      this.outputData = obj?.formInfo?.outFields
      this.inputData = obj?.formInfo?.inFields
      this.nodeRemark = obj?.formInfo?.remark || ''
      return
    }

    // 获取输入输出
    getTicketFlowDetail({
      ticketId: Number(this.$route.params.id),
      phaseId: val?.phaseId,
      wfHistoryId: val?.wfHistoryId
    }).then((res:any) => {
      this.outputData = res?.outFields
      this.inputData = res?.inFields
      this.nodeRemark = res?.remark || ''
    })
  }

  closeTab (val, idx, isCurrent) {
    this.processablePhases.splice(idx, 1)
    if (this.processablePhases.length) {
    // 获取输入输出
      this.getFlowDetail(this.processablePhases[0])
    }
  }

  nextProcessValidator = (rule, value, callback) => {
    console.log('nextProcessValidator')
    console.log('this.buttonClicked：', this.buttonClicked)
    if (this.buttonClicked === 'continue' && !this.workflowId) {
      if (!value) {
        callback(new Error('请选择下一阶段'))
      } else {
        callback()
      }
    } else {
      callback()
    }
  }

  rules = {
    nextProcess: [
      {
        validator: this.nextProcessValidator,
        trigger: 'blur'
      }
    ],
    newReply: [
      {
        // required: true,
        message: '请填写问题回复',
        trigger: 'blur'
      },
      {
        min: 1,
        message: '请填写问题回复',
        trigger: 'blur'
      },
    ],
  }

  buttonClicked = ''

  get isFinish () {
    if (this.ticket.fields?.curPhaseId) {
      return this.ticket.fields?.curStatus === 'closed'
    }
    return false
  }

  get isClaimable () {
    if (this.ticket.fields?.curPhaseId) {
      return this.ticket.fields?.curStatus === 'unassigned' && this.selfTagList?.includes(this.getLevel())
    }
    return this.processablePhases?.length <= 0 && this.claimablePhases.length > 0
  }

  get isHandleable () {
    if (this.ticket.fields?.curPhaseId) {
      return this.ticket.fields?.curStatus !== 'closed' && this.ticket.fields?.responsibleAgent === this.me.ldap
    }
    return this.processablePhases?.length > 0
  }

  get isEditable () {
    return this.ticket.fields?.curStatus === 'unassigned' && this.ticket.fields?.creator === this.me.ldap
  }

  get isDirectClosable () {
    return this.ticket.fields?.curStatus !== 'closed' && this.ticket.fields?.creator === this.me.ldap
  }

  get ticketDesc () {
    if (this.transactions.length > 0) {
      return this.transactions[0]
    }
    return null
  }

  get jiraButtonDiasable () {
    return (!this.jiraProject || !this.errorEnv) && this.detialShowTickets
  }

  get workFlowCurrentStatus () {
    type nodeType = {node: string, name: string}
    const status:nodeType[] = []
    if (this.ticket?.fields?.curPhases.length > 0) {
      this.ticket.fields.curPhases.forEach(item => {
        if (item.curStatus === 'pending') {
          status.push({ node: item.phaseName, name: item.responsibleAgentName + ' 处理中' })
        } else {
          status.push({ node: item.phaseName, name: '可认领人：' + item.claimable?.join(',') })
        }
      })
    }
    return status
  }

  get jiraButtonDiasable2 () {
    return (!this.jiraProject || !this.errorEnv || !this.handlerJira) && this.detialShowTickets
  }

  changeIsPass (k, val) {
    this.ruleForm[k] = val
  }

  getLevel = () =>
    this.processLabels?.find((label) => label.id === this.ticket?.fields?.curPhaseId)?.name

  assignMe () {
    if (this.ticket.fields?.curPhaseId) {
      return this.ticketClickAssignMe()
    } else if (this.claimablePhases?.length > 1) {
      this.showPhaseSelectDialog = true
      this.selectedPhase = ''
    } else {
      this.ticketClickAssignMeWithPhaseId(this.claimablePhases?.[0]?.phaseId)
    }
  }

  restartTicket () {
    const loadingInstance = ElLoading.service({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading'
    })
    ticketApi.ticketRestart(this.ticket.pk).then(() => {
      ElMessage({
        showClose: true,
        message: '重启成功',
        type: 'success'
      })
    }).catch(res => {
      console.error('重启失败：', res)
    }).finally(() => {
      this.loadTicketDetail()
      loadingInstance.close()
    })
  }

  assignWithPhase () {
    if (!this.selectedPhase) {
      ElMessage({
        type: 'warning',
        message: '请选择一个处理节点',
      })
      return
    }
    this.showPhaseSelectDialog = false
    this.ticketClickAssignMeWithPhaseId(this.selectedPhase)
  }

  ticketClickAssignMeWithPhaseId (phaseId) {
    const me = store.getters['auth/me']
    const loadingInstance = ElLoading.service({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading'
    })
    ticketApi.ticketsAssignUpdate(this.ticket.pk, me.ldap, me.username, phaseId).then(() => {
      ElMessage({
        showClose: true,
        message: '认领成功',
        type: 'success'
      })
    }).catch(res => {
      console.error('认领失败：', res)
    }).finally(() => {
      this.loadTicketDetail()
      loadingInstance.close()
      ticketApi.ticketCount().then((res) => {
        store.commit('updateTicketCount', res)
      })
    })
  }

  ticketClickAssignMe () {
    const me = store.getters['auth/me']
    const loadingInstance = ElLoading.service({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading'
    })
    ticketApi.ticketsAssignUpdate(this.ticket.pk, me.ldap, me.username).then(() => {
      ElMessage({
        showClose: true,
        message: '认领成功',
        type: 'success'
      })
    }).catch(res => {
      console.error('认领失败：', res)
    }).finally(() => {
      this.loadTicketDetail()
      loadingInstance.close()
    })
  }

  templateValueChange (e) {
    (this.$refs.templateGenerator as typeof TemplateGenerator).getData().then((data) => {
      const fieldInfo = deepCopy(data)
      const relationMap = deepCopy(this.currentTemplateInfo.relationMap)
      const templateRelationMap = this.currentTemplateInfo.template.fields.relationMap
      Object.keys(fieldInfo).forEach((key) => {
        const tagSetId = templateRelationMap[key].setId
        if (relationMap[tagSetId]) {
          relationMap[tagSetId].value = fieldInfo[key]
        }
      })
      const postData = {
        ticket: {
          fields: {
            fieldInfo,
            relationMap,
          }
        },
      }
      const loadingInstance = ElLoading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading'
      })
      ticketApi.updateTicket(this.ticket.pk, postData).then((res) => {
        console.log('updateTicket:', res)
      }, (err) => {
        console.error('updateTicket:', err)
      }).finally(() => {
        this.loadTicketDetail()
        loadingInstance.close()
      })
    })
  }

  displayNewline (text = '') {
    return text.replaceAll('\n', '<br>')
  }

  handleRemove (file) {
    const index = this.fileList.findIndex((fileInfo) => fileInfo.name === file.name)
    if (index >= 0) {
      this.fileList.splice(index, 1)
    }
  }

  handlePreview (file) {
    console.log(file)
  }

  uploadFile (info) {
    console.log('uploadFile info:', info)
    // if (this.uploading) {
    //   ElMessage({
    //     type: 'error',
    //     message: `文件 ${info.file.name} 上传失败：其他文件正在上传`
    //   })
    //   info.onError(new Error('其他文件正在上传'))
    //   return
    // }
    this.uploading = true
    const { file } = info
    const formData = new FormData()
    formData.append('file', file, file.name)
    return uploadFile(formData).then((res) => {
      console.log('上传附件', res)
      this.uploading = false
      this.fileList.push({
        name: file.name,
        key: res.fileId,
        url: res.url
      })
      console.log('fileList', this.fileList)
    }, (err) => {
      this.uploading = false
      ElMessage({
        type: 'error',
        message: `文件 ${file.name} 上传失败：${err.errmsg}`
      })
      console.error(err)
      throw Error(`文件 ${file.name} 上传失败：${err.errmsg}`)
    })
  }

  deleteExtraTags (id) {
    if (this.ticket.fields.extraTags) {
      const i = this.ticket.fields.extraTags.findIndex(t => t.id === id)
      if (i !== -1) {
        this.ticket.fields.extraTags.splice(i, 1)
      }
    }
  }

  ticketOr (row) {
    // console.log(row, 'rows')
    if (row) {
      // if (row.channel && row.channel === 5) {
      if (row.creatorName && row.creatorName !== '') {
        return row.creatorName
      } else {
        return row.creator
      }
    } else {
      return ''
    }
  }

  changeNextProcess (e) {
    this.loadAgentsByTag(e)
    const isLevel3 = this.processLabels.some(l => {
      return l.id === e && l.name === 'LEVEL-3'
    })
    if (isLevel3) {
      ElMessageBox.alert('请按照操作文档进行问题排查，并在问题回复中添加排查截图。', '提示', {
        confirmButtonText: '确定',
        callback: (action) => {
          // ElMessage({
          //   type: 'info',
          //   message: `action: ${action}`,
          // })
        },
      })
    }
  }

  formatDate (timestamp: number) {
    const two = (n) => n < 10 ? '0' + n : '' + n
    const d = new Date(timestamp * 1000)
    return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日 ${two(d.getHours())}:${two(d.getMinutes())}`
  }

  formatTime (timestamp: number) {
    // let result = parseInt(timestamp)
    const result = timestamp
    var h = Math.floor(result / 3600) < 10 ? Math.floor(result / 3600) : Math.floor(result / 3600)
    const m = Math.floor((result / 60 % 60)) < 10 ? Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60))
    const s = Math.floor((result % 60)) < 10 ? Math.floor((result % 60)) : Math.floor((result % 60))
    let res = ''
    if (h > 24) {
      const d = Math.floor(h / 24)
      res += `${d}天`
      h = h % 24
    }
    if (h !== 0) {
      res += `${h}小时`
    }
    if (m !== 0) {
      res += `${m}分钟`
    } else {
      res += `${s}秒`
    }
    return res
  }

  async loadAgentsByTag (tagId: number) : Promise<void> {
    this.handlers = []
    let res = await agent.agentsByTag(tagId)
    this.handlers.push(...res.results)
    while (res.next) {
      res = await agent.agentsByTag(tagId, res.page + 1)
      this.handlers.push(...res.results)
    }
  }

  async loadAgentsForJira () {
    const res = await agentsForJira()
    this.jiraHandlers = res.results
  }

  onSubmit (e) {
    this.ticket.fields.extraTags = e
    this.showTicketAddTag = false
  }

  ticketClose () {
    this.buttonClicked = 'close'
    const $refs = this.$refs as any
    $refs.ruleFormRef.validate((valid) => {
      if (valid) {
        ElMessageBox.confirm('确定已解决吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          if (this.ruleForm.newReply.trim().length === 0) {
            ElMessage({
              type: 'warning',
              message: '请填写问题回复'
            })
            return
          }
          const templateValueAllSelected = Object.keys(this.templateValue).every(k => !!this.templateValue[k])
          if (!templateValueAllSelected) {
            ElMessageBox.confirm('请完善左侧分类信息。', '提示', {
              confirmButtonText: '暂不完善',
              cancelButtonText: '去完善',
              type: 'warning'
            }).then((action) => {
              console.log(action)
              this.transTicket(TicketStatus.CLOSED, () => {
                this.$router.back()
              })
            }).catch((action) => {
              console.log(action)
            })
          } else {
            this.transTicket(TicketStatus.CLOSED, () => {
              this.$router.back()
            })
          }
        }).catch(() => {
          // ElMessage({
          //   type: 'info',
          //   message: '已取消操作'
          // })
        })
      }
    })
  }

  ticketDirectClose () {
    ElMessageBox.confirm('确定要直接关闭工单吗？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      const loadingInstance = ElLoading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading'
      })
      const ticketId = Number(this.$route.params.id)
      ticketApi.ticketDirectClose(ticketId).then((res) => {
        this.loadTicketDetail()
        ElMessage({
          type: 'success',
          message: '关闭成功'
        })
      }, (err) => {
        console.error(err)
        ElMessage({
          type: 'error',
          message: err.errmsg
        })
      }).finally(() => {
        loadingInstance.close()
      })
    }).catch(() => {
      // ElMessage({
      //   type: 'info',
      //   message: '已取消操作'
      // })
    })
  }

  async continueFormValid () {
    return new Promise((resolve, reject) => {
      this.buttonClicked = 'continue'
      const $refs = this.$refs as any
      $refs.ruleFormRef.validate((valid) => {
        if (valid) {
          if (!this.ruleForm.nextProcess && !this.workflowId) {
            ElMessage({
              type: 'warning',
              message: '请选择下一阶段'
            })
            reject(new Error('请选择下一阶段'))
            return
          }
          if (this.ruleForm.newReply.trim().length === 0) {
            ElMessage({
              type: 'warning',
              message: '请填写问题回复'
            })
            reject(new Error('请填写问题回复'))
            return
          }
          resolve(true)
        } else {
          reject(new Error('请检查参数'))
        }
      })
    })
  }

  // 生成jira.bug
  async ticketContJira (type) {
    this.contJiraType = type
    const ticketId = Number(this.$route.params.id)
    let loadingInstance = ElLoading.service({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading'
    })
    // this.loadAgentsForJira()
    if (type === 'continueRelation') {
      try {
        await this.continueFormValid()
      } catch (e) {
        return
      } finally {
        loadingInstance.close()
      }
      loadingInstance = ElLoading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading'
      })
      const data = await (this.$refs.templateGenerator as typeof TemplateGenerator).getData()
      const fieldInfo = deepCopy(data)
      // const relationMap = deepCopy(this.ticket.fields.relationMap)
      // const templateRelationMap = this.ticket.fields.template.fields.relationMap
      const relationMap = deepCopy(this.currentTemplateInfo.relationMap)
      const templateRelationMap = this.currentTemplateInfo.template.fields.relationMap
      Object.keys(fieldInfo).forEach((key) => {
        const tagSetId = templateRelationMap[key].setId
        if (relationMap[tagSetId]) {
          relationMap[tagSetId].value = fieldInfo[key]
        }
      })
      const payload = {
        assignee: this.userName,
        ticket: {
          model: this.ticket.model,
          pk: this.ticket.pk,
          fields: {
            fieldInfo,
            relationMap,
            // curResult: 'continued',
            curStatus: this.ticket.fields.curStatus,
          }
        },
        transaction: {
          extraTags: this.ticket.fields.extraTags,
          comment: this.ruleForm.newReply,
          attachments: this.fileList,
          nextPhaseId: this.ruleForm.nextProcess || this.ticket.fields.curPhaseId,
          nextAgent: this.ruleForm.nextHandler
        },
        jiraProject: this.jiraProject ? [this.jiraProject] : null,
        errorEnv: this.errorEnv ? [this.errorEnv] : null,
      }
      postJira(payload, ticketId).then((res) => {
        if (res?.errorEnv && res?.jiraProjectList && res?.errorEnv.length > 0 && res?.jiraProjectList.length > 0) {
          this.detialShowTickets = true
          this.jiraProjectList = res?.jiraProjectList
          this.errorEnvList = res?.errorEnv
          this.handlerJira = res?.ldapid
        } else {
          this.loadTicketDetail()
          ElMessage({
            type: 'success',
            message: '生成成功'
          })
          this.detialShowTickets = false
        }
      }, (err) => {
        console.error(err)
        ElMessage({
          type: 'error',
          message: err.errmsg
        })
      }).finally(() => {
        loadingInstance.close()
      })
    } else if (type === 'relation') {
      this.loadAgentsForJira()
      getJiraProjectAndErrorEnv(ticketId).then((res) => {
        this.detialShowTickets = true
        this.relationJiraBtn = true
        this.jiraProjectList = res?.jiraProjectList
        this.errorEnvList = res?.errorEnv
      }, (err) => {
        console.error(err)
        ElMessage({
          type: 'error',
          message: err.errmsg
        })
      }).finally(() => {
        loadingInstance.close()
      })
    }
  }

  ticketRelationJira () {
    const loadingInstance = ElLoading.service({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading'
    })
    const payload = {
      assignee: this.handlerJira,
      jiraProject: this.jiraProject ? this.jiraProject : null,
      errorEnv: this.errorEnv ? [this.errorEnv] : null,
    }
    const ticketId = Number(this.$route.params.id)
    ticketRelationJira(payload, ticketId).then((res) => {
      this.loadTicketDetail()
      ElMessage({
        type: 'success',
        message: '生成成功'
      })
      this.detialShowTickets = false
      this.relationJiraBtn = false
    }, (err) => {
      console.error(err)
      ElMessage({
        type: 'error',
        message: err.errmsg
      })
    }).finally(() => {
      loadingInstance.close()
    })
  }

  async ticketContinue (val) {
    this.buttonClicked = 'continue'
    // 是否是跳过
    if (val === 'skip') {
      if (!this.ruleForm.nextProcess && !this.workflowId) {
        ElMessage({
          type: 'warning',
          message: '请选择下一阶段'
        })
        return
      }
      this.ruleForm.newReply = '跳过<br/>'
      this.transTicket(TicketStatus.PENDING, () => {
        console.log('continue success')
      })
      return
    }
    // 验证输入框是否填写
    if (this.outputData?.length > 0) {
      try {
        const outputCom:any = this.$refs.outputCom
        await outputCom.validateAll()
      } catch (e) {
        ElMessage({
          type: 'warning',
          message: '请检查输入框必填项是否填写'
        })
        return
      }
    }
    const $refs = this.$refs as any
    $refs.ruleFormRef.validate((valid) => {
      if (valid) {
        if (!this.ruleForm.nextProcess && !this.workflowId) {
          ElMessage({
            type: 'warning',
            message: '请选择下一阶段'
          })
          return
        }
        if (this.ruleForm.newReply.trim().length === 0 && !this.outputData?.length) {
          ElMessage({
            type: 'warning',
            message: '请填写问题回复'
          })
          return false
        }
        // 是否通过是否填写
        for (const item in this.taskVariables) {
          if (!this.taskVariables[item]) {
            ElMessage({
              type: 'warning',
              message: '请检查是否填写是否通过'
            })
            return false
          }
        }
        this.transTicket(TicketStatus.PENDING, () => {
          console.log('continue success')
        })
      }
    })
  }

  delayedProcess () {
    if (this.ruleForm.newReply.trim().length === 0) {
      return ElMessage({
        type: 'warning',
        message: '请填写问题回复'
      })
    }
    const loadingInstance = ElLoading.service({
      lock: true,
      text: '工单挂起中...',
      spinner: 'el-icon-loading'
    })
    const postData = {
      transaction: {
        extraTags: this.ticket.fields.extraTags,
        comment: this.ruleForm.newReply,
        attachments: this.fileList,
        nextPhaseId: this.ticket.fields.curPhaseId ? (this.ruleForm.nextProcess || this.ticket.fields.curPhaseId) : this.selectedPhase,
        nextAgent: this.ruleForm.nextHandler
      }
    }
    ticketApi.ticketsSuspend(this.ticket.pk, postData).then((res) => {
      this.ruleForm.newReply = ''
    }, (err) => {
      ElMessage({
        type: 'error',
        message: err.errmsg
      })
    }).finally(() => {
      this.loadTicketDetail()
      loadingInstance.close()
    })
  }

  transTicket (ticketStatus: TicketStatus, callback: () => void) {
    // 继续/跳过
    (this.$refs.templateGenerator as typeof TemplateGenerator).getData().then((data) => {
      const loadingInstance = ElLoading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading'
      })
      const fieldInfo = deepCopy(data)
      // const relationMap = deepCopy(this.ticket.fields.relationMap)
      // const templateRelationMap = this.ticket.fields.template.fields.relationMap
      const relationMap = deepCopy(this.currentTemplateInfo.relationMap)
      const templateRelationMap = this.currentTemplateInfo.template.fields.relationMap
      Object.keys(fieldInfo).forEach((key) => {
        const tagSetId = templateRelationMap[key].setId
        if (relationMap[tagSetId]) {
          relationMap[tagSetId].value = fieldInfo[key]
        }
      })
      const postData = {
        ticket: {
          model: this.ticket.model,
          pk: this.ticket.pk,
          fields: {
            fieldInfo,
            relationMap,
            // curResult: 'continued',
            curStatus: ticketStatus
          }
        },
        transaction: {
          extraTags: this.ticket.fields.extraTags,
          comment: this.ruleForm.newReply,
          attachments: this.fileList,
          nextPhaseId: this.ruleForm.nextProcess || this.ticket.fields.curPhaseId,
          nextAgent: this.ruleForm.nextHandler,
          selectedPhaseId: this.workflowId ? this.selectedPhase : undefined,
          taskVariables: Object.keys(this.taskVariables).length > 0 ? this.taskVariables : undefined,
          formInfo: { outFields: this.outputParams },
        }
      }
      loadingInstance.close()
      // 提交
      ticketApi.transTicket(this.ticket.pk, postData).then((res) => {
        this.loadTicketDetail()
        this.loadWorkflowList()
        const fu = this.$refs.fileUploader as any
        fu.clearFiles()
        this.ruleForm.newReply = ''
        this.taskVariables = {}
        callback()
      }, (err) => {
        console.error(err)
      }).finally(() => {
        loadingInstance.close()
      })
    })
  }

  loadTicketDetail () {
    // 获取工单详情信息
    const id = Number(this.$route.params.id)
    ticketApi.ticketDetail(id).then((res:any) => {
      const list = res.ticket.fields?.templateList || []
      this.templateUsedRecord = list.filter(t => !t.isCurrent)
      this.currentTemplateInfo = list.filter(t => t.isCurrent)[0]
      // console.log('currentTemplateInfo', this.currentTemplateInfo)
      this.template = this.currentTemplateInfo.template?.fields?.template
      this.templateValue = this.currentTemplateInfo.fieldInfo
      // console.log('taskVariablesOptions', res.ticket.fields?.curPhases[0])
      // this.template = res.ticket.fields.template.fields.template
      // this.templateValue = res.ticket.fields.fieldInfo
      this.ticket = res.ticket
      if (!this.ticket.fields?.curPhaseId) {
        this.workflowId = res.ticket.fields?.workflow?.wfId
        this.workflowName = res.ticket.fields?.workflow?.wfName
        this.workflowDiagram = res.ticket.fields?.workflow?.wfDiagram
        this.workflowXml = res.ticket.fields?.workflow?.wfXml
        this.workflowInstanceId = res.ticket.fields?.workflow?.wfInstanceId
        this.workflowDiagramList.push(this.workflowDiagram)
        this.processablePhases = res.ticket.fields?.processablePhases
        this.claimablePhases = res.ticket.fields?.claimablePhases
        this.planTime = { start: res.ticket.fields?.workflow?.wfPlan?.start && new Date(res.ticket.fields?.workflow?.wfPlan?.start * 1000), end: res.ticket.fields?.workflow?.wfPlan?.end && new Date(res.ticket.fields?.workflow?.wfPlan?.end * 1000) }
        this.milestoneList = res.ticket.fields?.workflow?.wfMilestone?.length ? res.ticket.fields?.workflow?.wfMilestone?.map(i => ({ ...i, time: i.time ? i.time * 1000 : null })) : []
        this.selectedPhase = this.processablePhases[0]?.phaseId
        this.taskVariables = this.processablePhases[0]?.taskVariables || {}
        // this.rules = Object.assign(this.rules, this.taskVariables)
      }
      this.transactions = deepCopy(res.transactions) || []
      this.problemId = res.ticket.fields.problemId
      if (this.workflowId) {
        this.projectBasieInfo = {
          deliveryManager: res.ticket.fields.workflow.deliveryManager,
          projectManager: res.ticket.fields.workflow.projectManager,
        }
        const formInfo = res?.ticket?.fields?.processablePhases[0]?.formInfo
        this.outputData = formInfo?.outFields || []
        this.inputData = formInfo?.inFields || []
        this.nodeRemark = formInfo?.remark || ''
        this.replyDisable = false
        this.ruleForm.newReply = ''
        this.taskVariablesOptions = res.ticket.fields?.processablePhases[0]?.taskVariables ? Object.values(res.ticket.fields?.processablePhases[0]?.taskVariables)[0] : null
        // 清空通过
        if (this.taskVariables) {
          for (const key in this.taskVariables) {
            this.taskVariables[key] = null
          }
        }
      }
    }).catch(res => {
      console.error(res)
    })
  }

  checkImage (filename) {
    return checkImage(filename)
  }

  imageList (filelist) {
    return filelist?.filter(file => checkImage(file.name))
  }

  notImageList (filelist) {
    return filelist?.filter(file => !checkImage(file.name))
  }

  hideFilterBox (e) {
    if (e.target.closest('.detial-show-tickets') || e.target.closest('.jira-class')) {
      return false
    }
    this.detialShowTickets = false
    this.relationJiraBtn = false
  }

  beforeUnmount () {
    window.removeEventListener('click', this.hideFilterBox)
  }

  mounted () {
    this.loadTicketDetail()
    this.loadWorkflowList()

    const user = store.getters['auth/me']
    this.userName = user.username
    const admin = user.role
    admin.forEach(item => {
      if (item.roleName === 'ticket_admin') {
        this.ticketAdmin = true
      }
    })
    window.addEventListener('click', this.hideFilterBox)
    label.processLabels().then(res => {
      this.processLabels = res.filter(l => !l.isDeleted)
    }).catch(res => [
      console.log('processLabels error:', res)
    ])
    selfTags(this.me.ldap).then(res => {
      this.selfTagList = res
    }).catch(res => {
      console.error(res)
    })
    const individuation = localStorage.getItem('individuation')
    if (individuation) {
      this.replyShowType = JSON.parse(individuation)?.replyType
    }
  }

  loadWorkflowList () {
    getWorkflowAppointList(Number(Number(this.$route.params.id))).then((res) => {
      if (JSON.stringify(res) === '{}') {
        return
      }
      this.taskAppointTable = res.map(i => {
        i.planAt = i.planAt * 1000
        return i
      })
    })
  }

  loadWorkflowMap () {
    console.log('loadWorkflowMap() start')
    // console.log(this.workflowXml)
    if (this.loadedWorkflowMap) {
      return
    }
    if (!this.workflowXml) {
      return
    }
    this.$nextTick(() => {
      const canvas = this.$refs.mapCanvas

      const bpmnViewer = new CustomViewer({
        container: canvas,
        width: '100%',
        height: '100%'
      })

      const viewerCanvas = bpmnViewer.get('canvas')
      // const overlays = bpmnViewer.get('overlays')
      // const elementRegistry = bpmnViewer.get('elementRegistry')
      bpmnViewer.importXML(this.workflowXml).then(function (result) {
        const { warnings } = result
        console.log('success !', warnings)
        viewerCanvas.zoom('fit-viewport')
      }).catch(function (err) {
        const { warnings, message } = err
        console.log('something went wrong:', warnings, message)
      })

      ticketApi.getHistoryActivityInstances(this.workflowInstanceId).then(
        (actInstList) => {
          console.log('getHistoryActivityInstances() ok!')
          // console.log(actInstList)
          this.addHistoryInfoOverlay(viewerCanvas, actInstList)
        }
      ).catch(function (err) {
        const { warnings, message } = err
        console.log('something went wrong:', warnings, message)
      })
      /*
      ticketApi.getActivityInstances(this.workflowInstanceId).then(
        (actInstTree) => {
          console.log('getActivityInstances() ok!')
          console.log(actInstTree)
          this.addMarkerForActivities(viewerCanvas, actInstTree)
        }
      ).catch(function (err) {
        const { warnings, message } = err
        console.log('something went wrong:', warnings, message)
      }) */
    })
    this.loadedWorkflowMap = true
  }

  addMarkerForActivities (canvas, actInstTree) {
    if (actInstTree.childTransitionInstances.length === 0 && actInstTree.childActivityInstances.length === 0) {
      canvas.addMarker(actInstTree.activityId, 'highlight')
    } else {
      for (let index = 0; index < actInstTree.childTransitionInstances.length; ++index) {
        canvas.addMarker(actInstTree.childTransitionInstances[index].activityId, 'highlight')
      }
      for (let index = 0; index < actInstTree.childActivityInstances.length; ++index) {
        // add recursively
        this.addMarkerForActivities(canvas, actInstTree.childActivityInstances[index])
      }
    }
  }

  /*
  addHistoryInfoOverlay (overlays, elementRegistry, actInstList) {
    for (let index = 0; index < actInstList.length; ++index) {
      const activityId = actInstList[index].activityId
      const shape = elementRegistry.get(activityId)
      const overlayHtml =
        '<div class="highlight-overlay" width=' + shape.width + ' height=' + shape.height + '>'

      overlays.add(activityId, {
        position: {
          top: -5,
          left: -5
        },
        html: overlayHtml
      })
    }
  addHistoryInfoOverlay (canvas, actInstList) {
  }
  */
  addHistoryInfoOverlay (canvas, actInstList) {
    for (let index = 0; index < actInstList.length; ++index) {
      if (actInstList[index].endTime) {
        canvas.addMarker(actInstList[index].activityId, 'highlight-finish')
      } else {
        canvas.addMarker(actInstList[index].activityId, 'highlight')
      }
    }
  }

  changePersonSelect (val, type = null) {
    this.activeAppointType = type
    this.phaseId = val
    this.transferPersonVisible = true
  }

  changeManager (type) {
    this.activeManager = type
    this.showManagePersonalVisible = true
  }

  submitChangeManager (agent) {
    if (!agent) {
      ElMessage({
        showClose: true,
        message: '请选择经理',
        type: 'warning'
      })
      return
    }
    this.showManagePersonalVisible = false
    let data = {}
    if (this.activeManager === 'projectManager') {
      data = { projectManager: agent.name }
    } else {
      data = { deliveryManager: agent.name }
    }
    this.updateManager(data)
  }

  submitPersonSelect (agent) {
    if (!agent) {
      ElMessage({
        showClose: true,
        message: '请选择指派人',
        type: 'warning'
      })
      return
    }
    this.transferPersonVisible = false
    console.log(this.activeAppointType)
    if (this.activeAppointType !== 'pending') {
      ticketApi.ticketsAssignUpdate(this.ticket.pk, agent.ldap, agent.name, this.phaseId).then(res => {
        ElMessage({
          showClose: true,
          message: '指派成功',
          type: 'success'
        })
        this.loadTicketDetail()
      }).catch(res => {
        ElMessage({
          showClose: true,
          message: '指派失败:' + res,
          type: 'error'
        })
      })
    } else {
      ticketApi.transactCurDuePerson(this.ticket.pk, agent.ldap, agent.name, this.phaseId).then(res => {
        ElMessage({
          showClose: true,
          message: '指派成功',
          type: 'success'
        })
        this.loadTicketDetail()
      }).catch(res => {
        ElMessage({
          showClose: true,
          message: '指派失败:' + res,
          type: 'error'
        })
      })
    }
  }

  cancelPersonSelect () {
    this.transferPersonVisible = false
  }
}
