<template>
  <div class="page">
    <div class="header-nav">
      <span class="title">人员管理</span>
    </div>
    <div class="content1">
      <div class="work-order">
        <div class="search search-tag">
          <label>人员标签：</label>
          <el-cascader
            class="person-tag-int"
            :show-all-levels="false"
            :options="tagsOptions"
            v-model="tagIds"
            :props="props"
            collapse-tags
            filterable
            clearable
          />
          <label style="margin-left: 20px;">姓名：</label>
          <el-input
            style="width: 110px;"
            v-model="searchOfName"
            placeholder="输入姓名"
            @keyup.enter="fetchAgent"
            clearable
            @clear="fetchAgent"
          />
          <label style="margin-left: 20px;">部门：</label>
          <el-cascader
            :options="departmentTree"
            v-model="searchOfDepartment"
            :show-all-levels="false"
            collapse-tags
            clearable
            :props="{
              value: 'id',
              label: 'cName',
              checkStrictly: true,
              multiple: true,
              emitPath: false
            }"
          />
          <label style="margin-left: 20px;">人员类型：</label>
          <el-select v-model="isAdmin" clearable placeholder="请选择" class="select-admin">
            <el-option
              v-for="item in agentTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <button @click="fetchAgent" class="search-btn">查 询</button>
          <button v-if="selectPersonCurPage.length > 0 || selectPerson.length > 0" @click="addPersonTags" class="add-problem add-person-tags2">批量添加标签</button>
          <button v-else class="add-problem add-person-tags2 add-person-tags">批量添加标签</button>
        </div>
      </div>
      <div class="data-table" style="margin:0;">
        <el-table
          fit
          border
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            width="205">
            <template #default="{ row }">
              <div class="member-name">
                <span>{{row.name}}</span>
                <i v-if="row.isAdmin"></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="department"
            label="部门"
            width="100">
          </el-table-column>
          <el-table-column
            prop="tags"
            label="标签">
            <template #default="{ row }">
              <div v-if="row.tags.length > 0" class="tags-box">
                <span v-for="item in row.tags" :key="item.id" >
                  {{ item.name }}
                </span>
              </div>
              <div v-else>未配置</div>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="150">
            <template #default="{ row }">
              <div class="operation-box">
                <button @click="editPerson(row)" class="operation">编辑</button>
                <span>|</span>
                <button @click="setUpAdmin(row)" class="operation">{{row.isAdmin ? '取消管理员' : '设置管理员'}}</button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-wrap" v-if="totalCount > 0">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="prev, pager, next, sizes, jumper, total"
            :total="totalCount"
            :page-count="numPages">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadDepartmentTree } from '@/api/orgCharts'
import { fetchAgentByTags, setUpAdmin } from '@/api/person'
import { getLabelsBySetId, personLabelSet } from '@/api/label'
import { deepCopy } from '@/utils/utils'
import store from '@/store'

export default {
  name: 'person',
  data () {
    // const that = this
    return {
      tagList: [],
      tableData: [],
      props: {
        multiple: true,
        value: 'id',
        label: 'name',
        children: 'childrenData',
        lazy: true,
        lazyLoad: (node, resolve) => {
          this.getLabels(node, resolve)
        },
        emitPath: false
      },
      tagsOptions: [],
      tagIds: [],
      searchOfName: '',
      searchOfDepartment: [],
      pageSize: 10,
      pageNum: 1,
      numPages: 10,
      totalCount: null,
      selectPerson: [],
      selectPersonCurPage: [],
      selectPersonInternalChange: false,
      isAdmin: '',
      departmentTree: [],
      agentTypeOptions: [
        { label: '管理员', value: true },
        { label: '非管理员', value: false }
      ]
    }
  },
  created () {
    const user = store.getters['auth/me']
    const admin = user.role
    let ticketAdmin = false
    admin.forEach(item => {
      if (item.roleName === 'ticket_admin') {
        ticketAdmin = true
      }
    })
    const query = this.$route.query
    if (Object.keys(query).length > 0) {
      this.pageNum = Number(query.pageNum)
      this.pageSize = Number(query.pageSize)
    } else {
      this.pageNum = 1
      this.pageSize = 10
    }
    if (ticketAdmin) {
      this.fetchAgent()
      this.fetchDepartment()
    } else {
      this.$message({
        type: 'error',
        message: '您不是管理员不可访问页面哦！'
      })
      this.$router.push({
        path: '/home'
      })
    }
    // this.fetchAgent()
    // this.fetchDepartment()
    // this.fetchTags()
  },
  methods: {
    handleSelectionChange (val) {
      if (this.selectPersonInternalChange) {
        return
      }
      this.selectPersonCurPage = val
    },
    handleSelectPersonMerge () {
      if (this.selectPersonCurPage.length > 0) {
        this.selectPersonCurPage.forEach((person) => {
          if (this.selectPerson.find(v => v.id === person.id) !== undefined) {
            return
          }
          this.selectPerson.push(deepCopy(person))
        })
      }
      // 清空数组
      this.selectPersonCurPage = []
      this.selectPerson = this.selectPerson.filter((person) => {
        const res = this.tableData.find(v => v.id === person.id)
        if (res) {
          this.selectPersonCurPage.push(res)
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(res)
          })
          return false
        }
        return true
      })
      this.selectPersonInternalChange = false
    },
    fetchDepartment () {
      const removeEmptyChildren = (arr) => {
        arr.forEach(v => {
          if (v.children && Array.isArray(v.children)) {
            if (v.children.length === 0) {
              delete v.children
            } else {
              v.children = removeEmptyChildren(v.children)
            }
          }
        })
        return arr
      }
      loadDepartmentTree().then((res) => {
        // console.log('department', res)
        this.departmentTree = removeEmptyChildren(res[0].children)
      })
    },
    fetchAgent () {
      const data = {
        limit: this.pageSize,
        page: this.pageNum,
        name: this.searchOfName,
        tagIds: this.tagIds.join(','),
        isAdmin: this.isAdmin,
        department: this.searchOfDepartment.join(',')
      }
      fetchAgentByTags(data).then((rv) => {
        this.totalCount = rv.count
        this.numPages = rv.numPage
        this.tableData = rv.results
        this.handleSelectPersonMerge()
      }, (err) => {
        this.$message({
          type: 'error',
          message: err.errmsg
        })
        console.error(err)
      })
    },
    getLabels (node, resolve) {
      const { level } = node
      if (level === 0) {
        const del = 0
        personLabelSet(del).then((rv) => {
          resolve(rv.map(labelSet => Object.assign({ name: labelSet.labelSetName }, labelSet)))
        }, (err) => {
          this.$message({
            type: 'error',
            message: err.errmsg
          })
          resolve([])
        })
      } else {
        // const data = {
        //   id: node.data.id
        // }
        getLabelsBySetId(node.data.id).then((rv) => {
          resolve(rv.map(label => Object.assign({ leaf: true }, label)))
        }, (err) => {
          this.$message({
            type: 'error',
            message: err.errmsg
          })
          console.error(err)
        })
      }
    },
    searchTags () {
      if (this.tagIds.length === 0) {
        this.$message({
          type: 'warning',
          message: '请选择人员标签查询！'
        })
      } else {
        console.log('未选择人员标签')
      }
    },
    addPersonTags () {
      const personList = [...this.selectPersonCurPage, ...this.selectPerson]
      this.$router.push({
        path: '/manage/AllocationTags',
        query: { personList: JSON.stringify(personList), pageNum: this.pageNum, pageSize: this.pageSize }
      })
    },
    editPerson (row) {
      this.$router.push({
        path: '/manage/AllocationTags',
        query: { ldap: row.ldap, personList: JSON.stringify([row]), pageNum: this.pageNum, pageSize: this.pageSize }
      })
    },
    setUpAdmin (row) {
      let tip = ''
      if (row.isAdmin) {
        tip = `取消 ${row.name} 的管理员?`
      } else {
        tip = `将 ${row.name} 设置为管理员?`
      }
      this.$confirm(tip, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const data = {
          ldap: row.ldap,
          isAdmin: !row.isAdmin
        }
        console.log('row.ldap = ', row)
        setUpAdmin(data).then((rv) => {
          row.isAdmin = !row.isAdmin
          this.$message({
            type: 'success',
            message: '设置成功!'
          })
        }, (err) => {
          this.$message({
            type: 'error',
            message: err.errmsg
          })
          console.error(err)
        })
      }).catch(() => {
        console.log('调用失败')
      })
    },
    handleCurrentChange (e) {
      this.selectPersonInternalChange = true
      this.pageNum = e
      this.fetchAgent()
    },
    handleSizeChange (e) {
      this.selectPersonInternalChange = true
      this.pageSize = e
      // this.pageNum = 1
      this.fetchAgent()
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/style/home.less";
.operation {
  background: none;
}
</style>

<style lang="less">
.el-cascader__search-input {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
}
button {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
}
</style>
