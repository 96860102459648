import axios from '@/utils/request'

const app = '/unicorn-ticket'

const templateUrls = {
  templates: '/template_management/templates',
}

const alarmUrls = {
  alarm_rule: '/template_management/alarm_rule'
}

Object.keys(templateUrls).forEach((uri) => {
  templateUrls[uri] = app + templateUrls[uri]
})

Object.keys(alarmUrls).forEach((uri) => {
  alarmUrls[uri] = app + alarmUrls[uri]
})

export function getTemplateById (id) {
  const url = `${templateUrls.templates}/${id}`
  return axios({
    url,
    method: 'get'
  })
}

export function updateTemplateById (id, template) {
  const url = `${templateUrls.templates}/${id}`
  return axios({
    url,
    method: 'patch',
    data: template
  })
}

export function deleteTemplateById (id) {
  const url = `${templateUrls.templates}/${id}`
  return axios({
    url,
    method: 'delete'
  })
}

export function createTemplate (data) {
  const url = templateUrls.templates
  return axios({
    url,
    method: 'post',
    data
  })
}

export function loadTemplateList () {
  const url = templateUrls.templates
  return axios({
    url,
    method: 'get',
    params: {
      excludeFields: JSON.stringify(['template'])
    }
  })
}

export function loadAlarmRuleList (params) {
  const url = alarmUrls.alarm_rule
  return axios({
    url,
    method: 'get',
    params
  })
}

export function updateAlarmRuleById (id, data) {
  const url = `${alarmUrls.alarm_rule}/${id}`
  return axios({
    url,
    method: 'patch',
    data
  })
}

export function changeTemplate (ticketId, data) {
  const url = `/unicorn-ticket/tickets/${ticketId}/change_template`
  return axios({
    url,
    method: 'put',
    data
  })
}

export function changeTemplateMulti (data) {
  const url = '/unicorn-ticket/tickets/ticket_batch_change_template'
  return axios({
    url,
    method: 'post',
    data
  })
}

export function getWorkflowList () {
  const url = '/unicorn-ticket/template_management/workflows'
  return axios({
    url,
    method: 'get',
  })
}
export function getAppointListData (params) {
  const url = '/unicorn-ticket/template_management/workflow_task_form'
  return axios({
    url,
    method: 'get',
    params,
  })
}

export function getMilestoneList (name) {
  const url = '/unicorn-ticket/tags_fields/labels'
  return axios({
    url,
    method: 'get',
    params: {
      label_set_name: name
    }
  })
}
