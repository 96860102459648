import axios from '../utils/request'

const app = '/org-charts'

const orgCharts = {
  organization: '/v1/organization'
}

Object.keys(orgCharts).forEach((uri) => {
  orgCharts[uri] = app + orgCharts[uri]
})

export function loadDepartmentTree () {
  const url = orgCharts.organization
  return axios({
    url,
    method: 'get'
  })
}
