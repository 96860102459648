<template>
  <component
    v-for="config in configList"
    :key="config"
    :is="config"
    v-model:field-info="fieldInfoLocal"
  />
</template>

<script>
import { deepCopy } from '@/utils/utils'
import TemplateFieldConfigForm from '@/components/TemplateMaking/TemplateField/TemplateFieldConfigForm'
export default {
  name: 'TemplateFieldConfig',
  components: {
    ...TemplateFieldConfigForm
  },
  emits: ['update:fieldInfo'],
  props: {
    configList: {
      type: Array,
      require: true
    },
    fieldInfo: {
      type: Object,
      require: true
    }
  },
  data: () => ({
    fieldInfoLocal: {}
  }),
  watch: {
    fieldInfo: {
      handler (val) {
        if (this.internalChange) {
          return
        }
        this.fieldInfoLocal = deepCopy(val)
      },
      deep: true,
      immediate: true
    },
    fieldInfoLocal: {
      handler (val) {
        this.internalChange = true
        this.$emit('update:fieldInfo', val)
        this.$nextTick(() => {
          this.internalChange = false
        })
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
