<template>
  <div class="page">
    <div class="header-nav">
      <span @click="$router.back()" class="go-back">问题列表 / </span>
      <span class="title">创建工单</span>
    </div>
    <div class="content">
      <div class="ticket">
        <SelectTemplate :oriTemplateList="templateList" @select="selectTicketTemplate"></SelectTemplate>
        <div class="select-tags" v-if="Object.keys(template).length > 0">
          <div class="workflow-select-box" v-if="workflowList.length > 0">
            <span style="display:flex;algin-items:center">
              <img src="./svg/workflow.svg" />
              <span>工作流</span>
            </span>
            <el-select v-model="checkedWorkflow" value-key="key" clearable popper-class="workflow-select-options" filterable>
              <el-option v-for="workflow in workflowList" :key="workflow.key" :label="workflow.name" :value="workflow">
                <div style="display: flex; justify-content: space-between">
                  <span>{{workflow.name}}</span>
                  <div class="option-suffix" @click.stop="previewWorkflow(workflow)">
                    <img src="../svg/workflow-preview.svg" />
                    <span class="label">预览图</span>
                  </div>
                </div>
              </el-option>
            </el-select>
          </div>
          <div class="task-appoint"  v-if="workflowList.length > 0"><el-button type="primary" @click="showTaskAppointVisible">工作流任务设定</el-button></div>
          <div style="padding: 10px" class="generator">
            <template-generator
              ref="templateGenerator"
              v-if="Object.keys(template).length > 0"
              :template-data="template"
              v-model:value="templateValue"
            />
          </div>
          <div class="milestone-list" v-if="checkedWorkflow.key">
              <div class="title">项目计划时间</div>
              <div class="row">
                <span class="row-title">项目开始时间</span>
                <el-date-picker
                  v-model="planTime.start"
                  class="date-select"
                  type="date"
                  format="YY-MM-DD"
                  placeholder="选择项目开始时间"
                  :default-time="new Date(0,0,0,0,0,0)"
                />
              </div>
              <div class="row">
                <span class="row-title">项目结束时间</span>
                <el-date-picker
                  v-model="planTime.end"
                  class="date-select"
                  type="date"
                  format="YY-MM-DD"
                  placeholder="选择项目结束时间"
                  :default-time="new Date(0,0,0,23,59,59)"
                />
              </div>
          </div>
          <div class="milestone-list" v-if="workflowMilestoneTime?.length">
              <div class="title">里程碑关键节点设置</div>
              <div v-for="(item) in workflowMilestoneTime" :key="item.name" class="row">
                <span class="row-title">{{ item.name }}</span>
                <el-date-picker
                  v-model="item.time"
                  class="date-select"
                  type="date"
                  format="YY-MM-DD"
                  placeholder="选择计划时间"
                  :default-time="new Date(0,0,0,23,59,59)"
                />
              </div>
          </div>
<!--          <div class="tags-item" v-for="(item, index) in labelList" :key="index">-->
<!--            <p>{{item.labelName}}</p>-->
<!--            <el-select v-model="item.labelModelValue" placeholder="请选择">-->
<!--              <el-option-->
<!--                v-for="el in item.labelOptions"-->
<!--                :key="el.value"-->
<!--                :label="el.label"-->
<!--                :value="el.value">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </div>-->
        </div>
        <div class="problem-info">
          <div class="problem-title">
            <!-- {{problemForm.title}} -->
            <el-input
              v-model="problemForm.title"
              placeholder="请输入工单标题"
              clearable
              class="inp-width300"
              style="width:400px;font-family: 'PingFangSC', 'PingFangSC-Regular';color: rgba(0,0,0,0.85);"
              ></el-input>
          </div>
          <div class="problem-describe">
            <el-form label-position="left" label-width="80px" :model="problemForm">
              <el-form-item label="客户描述:">
                <div class="text" v-html="problemForm.describe"></div>
                <span class="date-right">{{problemForm.date}}</span>
              </el-form-item>
              <el-form-item label="">
                <div class="images-box" v-if="problemForm.file.length > 0 && notImageList(problemForm.file).length > 0">
                  <p v-for="file in problemForm.file" :key="file.key">
                    <template v-if="!checkImage(file.name)">
                      <span style="margin-right: 15px;">{{file.name}}</span>
                      <el-link target="_blank" :href="file.url" :download="file.name">
                        <i class="icon-download"></i>
                      </el-link>
                    </template>
                    <!-- <img :src="file.url" alt=""> -->
                  </p>
                </div>
                <!-- <div class="images-box" v-else>
                  未上传附件
                </div> -->
                <div v-if="problemForm.file.length > 0 && imageList(problemForm.file).length > 0" class="attach-img-box">
                  <template v-for="(file, ) in problemForm.file">
                    <div v-if="checkImage(file.name)" :key="file.key" class="img-div">
                      <el-image
                        :src="file.url"
                        :preview-src-list="[file.url]"
                        style="width: 100px; height: 80px">
                      </el-image>
                    </div>
                  </template>
                </div>
              </el-form-item>
            </el-form>
          </div>
          <div class="next-stage">
            <div class="next-top">
              <el-form :inline="true" :rules="rules" ref="ticketForm" :model="problemForm" class="demo-form-inline">
                <template v-if="checkedWorkflow.key">
                  <el-form-item label="当前节点:">
                    <el-input disabled model-value="创建工单" class="is-disabled"/>
                  </el-form-item>
                </template>
                <template v-else>
                  <el-form-item label="下一阶段:" prop="next">
                    <el-select v-model="problemForm.next" @change="changeNext" placeholder="请选择" clearable filterable>
                      <el-option v-for="label in ladelsList" :key="label.id"
                                 :label="label.name"
                                 :value="label.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="处理人:" prop="handler">
                    <el-select v-model="problemForm.handler" placeholder="选填" clearable filterable>
                      <el-option v-for="person in handlerList" :key="person.id"
                                 :label="person.name"
                                 :value="person.ldap">
                        {{person.name}}({{person.ldap}})
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
                <el-form-item label="紧急程度:">
                  <el-select class="urgent-level-select"  v-model="urgent">
                    <el-option v-for="urgent in urgentList" :key="urgent.name"
                      :label="urgent.name"
                      :value="urgent.name">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-upload
                    multiple
                    :show-file-list="false"
                    class="upload-demo"
                    action=" "
                    :http-request="uploadFile"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove">
                    <el-button class="upload-btn" size="small" v-loading.fullscreen.lock="uploading"><i class="upload-icon"></i>上传附件
                      <template v-if="fileList.length > 0">
                        <i @click.stop="showUploadFileList = false" v-if="showUploadFileList" class="icon-arrow el-icon-arrow-up"></i>
                        <i @click.stop="showUploadFileList = true" v-else class="icon-arrow el-icon-arrow-down"></i>
                      </template>
                    </el-button>
                    <div v-if="showUploadFileList && notImageList(fileList).length > 0" class="file-list-box">
                      <ul>
                        <li v-for="(f, i) in fileList" :key="f.name" style="position: relative;">
                          <template v-if="!checkImage(f.name)">
                            <!-- <div style="background: #eaecfb;max-width:50%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"> -->
                              <span style="background: #eaecfb;max-width:70%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap; margin-right:10px;">{{ f.name }}</span>
                            <!-- </div> -->
                            <el-link class="file-download" :href="f.url" @click.stop="" target="_blank">
                              <img src="@/assets/images/download.svg" />
                            </el-link>
                            <span>
                              <el-button style="position: absolute;right: 0;top: 8px;" @click.stop="fileList.splice(i, 1)" type="text">删除</el-button>
                            </span>
                          </template>
                        </li>
                      </ul>
                    </div>
                  </el-upload>
                  <!-- <el-button class="upload-btn" size="small"><i class="upload-icon"></i>上传附件</el-button> -->
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="ticket-describe">
            <el-form label-position="left" label-width="80px" :model="problemForm">
              <el-form-item label="工单描述:">
<!--                <el-input-->
<!--                  type="textarea"-->
<!--                  :autosize="{ minRows: 4, maxRows: 8}"-->
<!--                  placeholder="如果客户描述的不够清晰，请详细描述一下工单；工单描述模板：比如什么时间，什么问题；不填写默认为客户描述"-->
<!--                  v-model="problemForm.ticketDescribe">-->
<!--                </el-input>-->
                <TinymceEditor
                  :height="200"
                  placeholder="如果客户描述的不够清晰，请详细描述一下工单；工单描述模板：比如什么时间，什么问题；不填写默认为客户描述"
                  ref="refTinymceEditor"
                  v-model:value="problemForm.ticketDescribe"
                >
                </TinymceEditor>
              </el-form-item>
              <el-form-item label="附件:" v-if="fileList.length > 0 && imageList(fileList).length > 0">
                <div class="attach-img-box" style="width: 70%">
                  <template v-for="(file, i) in fileList">
                    <div v-if="checkImage(file.name)" :key="file.key" class="img-div">
                      <el-image
                        :src="file.url"
                        :preview-src-list="[file.url]"
                        style="width: 100px; height: 80px">
                      </el-image>
                      <span class="icon-del" @click.stop="fileList.splice(i, 1)"></span>
                    </div>
                  </template>
                </div>
              </el-form-item>
            </el-form>
          </div>
          <div class="btn-box2">
            <button @click="$router.back()">取 消</button>
            <button class="submit-btn" @click="createTicket">提 交</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="previewDialogTitle" v-model="previewDialogVisible" ref="dialog">
      <img :src="previewDialogImg" style="width: 100%"/>
    </el-dialog>
    <WorkflowTaskAppiontDrawer
    :edit="true"
    v-model:show="taskAppointVisible"
    :taskAppointTable = "taskAppointTable"
    @changeList="changeAppointList"
    ></WorkflowTaskAppiontDrawer>
  </div>
</template>
<script>
// import { Options, Vue } from 'vue-class-component';
import { deepCopy, getValueFromObject, checkImage } from '@/utils/utils'
import { loadTemplateList, getTemplateById } from '@/api/template'
import { getWorkflowAgent } from '@/api/agent.ts'
import { getProblemInfo, getProcessLadels, getAgentTags, postTicket } from '@/api/problem'
import { uploadFile } from '@/api/files'
import TemplateGenerator from '@/components/TemplateMaking/TemplateGenerator'
import { urgentLevels } from '@/api/label'
import TinymceEditor from '@/components/TinymceEditor.vue'
import SelectTemplate from '@/components/selectTemplate.vue'
import WorkflowTaskAppiontDrawer from '@/components/dialog/WorkflowTaskAppiontDrawer.vue'
export default {
  name: 'createTicket',
  // data
  components: {
    TemplateGenerator,
    TinymceEditor,
    SelectTemplate,
    WorkflowTaskAppiontDrawer,
  },
  data () {
    const checkNext = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择下一阶段'))
      } else {
        callback()
      }
    }
    return {
      isCheckedTemp: -1,
      problemId: '',
      showUploadFileList: false,
      checkedWorkflow: {
        key: undefined,
        name: undefined,
        planTime: {
          start: null,
          end: null
        }
      },
      planTime: {
        start: null,
        end: null
      },
      template: {},
      templateMap: {},
      workflowMap: {},
      workflowList: [],
      templateValue: {},
      templateList: [],
      labelList: [],
      problemForm: {
        describe: '',
        date: '',
        title: '',
        file: [],
        next: '',
        handler: '',
        ticketDescribe: ''
      },
      urgent: 'URGENT',
      ladelsList: [],
      handlerList: [],
      urgentList: [
      ],
      uploading: false,
      fileList: [],
      rules: {
        next: [
          // { required: true, message: '请选择下一阶段' },
          { validator: checkNext, trigger: 'blur' }
        ]
      },
      previewDialogVisible: false,
      previewDialogTitle: '',
      previewDialogImg: '',
      signal: null,
      workflowMilestoneTime: [],
      taskAppointVisible: false,
      taskAppointTable: [],
      activeAppointAgent: [],
      activeAppointItem: {},
      activeAgent: {},
      taskWorkflowList: [],
    }
  },
  watch: {
    previewDialogVisible (val) {
      if (val) {
        this.signal = new AbortController()
        document.addEventListener('mouseup', (e) => {
          e.stopPropagation()
          const popper = this.$refs.dialog.dialogRef
          if (e.target !== popper && !e.composedPath().includes(popper)) {
            this.previewDialogVisible = false
          }
        }, {
          capture: true,
          signal: this.signal.signal,
        })
      } else {
        this.signal.abort()
      }
    },
    'checkedWorkflow.milestone': {
      handler (val) {
        const arr = []
        let obj = {}
        for (let i = 0; i < this.checkedWorkflow?.milestone?.length; i++) {
          obj.name = this.checkedWorkflow?.milestone[i].name || '脏数据—模版删除'
          obj.time = null
          obj.delayDay = this.checkedWorkflow?.milestone[i].delayDay
          arr.push(obj)
          obj = {}
        }
        this.workflowMilestoneTime = arr
      },
      immediate: true,
      deep: true
    },
    workflowList (val) {
      this.workflowMilestoneTime = []
      this.checkedWorkflow = {}
    },
    // checkedWorkflow: {
    //   handler (val) {
    //     console.log('执行floew', val)
    //   },
    //   immediate: true,
    //   deep: true
    // }
  },
  created () {
    loadTemplateList().then((res) => {
      this.templateList = res
    }, (err) => {
      console.error(err)
      this.$message({
        type: 'error',
        message: err.errmsg
      })
    })
    this.getProcessLadels()
    const query = this.$route.query
    if (Object.keys(query).length > 0) {
      this.problemId = query.problemId
      this.getProblemInfo()
    } else {
      this.$router.back()
    }
    urgentLevels().then(res => {
      this.urgentList = res
      this.urgent = res[0]?.name || this.urgent
    }).catch(res => {
      console.error(res)
    })
  },
  mounted () {
    console.log('mounted')
  },
  methods: {
    changeAppointList (val) {
      console.log('执行', val)
      this.taskWorkflowList = val
    },
    changeWorkflowItem (row) {
      console.log(row)
    },
    getTaskAppointList (val, key) {
      getWorkflowAgent(val, key).then(res => {
        this.taskAppointTable = res
        console.log(res)
        const defaultAppoint = JSON.parse(localStorage.getItem('workflowAppoint'))
        this.taskAppointTable.map(i => {
          defaultAppoint && defaultAppoint.forEach(item => {
            if (i.tagId === item.tagId) {
              i.agentLdap = item.agentLdap
              i.agentName = item.agentName
              i.planAt = item.planAt ? item.planAt * 1000 : null
            }
          })
        })
      })
    },
    showTaskAppointVisible () {
      this.taskAppointVisible = true
    },
    changeMilestoneTime (val, key) {
      console.log(this.changeMilestoneTime)
    },
    previewWorkflow (workflow) {
      this.previewDialogTitle = workflow.name
      this.previewDialogImg = workflow.description
      this.previewDialogVisible = true
    },
    getProcessLadels () {
      getProcessLadels().then((res) => {
        this.ladelsList = res.filter(l => !l.isDeleted)
      }, (err) => {
        this.$message({
          type: 'error',
          message: err.errmsg
        })
        console.error(err)
      })
    },
    getProblemInfo () {
      const id = this.problemId
      getProblemInfo(id).then((res) => {
        const date = res.createTime.split(' ')[0]
        const t = res.createTime.split(' ')[1].split(':').slice(0, 2).join(':')
        const time = date + ' ' + t
        this.problemForm = {
          title: res.problemTitle,
          describe: res.problemDescribe,
          // ticketDescribe: res.problemDescribe,
          date: time,
          file: res.problemFiles || []
        }
      }, (err) => {
        this.$message({
          type: 'error',
          message: err.errmsg
        })
        console.error(err)
      })
    },
    selectTicketTemplate (item) {
      this.isCheckedTemp = this.templateList.findIndex(i => String(i.id) === String(item.id))
      const templateId = item.id
      if (this.templateMap[templateId]) {
        if (this.template !== this.templateMap[templateId]) {
          this.template = this.templateMap[templateId]
          this.templateValue = {}
        }
        this.workflowList = this.workflowMap[templateId] || []
        if (this.workflowList.length > 0) {
          this.$nextTick(() => {
            this.checkedWorkflow = this.workflowList[0]
            this.getTaskAppointList(this.checkedWorkflow.name, this.checkedWorkflow.key)
          })
        }
      } else {
        getTemplateById(templateId).then((res) => {
          this.templateMap[templateId] = res.template
          this.workflowMap[templateId] = res.workflows.list
          this.template = this.templateMap[templateId]
          this.workflowList = this.workflowMap[templateId] || []
          if (this.workflowList.length > 0) {
            this.$nextTick(() => {
              this.checkedWorkflow = this.workflowList[0]
              this.getTaskAppointList(this.checkedWorkflow.name, this.checkedWorkflow.key)
            })
          }
          this.templateValue = {}
        })
      }
    },
    uploadFile (info) {
      // if (this.uploading) {
      //   this.$message({
      //     type: 'error',
      //     message: `文件 ${info.file.name} 上传失败：其他文件正在上传`
      //   })
      //   info.onError(new Error('其他文件正在上传'))
      //   return
      // }
      this.uploading = true
      const { file } = info
      const formData = new FormData()
      formData.append('file', file, file.name)
      return uploadFile(formData).then((res) => {
        this.uploading = false
        this.fileList.push({
          name: file.name,
          key: res.fileId,
          url: res.url
        })
        // console.log('fileList', this.fileList)
      }, (err) => {
        this.uploading = false
        this.$message({
          type: 'error',
          message: `文件 ${file.name} 上传失败：${err.errmsg}`
        })
        console.error(err)
        throw Error(`文件 ${file.name} 上传失败：${err.errmsg}`)
      })
    },
    changeNext () {
      if (!this.problemForm.next) {
        this.problemForm.handler = ''
        this.handlerList = []
      } else {
        const data = {
          tagIds: this.problemForm.next,
          page: 1,
          limit: 600
        }
        getAgentTags(data).then((res) => {
          this.handlerList = res.results
        }, (err) => {
          this.$message({
            type: 'error',
            message: err.errmsg
          })
          console.error(err)
        })
      }
    },
    handleRemove (file) {
      const index = this.fileList.findIndex((fileInfo) => fileInfo.name === file.name)
      if (index >= 0) {
        this.fileList.splice(index, 1)
      }
    },
    handlePreview (file) {
      console.log(file)
    },
    createTicket () {
      if (this.isCheckedTemp < 0 || Object.keys(this.template).length === 0 || !this.$refs.templateGenerator) {
        this.$message({
          type: 'error',
          message: '请先选择模板'
        })
        window._trackEvent('ticket_create-tip_select_template', '弹出')
        return
      }
      this.$refs.templateGenerator.getData().then((data) => {
        const value = deepCopy(data)
        const labelInfo = {}
        Object.keys(this.templateList[this.isCheckedTemp].relationMap).forEach((key) => {
          labelInfo[this.templateList[this.isCheckedTemp].relationMap[key].setId] = {
            required: this.templateList[this.isCheckedTemp].relationMap[key].required,
            defaultValue: this.templateList[this.isCheckedTemp].relationMap[key].defaultValue,
            value: getValueFromObject(value, key)
          }
        })
        this.$refs.ticketForm.validate((valid) => {
          let titlePass = true
          const describePass = true
          if (!this.problemForm.title) {
            titlePass = false
            this.$message({
              type: 'error',
              message: '请输入工单标题！'
            })
          }
          // } else if (!this.problemForm.ticketDescribe) {
          //   describePass = false
          //   this.$message({
          //     type: 'error',
          //     message: '请描述一下工单！'
          //   })
          // }
          if (valid && titlePass && describePass) {
            const loading = this.$loading({
              lock: true,
              text: '工单创建中...',
              spinner: 'el-icon-loading',
              // background: 'rgba(0, 0, 0, 0.7)'
            })
            const data = {
              templateId: this.templateList[this.isCheckedTemp].id,
              problemId: Number(this.problemId),
              curPhaseId: this.problemForm.next,
              title: this.problemForm.title,
              relationMap: labelInfo,
              fieldInfo: value,
              responsibleAgent: this.problemForm.handler ? this.problemForm.handler : '',
              urgentLevel: this.urgent,
              // attachments: this.fileList,
              attachments: this.fileList.concat(this.problemForm.file),
              comment: this.problemForm.ticketDescribe ? this.problemForm.ticketDescribe : this.problemForm.describe,
              workflowKey: this.checkedWorkflow.key,
              workflowName: this.checkedWorkflow.name,
              workflowDiagram: this.checkedWorkflow.description,
              workflowPlan: {
                start: this.planTime.start && Math.floor((new Date(this.planTime.start).getTime() / 1000)),
                end: this.planTime.end && Math.floor((new Date(this.planTime.end).getTime() / 1000)),
              },
              workflowMilestone: this.workflowMilestoneTime?.map(i => ({
                ...i,
                time: i.time ? Math.floor((new Date(i.time).getTime() / 1000)) : null
              })),
              taskAssign: this.taskWorkflowList
            }
            postTicket(data).then((res) => {
              this.$message({
                type: 'success',
                message: '工单创建成功！'
              })
              this.$router.back()
              localStorage.setItem('updateProblemList', true)
            }, (err) => {
              this.$message({
                type: 'error',
                message: err.errmsg
              })
              console.error(err)
            }).finally(() => {
              loading.close()
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }, () => {
        this.$message({
          type: 'error',
          message: '工单不完整'
        })
      })
    },
    checkImage (filename) {
      return checkImage(filename)
    },
    imageList (filelist) {
      return filelist.filter(file => checkImage(file.name))
    },
    notImageList (filelist) {
      return filelist.filter(file => !checkImage(file.name))
    },
  },
  computed: {
  },
}
</script>

<style lang="less" scoped>
@import "../../assets/style/home.less";

.content{
  min-height: calc(100% - 70px) !important;
}
.workflow-select-box {
  height: 54px;
  border-bottom: 2px solid #f3f5f6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 10px;

  & > span {
    font-weight: 500;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
  }
}

.inp-width300 {
  ::v-deep(.el-input__inner) {
    width: 400px;
  }
}
#app .ticket .file-list-box {
  z-index: 10000;
  position: absolute;
  top: 34px;
  left: 0;
  width: 300px;
  //height: 136px;
  background: #eaecfb;
  border: 1px solid #4b79f3;
  border-radius: 3px;
  padding: 0px 16px;
  color: #4b79f3;
  //overflow: scroll;
  font-weight: 400;
  li {
    display: flex;
    // justify-content: space-between;
    // align-items: center;
    ::v-deep(button) {
      padding: 0;
      min-height: auto;
      color: #4b79f3;
      font-weight: 400;
    }
    & {
      // margin: 12px 0;
    }
  }
}
button {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
}
::v-deep {
  .tox-tinymce {
    border-color: transparent;
    border-radius: 3px;
    margin-top: -8px;
  }
}
.milestone-list{
  border-top: 2px solid #F3F5F6;
  padding:0 5px;
  .title{
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    // border-top: 2px solid #F3F5F6;
    padding: 15px 0;
  }
  .row{
    margin: 10px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .row-title{
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.65);
    max-width: 120px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  :deep(.date-select){
    width: 130px !important;
    .el-input__inner{
      width: 130px !important;
      padding-right: 20px !important;
      padding-left: 35px !important;
    }
  }
}
.generator{
    ::v-deep(.el-form-item) {
      display: flex;
      justify-content: flex-end;
    }
    :deep(.el-form-item__content){
      margin-left: 10px !important;
    }
}
.task-appoint{
  height: 50px;
  border-bottom: 2px solid #f3f5f6;
  display: flex;
  justify-content: center;
  align-items: center;
  & > button{
    min-width: 90%;
  }
}

</style>
<style lang="less">
@import "../../assets/style/workflow.less";
</style>
