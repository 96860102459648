<template>
  <div class="c-wrapper">
    <slot></slot>
  </div>
</template>
<script>

</script>
<style lang="less">
.c-wrapper {
  margin-left: 130px;
  margin-top: 48px;
  //height: fill;
  flex-grow: 1;
  display: flex;
  overflow: auto;
}
</style>
