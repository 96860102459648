import 'core-js/es'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import './permission'

const app = createApp(App)

app.config.errorHandler = (err, vm, info) => {
  // handle error
  // `info` is a Vue-specific error info, e.g. which lifecycle hook
  // the error was found in
  console.error(err)
  console.error(vm)
  console.error(info)
}

installElementPlus(app)
app.use(store).use(router).mount('#app')
