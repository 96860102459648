<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  mounted () {
    window.addEventListener('error', function (e) {
      const target = e.target // 当前dom节点
      const tagName = target.tagName
      // const count = Number(target.dataset.count) || 0 // 以失败的次数，默认为0
      // const max = 3 // 总失败次数，此时设定为3
      if (tagName?.toUpperCase() === 'IMG') {
        const urlObj = new URL(target.src)
        if (urlObj.host !== 'my.aibee.cn' || !urlObj.pathname.startsWith('/api/unicorn-files/v1/upload/')) {
          return
        }
        urlObj.host = window.location.host
        target.src = urlObj.href
      }
    }, true)
  },
}
</script>

<style lang="less">
@ticket-blue: #4b79f3;

html,body {
  background-color: #f3f5f6;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
}
html, body, #app {
  height: 100%;
}
#app {
  min-width: 1000px;
  display: flex;
  overflow: hidden;

  // 调整element-ui的默认样式
  .el-form-item__label {
    line-height: 32px;
  }
  .el-form-item__content {
    line-height: 32px;
  }
  .el-select {
    line-height: 32px;
  }
  .el-input {
    line-height: 32px;
  }
  .el-input__inner {
    line-height: 32px;
    height: 32px;
    // border: 1px solid #d9d9d9;
    border-radius: 3px;
    padding-left: 12px;
    font-family: PingFangSC, PingFangSC-Medium;
    color: rgba(0,0,0,0.85);
  }
  .el-input__icon {
    line-height: 32px;
  }
  .el-button:not(.el-button--text) {
    min-height: 32px;
    padding: 0 16px;
    border-radius: 2px;
    font-weight: 400;
  }

  .el-form-item {
    margin: 0;
  }
  .el-input--prefix .el-input__inner {
    padding-left: 30px;
  }
  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: inherit;
  }
  .el-form-item.is-error .el-input__inner, .el-form-item.is-error .el-input__inner:focus, .el-form-item.is-error .el-textarea__inner, .el-form-item.is-error .el-textarea__inner:focus, .el-message-box__input div.invalid>input, .el-message-box__input div.invalid>input:focus {
    border-color: #F26363;
    box-shadow: 0px -2px 0px 0px #ffccc7, -2px 0px 0px 0px #ffccc7, 2px 0px 0px 0px #ffccc7, 0px 2px 0px 0px #ffccc7;
  }
  .el-table .cell.el-tooltip {
    &::before {
      content: '';
      display: block;
    }
  }
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}
ol, ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: #000000;
  &:hover {
    color: @ticket-blue;
  }
}

.max-img {
  img {
    max-width: 100%;
  }
}
</style>
