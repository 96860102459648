import axios from '@/utils/request'

const app = '/unicorn-files'
// const app = '';

const unicornFilesUrl = {
  upload: '/v1/upload'
}

Object.keys(unicornFilesUrl).forEach((uri) => {
  unicornFilesUrl[uri] = app + unicornFilesUrl[uri]
})

export function uploadFile (data, onUploadProgress = null) {
  const url = unicornFilesUrl.upload
  return axios({
    url,
    method: 'post',
    data,
    timeout: 1000 * 60 * 10,
    onUploadProgress
  })
}
