import { generateFuncModules } from '@/components/TemplateMaking/TemplateField/TemplateFieldConfigForm'

const requireFieldList = require.context('.', true, /\.js$/)
const modules = {}

requireFieldList.keys().forEach((fileName) => {
  if (fileName === './index.js') return
  const path = fileName.replace(/(\.\/|\.js)/g, '')
  modules[path] = requireFieldList(fileName).default
  modules[path].configList.forEach((config) => {
    generateFuncModules[config](modules[path])
  })
})
export default modules
