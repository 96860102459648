<template>
  <div class="page">
    <div class="header-nav">
      <span @click="$router.back()" class="go-back">问题列表 / </span>
      <span class="title">{{pageTitle}}</span>
    </div>
    <div class="content">
      <div class="process" v-if="questionType == 'info'">
        <p>流程进度</p>
        <div style="overflow-x: auto;padding-bottom: 10px;">
          <!-- 问题流程改版 -->
          <el-steps :active="stepsBarList.length" align-center>
            <el-step v-for="(step, index) in stepsBarList" :key="index">
              <template #icon>
                <div class="icon"></div>
              </template>
              <template #title>
                <div class="step-title color-85">
                  <span>{{formatTitle(step.title)}}</span>
                  <div class="cost-time" v-if="step.costTime">
                    <p class="cost-icon"></p>
                    <p>{{formatTime(step.costTime)}}</p>
                  </div>
                </div>
              </template>
              <template #description>
                <div class="step-description">
                  <p class="step-name">{{(step.channel && step.channel === 5) ? step.operatorName : step.operator}}</p>
                  <p class="step-time">{{formatDate(step.createTime)}}</p>
                  <p v-if="step.title == 'doing' && step.operator && !(step.workflow)">
                    <button v-if="!step.pushable && step.operator != '待认领'" id="urged">催过啦<i class="feishu-icon"></i></button>
                    <button v-if="step.pushable && step.operator != '待认领'" @click="pushToFeiShu(step)" id="urge"
                      :data-track-click="JSON.stringify({
                         category: '问题详情-催一催',
                         action: '点击',
                      })"
                    >催一下<i class="feishu-icon"></i></button>
                    <span v-if="step.ifReaded && step.operator != '待认领'" class="icon-gou">
                      <el-tooltip content="催一下已被回复" placement="top">
                        <i></i>
                      </el-tooltip>
                    </span>
                  </p>
                  <!-- 工作流有多个任务并行，可通知多个处理人 -->
                  <p v-if="step.title == 'doing' && step.operator && step.workflow">
                    <span v-for="(notice, index) in step.notices" :key="index">
                      <button v-if="!notice.pushable && step.operator != '待认领'" id="urged">催过啦<i class="feishu-icon"></i></button>
                      <button v-if="notice.pushable && step.operator != '待认领'" @click="pushToFeiShu(notice)" id="urge"
                        :data-track-click="JSON.stringify({
                          category: '问题详情-催一催',
                          action: '点击',
                        })"
                      >催一下<i class="feishu-icon"></i></button>
                      <span v-if="notice.ifReaded && notice.operator != '待认领'" class="icon-gou">
                        <el-tooltip content="催一下已被回复" placement="top">
                          <i></i>
                        </el-tooltip>
                      </span>
                      &nbsp;
                    </span>
                  </p>
                </div>
              </template>
            </el-step>
          </el-steps>
        </div>
      </div>
      <div class="frame">
        <div v-if="replyForm.ticketId" class="left">
          <div class="tag">
            <template-generator
              :disabled="!isHandleable"
              ref="templateGenerator"
              v-if="Object.keys(template).length > 0"
              :template-data="template"
              v-model:value="templateValue"
              @change="templateValueChange"
              />
          </div>
        </div>
        <!-- 查看问题详情 -->
        <div class="form form2" v-if="questionType == 'info'">
          <el-form label-position="right" label-width="120px" :model="replyForm" :style="getStyle">
            <el-form-item label="问题编号:">
              <div class="text">{{problemId}}</div>
            </el-form-item>
            <el-form-item label="标题:">
              <div class="text">{{replyForm.title}}</div>
            </el-form-item>
            <el-form-item label="问题描述:">
              <div class="text" v-html="replyForm.describe"></div>
            </el-form-item>
            <el-form-item label="客户联系电话:" v-if="questionForm.channel === '5'">
              <div class="text" v-html="questionForm.telephone"></div>
            </el-form-item>
            <el-form-item label="紧急程度:">
              <div class="text">{{replyForm.urgent}}</div>
            </el-form-item>
            <el-form-item label="所属工单类型:">
              <div class="text">{{formatType(replyForm.problemType)}}</div>
            </el-form-item>
            <el-form-item label="创建时间:">
              <div class="text">{{replyForm.time}}</div>
            </el-form-item>
            <el-form-item label="问题来源及渠道:">
              <div class="text">{{formtterChannel(questionForm.channel)}}</div>
            </el-form-item>
            <el-form-item label="创建人:">
              <div class="text">{{questionSource}}</div>
            </el-form-item>
            <el-form-item label="关闭原因:" v-if="questionForm.status == -1">
              <div class="text" v-html="replyForm.problemComments ? replyForm.problemComments : '无'"></div>
              <!-- <div class="text">{{replyForm.problemComments}}</div> -->
            </el-form-item>
            <el-form-item label="关联的问题编号:" v-if="questionForm.status == -1">
              <div class="text">{{!replyForm.relateProblemList ? '无' : replyForm.relateProblemList}}</div>
            </el-form-item>
            <el-form-item label="" :class="{'last-message': commentsData.length > 0}">
              <div class="files-box" v-if="fileList.length > 0 && notImageList(fileList).length > 0" style="margin-top:10px;">
                <div v-for="(file, index) in fileList" :key="file.key">
                  <div  v-if="!checkImage(file.name)" class="flex-box">
                    <div class="flex-download">
                      <span style="margin-right: 10px;" class="file-name">{{file.name}}</span>
                      <el-link target="_blank" :href="file.url" :download="file.name">
                        <i class="icon-download"></i>
                      </el-link>
                    </div>
                    <button @click="deleteFile(index)">删除</button>
                  </div>
                </div>
              </div>
              <div v-if="fileList.length > 0 && imageList(fileList).length > 0" class="attach-img-box">
                <template v-for="(file, index) in fileList">
                  <div v-if="checkImage(file.name)"  :key="file.key" class="img-div">
                    <el-image
                      :src="file.url"
                      :preview-src-list="[file.url]"
                      style="width: 100px; height: 80px">
                    </el-image>
                    <span class="icon-del" @click="deleteFile(index)"></span>
                  </div>
                </template>
              </div>
            </el-form-item>
            <template v-if="commentsData.length > 0">
              <el-form-item label="客户评论:" v-for="(k, i) in commentsData" :key="i">
                <div class="text">{{k.content}}</div>
              </el-form-item>
            </template>
          </el-form>
          <!-- <div v-if="replyForm.ticketId && userLdapHashCode % 2 === 0" class="detail">
            <div @click="$router.push({name: '工单详情', params:{ id: replyForm.ticketId}})"
                class="submit-btn"
                :data-track-click="JSON.stringify({
                          category: 'question_detail-ticket_detail-2',
                          action: '点击',
                      })"
            >
              <span>进度详情</span>
              <i class="el-icon-arrow-down"></i>
            </div>
            <el-divider></el-divider>
          </div> -->
          <div class="btn-box" id="btn-box">
            <button @click="switchForm">取 消</button>
            <button @click="closeQuestion" v-if="questionForm.status == 0 || questionForm.status == 1" class="subscribe-btn">关 闭</button>
            <button @click="subscribeThis('1')" v-if="questionForm.status != -1 && questionForm.status != 1 && replyForm.subscribe == 0" class="subscribe-btn">订 阅</button>
            <button @click="subscribeThis('0')" v-if="questionForm.status != -1 && questionForm.status != 1 && replyForm.subscribe == 1" class="subscribe-btn">取消订阅</button>
            <button @click="createWorkOrder" v-if="questionForm.status == 0" class="submit-btn">创建工单</button>
            <template v-if="replyForm.ticketId">
              <button
                      @click="$router.push({name: '工单详情', params:{ id: replyForm.ticketId}})"
                      class="submit-btn"
                      style="width: 107px;"
                      :data-track-click="JSON.stringify({
                          category: 'question_detail-ticket_detail-1',
                          action: '点击',
                      })"
              >转至工单详情</button>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="close-question-dialog">
      <el-dialog
        title="关闭问题"
        v-model="closeQuestionDialog"
        width="40%"
        destroy-on-close
        :close-on-click-modal="false"
        center>
        <div>
          <div style="margin-bottom:20px;color: rgba(0, 0, 0, 0.85);">问题标题：<text v-html="closeQuestionTitle"></text></div>
          <div class="close-item">
            <label>原因：</label>
            <el-input v-model="comments" clearable placeholder="请输入反馈结果或关闭原因" style="margin-bottom:20px;"></el-input>
          </div>
          <div class="close-item">
            <label>关联问题：</label>
            <el-select
              v-model="associatedNumList"
              multiple
              clearable
              collapse-tags
              filterable
              remote
              :loading="selectLoading"
              :remote-method="remoteMethod"
              placeholder="输入问题编号或标题可查询问题">
              <el-option
                v-for="item in optionRelatedIssues"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <button @click="closeQuestionDialog = false" class="close-btn">取 消</button>
            <button @click="submitCloseQuestion" class="close-btn close-btn1">确 定</button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  addProblem, editProblem, getProblemInfo, problemProcess, getSubscribe, problemProcessUrge, closeProblemById,
  loadProblemList, loadGetGuestComment
} from '@/api/problem'
import * as ticketApi from '@/api/ticket'
import TemplateGenerator from '@/components/TemplateMaking/TemplateGenerator.vue'
import { uploadFile } from '@/api/files'
import store from '../../store'
import { urgentLevels } from '../../api/label'
import { hashCode, deepCopy, checkImage } from '../../utils/utils'
import { ElLoading } from 'element-plus'

export default {
  name: 'createQuestion',
  components: {
    TemplateGenerator,
  },
  // data
  data () {
    const checkTitle = (rule, value, callback) => {
      const title = value.replace(/(^\s*)|(\s*$)/g, '')
      if (title === '') {
        callback(new Error('请输入标题'))
      } else {
        callback()
      }
    }
    return {
      questionType: 'create',
      questionSource: '',
      pageTitle: '',
      problemId: '',
      questionForm: {
        title: '',
        describe: '',
        source: '',
        status: '',
        urgent: '',
        subscribe: '1',
        channel: '',
        problemType: '',
      },
      replyForm: {
        title: '',
        describe: '',
        reply: '',
        source: '',
        urgent: '',
        problemComments: '',
        relateProblemList: '',
        time: '',
        ticketId: '',
        file: {},
        subscribe: '',
        problemType: '',
      },
      uploading: false,
      fileList: [],
      urgentList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      commentsData: [],
      rules: {
        title: [
          { required: true, validator: checkTitle, trigger: 'blur' }
        ],
        describe: [
          { required: true, message: '请描述一下问题', trigger: 'blur' }
        ],
        urgent: [
          { required: true, message: '请选择问题紧急程度', trigger: 'blur' }
        ]
        // source: [
        //   { validator: checkSource, trigger: 'blur' }
        // ]
      },
      stepsList: [
        {
          stepTitle: '创建问题',
          title: 'create',
        },
        {
          stepTitle: '处理中',
          title: 'doing',
        },
        {
          stepTitle: '已解决',
          title: 'resolved',
        },
        {
          stepTitle: '已关闭',
          title: 'closed',
        },
      ],
      ongoing: 0,
      problemProcessMap: {},
      // 问题流程改版
      stepsBarList: [],

      closeQuestionDialog: false,
      optionRelatedIssues: [],
      closeQuestionTitle: '',
      comments: '',
      associatedNumList: [],

      ticket: {},
      template: {},
      templateValue: {},
      selectLoading: false,
      templateRecord: [],
    }
  },
  computed: {
    userLdapHashCode () {
      const user = store.getters['auth/me']
      return hashCode(user.ldap)
    },
    isHandleable () {
      return this.ticket.fields?.curStatus === 'closed' && this.questionForm.status !== -1
    },
    getStyle () {
      if (this.replyForm.ticketId) {
        return 'width: 100%;padding-left: 50px'
      } else {
        return ''
      }
    },
  },
  created () {
    const user = store.getters['auth/me']
    // this.questionSource = `${user?.group?.[0]?.groupName} - ${user?.username}`
    const query = this.$route.query
    if (query.problemId) {
      this.problemId = query.problemId
      this.questionType = query.type
      if (this.questionType === 'edit') {
        this.pageTitle = '修改问题'
      } else if (this.questionType === 'info') {
        this.pageTitle = '问题详情'
      }
      this.getProblemInfo()
    } else {
      this.pageTitle = '新建问题'
      this.questionType = 'create'
    }
    urgentLevels().then(res => {
      this.urgentList = res
    }).catch(res => {
      console.error(res)
    })
  },
  mounted () {
    this.commentFetch()
  },
  // methods
  methods: {
    templateValueChange (e) {
      this.$refs.templateGenerator.getData().then((data) => {
        const fieldInfo = deepCopy(data)
        const postData = {
          ticket: {
            fields: {
              fieldInfo,
            }
          },
        }
        const loadingInstance = ElLoading.service({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading'
        })
        ticketApi.updateTicket(this.ticket.pk, postData).then((res) => {
          console.log('updateTicket:', res)
        }, (err) => {
          console.error('updateTicket:', err)
        }).finally(() => {
          this.loadTicketDetail()
          loadingInstance.close()
        })
      })
    },

    labelsChange (e) {
      this.$refs.templateGenerator.getData().then((data) => {
        const fieldInfo = deepCopy(data)
        const postData = {
          ticket: {
            fields: {
              fieldInfo,
            }
          },
        }
        const loadingInstance = ElLoading.service({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading'
        })
        ticketApi.updateTicket(this.ticket.pk, postData).then((res) => {
          console.log('updateTicket:', res)
        }, (err) => {
          console.error('updateTicket:', err)
        }).finally(() => {
          this.loadTicketDetail()
          loadingInstance.close()
        })
      })
    },

    loadTicketDetail () {
      if (this.replyForm.ticketId) {
        const id = Number(this.replyForm.ticketId)
        ticketApi.ticketDetail(id).then(res => {
          const list = res.ticket.fields?.templateList || []
          this.templateRecord = list.filter(t => !t.isCurrent)
          const currentT = list.find(t => t.isCurrent)
          this.template = currentT?.template?.fields?.template
          this.templateValue = currentT?.fieldInfo
          this.ticket = res.ticket
          // this.template = res.ticket.fields.template.fields.template
          // this.templateValue = res.ticket.fields.fieldInfo
        }).catch(res => {
          console.error(res)
        })
      }
    },

    getProblemInfo () {
      const id = this.problemId
      getProblemInfo(id).then((res) => {
        const date = res.createTime.split(' ')[0]
        const t = res.createTime.split(' ')[1].split(':').slice(0, 2).join(':')
        this.replyForm = {
          title: res.problemTitle,
          describe: res.problemDescribe,
          source: res.problemOrigin,
          urgent: res.urgentLevel || '',
          problemComments: res.problemComments || '',
          relateProblemList: res.relateProblemList || '',
          time: date + ' ' + t,
          ticketId: res.ticketId,
          file: res.problemFiles || [],
          subscribe: String(res.subscribe),
          problemType: res.problemType || '',
        }
        this.fileList = res.problemFiles || []
        this.questionSource = Number(res.channel) === 5 ? res.creatorName : (res?.creatorObj?.name || res.creator)
        this.questionForm = {
          title: res.problemTitle,
          describe: res.problemDescribe,
          source: res.problemOrigin,
          status: res.problemStatus,
          urgent: res.urgentLevel || '',
          subscribe: String(res.subscribe),
          channel: String(res.channel),
          telephone: String(res.telephone),
          problemType: res.problemType || '',
        }
        this.replyForm.relateProblemList = this.replyForm.relateProblemList.replace(/\[|]|'/g, '')
        this.loadTicketDetail()
      }, (err) => {
        this.$message({
          type: 'error',
          message: err.errmsg
        })
        console.error(err)
      })
      problemProcess(id).then(res => {
        console.log(res)
        this.stepsBarList = res || []
      }, (err) => {
        this.$message({
          type: 'error',
          message: err.errmsg
        })
        console.error(err)
      })
    },
    uploadFile (info) {
      const { file } = info
      const formData = new FormData()
      formData.append('file', file, file.name)
      return uploadFile(formData).then((res) => {
        this.uploading = false
        this.fileList.push({
          name: file.name,
          key: res.fileId,
          url: res.url
        })
        this.fileList.concat(this.replyForm.file)
      }, (err) => {
        this.uploading = false
        this.$message({
          type: 'error',
          message: `文件 ${file.name} 上传失败：${err.errmsg}`
        })
        console.error(err)
        throw Error(`文件 ${file.name} 上传失败：${err.errmsg}`)
      })
    },
    deleteFile (index) {
      this.fileList.splice(index, 1)
    },
    handleRemove (file) {
      const index = this.fileList.findIndex((fileInfo) => fileInfo.name === file.name)
      if (index >= 0) {
        this.fileList.splice(index, 1)
      }
    },
    handlePreview (file) {
      console.log(file)
    },
    switchForm () {
      this.$router.back()
    },
    subscribeThis (type) {
      const num = Number(type)
      let tip = ''
      if (num === 0) {
        tip = '取消订阅'
      } else {
        tip = '订阅'
      }
      const loading = this.$loading({
        lock: true,
        text: `正在${tip}中...`,
        spinner: 'el-icon-loading',
        // background: 'rgba(0, 0, 0, 0.7)'
      })
      const param = {
        problemId: this.problemId,
        subscribe: num
      }
      getSubscribe(param).then(res => {
        loading.close()
        this.$message({
          type: 'success',
          message: `您已${tip}该问题`
        })
        this.getProblemInfo()
      }).catch(err => {
        loading.close()
        console.log(err)
      })
    },
    createWorkOrder () {
      this.$router.push({
        name: '创建工单',
        query: { problemId: this.problemId }
      })
    },
    pushToFeiShu (step) {
      const loading = this.$loading({
        lock: true,
        text: '正在发送飞书消息...',
        spinner: 'el-icon-loading',
        // background: 'rgba(0, 0, 0, 0.7)'
      })
      const data = {
        problemId: this.problemId,
        ldap: step.ldap
      }
      if (step.phaseId) {
        data.phaseId = step.phaseId
      }
      problemProcessUrge(data).then(res => {
        loading.close()
        this.$message({
          type: 'success',
          message: '您的催一下，已通过飞书发给对方'
        })
        this.getProblemInfo()
      }).catch(err => {
        loading.close()
        console.log(err)
      }).finally(() => {
        loading.close()
      })
    },
    submitQuestion (formName, type) {
      if (this.uploading) {
        this.$message({
          type: 'error',
          message: '附件正在上传中，无法提交问题，请耐心等待，稍后重试'
        })
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (type === 'add') {
            const loading = this.$loading({
              lock: true,
              text: '问题创建中...',
              spinner: 'el-icon-loading',
              // background: 'rgba(0, 0, 0, 0.7)'
            })
            const postData = {
              problemTitle: this.questionForm.title,
              problemDescribe: this.questionForm.describe,
              problemOrigin: this.questionSource,
              // problemOrigin: this.questionForm.source,
              problemFiles: this.fileList,
              urgentLevel: this.questionForm.urgent,
              subscribe: Number(this.questionForm.subscribe),
              problemType: this.questionForm.problemType,
            }
            addProblem(postData).then(() => {
              loading.close()
              this.$message({
                type: 'success',
                message: '问题提交成功'
              })
              this.$router.push({
                path: '/home'
              })
            }, (err) => {
              this.$message({
                type: 'error',
                message: err.errmsg
              })
              console.error(err)
            })
          } else {
            const loading = this.$loading({
              lock: true,
              text: '问题修改中...',
              spinner: 'el-icon-loading',
              // background: 'rgba(0, 0, 0, 0.7)'
            })
            const postData = {
              problemTitle: this.questionForm.title,
              problemDescribe: this.questionForm.describe,
              problemOrigin: this.questionSource,
              // problemOrigin: this.questionForm.source,
              problemFiles: this.fileList,
              problemStatus: this.questionForm.status,
              urgentLevel: this.questionForm.urgent,
              subscribe: Number(this.questionForm.subscribe),
              problemType: this.questionForm.problemType,
            }
            editProblem(this.problemId, postData).then(() => {
              loading.close()
              this.$message({
                type: 'success',
                message: '问题修改成功'
              })
              this.$router.back()
            }, (err) => {
              this.$message({
                type: 'error',
                message: err.errmsg
              })
              console.error(err)
            })
          }
        } else {
          return false
        }
      })
    },
    formatDate (date) {
      if (date) {
        const two = (n) => n < 10 ? '0' + n : '' + n
        const time = new Date(date * 1000)
        return `${time.getFullYear()}-${two(time.getMonth() + 1)}-${two(time.getDate())} ${two(time.getHours())}:${two(time.getMinutes())}`
      } else {
        return ''
      }
    },
    formatTime (time) {
      let theTime = parseInt(time)// 秒
      let min = 0// 分
      let hour = 0// 小时
      if (theTime > 59) {
        min = parseInt(theTime / 60)
        theTime = parseInt(theTime % 60)
        if (min > 59) {
          hour = parseInt(min / 60)
          min = parseInt(min % 60)
        }
      } else {
        return theTime + '秒'
      }
      // let result = '' + parseInt(theTime) + '秒'
      let result = ''
      if (min > 0) {
        result = '' + parseInt(min) + '分钟' + result
      }
      if (hour > 0) {
        result = '' + parseInt(hour) + '小时' + result
      }
      return result
    },
    formatTitle (title) {
      switch (title) {
        case 'create':
          return '创建问题'
        case 'doing':
          return '处理中'
        case 'resolved':
          return '已解决'
        case 'closed':
          return '已关闭'
        default:
          return ''
      }
    },
    formatType (type) {
      switch (type) {
        case 'task':
          return '任务'
        case 'warning':
          return '告警'
        case 'after_sale':
          return '售后'
        case 'test':
          return '测试'
        default:
          return ''
      }
    },
    formtterChannel (type) {
      switch (Number(type)) {
        case 1:
          return '内部来源 | 内部用户直接录入'
        case 2:
          return '内部来源 | Atlas'
        case 3:
          return '内部来源 | API'
        case 4:
          return '外部来源 | 400'
        case 5:
          return '外部来源 | 外部用户直接录入'
        case 6:
          return '内部来源 | 邮件'
        case 7:
          return '内部来源 | 小程序'
        case 8:
          return '外部来源 | 微信'
        case 9:
          return '外部来源 | Webhook'
        case 10:
          return '内部来源 | 客服小程序'
        default:
          return type
      }
    },
    checkImage (filename) {
      return checkImage(filename)
    },
    imageList (filelist) {
      return filelist.filter(file => checkImage(file.name))
    },
    notImageList (filelist) {
      return filelist.filter(file => !checkImage(file.name))
    },
    closeQuestion () {
      this.closeQuestionDialog = true
      this.loadRelatedIssues()
      this.comments = ''
      this.associatedNumList = []
      this.closeQuestionTitle = this.questionForm.title
    },
    submitCloseQuestion () {
      closeProblemById(this.problemId, this.comments, this.associatedNumList).then(() => {
        this.closeQuestionDialog = false
        this.$message({
          type: 'success',
          message: '关闭问题成功！'
        })
        this.switchForm()
      }, (err) => {
        this.$message({
          type: 'error',
          message: err.errmsg
        })
        console.error(err)
      })
    },
    loadRelatedIssues (keywords) {
      loadProblemList(keywords).then((res) => {
        this.relatedIssues = res.results
        this.optionRelatedIssues = this.relatedIssues.map(item => {
          return { value: `${item.problemId}`, label: `[${item.problemId}]${item.problemTitle}` }
        })
        this.selectLoading = false
      }, (err) => {
        this.$message({
          type: 'error',
          message: err.errmsg
        })
        console.error(err)
      })
    },
    remoteMethod (query) {
      this.selectLoading = true
      this.loadRelatedIssues(query)
    },
    commentFetch () {
      loadGetGuestComment({
        problemId: this.problemId,
      }).then(res => {
        this.commentsData = res
      })
    }
  },
}
</script>

<style lang="less" scoped>
@import "../../assets/style/home.less";
.ticket-add-tag-dialog {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 16px 24px;
  }
}
button {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
}
.urgent-select {
  width: 100%;
}
::v-deep {
  .tox-tinymce {
    border-color: #DCDFE6;
    border-radius: 3px;
  }
}
.content {
  flex-grow: 1;
  margin-top: -8px;
  justify-content: flex-start;
  flex-direction: column;
  .process {
    background: #fff;
    margin-bottom: 8px;
    padding: 8px 16px 0;
    font-size: 16px;
    ::v-deep(.el-steps) {
      .el-step {
        min-width: 130px;
        .el-step__line {
          height: 2px;
          top: 12px;
          background-color: #e9e9e9;
        }
      }
      .el-step__icon.is-text {
        border: 0;
      }
      .icon {
        width: 8px;
        height: 8px;
        background: #bfbfbf;
        border-radius: 50%;
      }
      .step-title {
        position: relative;
        .cost-time {
          font-size: 12px;
          color: #4b79f3;
          position: absolute;
          top: -10px;
          // left: -40px;
          left: -50%;
          right: 50%;
          .cost-icon {
            width: 12px;
            height: 12px;
            background: url(../../assets/images/cost-time.svg);
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      .step-title {
        font-size: 16px;
        color: rgba(0,0,0,0.45);
      }
      .step-name {
        font-size: 14px;
        color: rgba(0,0,0,0.65);
      }
      .step-time {
        font-size: 12px;
        color: rgba(0,0,0,0.45);
      }
      .is-process {
        .step-title {
          // color: rgba(0,0,0,0.85);
          color: #4b79f3;
        }
        .icon {
          background: #4b79f3;
        }
      }
      .is-finish {
        .step-title {
          color: rgba(0,0,0,0.65);
        }
        .icon {
          background: #4b79f3;
        }
        .el-step__line {
          background-color: #4b79f3;
        }
      }
      #urge {
        height: 21px;
        line-height: 21px;
        padding: 0 6px;
        border-radius: 2px;
        background: #4b79f3;
        color: #fff;
        font-size: 12px;
      }
      .feishu-icon {
        display: inline-block;
        width: 13px;
        height: 13px;
        background: url(../../assets/images/feishu-icon.svg);
        position: relative;
        top: 2px;
        margin-left: 6px;
      }
      #urged {
        height: 21px;
        line-height: 20px;
        padding: 0 6px;
        border-radius: 2px;
        background: #c3c3c9;
        color: #ffffff;
        font-size: 12px;
      }
      .icon-gou {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: url(../../assets/images/gou.svg);
        vertical-align: middle;
        margin-left: 4px;
      }
    }
  }
  .frame {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-grow: 1;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-grow: 0;
      .tag {
        // width: 30vw;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 25px 16px 0 0;
        margin: 0px 6px 0px 0px;
        min-width: 25vw;
        flex-grow: 1;
        ::v-deep(.el-input) {
          width: calc(25vw - 110px);
        }
      }
    }

  }
  .detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div:first-child {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 110px;
      > :first-child {
        margin-right: 8px;
        font-size: 16px;
      }
    }
  }
  #btn-box .subscribe-btn {
    margin-left: 16px;
    background: rgba(53,73,224,0.10);
    border: 1px solid #4b79f3;
    color: #4b79f3;
  }
}
.last-message {
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(196, 196, 196, 0.32);
}
</style>

<style lang="less">
.el-select-dropdown__item.selected {
  color: #4b79f3;
}
</style>
