<template>
  <el-select v-model="value" v-bind="$attrs" :disabled="disable">
    <el-option
      v-for="item in itemList"
      :key="item.value"
      :label="item.label"
      :value="item.value"
      :disabled="item.isDeleted && (value === item.value || (Array.isArray(value) && value.indexOf(item.value) < 0))"
    >
    </el-option>
  </el-select>
</template>

<script>

export default {
  name: 'HSelect',
  emits: ['update:modelValue'],
  props: {
    fieldDetail: {
      type: Object,
      default: () => ({})
    },
    disable: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [Array, Number, String, Object]
    },
    itemList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    }
  }
}
</script>

<style scoped>

</style>
