import axios from '../utils/request'

const app = '/unicorn-ticket'

const authUrls = {
  login: '/auth/login',
  logout: '/auth/logout',
  readme: '/auth/readme',
  getSignature: `${app}/auth/signature`,
  larkLogin: `${app}/auth/lark_login`,
  changePassword: '/auth/change-password',
}

export function larkLogin (code) {
  const data = {
    code
  }
  const url = authUrls.larkLogin
  return axios({
    url,
    method: 'post',
    data
  })
}

export function login (code) {
  const data = {
    code
  }
  const url = authUrls.login
  return axios({
    url,
    method: 'post',
    data
  })
}

export function readme () {
  const url = authUrls.readme
  return axios({
    url,
    method: 'get'
  })
}

export function logout () {
  const url = authUrls.logout
  return axios({
    url,
    method: 'post'
  })
}

export function getSignature () {
  return axios({
    url: authUrls.getSignature,
    method: 'post',
    data: {
      url: navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
        ? location.href.split('#')[0].replace('{', '%7B').replace('}', '%7D')
        : location.href.split('#')[0],
    },
  })
}

export function NewPassword (code) {
  const data = code
  const url = authUrls.changePassword
  return axios({
    url,
    method: 'post',
    data
  })
}
