<template>
  <div class="ticket-view-search-form" id="ticket-view-search-form">
    <el-form :inline="true" :model="formData" class="search-form">
      <el-form-item label="关键字:" class="keywords">
        <el-input @keyup.enter="submit(formData)" v-model="formData.keywords" placeholder="请输入工单编号、标题或工单描述进行查询"></el-input>
      </el-form-item>
      <el-form-item label="状态:" class="urgent" v-if="useStatus">
        <el-select v-model="formData.curStatus" placeholder="请选择">
          <el-option v-for="s in statusList" :key="s.value" :label="s.label" :value="s.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="紧急程度:" class="urgent">
        <el-select v-model="formData.urgentLevel" placeholder="请选择">
          <el-option label="所有" :value="''"></el-option>
          <el-option v-for="l in urgentLevels" :key="l.name" :label="l.name" :value="l.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建时间:" class="times">
        <el-date-picker
          :editable="false"
          prefix-icon=""
          v-model="formData.dateRange"
          type="datetimerange"
          format="YYYY-MM-DD HH:mm"
          unlink-panels
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :shortcuts="shortcuts"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="工单类型:" class="works">
        <el-select v-model="formData.workType" placeholder="请选择">
          <el-option v-for="w in workTypes" :key="w.label" :label="w.label" :value="w.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="工单模版:" class="urgent">
        <el-select v-model="formData.templateId" placeholder="请选择" filterable>
          <el-option label="所有" :value="''"></el-option>
          <el-option v-for="t in templateList" :key="t.id" :label="t.name" :value="t.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="处理时长:" class="duration" v-if="useStatus">
        <el-select v-model="formData.processDuration" placeholder="输入数字" filterable allow-create default-first-option @change="formatDuration">
          <el-option label="所有" :value="null"></el-option>
          <el-option v-for="t in processList" :key="t.value" :label="t.label" :value="t.value" :disabled="t.disabled"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="使用工作流:" class="works">
        <el-select v-model="formData.workflow" placeholder="请选择">
          <el-option v-for="w in workflowTypes" :key="w.label" :label="w.label" :value="w.value"></el-option>
        </el-select>
      </el-form-item>
      <span class="search-int"  v-for="set in extraFilters" :key="set.id">
      <el-form-item :label="`${set.labelSetName}:`" class="works">
        <span class="custom-search">
          <el-select v-model="set.setValue" filterable clearable placeholder="请选择" @change="loadTagFilters">
            <el-option
              v-for="l in set.labels"
              :key="l.id"
              :label="l.name"
              :value="l.id">
            </el-option>
          </el-select>
        </span>
      </el-form-item>
          </span>

      <el-form-item class="form-item submit-button">
          <span class="add-search" style="margin-right: 19px;">
            <span>|</span>
            <el-tooltip content="点击可添加筛选条件" placement="top">
              <i @click="showQuestionFilterSelect = true"
                 :data-track-click="JSON.stringify({
                   category: '问题列表-添加筛选条件',
                   action: '点击',
                 })"
              ></i>
            </el-tooltip>
          </span>
      </el-form-item>
      <el-form-item class="form-item submit-button">
        <el-button type="primary" @click="submit(formData)">查询</el-button>
      </el-form-item>
    </el-form>
  </div>
          <div class="filter-select">
            <el-dialog
              :destroy-on-close="true"
              width="681px"
              :show-close="true"
              :before-close="onQuestionFilterSelectClose"
              v-model="showQuestionFilterSelect"
            >
            <QuestionFilterSelect
              :cancel="cancelQuestionFilterSelect"
              :submit="submitQuestionFilterSelect"
              :setIds="setIds"
            ></QuestionFilterSelect>
            </el-dialog>
          </div>
</template>
<script>
import { Options, Vue } from 'vue-class-component'
import { urgentLevels } from '@/api/label'
import QuestionFilterSelect from '@/components/QuestionFilterSelect.vue'
import * as labelApi from '@/api/label'
import { loadTemplateList } from '@/api/template'
import { ElMessage } from 'element-plus'
import {
  getProcessLadels,
} from '@/api/problem'
@Options({
  name: 'TicketViewSearchForm',
  props: {
    submit: Function,
    keywords: String,
    useStatus: Boolean,
    queryForm: String,
  },
  emits: ['update:keywords'],
  components: { QuestionFilterSelect }
})
export default class TicketViewSearchForm extends Vue {
  formData = {
    keywords: '',
    urgentLevel: '',
    dateRange: [],
    workType: '',
    tagFilter: null,
    curStatus: '',
    templateId: '',
    processDuration: null,
    workflow: '',
  }

  shortcuts = [{
    text: '最近7天',
    value: (() => {
      const end = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1) // 当天23:59
      const start = new Date(new Date(new Date().toLocaleDateString()).getTime()) // 当天0点
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
      return [start, end]
    })()
  }, {
    text: '最近30天',
    value: (() => {
      const end = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1) // 当天23:59
      const start = new Date(new Date(new Date().toLocaleDateString()).getTime()) // 当天0点
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 29)
      return [start, end]
    })()
  }, {
    text: '最近90天',
    value: (() => {
      const end = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1) // 当天23:59
      const start = new Date(new Date(new Date().toLocaleDateString()).getTime()) // 当天0点
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 89)
      return [start, end]
    })()
  }]

  urgentLevels = []
  templateList = []
  extraFilters = []
  workTypes = [
    {
      value: '',
      label: '所有'
    },
    {
      value: 'task',
      label: '任务'
    },
    {
      value: 'warning',
      label: '告警'
    },
    {
      value: 'after_sale',
      label: '售后'
    },
    {
      value: 'test',
      label: '测试'
    },
  ]

  workflowTypes = [
    {
      value: '',
      label: '所有'
    },
    {
      value: 'true',
      label: '是'
    },
    {
      value: 'false',
      label: '否'
    },
  ]

  processList = [
    {
      value: '4',
      label: '<=4小时'
    },
    {
      value: '8',
      label: '<=8小时'
    },
    {
      value: '24',
      label: '<=24小时'
    },
    {
      value: '48',
      label: '<=48小时'
    },
    {
      value: '72',
      label: '<=72小时'
    },
    {
      value: '>-48',
      label: '>=48小时'
    },
    {
      value: '>-72',
      label: '>=72小时'
    },
    {
      value: -100,
      label: '可输入时长查询',
      disabled: true,
    },
  ]

  statusList = [
    {
      value: '',
      label: '所有'
    },
    {
      value: 'unassigned',
      label: '待认领'
    },
    {
      value: 'pending',
      label: '处理中'
    },
    {
      value: 'suspended',
      label: '已挂起'
    },
    {
      value: 'closed',
      label: '已解决'
    },
  ]

  showQuestionFilterSelect = false

  onQuestionFilterSelectClose () {
    this.showQuestionFilterSelect = false
  }

  cancelQuestionFilterSelect () {
    this.showQuestionFilterSelect = false
  }

  submitQuestionFilterSelect (e) {
    // TODO 把setIds存储到本地
    const setIds = e.map(s => s.id)
    localStorage.setItem('__ticket_ticketList_setIds', JSON.stringify(setIds))
    this.showQuestionFilterSelect = false
    this.loadExtraFilters()
  }

  loadExtraFilters () {
    const s = localStorage.getItem('__ticket_ticketList_setIds')
    if (s) {
      const setIds = JSON.parse(s)
      if (setIds.length > 0) {
        const setIdValueMap = this.extraFilters.reduce((ac, cu) => {
          ac[cu.id] = cu.setValue
          return ac
        }, {})
        this.loadSetValues(setIds, setIdValueMap)
      } else {
        this.extraFilters = []
      }
    }
  }

  formatDuration () {
    // if (isNaN(Number(this.formData.processDuration))) {
    //   this.formData.processDuration = null
    //   return ElMessage({
    //     showClose: true,
    //     message: '请输入数字',
    //     type: 'warning'
    //   })
    // }
    const chinese = /[\u4E00-\u9FA5A-Za-z]|[^0-9]$/
    if (this.formData.processDuration && chinese.test(this.formData.processDuration)) {
      this.formData.processDuration = null
      return ElMessage({
        showClose: true,
        message: '请正确输入数字',
        type: 'warning'
      })
    }
  }

  async loadSetValues (setIds, setIdValueMap = {}) {
    try {
      const res = await labelApi.ticketLabelSet(0)
      const ticketLabelSetList = res.filter(e => e.useForTicket && setIds.includes(e.id))
      this.extraFilters = ticketLabelSetList
      for (const e of this.extraFilters) {
        labelApi.getLabelsBySetId(e.id).then(res => {
          e.labels = res
          e.setValue = setIdValueMap[e.id]
        }).catch(err => {
          console.error(err)
        })
      }
    } catch (e) {
      console.error('listLabelSet error:', e)
      this.extraFilters = []
    }
  }

  loadTagFilters () {
    const list = this.extraFilters.map(el => {
      if (el.setValue) {
        return `${el.id}-${el.setValue}`
      }
    })
    const tagFilter = list.filter(item => item)
    this.formData.tagFilter = tagFilter.join(',')
    return this.formData.tagFilter
  }

  mounted () {
    let setIds = []
    let setIdValueMap = {}
    let query = this.$route.query

    if (Object.keys(query).length === 0) {
      // const c = localStorage.getItem('__ticket_TicketList_query_criteria')
      const c = this.queryForm
      if (c) {
        const q = JSON.parse(c)
        if (Object.keys(q).length > 0) {
          query = q
        }
      }
    }

    const defaultEnd = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1) // 当天23:59
    const defaultStart = new Date(new Date(new Date().toLocaleDateString()).getTime()) // 当天0点
    defaultStart.setTime(defaultStart.getTime() - 3600 * 1000 * 24 * 29)
    this.formData.dateRange = [defaultStart, defaultEnd]

    if (query.keywords) {
      this.formData.keywords = query.keywords
    }
    if (query.urgentLevel) {
      this.formData.urgentLevel = query.urgentLevel
    }
    if (query.workType) {
      this.formData.workType = query.workType
    }
    if (query.startTime && query.endTime) {
      this.formData.dateRange = [new Date(Number(query.startTime) * 1000), new Date(Number(query.endTime) * 1000)]
    }
    if (query.curStatus) {
      this.formData.curStatus = query.curStatus
    }
    if (query.templateId) {
      this.formData.templateId = query.templateId
    }
    if (query.processDuration) {
      this.formData.processDuration = query.processDuration
    }
    if (query.workflow) {
      this.formData.workflow = query.workflow
    }

    if (query.tagFilter) {
      // 形如 2-5,3-10,14-63
      const setIdValueArray = query.tagFilter.split(',').map(e => e.split('-'))
      setIds = setIdValueArray.map(e => Number(e[0]))
      setIdValueMap = setIdValueArray.reduce((ac, cu) => {
        ac[cu[0]] = Number(cu[1])
        return ac
      }, {})
      this.extraFilters = setIdValueArray.map(e => ({
        id: Number(e[0]),
        setValue: Number(e[1]),
      }))
    }
    const s = localStorage.getItem('__ticket_ticketList_setIds')
    if (s) {
      const setIds2 = JSON.parse(s)
      if (setIds2.length > 0) {
        setIds = [...new Set(setIds.concat(setIds2)).add(1)]
      }
    }
    this.loadSetValues(setIds, setIdValueMap)
    this.loadTagFilters()
    urgentLevels().then(res => {
      this.urgentLevels = res
    }).catch(res => {
      console.error(res)
    })

    loadTemplateList().then(res => {
      this.templateList = res
      // console.log('templateList', this.templateList)
    }, (err) => {
      console.error(err)
    })

    this.$watch('formData', () => {
      const ss = []
      if (this.formData.keywords) {
        ss.push(this.formData.keywords)
      }
      if (this.formData.urgentLevel) {
        ss.push(this.formData.urgentLevel)
      }
      if (this.formData.templateId) {
        ss.push(this.formData.templateId)
      }
      if (this.formData.processDuration) {
        ss.push(this.formData.processDuration)
      }
      if (this.formData.workflow) {
        ss.push(this.formData.workflow)
      }
      if (this.formData.workType) {
        ss.push(this.workTypes.find(w => w.value === this.formData.workType).label)
      }
      const s = ss.join(' | ')
      this.$emit('update:keywords', s)
    }, {
      deep: true
    })
  }

  get setIds () {
    return this.extraFilters.map(e => e.id)
  }
}
</script>
<style lang="less">
#ticket-view-search-form {
  .submit-button {
    margin-bottom: 16px;
  }
  .el-form-item {
    float: left;
  }

  .el-form-item__label {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0,0,0,0.85);
  }

  .keywords {
    margin-right: 32px;
    margin-bottom: 10px;
    .el-form-item__content {
      width: 226px;
    }
  }
  .urgent {
    margin-right: 32px;
    margin-bottom: 10px;
    .el-form-item__content {
      width: 140px;
    }
  }
  .duration {
    margin-right: 32px;
    margin-bottom: 10px;
    .el-form-item__content {
      width: 110px;
    }
  }
  .times {
    margin-right: 32px;
    margin-bottom: 10px;
    .el-date-editor--daterange {
      width: 100%;
    }
    .el-form-item__content {
      width: 310px;
    }
    .el-range-separator {
      // display: flex;
      // align-items: center;
      line-height: 20px;
    }
    .el-range-input {
      width: 115px;
    }
    .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner {
      width: 310px;
    }
  }
  .works {
    margin-right: 32px;
    margin-bottom: 10px;
    .el-form-item__content {
      width: 130px;
    }
  }
}
      .add-search {
        margin-left: -32px;
        span {
          color: #e9e9e9;
          margin: 0 8px;
        }
        i {
          display: inline-block;
          width: 24px;
          height: 24px;
          background: url('../assets/images/add-search.svg');
          vertical-align: middle;
          cursor: pointer;
        }
      }
</style>
