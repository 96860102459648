<template>
  <div class="optIpt-drawer">
    <el-drawer
      v-model="addOptVisible"
      :title="'表单设定-' + (appointInfo?.tagName || '')"
      direction="rtl"
      size="60%"
      :before-close="beforeCloseDrawer"
    >
      <div class="drawer-body">
        <div
          class="layoutHeight input-box"
          ref="inputBox"
          style="height: calc(50% - 50px)"
        >
          <div class="title">
            <span>输入：</span>
            <el-button type="primary" @click="addInputRow">添加一行</el-button>
          </div>
          <div class="body"  style="height:100%">
            <el-table
              :data="inputData"
              stripe
              style="width: 100%;height: calc(100% - 50px) "
              height="calc(50% - 50px)"
              :row-class-name="activeInputClass"
              class="inputTable"
            >
              <el-table-column prop="label" label="数据源" min-width="180">
                <template #default="{ row, $index }">
                  <div class="input-select-box">
                    <el-select
                      v-model="row.fromTagId"
                      style="width: 40%"
                      placeholder="请选择节点"
                      size="large"
                      @change="changeTagId(row, $index)"
                    >
                      <el-option
                        v-for="item in tagOptions"
                        :key="item.fromTagId"
                        :label="item.tagName"
                        :value="item.fromTagId"
                      >{{ item.tagName }}</el-option>
                    </el-select>
                    <span style="margin:0 20px;">-</span>
                    <el-select
                      v-model="row.fromFieldLabel"
                      style="width: 50%"
                      placeholder="请先选择节点"
                      size="large"
                      @change="changeInput"
                    >
                      <el-option
                        v-for="item in getChildOptions(row)"
                        :key="item.title"
                        :label="item.title"
                        :value="item.title"
                      />
                    </el-select>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100">
                <template #default="{ row, $index }">
                  <span @click="delInputRow(row, $index)" class="del-icon"
                    >删除</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div
          class="layoutHeight output-box"
          id="control"
          style="height: calc(50% + 50px)"
        >
          <div class="title">
            <span>输出：</span>
            <el-button type="primary" @click="addOutputRow">添加一行</el-button>
          </div>
          <div class="body" style="height:100%">
            <el-table
              :data="outputData"
              stripe
              style="width: 100%;height: calc(100% - 50px)"
              height=" calc(100% - 50px)"
              @row-click="selectOutputRow(i)"
              :row-class-name="activeClass"
              class="outputTable"
              >
              <el-table-column prop="label" label="名称" min-width="150">
                <template #default="scope">
                  <el-input
                    v-model="scope.row.label"
                    placeholder="请输入label名称"
                    maxlength="15"
                    show-word-limit
                  />
                </template>
              </el-table-column>
              <el-table-column prop="type" label="类型" min-width="100">
                <template #default="{ row, $index }">
                  <el-select
                    v-model="row.type"
                    class="m-2"
                    placeholder="Select"
                    size="large"
                    @change="changOtpType(row, $index)"
                  >
                    <el-option
                      v-for="item in typeOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="required" label="必填" min-width="80">
                <template #default="scope">
                  <el-switch v-model="scope.row.required" />
                </template>
              </el-table-column>
              <el-table-column label="备注" min-width="160">
                <template #default="scope">
                  <el-input
                    class="optIptRemark"
                    v-model="scope.row.remark"
                    placeholder="请输入备注"
                    maxlength="30"
                    show-word-limit
                  />
                </template>
              </el-table-column>
              <el-table-column label="操作" min-width="90">
                <template #default="{ row, $index }">
                  <span @click="delOutputRow(row, $index)" class="del-icon"
                    >删除</span
                  >
                  <span v-if="row.type === 'checkbox' || row.type === 'radio'"
                    >&nbsp;/&nbsp;</span
                  >
                  <span
                    @click="editOutputRow(row, $index)"
                    class="del-icon"
                    v-if="row.type === 'checkbox' || row.type === 'radio'"
                    >编辑</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 编辑选项 -->
        <div class="options-box" v-if="showEditOptionVisible">
          <div class="header">
            <span class="return-button" @click="returnOutput">
              <img src="@/assets/images/return-icon.svg" /> 返回输出
            </span>
            编辑选项：
            <el-button type="primary" @click="addOptionsRow"
              >添加一行</el-button
            >
          </div>
          <div class="body">
            <el-table
              :data="outputData[editOptionInfo?.index]?.options"
              stripe
              style="width: 100%; height: 100%"
            >
              <el-table-column prop="label" label="选项名称" min-width="180">
                <template #default="{ row }">
                  <el-input v-model="row.label"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100">
                <template #default="{ row, $index }">
                  <span @click="delOutputOptionRow(row, $index,editOptionInfo?.index)" class="del-icon"
                    >删除</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <template #footer><button></button></template>
    </el-drawer>
    <template v-if="addOptVisible&&outputData?.length>0">
      {{ showInputOverview }}
      <div class="overview-box" v-if="showInputOverview">
        <div class="header">预览:输入</div>
        <div class="body">
          <OutputListCom :dataList="outputData" ></OutputListCom>
        </div>
      </div>
      <div class="overview-box"  v-if="!showInputOverview">
        <div class="header">预览:输出</div>
        <div class="body">
          <OutputListCom :dataList="outputData" :disable="true" type="overview"></OutputListCom>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import OutputListCom from '../OutputListCom.vue'
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    appointInfo: {
      type: Object,
    },
    taskList: {
      type: Array,
    }
  },
  components: { OutputListCom },
  data () {
    return {
      index: 1,
      showOutputOverView: false,
      showEditOptionVisible: false,
      showInputOverview: false,
      editOptionsData: [],
      editOptionInfo: null,
      overviewData: [],
      overviewVisible: true,
      tagOptions: [],
      typeOptions: [
        { label: '输入框', value: 'input' },
        { label: '截图', value: 'img' },
        { label: '单选', value: 'radio' },
        { label: '复选', value: 'checkbox' },
        { label: '链接', value: 'url' },
        { label: '附件', value: 'file' },
        { label: '时间', value: 'date' }
      ],
      dragIndex: null,
      newDragIndex: null,
      activeType: 'output'
    }
  },
  mounted () {
    console.log('mounted')
  },
  methods: {
    addInputRow (val) {
      this.inputData.push({
        label: '',
        fromTagId: null,
        fromTagName: '',
        fromFieldLabel: '',
        remark: '',
        title: ''
      })
      // 给新添加的行增加 拖拽
      this.$nextTick(() => {
        const tbody = document.querySelectorAll('.inputTable .isDragBox')
        tbody.forEach((i, idx) => {
          i.setAttribute('draggable', 'true')
          i.ondragend = () => this.dragEndItem('input')
          i.ondragstart = () => this.dragStartItem(idx)
          i.ondragover = () => this.dragOverItem(idx)
        })
      })
    },
    delOutputOptionRow (val, idx, fatherIndx) {
      this.outputData[fatherIndx].options.splice(idx, 1)
    },
    delInputRow (val, idx) {
      this.inputData.splice(idx, 1)
    },
    addOutputRow () {
      this.outputData.push({
        name: 'name',
        type: 'input',
        title: 'title',
        label: '默认名称' + this.index++,
        value: null,
        required: false,
        remark: '',
        placeholder: '只做展示用',
        options: []
      })
      // 给新添加的行增加 拖拽
      this.$nextTick(() => {
        const tbody = document.querySelectorAll('.outputTable .isDragBox')
        tbody.forEach((i, idx) => {
          i.setAttribute('draggable', 'true')
          i.ondragend = () => this.dragEndItem('output')
          i.ondragstart = () => this.dragStartItem(idx)
          i.ondragover = () => this.dragOverItem(idx)
        })
      })
    },
    changOtpType (val, index) {
      if (val?.type === 'radio') {
        this.outputData[index].options = [{ label: '选项1', value: null }, { label: '选项2', value: null }]
      } else if (val?.type === 'checkbox') {
        this.outputData[index].value = []
        this.outputData[index].options = [{ label: '选项1', value: null }, { label: '选项2', value: null }]
      } else if (val?.type === 'img') {
        this.outputData[index].value = []
      } else {
        this.outputData[index].value = null
      }
    },
    delOutputRow (val, idx) {
      this.outputData.splice(idx, 1)
    },
    editOutputRow (val, idx) {
      this.showEditOptionVisible = true
      this.editOptionInfo = val
      this.editOptionInfo.index = idx
    },
    returnOutput () {
      this.showEditOptionVisible = false
    },
    selectOutputRow (val) {
      console.log('row', val)
    },
    addOptionsRow () {
      if (this.outputData[this.editOptionInfo?.index].options instanceof Array) {
        this.outputData[this.editOptionInfo?.index].options.push({
          label: '选项' + this.index++,
          value: null
        })
      } else {
        this.outputData[this.editOptionInfo?.index].options = [{
          label: '选项',
          value: null
        }]
      }
    },
    changeTagId (val, index) {
      const tag = this.taskList.find(i => i.tagId === val?.fromTagId)
      this.appointOptions = tag?.formInfo?.outFields?.map(i => ({
        title: i.label,
      }))
      this.inputData[index].fromTagName = tag?.tagName
      this.inputData[index].fromFieldLabel = this.appointOptions[0]?.title || null
    },
    changeInput (val) {
      console.log('input-last', this.inputData)
    },
    getChildOptions (row) {
      return this.tagOptions?.find(i => i.fromTagId === row.fromTagId)?.childOptions || []
    },
    beforeCloseDrawer (done) {
      // 验证输出名称是否重复
      const outputDataLen = this.outputData.length
      const labelData = this.outputData.map(i => i.label)
      if (Array.from(new Set(labelData)).length < outputDataLen) {
        this.$message({
          type: 'warning',
          message: '输出名称不允许重复，请检查'
        })
        return done(true)
      }
      // 验证输入不能为空
      const inputDataLen = this.inputData.length
      const isntNullData = this.inputData.filter(i => {
        return i.fromTagId && i.fromFieldLabel
      })
      if (inputDataLen !== isntNullData?.length) {
        this.$message({
          type: 'warning',
          message: '输入数据源不能为空，将为您自动清除数据源为空的输入'
        })
        this.inputData = [].concat(isntNullData)
        return done(true)
      }
      done(false)
    },
    // 拖拽系列
    activeClass ({ row, rowIndex }) {
      if (rowIndex === this.newDragIndex && this.activeType === 'output') {
        return 'isDragBox active-drag'
      }
      return 'isDragBox'
    },
    activeInputClass ({ row, rowIndex }) {
      if (rowIndex === this.newDragIndex && this.activeType === 'input') {
        return 'isDragBox active-drag'
      }
      return 'isDragBox'
    },
    dragStartItem (idx, type) {
      this.activeType = type
      this.dragIndex = idx
    },
    dragOverItem (index) {
      this.newDragIndex = index
    },
    dragEndItem (type) {
      if (type === 'output') {
        const data = this.outputData[this.dragIndex]
        this.outputData.splice(this.dragIndex, 1)
        this.outputData.splice(this.newDragIndex, 0, data)
      } else if (type === 'input') {
        const data = this.inputData[this.dragIndex]
        this.inputData.splice(this.dragIndex, 1)
        this.inputData.splice(this.newDragIndex, 0, data)
      }
      this.newDragIndex = null
    },
  },
  emits: ['update:show', 'update:outputData', 'update:inputData'],
  computed: {
    addOptVisible: {
      get () {
        if (this.show === true) {
          this.$nextTick(() => {
            const tbody = document.querySelectorAll('.outputTable .isDragBox')
            tbody.forEach((i, idx) => {
              i.setAttribute('draggable', 'true')
              i.ondragend = () => this.dragEndItem('output')
              i.ondragstart = () => this.dragStartItem(idx, 'output')
              i.ondragover = () => this.dragOverItem(idx, 'output')
            })
            const inputBody = document.querySelectorAll('.inputTable .isDragBox')
            inputBody.forEach((i, idx) => {
              i.setAttribute('draggable', 'true')
              i.ondragend = () => this.dragEndItem('input')
              i.ondragstart = () => this.dragStartItem(idx, 'input')
              i.ondragover = () => this.dragOverItem(idx, 'input')
            })
          })
        }
        return this.show
      },
      set (val) {
        this.$emit('update:show', val)
      },
    },
    outputData: {
      get () {
        return this.appointInfo?.formInfo?.outFields
      },
      set (val) {
        this.$emit('update:outputData', val)
      }
    },
    inputData: {
      get () {
        return this.appointInfo?.formInfo?.inFields
      },
      set (val) {
        this.$emit('update:inputData', val)
      }
    },
  },
  watch: {
    appointInfo: {
      handler (val) {
        this.tagOptions = this.taskList.filter(i => i?.formInfo?.outFields?.length > 0 && val?.tagId !== i.tagId).map(i => ({
          fromTagId: i.tagId,
          tagName: i.tagName || i.fromTagName,
          childOptions: i.formInfo?.outFields?.map(i => ({ title: i.label }))
        }))
      },
      deep: true,
      // immediate: true,
    },
    taskList: {
      handler (val) {
        this.tagOptions = val.filter(i => i?.formInfo?.outFields?.length > 0 && this.appointInfo?.tagId !== i.tagId).map(i => ({
          fromTagId: i.tagId,
          tagName: i.tagName || i.fromTagName,
          childOptions: i.formInfo?.outFields?.map(i => ({ title: i.label }))
        }))
      },
      deep: true,
    }
  },
}
</script>

<style lang="less" scoped>
.drag-item{
  cursor: move;
}
:deep(.isDragBox){
  cursor: move;
  position: relative;
  transition: transform 0.3s;
}
:deep(.active-drag){
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #4B79F3;
    z-index:99;
  }
}
.output-box {
  &:hover .overview-box {
    display: none;
  }
}

.optIpt-drawer {
  .optIptRemark{
    :deep(.el-input__inner){
      padding-right: 50px;
    }
  }
  .el-drawer {
    // overflow: visible !important;
  }

  .el-drawer__title {
    margin: 0;
  }
}

.drawer-body {
  padding: 10px 20px;
  height: 100%;
}

.del-icon {
  color: #4b79f3;

  &:hover {
    cursor: pointer;
  }
}
.input-select-box {
  display: flex;
}

.overview-box {
  background: white;
  height: 50%;
  width: 25%;
  position: absolute;
  right: 60%;
  bottom: 0;
  z-index: 9999;
  border-right: 1px solid #666;
  padding: 15px;

  .header {
    height: 30px;
    width: 100%;
  }

  .body {
    height: calc(100% - 50px);
    width: 100%;
    overflow: auto;
  }
}

.options-box {
  background: white;
  height: calc(50% + 50px);
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 9999;
  padding: 15px;

  .header {
    margin-left: 10px;

    .return-button {
      color: #4b79f3;

      img {
        height: 15px;
        width: 15px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
