
import { Options, Vue } from 'vue-class-component'
import * as labelApi from '@/api/label'
import store from '@/store'
import { Label, LabelSet } from '@/api/entity'
import { ticketLabelSet } from '@/api/label'

@Options({
  name: 'QuestionFilterSelect',
  props: {
    cancel: Function,
    submit: Function,
    setIds: Array
  }
})
export default class QuestionFilterSelect extends Vue {
  setIds: Array<any> = []
  checkList: Array<any> = []
  ticketLabelSetList: Array<LabelSet> = []

  get ticketLabelSetListSelected () : any {
    return this.ticketLabelSetList.filter(s => this.checkList.includes(s.id))
  }

  mounted () {
    this.checkList.push(...this.setIds)
    labelApi.ticketLabelSet(0).then((res) => {
      const ticketLabelSetList = res.filter(e => e.useForTicket)
      ticketLabelSetList.forEach(e => {
        e.labels = []
        e.showLabeItemlList = false
      })
      this.ticketLabelSetList = ticketLabelSetList
      this.ticketLabelSetList.forEach((e: LabelSet) => {
        labelApi.getLabelsBySetId(e.id).then(res => {
          e.labels = res
        }).catch(res => {
          console.error('getLabelsBySetId error:', res)
        })
      })
    }).catch((res) => {
      console.error('listLabelSet error:', res)
    })
  }
}
