import { larkLogin, login, logout, readme } from '@/api/auth'
import { getToken } from '@/utils/corsLocalStorageUtil'

const state = {
  token: localStorage.getItem('token') || null,
  me: {}
}

const getters = {
  me: (state) => {
    return state.me
  },
  token: (state) => {
    return state.token
  },
  isTicketAdmin: (state) => {
    return state.me.role?.some(r => r.roleName === 'ticket_admin')
  }
}

function makeAuthCommonParams (url, params) {
  let connect = '?'
  if (url.indexOf('?') !== -1) {
    connect = '&'
  }
  const [...paramPair] = Object.keys(params).map((key) => `${key}=${params[key]}`)
  const queryString = paramPair.join('&')
  return url + connect + queryString
}

const actions = {
  redirectAuth ({ commit }, redirectUrl) {
    commit('logout')
    const queryParam = {
      // scope: 'all:all',
      // response_type: 'code',
      // client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
      // redirect_uri: process.env.VUE_APP_AUTH_REDIRECT_URL
      theme: 'support',
    }
    if (redirectUrl && redirectUrl !== '') {
      queryParam.state = encodeURIComponent(window.location.origin + redirectUrl)
    } else {
      queryParam.state = encodeURIComponent(window.location.origin)
    }
    window.location.href = makeAuthCommonParams(process.env.VUE_APP_AUTH_LOGIN_PAGE_URL, queryParam)
  },
  redirectFeishuAuth ({ commit }, redirectUrl) {
    commit('logout')
    const domain = window.location.origin
    const uri = `${process.env.BASE_URL}login`
    // if (process.env.NODE_ENV === 'development') {
    //   uri = '/login';
    // }
    const queryParam = {
      app_id: process.env.VUE_APP_ID,
      redirect_uri: `${domain}${uri}`,
    }
    if (redirectUrl !== '') {
      queryParam.state = encodeURIComponent(redirectUrl)
    }
    window.location.href = makeAuthCommonParams('https://open.feishu.cn/open-apis/authen/v1/index', queryParam)
  },
  readmeFromAuth ({ commit }) {
    return getToken().then((value) => {
      commit('login', value)
    }, (err) => Promise.reject(err))
  },
  readme ({ commit }) {
    return readme().then((value) => {
      commit('readme', value)
    }, (err) => Promise.reject(err))
  },
  login ({ commit }, code) {
    return login(code).then((value) => {
      commit('login', value.token)
      commit('readme', value)
    }, (err) => Promise.reject(err))
  },
  larkLogin ({ commit }, code) {
    return larkLogin(code).then((value) => {
      commit('login', value.token)
      commit('readme', value)
    }, (err) => Promise.reject(err))
  },
  logout ({ commit }) {
    return logout().then(() => {
      commit('logout')
    }, (err) => Promise.reject(err))
  }
}

const mutations = {
  login (state, token) {
    localStorage.setItem('token', token)
    state.token = token
  },
  logout (state) {
    state.token = null
    state.me = {}
    localStorage.removeItem('token')
  },
  readme (state, value) {
    state.me = value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
