
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'BreadCrumb'
})
export default class BreadCrumb extends Vue {
  mounted () {
    console.log()
  }
}
