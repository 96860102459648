<template>
  <div class="page">
    <div class="header-nav">
      <span class="title">值班信息</span>
    </div>
    <div class="onduty">
      <div class="btn" v-if="userIsAdmin">
        <button @click="setUrgency">设置紧急联系人</button>
        <button v-if="checkList.length > 0" @click="setOnduty" class="set-onduty">批量排班</button>
        <button v-else class="set-onduty-disabled">批量排班</button>
      </div>
      <el-checkbox-group v-model="checkList">
      <el-calendar v-model="dateChange">
        <template #dateCell="{data}">
          <div class="calendar">
            <el-tooltip placement="bottom" :show-after="500">
              <template #content>
                <div v-if="monthOnduty[data.day] && monthOnduty[data.day].ondutyInfo.length > 0">
                  <div class="not-full" v-if="monthOnduty[data.day] && monthOnduty[data.day].status === 0">
                    <i></i>&nbsp;&nbsp;排班时间，没有覆盖24个小时
                  </div>
                  <div v-for="(item, index) in monthOnduty[data.day].ondutyInfo" :key="index" class="onduty-info">
                    <div class="left">{{item.level}}</div>
                    <div class="right">
                      <p v-for="people in item.people" :key="people.id">
                        {{people.startTime > 9 ? people.startTime + ':00' : '0' + people.startTime + ':00'}} - {{people.endTime > 9 ? people.endTime + ':00' : '0' + people.endTime + ':00'}} | {{people.name?.join('、')}}
                      </p>
                    </div>
                  </div>
                </div>
                <div v-else style="font-size:14px;">暂无排班</div>
              </template>
              <div style="outline:0;height: 20px;">
                <el-checkbox :label="data.day" :disabled="filterDissbledIsTrue(data)"></el-checkbox>
                <span @click="editOnduty(data)">
                  <span class="duty-day" style="font-size:18px;margin-left:6px;">{{ Number(data.day.split('-').slice(2).join('-')) }}日</span>
                  <i class="icon-selected" v-if="monthOnduty[data.day] && monthOnduty[data.day].status === 1"></i>
                  <i class="icon-selected-grey" v-if="monthOnduty[data.day] && monthOnduty[data.day].status === 0"></i>
                </span>
                <div v-if="monthOnduty[data.day]?.ondutyInfo.length > 0">
                  <div v-for="(item, index) in monthOnduty[data.day].ondutyInfo" :key="index">
                    <div v-for="people in item.people" :key="people.id" class="day-info">
                      <span class="left">{{people.startTime > 9 ? people.startTime + ':00' : '0' + people.startTime + ':00'}} - {{people.endTime > 9 ? people.endTime + ':00' : '0' + people.endTime + ':00'}}</span>
                      <!-- <span class="right">{{people.name?.length > 1 ? people.name.slice(0,1).join('、') + '...' : people.name.join('、')}}</span> -->
                      <span class="right">{{people.name.join('、')}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </el-tooltip>
          </div>
        </template>
      </el-calendar>
      </el-checkbox-group>
    </div>
    <SchedulingDialog :dates="dates" v-if="showSchedulingDialog" :cancel="cancelScheduling" :submit="submitScheduling"></SchedulingDialog>
    <!-- 紧急联系人 -->
    <SchedulingUrgencyPeople v-if="ShowSchedulingUrgencyPeople" :cancel="cancelUrgency" :submit="submitUrgency"></SchedulingUrgencyPeople>
  </div>
</template>

<script>
import SchedulingDialog from '@/components/SchedulingDialog.vue'
import { saveBatchScheduling, saveEmergencyDuty, getEmployeeScheduling, employeeSchedulingStatus, updateScheduling } from '../../api/scheduling'
import SchedulingUrgencyPeople from '@/components/SchedulingUrgencyPeople.vue'
import { ElLoading, ElMessage } from 'element-plus'
export default {
  name: 'Onduty',
  components: {
    SchedulingDialog,
    SchedulingUrgencyPeople,
  },
  data () {
    return {
      dateChange: new Date(),
      checkList: [],
      monthOnduty: {},
      ondutyInfo: [],
      edit: false,
      showSchedulingPersonSelect: false,
      showSchedulingDialog: false,
      ShowSchedulingUrgencyPeople: false,
      dates: [
      ],
    }
  },
  created () {
    const year = this.dateChange.getFullYear()
    const month = this.dateChange.getMonth() + 1
    this.loadDutyOfDate(year, month)
  },
  mounted () {
    const week = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
    const thead = document.getElementsByClassName('el-calendar-table')[0].children[0].children
    thead.forEach((item, index) => {
      item.innerHTML = week[index]
    })
  },
  computed: {
    userIsAdmin () {
      return this.$store.getters['auth/isTicketAdmin']
    }
  },
  methods: {
    loadDutyOfDate (year, month) {
      employeeSchedulingStatus(year, month).then((res) => {
        this.monthOnduty = res
      }, (err) => {
        this.$message({
          type: 'error',
          message: err.errmsg
        })
        console.error(err)
      })
    },
    setOnduty () {
      const dates = this.checkList.slice().sort().map((item, index) => {
        const a = item.split('-')
        return {
          year: Number(a[0]),
          month: Number(a[1]),
          date: Number(a[2]),
        }
      })
      this.dates = dates
      this.showSchedulingDialog = true
    },
    editOnduty (data) {
      if (!this.userIsAdmin) {
        return
      }
      if (this.monthOnduty[data.day]?.ondutyInfo && this.isPastTime(data.date)) {
        return this.$message({
          type: 'warning',
          message: '此日期不可修改排班！',
          showClose: true,
        })
      } else {
        const d = data.day.split('-')
        this.dates = [{
          year: Number(d[0]),
          month: Number(d[1]),
          date: Number(d[2]),
        }]
        this.showSchedulingDialog = true
      }
    },
    // 日期多选框是否可选状态
    filterDissbledIsTrue (data) {
      if (this.monthOnduty[data.day]?.ondutyInfo && this.isPastTime(data.date)) {
        return true
      } else {
        return false
      }
    },
    // 判断是否为过去时间
    isPastTime (date) {
      const t = new Date()
      const num = date.getTime() - t.getTime()
      if (num > 0) {
        return false
      } else {
        return true
      }
    },
    cancelScheduling (data) {
      this.showSchedulingDialog = false
    },
    submitScheduling (data) {
      console.log('submitScheduling:', data)
      this.showSchedulingDialog = false
      // TODO 设置排班 调用接口
      const payload = {}
      payload.schedulingDates = data.datesSelected.map(e => `${e.year}-${e.month}-${e.date}`)
      payload.timeArrangements = data.scheduleList.map(e => {
        return {
          startTime: Number(e.startTime.split(':')[0]),
          endTime: Number(e.endTime.split(':')[0]),
          ldaps: e.persons.map(p => p.ldap)
        }
      })
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading'
      })
      if (payload.schedulingDates.length === 1) {
        payload.schedulingDate = payload.schedulingDates[0]
        updateScheduling(payload).then(res => {
          console.log(res)
          ElMessage({
            showClose: true,
            message: '提交成功',
            type: 'success'
          })
        }).catch(err => {
          console.error(err)
          ElMessage({
            showClose: true,
            message: '提交失败',
            type: 'error'
          })
        }).finally(() => {
          loading.close()
          this.loadDutyOfDate(this.dateChange.getFullYear(), this.dateChange.getMonth() + 1)
          this.checkList.splice(0)
        })
      } else {
        saveBatchScheduling(payload).then(res => {
          console.log(res)
          ElMessage({
            showClose: true,
            message: '提交成功',
            type: 'success'
          })
        }).catch(err => {
          console.error(err)
          ElMessage({
            showClose: true,
            message: '提交失败',
            type: 'error'
          })
        }).finally(() => {
          loading.close()
          this.loadDutyOfDate(this.dateChange.getFullYear(), this.dateChange.getMonth() + 1)
          this.checkList.splice(0)
        })
      }
    },
    setUrgency () {
      console.log(this.ShowSchedulingUrgencyPeople, '123')
      this.ShowSchedulingUrgencyPeople = true
    },
    cancelUrgency () {
      console.log(this.ShowSchedulingUrgencyPeople)
      this.ShowSchedulingUrgencyPeople = false
    },
    submitUrgency (val) {
      saveEmergencyDuty(val).then(rv => {
        console.log(rv)
        this.ShowSchedulingUrgencyPeople = false
        ElMessage({
          showClose: true,
          message: '提交成功',
          type: 'success'
        })
      }).catch(err => {
        console.log(err)
        ElMessage({
          showClose: true,
          message: '提交失败',
          type: 'error'
        })
      })
    }
  },
  watch: {
    dateChange (newdate) {
      const year = newdate.getFullYear()
      const month = newdate.getMonth() + 1
      this.loadDutyOfDate(year, month)
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/style/home.less";
.onduty {
  background: #ffffff;
  border-radius: 2px;
  margin: 16px 16px 0 16px;
  padding: 16px;
  flex-grow: 1;
  min-width: 1000px;
}
#app .btn {
  float: right;
  button {
    display: inline-block;
    padding: 0 12px;
    height: 32px;
    line-height: 32px;
    color: #4b79f3;
    background: rgba(53,73,224,0.10);
    border: 1px solid #4b79f3;
    border-radius: 3px;
    font-size: 14px;
  }
  .set-onduty {
    background: #4b79f3;
    color: #ffffff;
    margin-left: 16px;
  }
  .set-onduty-disabled {
    background: #c3c3c9;
    color: #ffffff;
    border: 1px solid #c3c3c9;
    margin-left: 16px;
  }
}
#app .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #000;
}
::v-deep(.el-checkbox-group) {
  font-size: 14px;
}
::v-deep(.el-calendar-table) {
  td.is-selected {
    background-color: #fff;
  }
  .el-calendar-day {
    // padding: 30px 0 0 0;
    // text-align: center;
    height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      background: #eaecfb;
    }
    .calendar {
      // height: 86px;
      // overflow: hidden;
    }
  }
  .prev, .next {
    .el-calendar-day {
      display: none;
    }
  }
  tr td:first-child {
    border-left: 0;
  }
  td {
    border-right: 0;
  }
  thead th {
    color: rgba(0,0,0,0.85);
    background: #eaecfb;
    padding: 0;
    border-right: 1px solid #ffffff;
  }
  thead th:last-child {
    border-right: 0;
  }
  tr:first-child td {
    border-top: 1px solid #ffffff;
  }
  tr:last-child td {
    border-bottom: 0;
  }
  td.is-today {
    // color: #000;
    color: #4b79f3;
    // .duty-day {
    //   display: inline-block;
    //   width: 50px;
    //   height: 50px;
    //   line-height: 50px;
    //   background: #4b79f3;
    //   border-radius: 50%;
    //   color: #ffffff;
    //   position: relative;
    //   top: -12px;
    // }
    // .el-checkbox {
    //   position: relative;
    //   top: -12px;
    // }
    // .icon-selected, .icon-selected-grey {
    //   position: relative;
    //   top: -9px !important;
    // }
  }
  .el-checkbox__label {
    display: none;
  }
}
::v-deep(.el-calendar) {
  .el-checkbox {
    color: #000;
  }
  .el-calendar__header {
    padding: 0;
    justify-content: center;
    border-bottom: 0;
    display: block;
    text-align: center;
  }
  .el-calendar__title {
    color: rgba(0,0,0,0.85);
    font-size: 20px;
    float: left;
    width: 135px;
    text-align: left;
  }
  .el-calendar__body {
    padding: 0;
    margin-top: 16px;
  }
  .el-button-group {
    .el-button {
      background: rgba(53,73,224,0.10);
      border: 1px solid #4b79f3;
      color: #4b79f3;
      padding: 0 16px;
      span {
        display: none;
      }
      &:nth-of-type(2) {
        margin: 0 8px;
        span {
          display: block;
          font-size: 14px;
        }
      }
      &:nth-of-type(1), &:nth-of-type(3) {
        border: 0;
        background: url(../../assets/images/icon-prve-blue.svg) no-repeat center;
      }
      &:nth-of-type(3) {
        transform: rotate(180deg);
      }
    }
  }
}
#app .onduty {
  .top {
    display: flex;
    justify-content: space-between;
  }
}
.day-info {
  // padding-right: 8px;
  font-size: 12px;
  min-width: 145px;
  margin-bottom: 4px;
  height: 19px;
  clear: both;
  .left {
    float: left;
    // padding: 0 3px;
    width: 85px;
    text-align: center;
    background: #eaecfb;
    border-radius: 2px;
    color: rgba(34,35,37,0.75);
  }
  .right {
    float: left;
    margin-left: 10px;
    // width: 34%;
    width: calc(100% - 100px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgba(0,0,0,0.75);
  }
}
</style>

<style lang="less">
.el-popper {
  .not-full {
    color: #ED6A0C;
    font-size: 14px;
    margin-bottom: 8px;
    i {
      display: inline-block;
      width: 12px;
      height: 11px;
      background: url(../../assets/images/warning.svg);
    }
  }
  .onduty-info {
    display: flex;
    margin-bottom: 13px;
    font-size: 14px;
    div {
      line-height: 20px;
    }
    p {
      line-height: 20px;
    }
    .left {
      min-width: 70px;
      margin-right: 15px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
button {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
}
</style>
