<template>
  <div class="page">
    <div class="header-nav">
      <span class="title">{{pageTitle}}</span>
      <img
        @click="refresh"
        class="btn-refresh"
        src="@/assets/images/icon-refresh.png"
        :class="{
          loading: refreshing
        }"
      >
    </div>
    <div class="content1">
      <div class="work-order">
        <div class="search" style="">
          <span class="search-item">
            <el-select v-model="statusValue" filterable placeholder="请选择问题状态">
              <el-option
                v-for="item in orderStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </span>
          <span class="search-item">
            <el-input
              style="width: 241px;"
              v-model="keywords"
              placeholder="输入编号、标题或问题描述查询"
              @keyup.enter="searchTicket"
              clearable
              @clear="searchTicket"
            />
          </span>
          <span class="search-item times">
            <label>创建时间：</label>
            <el-date-picker
              :editable="false"
              prefix-icon=""
              v-model="dateRange"
              type="daterange"
              unlink-panels
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :shortcuts="shortcuts"
            >
            </el-date-picker>
          </span>
          <el-button type="primary" @click="searchTicket" class="search-btn" style="margin:0;">查 询</el-button>
        </div>
      </div>
      <div class="data-table">
        <div class="ops-btns">
          <el-button type="primary" @click="createQuestion" class="add-problem"><i class="el-icon-plus" style="margin-right: 8px;"></i>新建问题</el-button>
        </div>
        <el-table
          class="question-list"
          v-loading="loading"
          :data="tableData"
          ref="questionTable"
          border
          @sort-change="sortChange"
          :header-cell-class-name="handleHeaderCellClass"
          tooltip-effect="dark"
          style="width: 100%">
          <el-table-column
            prop="problemId"
            show-overflow-tooltip
            label="问题编号"
            sortable="custom"
          >
            <template #default="{row}">
              <span @click="getProblemInfo(row, 'info')" class="color-blue">{{row.problemId}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="problemTitle"
            show-overflow-tooltip
            label="标题">
            <template #default="{row}">
              <span @click="getProblemInfo(row, 'info')" class="color-blue">{{row.problemTitle}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="problemDescribe"
            show-overflow-tooltip
            label="问题描述">
            <template #default="scope">
              <el-tooltip v-if="scope.row.problemDescribe"  placement="top">
                <template #content>
                  <div v-html="scope.row.problemDescribe"></div>
                </template>
                <div class="problem-describe" v-html="scope.row.problemDescribe"></div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="creatorName"
            show-overflow-tooltip
            label="创建人">
          </el-table-column>
          <el-table-column
            prop="problemStatus"
            show-overflow-tooltip
            label="问题状态"
            sortable="custom">
            <template #default="{row}">
              <div>
                <i class="status-icon" :class="{'icon-green':row.problemStatus == 0 || row.problemStatus == 2,'icon-blue':row.problemStatus == 1 || row.problemStatus == -1}"></i>
                <span v-if="row.problemStatus == 0 || row.problemStatus == 2 ">进行中</span>
                <span v-if="row.problemStatus == 1 || row.problemStatus == -1 ">已完成</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            show-overflow-tooltip
            sortable="custom"
            label="创建时间"
            :formatter="createTimeFilter">
          </el-table-column>
        </el-table>
        <div class="pagination-wrap" v-if="totalCount > 0">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="prev, pager, next, sizes, jumper, total"
            :total="totalCount">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { unref } from 'vue'
import { toLine, deepCopy } from '@/utils/utils'
import * as labelApi from '../../api/label'
import { ElMessage } from 'element-plus'
import { agentsByTag } from '@/api/agent.ts'
import { guestGetProblem } from '@/api/problem'
import * as _ from 'lodash'

export default {
  name: 'questionList',
  components: {
  },
  // data
  data () {
    return {
      pageTitle: '问题列表',
      loading: false,
      orderStatus: [
        {
          value: 'all',
          label: '全部'
        },
        {
          value: 'doing',
          label: '进行中'
        },
        {
          value: 'done',
          label: '已完成'
        },
      ],
      statusMap: {
        all: '',
        doing: '0,2',
        done: '1,-1',
      },
      statusValue: 'all',
      keywords: '',
      dateRange: [],
      create_or_subscribe: '',
      shortcuts: [{
        text: '最近7天',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
          return [start, end]
        })()
      }, {
        text: '最近30天',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 29)
          return [start, end]
        })()
      }, {
        text: '最近90天',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 89)
          return [start, end]
        })()
      }],
      pageSize: 10,
      pageNumber: 1,
      totalCount: 0,
      tableData: [],
      refreshing: false,
      hackTableFlag: false,
      orderArray: [
        { prop: 'problemStatus', order: 'ascending' },
        { prop: 'createTime', order: 'descending' }
      ],
    }
  },
  methods: {
    refresh () {
      this.refreshing = true
      this.orderArray = [
        { prop: 'problemStatus', order: 'ascending' },
        { prop: 'createTime', order: 'descending' }
      ]
      this.pageNumber = 1
      this.pageSize = 10
      this.statusValue = 'all'
      this.keywords = ''
      this.dateRange = []
      this.init()
      setTimeout(() => {
        this.refreshing = false
        this.$message({
          showClose: true,
          message: '刷新成功',
          type: 'success'
        })
      }, 1000)
    },
    searchTicket () {
      this.numInit()
    },
    // creatorFilter (row) {
    //   if (row.creatorObj) {
    //     return row.creatorObj.name
    //   } else {
    //     return row.creatorName
    //   }
    // },
    createTimeFilter (row) {
      const date = row.createTime.split(' ')[0]
      const time = row.createTime.split(' ')[1].split(':').slice(0, 2).join(':')
      return date + ' ' + time
    },
    createQuestion () {
      this.$router.push({
        name: '创建问题1'
      })
    },
    sortChange ({ prop, order }) {
      console.log(prop, 'prop')
      console.log(order, 'order')
      if (this.hackTableFlag === false) {
        console.log()
        this.$refs.questionTable.store.mutations.changeSortCondition = (states, options) => {
          const { sortingColumn: column, sortProp: prop, sortOrder: order } = states
          const columnValue = unref(column)
          const propValue = unref(prop)
          const orderValue = unref(order)
          if (unref(order) === null) {
            states.sortingColumn.value = null
            states.sortProp.value = null
          }
          const ingore = { filter: true }
          this.$refs.questionTable.store.execQuery(ingore)

          if (!options || !(options.silent || options.init)) {
            this.$refs.questionTable.$emit('sort-change', {
              column: columnValue,
              prop: propValue,
              order: orderValue,
            })
          }
        }
        this.hackTableFlag = true
      }
      if (order) { // 参与排序
        let flagIsHave = false
        this.orderArray.forEach(element => {
          if (element.prop === prop) {
            element.order = order
            flagIsHave = true
          }
        })
        if (!flagIsHave) {
          this.orderArray.push({
            prop: prop,
            order: order
          })
        }
      } else { // 不参与排序
        let orderIndex = 0
        this.orderArray.forEach((element, index) => {
          if (element.prop === prop) {
            orderIndex = index
          }
        })
        this.orderArray.splice(orderIndex, 1)
      }
      this.init()
    },
    handleHeaderCellClass ({ row, column, rowIndex, columnIndex }) {
      this.orderArray.forEach(element => {
        if (column.property === element.prop) {
          column.order = element.order
        }
      })
    },
    numInit () {
      this.pageNumber = 1
      this.init()
    },
    async init () {
      const ordering = this.orderArray.reduce((res, cur) => {
        let temp = res
        if (res !== '') {
          temp += ','
        }
        if (cur.order === 'ascending') {
          temp += toLine(cur.prop)
        } else {
          temp += `-${toLine(cur.prop)}`
        }
        return temp
      }, '')
      const [startTime, endTime] = this.getStartEndTime(this.dateRange)
      const data = {
        problemStatus: this.statusMap[this.statusValue],
        ordering,
        keywords: this.keywords,
        startTime,
        endTime,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      }
      this.loading = true
      try {
        const res = await guestGetProblem(data)
        console.log('res', res)
        this.totalCount = res.count
        this.tableData = res.results
      } catch (e) {
        this.$message({
          type: 'error',
          message: e.errmsg
        })
      } finally {
        this.loading = false
      }
    },
    getStartEndTime () {
      if (this.dateRange?.length !== 2) return []
      const d1 = this.dateRange[0]
      const d2 = this.dateRange[1]
      d1.setHours(0)
      d1.setMinutes(0)
      d1.setSeconds(0)
      d1.setMilliseconds(0)
      d2.setHours(23)
      d2.setMinutes(59)
      d2.setSeconds(59)
      d2.setMilliseconds(999)
      const start = Math.floor(d1.getTime() / 1000)
      const end = Math.floor(d2.getTime() / 1000)
      return [start, end]
    },
    getProblemInfo (row, type) {
      const href = this.$router.resolve({
        name: '查看问题',
        query: { problemId: row.problemId, type: type }
      }).href
      window.open(href, '_blank')
    },
    handleCurrentChange (e) {
      this.pageNumber = e
      this.init()
    },
    handleSizeChange (e) {
      this.pageSize = e
      // this.pageNum = 1
      this.init()
    },
  },
  mounted () {
    this.init()
  },
  // computed
  computed: {
  },
  watch: {
  },
}
</script>

<style lang="less" scoped>
@import "../../assets/style/home.less";
.title {
  vertical-align: middle;
}
.text {
  cursor: default;
  pointer-events: none;
  &::before {
    content: '';
    display: block;
  }
}
.problem-describe {
  width: 100%;
  height:23px;
  overflow:hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
  &::before {
    content: '';
    display: block;
  }
}
.btn-refresh {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  cursor: pointer;
  &.loading {
    animation: refresh 1s;
  }
}
@keyframes refresh
{
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.status-num-box {
  float: right;
  margin-top: 15px;
  height: 30px;
  line-height: 28px;
  padding: 0 24px;
  background: rgba(75,121,243,0.15);
  border: 1px solid rgba(75,121,243,0.85);
  border-radius: 3px;
  display: flex;
  .status-num-item {
    margin-right: 32px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    i {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 6px;
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
    }
    .line {
      margin: 0 8px;
      color: #4b79f3;
    }
    .status-title {
      color: rgba(0,0,0,0.65);
    }
    .new-font-weight {
      font-weight: 700;
    }
  }
}
.status-icon {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 6px;
  vertical-align: middle;
  background: #2246a6;
}
.icon-red {
  background: #f26363;
}
.icon-green {
  background: #22c081;
}
.icon-blue {
  background: #4b79f3;
}
.status-num-box.new-box {
  margin-right: 32px;
}
.close-question-btn {
  float: left;
  height: 32px;
  line-height: 32px;
  padding: 0 13px;
  background: #c3c3c9;
  color: #ffffff;
  margin-top: 17px;
  border-radius: 2px;
  cursor: pointer;
}
.close-question-btn-active {
  background: #4b79f3;
}
.search {
  .search-item {
    display: inline-block;
    margin: 0 24px 8px 0;
    .custom-search {
      margin-right: 32px;
      ::v-deep(.el-select) {
        .el-input__inner {
          width: auto!important;
        }
      }
    }
    &.times {
      ::v-deep {
        .el-input__inner {
          width: 240px;
        }
        .el-range-input {
          width: 85px !important;
        }
      }
    }
  }
  .add-search {
    margin-left: -32px;
    span {
      color: #e9e9e9;
      margin: 0 8px;
    }
    i {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(../../assets/images/add-search.svg);
      vertical-align: middle;
      cursor: pointer;
    }
  }
}
.filter-select {
  ::v-deep(.el-dialog) {
    .el-dialog__body {
      padding: 20px;
      color: rgba(0,0,0,0.85);
    }
  }
}
.operation {
  background: none;
}
</style>

<style lang="less">
.is-dark {
  max-width: 400px !important;
  line-height: 20px;
  font-size: 14px;
  img {
    max-width: 100%;
  }
}
.is-message-box {
  .el-message-box__title {
    font-size: 16px;
  }
  .el-message-box__input {
    padding-top: 0;
  }
}
button {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  color: #4B79F3;
}
.el-select-dropdown {
  max-width: 400px;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item span {
  display: inline-block;
  max-width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.popover-item {
  position: relative;
  .el-dropdown{
    position: absolute;
    right: 10;
    top: 50%;
    height: 20px;
    width: 20px;
    transform: translateY(-50%);
    .el-dropdown-link{
      display: block;
      width: 100%;
      height: 100%;
      background-image: url('../../assets/images/more.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px 20px;
      cursor: pointer;
    }
  }
}
.select-box{
 .box{
   width: 100px;
   text-align: center;
   height: 28px;
   line-height: 28px;
   color: #4b79f3;
   font-size: 14px;
 }
}
.el-dialog.save-filter {
  .el-form-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .el-form-item__content {
      flex-grow: 1;
    }
  }
  .desc {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 24px;
  }
}
.problem-export-dialog {
  .title {
    font-size: 18px;
  }
  .desc {
    font-size: 16px;
    .total {
      margin: 0 5px;
      color: rgba(34,192,129,1);
      font-size: 20px;
    }
  }
  .info {
    margin-top: 16px;
    margin-bottom: 10px;
    font-size: 14px;
    color: #4b79f3;
    .file-name.ml {
      margin-left: 35px;
    }
  }
}
</style>
