
import { Options, Vue } from 'vue-class-component'
import * as labelApi from '@/api/label'
import { Label, LabelSet } from '@/api/entity'
import store from '@/store'
import { ElMessage } from 'element-plus'

@Options({
  name: 'TagEdit',
  emits: ['updateTagSet'],
  components: {
  }
})
export default class TagEdit extends Vue {
  tagSet: LabelSet = {
    id: 0,
    labelSetName: '',
    useForTicket: true,
    useForUser: true,
    useForRepository: true,
    labelSetType: ''
  }

  tagSetLabels: Array<Label> = [{
    name: ''
  }]

  dragIndex = 0
  dropIndex = -1
  scrollSpeed = 0
  scrollInterval: any = null

  get isLabelSetNameDuplicated () {
    return store.state.tagSetList.some(s => s.labelSetName === this.tagSet.labelSetName && s.id !== this.tagSet.id)
  }

  get labelNameCount () {
    const c = {}
    for (const l of this.tagSetLabels) {
      if (l.name !== '') {
        if (c[l.name]) {
          c[l.name] += 1
        } else {
          c[l.name] = 1
        }
      }
    }
    return c
  }

  dragStart (event, index) {
    const dataTransfer = event.dataTransfer
    dataTransfer.setDragImage(event.target.parentElement, 0, 0)
    this.dragIndex = index
  }

  handleDrop (event) {
    this.stopInterval()
    if (this.dragIndex > this.dropIndex) {
      const data = this.tagSetLabels[this.dragIndex]
      this.tagSetLabels.splice(this.dragIndex, 1)
      this.tagSetLabels.splice(this.dropIndex, 0, data)
    } else {
      const data = this.tagSetLabels[this.dragIndex]
      this.tagSetLabels.splice(this.dropIndex, 0, data)
      this.tagSetLabels.splice(this.dragIndex, 1)
    }
    this.dropIndex = -1
  }

  startScrollInterval () {
    if (this.scrollInterval) {
      return
    }
    this.scrollInterval = setInterval(() => {
      const refs = this.$refs as any
      const scrollRect = refs.scroll
      scrollRect.scrollTop -= this.scrollSpeed
    })
  }

  stopInterval () {
    if (this.scrollInterval) {
      clearInterval(this.scrollInterval)
      this.scrollInterval = null
    }
  }

  handleDragOver (event, index) {
    const mouseY = event.clientY
    const dom = event.target.closest('.tag-set-value-item')
    const divRect = dom.getBoundingClientRect()
    const offsetY = mouseY - divRect.top
    if (offsetY < divRect.height / 2) {
      this.dropIndex = index
    } else if (index + 1 === this.tagSetLabels.length) {
      this.dropIndex = index
    } else {
      this.dropIndex = index + 1
    }
    // 滚动屏幕
    const refs = this.$refs as any
    const scrollRect = refs.scroll.getBoundingClientRect()
    const offsetScrollTop = mouseY - scrollRect.top
    const offsetScrollBottom = scrollRect.top + scrollRect.height - mouseY
    const minGap = divRect.height / 4 > 100 ? divRect.height / 4 : 100
    if (offsetScrollTop < minGap) {
      this.scrollSpeed = (minGap - offsetScrollTop) / 2
      this.startScrollInterval()
    } else if (offsetScrollBottom < minGap) {
      this.scrollSpeed = (offsetScrollBottom - minGap) / 2
      this.startScrollInterval()
    } else {
      this.stopInterval()
    }
  }

  addInputIfNeed (value: string, index: number) {
    if (index === this.tagSetLabels.length - 1) {
      this.tagSetLabels.push({
        name: ''
      })
      const refs = this.$refs as any
      const divRect = refs.scroll.getBoundingClientRect()
      if (divRect.height < this.tagSetLabels.length * 40) {
        this.$nextTick(() => {
          refs.scroll.scrollTop = refs.scroll.scrollTop + 40
        })
      }
    } else if (index === this.tagSetLabels.length - 2) {
      if (value.length === 0) {
        this.tagSetLabels.splice(this.tagSetLabels.length - 1, 1)
      }
    }
  }

  deleteTag (index: number) {
    this.tagSetLabels.splice(index, 1)
  }

  cancel () {
    this.$router.replace({
      name: '配置标签'
    })
  }

  validateLabelsNames () {
    const c = this.labelNameCount
    return Object.keys(c).every(k => c[k] === 1)
  }

  submit () {
    if (this.tagSet.labelSetName.trim().length === 0) {
      ElMessage({
        showClose: true,
        message: '标签集名不能为空',
        type: 'warning'
      })
      return
    }
    if (this.isLabelSetNameDuplicated) {
      ElMessage({
        showClose: true,
        message: '标签集名不能重复',
        type: 'warning'
      })
      return
    }
    if (this.tagSetLabels.length <= 1) {
      ElMessage({
        showClose: true,
        message: '标签集值不能为空',
        type: 'warning'
      })
      return
    }
    if (!this.validateLabelsNames()) {
      ElMessage({
        showClose: true,
        message: '标签集值不能重复',
        type: 'warning'
      })
      return
    }
    const tagSet = {
      userId: store.getters['auth/me'].ldap,
      id: this.tagSet.id,
      labelSetName: this.tagSet.labelSetName,
      useForTicket: this.tagSet.useForTicket,
      useForUser: this.tagSet.useForUser,
      useForRepository: this.tagSet.useForRepository,
      labelSetType: this.tagSet.labelSetType,
      labels: this.tagSetLabels.filter(v => v.name.trim() !== '').map(v => ({
        name: v.name,
        extra_name: v.extraName
      }))
    }
    labelApi.saveLabelSet(tagSet).then(res => {
      ElMessage({
        showClose: true,
        message: '恭喜你，提交成功',
        type: 'success'
      })
      this.$emit('updateTagSet')
      this.$router.replace({
        name: '配置标签-编辑',
        params: {
          tagSetId: res.id
        }
      })
    }).catch(res => {
      console.log('updateLabelSet error:', res)
      ElMessage({
        showClose: true,
        message: '提交失败，请稍后再试',
        type: 'error'
      })
    })
  }

  mounted () {
    console.log(store.getters)
  }
}
