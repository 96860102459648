import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

// TODO 梳理路由名称和结构，使其风格统一，层次分明

const routes: Array<RouteRecordRaw> = [
  {
    path: '/feishu/question/create-quickly',
    name: '快捷创建问题',
    component: () => import(/* webpackChunkName: "createQuickly" */ '../views/questionList/createQuickly.vue')
  },
  {
    path: '/',
    redirect: '/home/list',
    name: '首页',
    component: () => import(/* webpackChunkName: "TheContainer" */ '@/containers/TheContainer.vue'),
    children: [
      {
        path: '/home',
        redirect: '/home/list',
        name: '问题',
        // component: () => import(/* webpackChunkName: "Home" */ '@/views/questionList/questionList.vue')
      },
      {
        path: '/home/list',
        name: '问题列表',
        component: () => import(/* webpackChunkName: "Home" */ '@/views/questionList/questionList.vue')
      },
      {
        path: '/home/panel',
        name: '问题面板',
        component: () => import(/* webpackChunkName: "QuestionPanel" */ '@/views/questionList/QuestionPanel.vue')
      },
      {
        path: '/home/createQuestion',
        name: '创建问题',
        component: () => import(/* webpackChunkName: "createQuestion" */ '@/views/questionList/createQuestion.vue')
      },
      {
        path: '/home/edit-question',
        name: '修改问题',
        component: () => import(/* webpackChunkName: "EditQuestion" */ '@/views/questionList/EditQuestion.vue')
      },
      {
        path: '/home/question-info',
        name: '问题详情',
        component: () => import(/* webpackChunkName: "EditQuestion" */ '@/views/questionList/QuestionInfo.vue')
      },
      {
        path: '/home/createTicket',
        name: '创建工单',
        component: () => import(/* webpackChunkName: "createTicket" */ '@/views/questionList/createTicket.vue')
      },
      {
        path: '/tickets/:id',
        name: '工单详情',
        component: () => import(/* webpackChunkName: "createTicket" */ '@/views/TicketDetail.vue')
      },
      {
        path: '/tickets_edit/:id',
        name: '工单时间修改',
        component: () => import(/* webpackChunkName: "createTicket" */ '@/views/TicketTimeEdit.vue')
      },
      {
        path: '/tickets',
        name: '工单视图',
        // redirect: '/tickets/claim-waiting',
        component: () => import(/* webpackChunkName: "TicketView" */ '@/containers/TicketView.vue'),
        children: [
          {
            path: 'claim-waiting',
            name: '待认领工单',
            component: () => import(/* webpackChunkName: "TicketsClaimWaiting" */ '@/views/TicketsClaimWaiting.vue')
          },
          {
            path: 'handle-waiting',
            name: '待处理工单',
            component: () => import(/* webpackChunkName: "TicketsHandleWaiting" */ '@/views/TicketsHandleWaiting.vue')
          },
          {
            path: 'doing',
            name: '进行中工单',
            component: () => import(/* webpackChunkName: "TicketsDoing" */ '@/views/TicketsDoing.vue')
          },
          {
            path: 'delayed',
            name: '已挂起工单',
            component: () => import(/* webpackChunkName: "TicketsDelayed" */ '@/views/TicketsDelayed.vue')
          },
          {
            path: 'done',
            name: '已完成工单',
            component: () => import(/* webpackChunkName: "TicketsDone" */ '@/views/TicketsDone.vue')
          },
          {
            path: 'all-claim-waiting',
            name: '所有待认领工单',
            component: () => import(/* webpackChunkName: "AllTicketsClaimWaiting" */ '@/views/AllTicketsClaimWaiting.vue')
          },
          {
            path: 'all-handle-waiting',
            name: '所有待处理工单',
            component: () => import(/* webpackChunkName: "AllTicketsHandleWaiting" */ '@/views/AllTicketsHandleWaiting.vue')
          },
          {
            path: 'all-doing',
            name: '所有进行中工单',
            component: () => import(/* webpackChunkName: "AllTicketsDoing" */ '@/views/AllTicketsDoing.vue')
          },
          {
            path: 'all-delayed',
            name: '所有已挂起工单',
            component: () => import(/* webpackChunkName: "AllTicketsDelayed" */ '@/views/AllTicketsDelayed.vue')
          },
          {
            path: 'all-done',
            name: '所有已完成工单',
            component: () => import(/* webpackChunkName: "AllTicketsDone" */ '@/views/AllTicketsDone.vue')
          },
          {
            path: 'all',
            name: '所有工单',
            component: () => import(/* webpackChunkName: "AllTickets" */ '@/views/AllTickets.vue')
          },
          {
            path: 'admin-sos',
            name: '紧急工单',
            component: () => import(/* webpackChunkName: "AllTickets" */ '@/views/AllTicketsAdminSos.vue')
          }
        ]
      },
      {
        path: '/config/template',
        name: '配置模板',
        component: () => import(/* webpackChunkName: "ConfigurationTemplate" */ '@/views/Configuration/ConfigurationTemplate.vue'),
        children: [
          {
            path: 'create',
            name: '新建模板',
            component: () => import(/* webpackChunkName: "EditTemplate" */ '@/views/Configuration/Components/EditTemplate.vue')
          },
          {
            path: 'update/:templateId',
            name: '编辑模板',
            component: () => import(/* webpackChunkName: "EditTemplate" */ '@/views/Configuration/Components/EditTemplate.vue'),
            props: true
          }
        ]
      },
      {
        path: '/config/alarm',
        name: '配置报警',
        component: () => import(/* webpackChunkName: "ConfigurationTemplate" */ '@/views/Configuration/ConfigurationAlarm.vue'),
      },
      {
        path: '/config/tag',
        name: '配置标签',
        component: () => import(/* webpackChunkName: "Tag" */ '../views/Tag.vue'),
        children: [
          {
            path: 'new',
            name: '配置标签-新建',
            component: () => import(/* webpackChunkName: "TagNew" */ '../views/TagNew.vue')
          },
          {
            path: ':tagSetId',
            name: '配置标签-编辑',
            component: () => import(/* webpackChunkName: "TagEdit" */ '../views/TagEdit.vue')
          }
        ]
      },
      {
        path: '/manage/person',
        name: '人员管理',
        component: () => import(/* webpackChunkName: "person" */ '../views/person/person.vue')
      },
      {
        path: '/manage/AllocationTags',
        name: '配置人员标签',
        component: () => import(/* webpackChunkName: "AllocationTags" */ '../views/person/AllocationTags.vue')
      },
      {
        path: '/Onduty',
        alias: '/manage/Onduty',
        name: '值班信息',
        component: () => import(/* webpackChunkName: "Onduty" */ '../views/person/Onduty.vue')
      },
      {
        path: '/manage/WithoutPerson',
        name: '外部人员',
        component: () => import(/* webpackChunkName: "Onduty" */ '../views/person/WithoutPerson.vue')
      },
      {
        path: '/manage/outsource',
        name: '外包人员',
        component: () => import(/* webpackChunkName: "Onduty" */ '../views/person/outsourcePerson.vue')
      },
      {
        path: '/dashboard',
        name: '数据面板',
        component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue')
      },
      {
        path: '/individuation',
        name: '用户自定义',
        component: () => import(/* webpackChunkName: "person" */ '../views/individuation/Individuation.vue')
      },
      {
        path: '/test/echarts',
        name: '测试echarts',
        component: () => import(/* webpackChunkName: "TestEcharts" */ '../views/test/TestEcharts.vue')
      },
      {
        path: '/:pathMatch(.*)*',
        redirect: '/home/list',
        name: '404',
      }
      // 外部用户
      // {
      //   path: 'out/home/list',
      //   name: '问题列表1',
      //   component: () => import(/* webpackChunkName: "Home" */ '@/views/outHideQuestionList/questionList.vue')
      // },
      // {
      //   path: 'out/home/createQuestion',
      //   name: '创建问题1',
      //   component: () => import(/* webpackChunkName: "createQuestion" */ '@/views/outHideQuestionList/createQuestion1.vue')
      // },
      // {
      //   path: 'out/home/questionInfos',
      //   name: '查看问题',
      //   component: () => import(/* webpackChunkName: "createQuestion" */ '@/views/outHideQuestionList/questionInfos.vue')
      // },
    ]
  },
]
const newRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/out/home/list',
    name: '首页',
    component: () => import(/* webpackChunkName: "TheContainer" */ '@/containers/TheContainer.vue'),
    children: [
      // 外部用户
      {
        path: 'out/home/list',
        name: '问题列表1',
        component: () => import(/* webpackChunkName: "Home" */ '@/views/outHideQuestionList/questionList.vue')
      },
      {
        path: 'out/home/createQuestion',
        name: '创建问题1',
        component: () => import(/* webpackChunkName: "createQuestion" */ '@/views/outHideQuestionList/createQuestion1.vue')
      },
      {
        path: 'out/home/questionInfos',
        name: '查看问题',
        component: () => import(/* webpackChunkName: "createQuestion" */ '@/views/outHideQuestionList/questionInfos.vue')
      },
      {
        path: '/:pathMatch(.*)*',
        redirect: '/out/home/list',
        name: '404',
      }
    ]
  },
  // {
  //   path: '/feishu/question/create-quickly',
  //   name: '快捷创建问题',
  //   component: () => import(/* webpackChunkName: "createQuickly" */ '../views/questionList/createQuickly.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [],
})
export default router
export { routes, newRoutes }
