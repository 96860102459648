
import nav from './_nav'
import { routes } from '@/router/index'
import { Options, Vue } from 'vue-class-component'
import store from '../store'

const userIsAdmin = store.getters['auth/isTicketAdmin']

@Options({
  name: 'TheSidebar',
  components: {
  }
})
export default class TheSidebar extends Vue {
  // datta
  routes = routes
  hoverNav = null
  baseUrl = process.env.BASE_URL
  navs = store.getters['auth/me'].thirdPartyUser ? nav[1]._children : nav[0]._children

  get userIsAdmin () {
    return userIsAdmin
  }

  showNav (nav) {
    if (nav.isAdmin) {
      return this.userIsAdmin
    }
    return true
  }

  get isWidthDone () {
    const uu = store.getters['auth/me']?.thirdPartyUser
    return uu
  }

  mounted () :void {
    // console.log(, 'kkk')
  }
}

