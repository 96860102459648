<template>
    <div class="ExportTickets">
      <div>
        <el-button type="primary" plain @click="problemExport">导出查询结果</el-button>
      </div>
      <el-dialog
        v-model="showExportDialog"
        center
        destroy-on-close
        custom-class="problem-export-dialog"
        :before-close="beforeCloseDialog"
      >
        <template #title>
          <div class="title">批量导出数据</div>
        </template>
        <div class="desc">批量导出<span class="total">{{ total }}</span>条数据，正在生成Excel表格</div>
        <div class="info"><span class="keywords">{{ keywords }}</span><span class="file-name" :class="{
          ml: keywords?.length > 0
        }">{{ exportFileName }}</span></div>
        <el-progress :percentage="exportPercentage"></el-progress>
      </el-dialog>
    </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component'
import moment from 'moment'
import { agentsWithLevelTags } from '@/api/agent'

@Options({
  name: 'ExportTickets',
  props: {
    keywords: String,
    total: Number,
    action: Function,
  }
})
export default class ExportTickets extends Vue {
  showExportDialog = false
  exportPercentage = 0
  exportFileName = ''

  animateExportPercentage () {
    this.exportPercentage = 0
    const s = Date.now()
    const len = 1000
    const move = () => {
      const p = Math.floor((Date.now() - s) / len * 100)
      if (p <= 100) {
        this.exportPercentage = p
        window.requestAnimationFrame(move)
      } else {
        this.exportPercentage = 100
      }
    }
    window.requestAnimationFrame(move)
  }

  async problemExport () {
    this.showExportDialog = true
    const name = await this.action()
    this.animateExportPercentage()
    this.exportFileName = name
  }

  beforeCloseDialog (done) {
    this.exportFileName = ''
    this.exportPercentage = 0
    done()
  }
}
</script>
<style lang="less">
.ExportTickets {
  float: left;
  margin-left: 16px;
  margin-bottom: 16px;
}
.problem-export-dialog {
  .title {
    font-size: 18px;
  }
  .desc {
    font-size: 16px;
  .total {
    margin: 0 5px;
    color: rgba(34,192,129,1);
    font-size: 20px;
  }
  }
  .info {
    margin-top: 16px;
    margin-bottom: 10px;
    font-size: 14px;
    color: #4b79f3;
    .file-name.ml {
      margin-left: 35px;
    }
  }
}
</style>
