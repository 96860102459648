
import { ticketCount } from '@/api/ticket'
import { Options, Vue } from 'vue-class-component'
import Breadcrumb from '@/components/Breadcrumb.vue'
import store from '@/store'
import { ElMessage } from 'element-plus'

const userIsAdmin = store.getters['auth/isTicketAdmin']

@Options({
  name: 'TicketView',
  components: {
    Breadcrumb
  },
  beforeRouteEnter (to, from, next) {
    // 在渲染该组件的对应路由被 confirm 前调用
    // 不！能！获取组件实例 `this`
    // 因为当守卫执行前，组件实例还没被创建
    if (to.name === '工单视图') {
      if (userIsAdmin) {
        next({
          name: '待认领工单'
        })
      } else {
        next({
          name: '待认领工单'
        })
      }
    } else {
      console.log('to:', to)
      next()
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (to.name === '工单视图') {
      let r
      if (userIsAdmin) {
        this.navTabsFlag = 2
        r = {
          name: '待认领工单',
        }
      } else {
        this.navTabsFlag = 2
        r = {
          name: '待认领工单',
        }
      }
      next(r)
      this.activeTabName = r.name
    } else {
      next()
      this.activeTabName = to.name
    }
  }
})
export default class TicketView extends Vue {
  hoverNav = ''
  refreshing = false
  navTabsFlag = 2
  activeTabName: any = ''
  optionsVisible = false

  get isAdministrator () {
    return userIsAdmin
  }

  get ticketCount () {
    return store.state.ticketCount
  }

  optionsVisibleChange (e) {
    this.optionsVisible = e
  }

  adminOnly: Array<any> = [
    '所有待认领工单',
    '所有待处理工单',
    '所有进行中工单',
    '所有已完成工单',
    '所有已挂起工单',
  ]

  /**
   * 切换导航
   * @param e
   */
  navTabsFlagChanged (e) {
    let r
    if (e === 1) {
      r = {
        name: '所有待认领工单',
      }
    } else {
      r = {
        name: '待认领工单',
      }
    }
    this.$router.replace(r)
    this.activeTabName = r.name
  }

  mounted () {
    this.loadTicketCount()
    if (this.isAdministrator) {
      this.navTabsFlag = 2
      if (this.adminOnly.includes(this.$route.name)) {
        this.navTabsFlag = 1
      }
    } else {
      this.navTabsFlag = 2
      if (this.adminOnly.includes(this.$route.name)) {
        this.$router.replace({
          name: '工单视图',
        })
        return
      }
    }
    this.activeTabName = this.$route.name
  }

  handleClickTabName (e) {
    console.log(e)
    this.$router.push({
      name: e.props.name,
    })
  }

  loadTicketCount () {
    ticketCount().then((res) => {
      console.log(res)
      store.commit('updateTicketCount', res)
    })
  }

  refresh () {
    this.refreshing = true
    const child = this.$refs.child as any
    child.loadTicketList()
    this.loadTicketCount()
    setTimeout(() => {
      this.refreshing = false
      ElMessage({
        showClose: true,
        message: '刷新成功',
        type: 'success'
      })
    }, 1000)
  }
}
