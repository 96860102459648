<template>
  <el-form-item :label="fieldDetail.options.label || fieldDetail.name" :prop="fieldDetail.options.model">
    <component
      :is="fieldDetail.components"
      v-model="value"
      :field-detail="fieldDetail"
      v-bind="fieldDetail.options"
      class="template-field"
      :disable="disable"
    />
  </el-form-item>
</template>

<script>
import TemplateFieldRenderList from '@/components/TemplateMaking/TemplateField/TemplateFieldRenderList'
export default {
  name: 'TemplateFieldRender',
  emits: ['update:modelValue'],
  components: {
    ...TemplateFieldRenderList
  },
  props: {
    fieldDetail: {
      type: Object,
      require: true
    },
    disable: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [Array, Number, String, Object]
    }
  },
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    }
  }
}
</script>

<style scoped>
  /*.template-field ::v-deep(.el-input__inner, .el-textarea__inner) {*/
  /*  width: 80%;*/
  /*}*/
</style>
