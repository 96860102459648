<template>
  <el-form
    label-width="80px"
  >
    <el-form-item label="字段标识" v-show="false">
      <el-input v-model="fieldInfoLocal.options.model"></el-input>
    </el-form-item>
    <el-form-item label="标题">
      <el-input v-model="fieldInfoLocal.options.label"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
import { deepCopy } from '@/utils/utils'

export function generateData (originData) {
  const options = {
    label: originData.name,
    model: '',
    defaultValue: ''
  }
  if (!originData.options) {
    originData.options = options
  } else {
    originData.options = Object.assign({}, options, originData.options)
  }
}

export function postTreatment (originData) {
  return originData
}

export default {
  name: 'BasicConfig',
  props: {
    fieldInfo: {
      type: Object,
      require: true
    }
  },
  emits: ['update:fieldInfo'],
  data: () => {
    return {
      internalChange: false,
      fieldInfoLocal: {}
    }
  },
  watch: {
    fieldInfo: {
      handler (val) {
        if (this.internalChange) {
          return
        }
        this.fieldInfoLocal = deepCopy(val)
      },
      deep: true,
      immediate: true
    },
    fieldInfoLocal: {
      handler (val) {
        this.internalChange = true
        this.$emit('update:fieldInfo', val)
        this.$nextTick(() => {
          this.internalChange = false
        })
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
