<template>
    <div class="chart-item" v-loading="loading">
        <div class="item-title"
        style="height: 47px; background: #fff;margin-bottom: 1px;padding: 12px">
            <p style="display: block; margin-left: 16px;">工单数量趋势</p>
        </div>
        <div id="line-chart" style="width:100%;height:350px;background: #fff;overflow-x: auto;overflow-y:hidden">
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts'
import moment from 'moment'
import { dashboard } from '@/api/dashboard'

export default {
  name: 'DashboardLine',
  props: {
    obj2: Object
  },
  data () {
    return {
      pieEndTime: '',
      pieStartTime: '',
      extre: '',
      newObj: null,
      loading: false,
    }
  },
  methods: {
    async lineChart () {
      // if (this.newObj.extreme.charAt(this.newObj.extreme.length - 1) === '%') {
      //   this.extre = this.newObj.extreme.substring(0, this.newObj.extreme.length - 1)
      // } else {
      //   this.extre = this.newObj.extreme
      // }
      if (typeof this.newObj.extreme === 'string') {
        if (this.newObj.extreme.charAt(this.newObj.extreme.length - 1) === '%') {
          this.extre = this.newObj.extreme.substring(0, this.newObj.extreme.length - 1)
        }
      } else {
        this.extre = this.newObj.extreme
      }
      let data
      try {
        data = await dashboard({
          exportData: 0,
          ticketType: this.newObj.alarmValue,
          extremum: this.extre,
          startDate: this.pieStartTime,
          endDate: this.pieEndTime,
          channel: this.newObj.channel,
          businessLine: this.newObj.product,
          level: this.newObj.level,
          negationExtremum: this.newObj.negationExtremum,
          // otherTags: this.newObj.otherTags,
          searchType: 2,
        })
      } catch (e) {
        console.error(e)
        return
      }
      const lineData = data.data.map(item => {
        return {
          name: item.product,
          type: 'line',
          data: item.counts
        }
      })
      var chartDom = document.getElementById('line-chart')
      if (!chartDom) return
      var myChart = echarts.getInstanceByDom(chartDom)
      if (myChart) {
        myChart.dispose()
      }
      myChart = echarts.init(chartDom)
      var option
      setTimeout(() => {
        option = {
          tooltip: {
            trigger: 'axis'
          },
          color: [
            '#1890FF',
            '#13C2C2',
            '#2FC25B',
            '#FACC14',
            '#F04864',
            '#8543E0',
            '#4B79F3'
          ],
          legend: {
            data: data.legend,
            icon: 'rect',
            itemWidth: 16,
            itemHeight: 10,
            left: '16px',
            top: '16px'
          },
          grid: {
            left: '3%',
            right: '8%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: data.xAxis
          },
          yAxis: {
            type: 'value'
          },
          series: lineData
        }
        myChart.setOption(option)
      })
      option && myChart.setOption(option)
      window.addEventListener('resize', function () {
        if (!myChart?.isDisposed()) {
          myChart.resize()
        }
      })
    },
    pieStartEndTime () {
      [this.pieStartTime, this.pieEndTime] = this.getStartEndDate(this.newObj.dateRange)
    },
    getStartEndDate (dateRange1, type = '') {
      if (dateRange1.length === 0) {
        return []
      }
      const [date1, date2] = dateRange1
      if (type === 'month') {
        date1.setDate(1)
        date2.setDate(1)
        date2.setMonth(date2.getMonth() + 1)
        date2.setDate(date2.getDate() - 1)
      } else if (type === 'week') {
      // 注意：此处以周一为每周起始
        if (date1.getDay() > 1) {
          date1.setDate(date1.getDate() - (date1.getDay() - 1)) // 设置成周一
        } else if (date1.getDay() === 0) {
          date1.setDate(date1.getDate() - (7 - 1)) // 设置成周一
        }
        if (date2.getDay() !== 0) {
          date2.setDate(date2.getDate() + (7 - date2.getDay())) // 设置成周日
        }
      } else {
      // nothing to do
      }
      const f = 'YYYY-MM-DD'
      return [moment(date1).format(f), moment(date2).format(f)]
    },
  },
  mounted () {
    this.loading = true
    this.$watch(function () {
      return this.obj2
    }, async function (newV, oldV) {
      this.newObj = newV
      await this.pieStartEndTime()
      await this.lineChart()
      this.loading = false
    }, {
      deep: true
    })
  }
}
</script>
