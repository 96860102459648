import { getSignature } from '@/api/auth'
import router from './router'

function closeWindow () {
  window.h5sdk.ready(function () {
    window.h5sdk.biz.navigation.close({
      onSuccess: function (result) {
        console.log(result)
      }
    })
  })
}

function h5sdkConfig () {
  return new Promise((resolve, reject) => {
    getSignature().then((res) => {
      window.h5sdk.config({
        appId: process.env.VUE_APP_ID,
        timestamp: Number(res.timestamp),
        nonceStr: res.noncestr,
        signature: res.signature,
        jsApiList: [
          'biz.user.getUserInfo',
          'device.health.getStepCount',
          'biz.user.openDetail',
          'biz.contact.open',
          'device.base.getSystemInfo',
          'biz.util.getClipboardInfo',
          'biz.util.openDocument',
          'biz.util.downloadFile',
          'device.geolocation.get',
          'device.geolocation.start',
          'device.geolocation.stop',
          'biz.user.getUserInfoEx',
          'device.connection.getNetworkType'
        ],
        onSuccess: () => {
          resolve()
        },
      })
      window.h5sdk.error(err => {
        console.error('h5sdk error: ', err)
        reject(err)
      })
    }, (err) => {
      console.error('getSignature error: ', err)
      reject(err)
    })
  })
}

export { closeWindow, h5sdkConfig }
