import axios from '@/utils/request.js'
const app = '/unicorn-ticket'

export function agentsByTag (id: number, page = 1, pageSize = 700) {
  const url = `${app}/agents/tags`
  return axios({
    url,
    method: 'get',
    params: {
      tagIds: id,
      page,
      limit: pageSize
    }
  })
}

export function agentsAll (page = 1, pageSize = 100) {
  const url = `${app}/agents/all`
  return axios({
    url,
    method: 'get',
    params: {
      page,
      limit: pageSize
    }
  })
}

export function agentsWithLevelTags (page = 1, pageSize = 100) {
  const url = `${app}/agents/level_tags`
  return axios({
    url,
    method: 'get',
    params: {
      page,
      limit: pageSize
    }
  })
}
export function getWorkflowAppointList (ticketId) {
  const url = `${app}/tickets/wf_task_assign_detail`
  return axios({
    url,
    method: 'get',
    params: {
      ticketId,
    }
  })
}

export function updateWorkflowAgent (data) {
  const url = `${app}/tickets/wf_task_assign_detail`
  return axios({
    url,
    method: 'post',
    data,
  })
}

export function getWorkflowAgent (workflowName, workflowKey) {
  const url = `${app}/agents/search_agents_for_workflow`
  return axios({
    url,
    method: 'get',
    params: {
      workflowName,
      workflowKey
    }
  })
}
