<template>
  <!-- <div style="width: 80px; height: 80px;" class="field-label-box"> -->
  <div style="width: 80px; height: 80px;" class="field-label-box" v-if="fieldInfo.name == '下拉框'">
    <div>
      <i class="el-icon-edit"></i>
      <div>{{fieldInfo.name}}</div>
      <el-tooltip class="item" effect="dark" placement="bottom">
        <i class="el-icon-info"></i>
        <template #content>
          <div style="word-break:break-all; width: 200px">
            {{fieldInfo.tips}}
          </div>
        </template>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TemplateFieldThumbnail',
  props: {
    fieldInfo: {
      type: Object,
      require: true
    }
  }
}
</script>

<style scoped>
  .field-label-box {
    border-radius: 5px;
    border: 1px #d9d9d9 solid;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
</style>
