
import { Options, Vue } from 'vue-class-component'
import * as ticketApi from '@/api/ticket'
import { ElMessage, ElLoading } from 'element-plus'
import store from '@/store'
import { dateFormat, getSearchOpsFormFormData, urgentLevelFormat, deepCopy } from '@/utils/utils'
import TicketViewSearchForm from '@/components/TicketViewSearchForm.vue'
import BatchProcess from '@/components/BatchProcess.vue'

// safari 对 es2020的模块机制支持有问题（不支持导出函数作为事件回调），故此copy一份两个函数在此
function fixElTableColumnWidth (elTableClass, index) {
  // const elTableClass = 'ticket-table'
  // const index = 3
  const w = document.querySelector(`.${elTableClass} table th:nth-child(${index})`)?.getBoundingClientRect().width
  document.querySelectorAll(`.${elTableClass} table td:nth-child(${index}) > div`).forEach((e: any) => {
    e.style.width = w! - 1 + 'px'
  })
}

function fixDescDisplay () {
  document.querySelectorAll('.ticket-desc').forEach((e: any) => {
    if (e.innerText?.length > 10) {
      e.innerHTML = e.innerText
    }
  })
}

@Options({
  name: 'TicketsHandleWaiting',
  components: {
    BatchProcess,
    TicketViewSearchForm
  },
  beforeRouteUpdate (to, from, next) {
    this.loadTicketList()
    next()
  },
})
export default class TicketsHandleWaiting extends Vue {
  loading = false
  tableData: Array<any> = []
  currentPage = 1
  pageSize = 10
  total = 0
  urgentLevel = 0
  searchFormData = {}
  showBatchProcessDialog = false
  orderField: any = {
    prop: 'urgentLevel',
    order: 'descending'
  }

  selectedTickets: Array<any> = []
  selectedTicketCurPage: Array<any> = []
  ticketsId: Array<any> = []
  selectedTicketInternalChange = false
  showMouseEnter = true
  title = '批量处理工单'
  showPhaseSelectDialog = false
  phaseList: Array<any> = []
  selectedPhase = ''
  selectedTicketId = 0

  savedQueryData: any = null

  sortChange (data) {
    if (data.order) {
      this.orderField = data
    } else {
      this.orderField = null
    }
    this.loadTicketList()
  }

  goBlankRouter (scope) {
    const href = this.$router.resolve({
      name: '工单详情',
      params: {
        id: scope.row.id
      }
    }).href
    window.open(href, '_blank')
  }

  ticketViewSearchFormSubmit (data) {
    this.searchFormData = data
    console.log('ticketViewSearchFormSubmit:', data)
    this.loadTicketList()
  }

  handleCancelClick (row) {
    if (row.curPhaseId) {
      return this.cancel(row)
    } else if (row.processablePhases?.length > 1) {
      this.showPhaseSelectDialog = true
      this.phaseList = row.processablePhases
      this.selectedPhase = ''
      this.selectedTicketId = row.id
    } else {
      this.ticketClickAssignMeWithPhaseId(row.id, row.processablePhases?.[0]?.phaseId)
    }
  }

  assignWithPhase () {
    if (!this.selectedPhase) {
      ElMessage({
        type: 'warning',
        message: '请选择一个处理节点',
      })
      return
    }
    this.showPhaseSelectDialog = false
    this.ticketClickAssignMeWithPhaseId(this.selectedTicketId, this.selectedPhase)
  }

  ticketClickAssignMeWithPhaseId (id, phaseId) {
    const me = store.getters['auth/me']
    this.loading = true
    ticketApi.ticketsAssignRedraw(id, me.ldap, me.username, 'personal', phaseId).then(() => {
      ElMessage({
        showClose: true,
        message: '取消成功',
        type: 'success'
      })
    }).catch(res => {
      console.error('取消失败：', res)
    }).finally(() => {
      this.loading = false
      this.loadTicketList()
      ticketApi.ticketCount().then((res) => {
        store.commit('updateTicketCount', res)
      })
    })
  }

  cancel (row) {
    const me = store.getters['auth/me']
    this.loading = true
    ticketApi.ticketsAssignRedraw(row.id, me.ldap, me.username, 'personal').then(res => {
      ElMessage({
        showClose: true,
        message: '取消成功',
        type: 'success'
      })
    }).catch(res => {
      console.error('取消失败:', res)
      ElMessage({
        showClose: true,
        message: '取消失败',
        type: 'error'
      })
    }).finally(() => {
      this.loading = false
      this.loadTicketList()
      ticketApi.ticketCount().then((res) => {
        store.commit('updateTicketCount', res)
      })
    })
  }

  handleSelectionChange (data) {
    if (this.selectedTicketInternalChange) {
      return
    }
    this.showMouseEnter = true
    this.selectedTicketCurPage = data
    const $refs = this.$refs as any
    const disCheck = data.filter(t => t.templateId !== data[0].templateId)
    disCheck.forEach(row => {
      $refs.ticketTable.toggleRowSelection(row)
    })
  }

  cellMouseEnter (row, column) {
    if (!this.showMouseEnter) {
      return
    }
    if (column && column.className === 'el-table-column--selection') {
      if (this.selectedTicketCurPage.length > 0) {
        if (this.selectedTicketCurPage[0]?.templateId !== row.templateId) {
          this.showMouseEnter = false
          return ElMessage({
            showClose: true,
            message: '请选择模版一致的工单，进行批量处理操作',
            type: 'warning'
          })
        } else {
          this.showMouseEnter = true
        }
      } else if (this.selectedTickets.length > 0) {
        if (this.selectedTickets[0]?.templateId !== row.templateId) {
          this.showMouseEnter = false
          return ElMessage({
            showClose: true,
            message: '请选择模版一致的工单，进行批量处理操作',
            type: 'warning'
          })
        } else {
          this.showMouseEnter = true
        }
      }
    }
  }

  selectTable (row) {
    if (!row.curPhaseId) {
      return false
    }
    if ((this.selectedTicketCurPage.length > 0) || (this.selectedTickets.length > 0)) {
      if ((this.selectedTicketCurPage[0]?.templateId === row.templateId) || (this.selectedTickets[0]?.templateId === row.templateId)) {
        return true
      } else {
        return false
      }
    }
    return true
  }

  handleSelectPersonMerge () {
    const $refs = this.$refs as any
    if (this.selectedTicketCurPage.length > 0) {
      this.selectedTicketCurPage.forEach((person) => {
        if (this.selectedTickets.find(v => v.id === person.id) !== undefined) {
          return
        }
        this.selectedTickets.push(deepCopy(person))
      })
    }
    // 清空数组
    this.selectedTicketCurPage = []
    this.selectedTickets = this.selectedTickets.filter((person) => {
      const res = this.tableData.find(v => v.id === person.id)
      if (res) {
        this.selectedTicketCurPage.push(res)
        this.$nextTick(() => {
          $refs.ticketTable.toggleRowSelection(res)
        })
        return false
      }
      return true
    })
    this.selectedTicketInternalChange = false
  }

  showBatchProcess () {
    const selectedList = [...this.selectedTickets, ...this.selectedTicketCurPage]
    this.ticketsId = selectedList.map(t => {
      return t.id
    })
    this.showBatchProcessDialog = true
  }

  solvedBatchProcess (ruleFormData) {
    if (!ruleFormData.comment) {
      ElMessage({
        showClose: true,
        message: '问题回复不可为空',
        type: 'warning'
      })
    } else {
      const loadingInstance = ElLoading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading'
      })
      const data = {
        ticketList: this.ticketsId,
        curStatus: 'closed',
        transaction: ruleFormData,
      }
      ticketApi.ticketBatchDeal(data).then((res) => {
        ElMessage({
          showClose: true,
          message: '批量处理已完成',
          type: 'success'
        })
        this.selectedTickets = []
        this.selectedTicketCurPage = []
        this.showBatchProcessDialog = false
      }, (err) => {
        console.error('批量处理-解决:', err)
        ElMessage({
          showClose: true,
          message: err.errmsg,
          type: 'error'
        })
        this.selectedTickets = []
        this.selectedTicketCurPage = []
        this.showBatchProcessDialog = false
      }).finally(() => {
        this.loadTicketList()
        loadingInstance.close()
        ticketApi.ticketCount().then((res) => {
          console.log(res)
          store.commit('updateTicketCount', res)
        })
      })
    }
  }

  proceedBatchProcess (ruleFormData) {
    if (!ruleFormData.comment || !ruleFormData.nextPhaseId) {
      ElMessage({
        showClose: true,
        message: '下一阶段和问题回复不可为空',
        type: 'warning'
      })
    } else {
      const loadingInstance = ElLoading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading'
      })
      const data = {
        ticketList: this.ticketsId,
        curStatus: 'pending',
        transaction: ruleFormData,
      }
      ticketApi.ticketBatchDeal(data).then((res) => {
        ElMessage({
          showClose: true,
          message: '批量处理已完成',
          type: 'success'
        })
        this.selectedTickets = []
        this.selectedTicketCurPage = []
        this.showBatchProcessDialog = false
      }, (err) => {
        console.error('批量处理-继续:', err)
        ElMessage({
          showClose: true,
          message: err.errmsg,
          type: 'error'
        })
        this.selectedTickets = []
        this.selectedTicketCurPage = []
        this.showBatchProcessDialog = false
      }).finally(() => {
        this.loadTicketList()
        loadingInstance.close()
        ticketApi.ticketCount().then((res) => {
          console.log(res)
          store.commit('updateTicketCount', res)
        })
      })
    }
  }

  delayedTickets (ruleFormData) {
    if (!ruleFormData.comment) {
      return ElMessage({
        showClose: true,
        message: '问题回复不可为空',
        type: 'warning'
      })
    }
    const loadingInstance = ElLoading.service({
      lock: true,
      text: '批量挂起中...',
      spinner: 'el-icon-loading'
    })
    const data = {
      ticketList: this.ticketsId,
      curStatus: 'suspend',
      transaction: ruleFormData,
    }
    ticketApi.ticketBatchSuspend(data).then((res) => {
      this.selectedTickets = []
      this.selectedTicketCurPage = []
      this.showBatchProcessDialog = false
      ElMessage({
        showClose: true,
        message: '批量挂起成功',
        type: 'success'
      })
    }, (err) => {
      ElMessage({
        showClose: true,
        message: err.errmsg,
        type: 'error'
      })
      console.error('批量挂起:', err)
    }).finally(() => {
      this.loadTicketList()
      loadingInstance.close()
      ticketApi.ticketCount().then((res) => {
        console.log(res)
        store.commit('updateTicketCount', res)
      })
    })
  }

  cancelBatchProcess () {
    this.showBatchProcessDialog = false
  }

  onSubmit (e) {
    console.log(e)
  }

  priorityFormatter (row, column) {
    return urgentLevelFormat(row.urgentLevel)
  }

  createTimeFormatter (row, column) {
    return dateFormat(row.createdAt)
  }

  created () {
    this.savedQueryData = localStorage.getItem('__ticket_TicketsHandleWaiting_query_criteria')
  }

  mounted () {
    if (this.$route.query.pageSize) {
      this.pageSize = Number(this.$route.query.pageSize)
    }
    if (this.$route.query.page) {
      this.currentPage = Number(this.$route.query.page)
    }
    if (this.savedQueryData) {
      const q = JSON.parse(this.savedQueryData)
      this.searchFormData = q
      if (q.orderField) {
        this.orderField = q.orderField
      }
    }
    this.loadTicketList()
    window.addEventListener('resize', () => {
      fixElTableColumnWidth('ticket-table', 3)
    })
  }

  loadTicketList () {
    const searchOps = getSearchOpsFormFormData(this.searchFormData)
    if (this.orderField?.order) {
      searchOps.orderField = this.orderField
    }
    this.loading = true
    ticketApi.ticketList(ticketApi.ViewType.PENDING, this.currentPage, this.pageSize, searchOps).then(res => {
      this.tableData.splice(0)
      this.tableData.push(...res.results)
      this.total = res.count
      this.handleSelectPersonMerge()
    }).catch(res => {
      console.log(res)
    }).finally(() => {
      this.loading = false
      // fix safari表头错位
      setTimeout(() => {
        fixElTableColumnWidth('ticket-table', 3)
        fixDescDisplay()
      }, 0)
      this.saveCriteria(searchOps)
    })
  }

  saveCriteria (data) {
    const q = { ...data }
    console.log('TicketsHandleWaiting saveCriteria=', q)
    localStorage.setItem('__ticket_TicketsHandleWaiting_query_criteria', JSON.stringify(q))
  }

  handleCurrentChange (e) {
    console.log('handleCurrentChange', e)
    this.selectedTicketInternalChange = true
    this.showMouseEnter = true
    this.currentPage = e
    this.$router.push({
      name: this.$route.name!,
      query: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
    })
  }

  handleSizeChange (e) {
    console.log('handleSizeChange', e)
    this.selectedTicketInternalChange = true
    this.showMouseEnter = true
    this.pageSize = e
    this.$router.push({
      name: this.$route.name!,
      query: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
    })
  }
}
