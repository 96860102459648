<template>
  <div style="background:white">
    <div class="individuation-box">
    <el-form :model="form" label-width="200px">
      <el-form-item label="问题回复显示方式">
        <div class="reply-box">
        <el-radio-group v-model="form.replyType" @change="changeReplyType">
          <el-radio :label=1 size="large">
            最底部回复模式
            <div class="reply-image">
              <img src="./images/reply-1.jpg" />
            </div>
          </el-radio>
          <el-radio :label=2 size="large">
            展开收起粘住模式
            <div class="reply-image">
              <img src="./images/reply-2.jpg" />
            </div>
          </el-radio>
        </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item>
      <el-button type="primary" @click="saveFrom">
        保存
      </el-button>
    </el-form-item>
    </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {}
    }
  },
  mounted () {
    const form = localStorage.getItem('individuation')
    if (form) {
      this.form = JSON.parse(form)
    }
  },
  methods: {
    changeReplyType () {
      localStorage.setItem('individuation', JSON.stringify(this.form))
    },
    saveFrom () {
      this.$message({
        type: 'success',
        message: '保存成功'
      })
    }
  }
}
</script>

<style lang="less">
.individuation-box{
  margin: 20px;
}
.reply-box{
  margin-top: 20px;
  .reply-image{
    margin-top: 10px;
    img{
      height: 300px
    }
  }
}

</style>
